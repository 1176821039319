import {
  Button,
  Dropdown,
  Flex,
  Icon,
  Input,
  Popup,
  Switch,
} from 'elements_v2';
import React, { useEffect, useReducer, useState } from 'react';
import List from 'elements_v2/List';
import { randomString } from 'utils';

export function generateFontFaceStyleSheet(font, generateUrl, customId) {
  if (!font?._id) {
    return null;
  }
  const isCdnEnabled = font?.data?.fontFamily?.cdnEnabled;
  const fontFace = Object.keys(font?.data?.value || {}).map((value) => {
    const fontId = font.data?.value[value];
    const fontFile = font.data?.files?.find((file) => file.id === fontId);
    const parts = value.split('-');
    const weight = parts?.[0];
    const isItalic = !!parts?.[1];
    if (!fontFile) {
      return null;
    }
    return `
      @font-face {
        font-family: "${font.name}${customId ? `-${customId}` : ''}";
        src: url(${
          !isCdnEnabled
            ? generateUrl(fontFile.url)
            : `https://embed.pickit.com/c-${font.communitySlug}-fonts-${font._id}/${fontFile.id}`
        });
        font-weight: ${weight};
        font-style: ${isItalic ? 'italic' : 'normal'};
      }
    `;
  });
  return `
    ${fontFace.join(' ')}
  `;
}

export const allFontWeights = [
  { value: '100', text: 'Thin' },
  { value: '200', text: 'Extra Light' },
  { value: '300', text: 'Light' },
  { value: '400', text: 'Regular' },
  { value: '500', text: 'Medium' },
  { value: '600', text: 'Semi Bold' },
  { value: '700', text: 'Bold' },
  { value: '800', text: 'Extra Bold' },
  { value: '900', text: 'Black' },
  { value: '950', text: 'Extra Black' },
  { value: '100-italic', text: 'Thin (Italic)' },
  { value: '200-italic', text: 'Extra Light (Italic)' },
  { value: '300-italic', text: 'Light (Italic)' },
  { value: '400-italic', text: 'Regular (Italic)' },
  { value: '500-italic', text: 'Medium (Italic)' },
  { value: '600-italic', text: 'Semi Bold (Italic)' },
  { value: '700-italic', text: 'Bold (Italic)' },
  { value: '800-italic', text: 'Extra Bold (Italic)' },
  { value: '900-italic', text: 'Black (Italic)' },
  { value: '950-italic', text: 'Extra Black (Italic)' },
];

export function getFontWeights(isItalics, selectedFonts) {
  return allFontWeights
    .sort((a, b) => {
      return a.value - b.value;
    })
    .map((row) => ({
      ...row,
      style: selectedFonts?.includes(row.value) ? { opacity: 0.35 } : {},
    }));
}

export default function FontForm(props) {
  const [files, setFiles] = useReducer((state, action) => [...action], []);
  const [fonts, setFonts] = useReducer(
    (state, action) => ({
      ...state,
      ...action,
    }),
    {}
  );
  const [isItalics, setIsItalics] = useState(false);
  const [name, setName] = useState('');
  const [status, setStatus] = useState(false);
  const [isCdn, setIsCdn] = useState(
    props.metadata?.data?.fontFamily?.cdnEnabled
  );

  useEffect(() => {
    if (!props.isOpen) {
      setFonts({});
      setFiles([]);
      setName('');
      setStatus(false);
      setIsItalics(false);
    }
  }, [props.isOpen]);

  const weights = getFontWeights(
    isItalics,
    Object.keys(fonts)?.filter((font) => !!fonts[font])
  );

  const weightNames = [
    {
      value: '100',
      match: ['thin', 'hairline'],
    },
    {
      value: '200',
      match: [
        'extralight',
        'extra-light',
        'extra light',
        'ultra light',
        'ultralight',
        'ultra-light',
      ],
    },
    {
      value: '300',
      match: ['light'],
    },
    {
      value: '400',
      match: ['normal', 'regular'],
    },
    {
      value: '500',
      match: ['medium'],
    },
    {
      value: '600',
      match: ['semi-bold', 'semibold'],
    },
    {
      value: '800',
      match: ['extra-bold', 'extrabold'],
    },
    {
      value: '700',
      match: ['bold'],
    },
    {
      value: '950',
      match: ['extra-black', 'extrablack', 'ultra-black', 'ultrablack'],
    },
    {
      value: '900',
      match: ['black', 'heavy'],
    },
  ];

  useEffect(() => {
    if (props.metadata) {
      setFonts(props.metadata.data?.value);
      setFiles(props.metadata.data.files);
      setName(props.metadata.name);
      setIsItalics(
        !!Object.keys(props.metadata?.data?.value || {})?.find((row) =>
          row.includes('italic')
        )
      );
    }
  }, [props.metadata]);

  useEffect(() => {
    if (files?.length && !props.metadata?._id) {
      files.forEach((file) => {
        let checkForItalics = isItalics;
        const fileName = file.name.toLowerCase();
        if (fileName.includes('italic') && !isItalics) {
          setIsItalics(true);
          checkForItalics = true;
        }
        const value = weightNames.reduce((result, row) => {
          const val = row.value;
          if (result) {
            return result;
          }
          const foundName = row.match.find((name) => {
            return fileName.includes(name);
          });
          if (foundName) {
            if (checkForItalics && fileName.includes('italic')) {
              return `${val}-italic`;
            }
            return val;
          }
        }, null);
        if (value) {
          setFonts({
            [value]: file,
          });
        }
      });
    }
  }, [files, isItalics]);

  const isEditingExisting = !!props.metadata?._id;

  return (
    <Popup
      isOpen={props.isOpen}
      width={750}
      title={isEditingExisting ? 'Edit font family' : 'Add font family'}
      actions={
        <>
          <Button
            inline
            style={{ marginRight: 'auto' }}
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button
            inline
            primary
            loading={status}
            disabled={!name || !files?.length}
            onClick={async () => {
              // if (!value) {
              //   return false;
              // }
              if (props.metadata?._id) {
                const font = await props.context.saveDocument({
                  data: {
                    ...props.metadata.data,
                    value: fonts,
                    fontFamily: {
                      cdnEnabled: isCdn,
                    },
                  },
                  _id: props.metadata?._id,
                  libraries: props.libraries,
                  name,
                });
                if (props.onSave) {
                  props.onSave(font);
                }
                props.onClose();
                return false;
              }
              setStatus('Uploading fonts...');
              const filesMap = {};
              let font = await props.context.saveDocument({
                data: {
                  type: 'font-family',
                  value: filesMap,
                  fontFamily: {
                    cdnEnabled: isCdn,
                  },
                },
                folders: props.toFolder,
                libraries: props.libraries,
                name,
                categories: ['font'],
              });
              const files = await Promise.all(
                Object.keys(fonts)
                  .filter((key) => !!fonts[key])
                  .map(async (fontValue) => {
                    const file = fonts[fontValue];
                    file.id = randomString(8);
                    filesMap[fontValue] = file.id;
                    const controller = new AbortController();
                    const uploadedFile = await props.context.uploadFile(
                      file,
                      'brand',
                      {
                        onProgress: () => {},
                        abort: controller,
                        onFinish: () => {},
                        onError: () => {},
                      },
                      {
                        returnResults: true,
                        document_id: font._id,
                      }
                    );
                    return uploadedFile;
                  })
              );
              setStatus('Creating font family...');
              font = await props.context.saveDocument({
                _id: font._id,
                data: {
                  type: 'font-family',
                  value: filesMap,
                  files,
                  fontFamily: {
                    cdnEnabled: isCdn,
                  },
                },
                folders: props.toFolder,
                libraries: props.libraries,
                name,
                categories: ['font'],
              });
              if (props.onSave) {
                props.onSave(font);
              }
              // setIsSaving(true);
              // const color = await props.context.saveDocument({
              //   data,
              //   name: name || value,
              //   categories: ['color'],
              //   _id: props.metadata?._id,
              //   folders: props.toFolder,
              //   file: {
              //     ext: 'color',
              //   },
              // });
              // if (props.onSave) {
              //   props.onSave(color);
              // }
              props.onClose();
            }}
          >
            <Icon name="Type" />{' '}
            {props.metadata?._id ? 'Save changes' : 'Add font family'}
          </Button>
        </>
      }
      onClose={props.onClose}
    >
      <Input.Field
        inline
        label="Name of the font"
        maxlength={35}
        value={name}
        onChange={(val) => {
          setName(val);
        }}
      />
      <Input.Field
        inline
        label="Boost performance"
        style={{ marginTop: 15 }}
        description="Font will load faster in your guides or boards. The download URL for the font will be publicly available."
        maxlength={35}
        custom={
          <Switch
            active={isCdn}
            label="Enable CDN"
            onChange={(val) => setIsCdn(val)}
          />
        }
      />
      {!props.metadata?._id && (
        <Input.Field
          onChange={(files) => {
            setFiles(files);
          }}
          accept={['font/ttf', 'font/otf']}
          label="Upload font files"
          style={{ marginTop: 15 }}
          type="file"
          multiple
        />
      )}
      <Input.Field
        label="Map weights to correct font"
        style={{ marginTop: 15 }}
        custom={
          <List compact>
            {files
              .sort((a, b) => {
                const keyA = Object.keys(fonts).find(
                  (key) => fonts[key] === a.id
                );
                const keyB = Object.keys(fonts).find(
                  (key) => fonts[key] === b.id
                );
                return keyA - keyB;
              })
              .map((file) => (
                <List.Item
                  key={file.name}
                  title={file.name}
                  icon={
                    !(isEditingExisting
                      ? Object.keys(fonts).find((f) => fonts[f] === file.id)
                      : Object.keys(fonts).find(
                          (f) => fonts[f]?.name === file.name
                        ) || undefined) ? (
                      <Icon name="ExclamationTriangleFill" />
                    ) : (
                      ''
                    )
                  }
                  actions={
                    <Dropdown
                      position="bottom-end"
                      noClear
                      value={
                        isEditingExisting
                          ? Object.keys(fonts).find((f) => fonts[f] === file.id)
                          : Object.keys(fonts).find(
                              (f) => fonts[f]?.name === file.name
                            ) || undefined
                      }
                      options={weights}
                      onChange={(value) => {
                        const oldValues = Object.keys(fonts).reduce(
                          (result, value) => {
                            if (fonts[value] === file.id && isEditingExisting) {
                              result[value] = null;
                            } else if (
                              fonts[value]?.name === file?.name &&
                              !isEditingExisting
                            ) {
                              result[value] = null;
                            }
                            return result;
                          },
                          {}
                        );
                        setFonts({
                          [value]: isEditingExisting ? file.id : file,
                          ...oldValues,
                        });
                      }}
                    />
                  }
                />
              ))}
          </List>
        }
      />
    </Popup>
  );
}
