import React, { cloneElement, useCallback, useMemo, useRef } from 'react';
import './style.scss';
import { useMediaContext, useTextContext, useUserContext } from 'hooks';
import useUserStatus from 'hooks/useUserStatus';
import FileThumbnail, { FileThumbnailV2 } from 'components/FileThumbnail';
import { PinkToolbar } from 'elements_v2/PinkToolbar';
import { useDraggable } from '@dnd-kit/core';
import { mergeRefs, randomString } from 'utils/utils';
import { Flex, Icon, Icon as PixiIcon, Tooltip } from '../../elements_v2';

const DocumentCollectionImage = React.memo((props) => {
  const imageRef = useRef();
  if (React.isValidElement(props.thumbnail)) {
    return (
      <div className="DocumentCollection__image" ref={imageRef}>
        {props.thumbnail}
        {!!props.toolbar && (
          <PinkToolbar parentRef={imageRef}>{props.toolbar}</PinkToolbar>
        )}
      </div>
    );
  }
  return (
    <>
      <div
        className="DocumentCollection__image"
        ref={imageRef}
        style={{ backgroundImage: `url(${props.thumbnail})` }}
      >
        {props.type?.includes('video') && (
          <video muted autoPlay="autoplay" loop playsinline>
            <source src={props.thumbnail} mimetype={props.type} />
          </video>
        )}
        {!!props.toolbar && (
          <PinkToolbar parentRef={imageRef}>{props.toolbar}</PinkToolbar>
        )}
      </div>
    </>
  );
});

const DocumentCollection = React.forwardRef((props, ref) => {
  const Text = useTextContext();
  const User = useUserContext();
  const userStatus = useUserStatus();
  const contentRef = useRef();
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `${props.collection?._id}`,
    data: !props.draggable
      ? undefined
      : {
          type: 'collection',
        },
  });

  const mergedRefs = mergeRefs(
    props.disableDragging ? [ref] : [ref, setNodeRef],
  );

  const className = ['DocumentCollection'];
  if (props.className) {
    className.push(props.className);
  }
  if (props.createNewPlaceholder) {
    className.push('DocumentCollection--createNewPlaceholder');
  }
  if (props.ignoreResponsive) {
    className.push('DocumentCollection--ignoreResponsive');
  }
  if (props.onClick && !props.disabled && !props.isRearrange) {
    className.push('DocumentCollection--clickable');
  }
  if (props.createNewPlaceholder) {
    className.push('DocumentCollection--createNewPlaceholder');
  }
  if (props.hero && !props.noHero) {
    className.push('DocumentCollection--hero');
  }
  if (props.isRearrange) {
    className.push('DocumentCollection--wobble');
  }
  if (props.small) {
    className.push('DocumentCollection--small');
  }
  if (props.titleStyle) {
    className.push(`DocumentCollection--style-${props.titleStyle}`);
  }
  if (props.active) {
    className.push('DocumentCollection--active');
  }

  if (props.isDragging) {
    className.push('DocumentCollection--isDragging');
  }
  const toolbar = props.toolbar && (
    <PinkToolbar parentRef={contentRef}>
      {props.toolbar?.map((item) => (
        <Tooltip
          key={item.tooltip}
          position="bottom"
          message={item.tooltip}
          small
          delay={800}
        >
          <PixiIcon
            button
            bubble
            name={item.icon}
            style={item.iconStyle}
            onClick={(event) => item.onClick(event, props.collection)}
          />
        </Tooltip>
      ))}
    </PinkToolbar>
  );

  return (
    <div
      ref={mergedRefs}
      className={className.join(' ')}
      {...(!props.disableDragging ? attributes : {})}
      {...(!props.disableDragging ? listeners : {})}
      style={{
        ...(props.style || {}),
      }}
    >
      <div className="DocumentCollection__wrapper" ref={contentRef}>
        {props.overlay}
        <PinkToolbar parentRef={contentRef} {...(props.contextMenuProps || {})}>
          {props.contextMenu}
        </PinkToolbar>
        <div
          className="DocumentCollection__wrapper__content brand__color__border__before"
          onClick={props.onClick}
          ref={setNodeRef}
        >
          {props.createNewPlaceholder && (
            <div className="DocumentCollection__wrapper__content__overlay">
              {props.overlayContent}
            </div>
          )}

          {props.isNew && (
            <div className="newBadge" title={Text.get('ui/new')} />
          )}
          {props.isUpdated && !props.isNew && (
            <div className="updatedBadge" title={Text.get('ui/updated')} />
          )}
          {toolbar}
          <DocumentCollectionImage
            type={props.type}
            thumbnail={
              <>
                {props.withArrows && (
                  <>
                    <div
                      className="DocumentCollection__action DocumentCollection__action--left"
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        props.onLeftArrowClick();
                      }}
                    >
                      <Icon name="ChevronLeft" />
                    </div>
                    <div
                      className="DocumentCollection__action DocumentCollection__action--right"
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        props.onRightArrowClick();
                      }}
                    >
                      <Icon name="ChevronRight" />
                    </div>
                  </>
                )}
                {typeof props.thumbnail === 'string' ? (
                  <img src={props.thumbnail} alt="Placeholder" />
                ) : (
                  props.thumbnail ||
                  (props.context && (
                    <FileThumbnailV2
                      cover
                      file={
                        props.collection.thumbnail?.id
                          ? props.collection.thumbnail
                          : props.collection.thumbnail_media?.file ||
                            (props.collection.thumbnail_original
                              ?.contentType === 'image/gif'
                              ? props.collection.thumbnail_original
                              : props.collection.thumbnail)
                      }
                      noIcon
                      ignoreFileSize
                      forceFile={!!props.collection?.thumbnail?.id}
                      media={!!props.collection.thumbnail_media?.file}
                      context={props.context}
                    />
                  ))
                )}
              </>
            }
            overlay={props.overlay}
          />
          {!props.collection?.thumbnail?.id &&
            !props.collection?.thumbnail_media?._id &&
            props.noThumbnailView && (
              <div className="DocumentCollection__wrapper__noThumbnailView">
                {props.noThumbnailView}
              </div>
            )}
          {props.icon ? (
            <div className="DocumentCollection__icon">
              <Tooltip message={props.icon.label}>
                <Icon name={props.icon.name} />
              </Tooltip>
            </div>
          ) : (
            ''
          )}
          {!!props.collection?.name &&
            (!props.collection?.hide_name || props.forceName) && (
              <div className="DocumentCollection__name">
                <h1 className="no_global_style">{props.collection?.name}</h1>
              </div>
            )}
          {!userStatus.product.isBusinessAdmin &&
            props.collection?.libraryType &&
            props.collection?.permissions?.contributor_users?.some(
              (obj) =>
                obj?.email === User.djangoProfile?.email ||
                obj.user_id === User.djangoProfile?.id,
            ) && (
              <div
                className="DocumentCollection__author-icon brand__color__bg"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  User.openInWeb(
                    `/manage/${props.collection?.libraryType}?library-collection=${props.collection._id}`,
                    true,
                  );
                }}
              >
                <Tooltip message="The admin has given you access to upload to this collection">
                  <Flex>
                    <PixiIcon
                      name="CloudArrowUpFill"
                      key="preview_collection"
                    />
                  </Flex>
                </Tooltip>
              </div>
            )}
        </div>
      </div>
    </div>
  );
});

export default DocumentCollection;
