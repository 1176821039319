import {
  ActionIcon,
  Badge,
  Group,
  Highlight,
  Pill,
  PillsInput,
  PillsInputProps,
  ThemeIcon,
} from '@mantine/core';
import PixiIcon from '@pixi/elements/Icon';
import { useAssetGridContext } from '../AssetGridContext';
import { useState } from 'react';
import PixiDropdown from '@pixi/elements/Dropdown';
import { useUserContext } from 'hooks';
import PixiButton from '@pixi/elements/Button';

export default function AssetGridSearch({ ...rest }: Partial<PillsInputProps>) {
  const { setFilter, activeFilters, availableFilters } = useAssetGridContext();
  const User = useUserContext();
  const isActive = !!activeFilters.$query?.length;
  const [searchValue, setSearchValue] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const [isSelectingKeywords, setIsSelectingKeywords] = useState(false);

  const keywordsFilter = availableFilters?.tags;
  const keywordsFilterExists = !!keywordsFilter?.data?.length;

  const isGlobalTagsEnabled =
    User.data?.selectedCommunity?.settings?.preferences
      ?.enableGlobalTagsSearch &&
    User.data?.selectedCommunity?.communityTags?.length;
  const isTagsEnabled =
    User.data?.selectedCommunity?.settings?.preferences?.enableTagsSearch &&
    keywordsFilterExists;

  return (
    <PixiDropdown
      width={360}
      height={340}
      disabled={!isTagsEnabled && !isGlobalTagsEnabled}
      boxProps={{
        onMouseEnter: () => {
          setIsSelectingKeywords(true);
        },
        onMouseLeave: () => {
          setIsSelectingKeywords(false);
        },
      }}
      closeOnItemClick
      offset={0}
      target={
        <PillsInput
          leftSection={!isActive && <PixiIcon name="magnifying-glass" />}
          variant="glass"
          {...rest}
        >
          <Pill.Group>
            {activeFilters.$query?.map((val) => (
              <Pill
                key={val.value as string}
                withRemoveButton
                color="dark"
                variant="contrast"
                onRemove={() => {
                  const newQuery = activeFilters.$query?.filter(
                    (tag) => tag.value !== val.value,
                  );
                  if (!newQuery.length) {
                    setFilter('$query', null);
                    return;
                  }
                  setFilter('$query', newQuery, {
                    replace: true,
                  });
                }}
              >
                {val.value as string}
              </Pill>
            )) || []}
            <PillsInput.Field
              placeholder="Search"
              onChange={(event) => {
                setSearchValue(event.currentTarget.value);
              }}
              mih={31}
              onFocus={() => setIsFocused(true)}
              onBlur={() => {
                setIsFocused(false);
                // if (!searchValue) {
                //   return;
                // }
                // setFilter(
                //   '$query',
                //   [...(activeFilters.$query || []), { value: searchValue }],
                //   {
                //     replace: true,
                //   },
                // );
                // setSearchValue('');
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  if (!searchValue) {
                    return;
                  }
                  setFilter(
                    '$query',
                    [...(activeFilters.$query || []), { value: searchValue }],
                    {
                      replace: true,
                    },
                  );
                  setSearchValue('');
                }
                if (
                  event.key === 'Backspace' &&
                  activeFilters.$query?.length &&
                  !event.currentTarget?.value
                ) {
                  // pop and remove last item in activeFitlers.$query
                  const newQuery = activeFilters.$query?.slice(0, -1);
                  setFilter('$query', newQuery, {
                    replace: true,
                  });
                }
              }}
              value={searchValue}
            />
          </Pill.Group>
        </PillsInput>
      }
    >
      {isGlobalTagsEnabled ? (
        <>
          {User?.data?.selectedCommunity?.communityTags
            ?.filter((tag: string) => {
              return tag
                .toLowerCase()
                .includes(
                  searchValue.toLowerCase().trim().replace(/\s+/g, ' '),
                );
            })
            ?.sort((a: string, b: string) => {
              const aKeywordFilter =
                availableFilters?.tags?.data?.find(
                  (r) =>
                    (r.value as string)?.toLowerCase() === a?.toLowerCase(),
                )?.count || 0;
              const bKeywordFilter =
                availableFilters?.tags?.data?.find(
                  (r) =>
                    (r.value as string)?.toLowerCase() === b?.toLowerCase(),
                )?.count || 0;
              return bKeywordFilter - aKeywordFilter;
            })
            .map((tag: string) => {
              const keywordFilter = availableFilters?.tags?.data?.find(
                (r) =>
                  (r.value as string)?.toLowerCase() === tag?.toLowerCase(),
              );
              return (
                <PixiDropdown.Item
                  key={tag}
                  onClick={() => {
                    setFilter(
                      '$query',
                      [...(activeFilters.$query || []), { value: tag }],
                      {
                        replace: true,
                      },
                    );
                  }}
                  leftSection={
                    keywordFilter?.count && !searchValue ? (
                      <Badge size="xs" variant="filled" color="gray">
                        {keywordFilter?.count}
                      </Badge>
                    ) : undefined
                  }
                >
                  <Highlight highlight={searchValue}>{tag}</Highlight>
                </PixiDropdown.Item>
              );
            })}
        </>
      ) : (
        <Group gap="5" p="sm">
          {keywordsFilter?.data
            ?.sort((a, b) => (b.count as number) - (a.count as number))
            .filter(
              (f) => !activeFilters.$query?.some((q) => q.value === f.value),
            )
            .filter((f) => {
              return f.value
                ?.toString()
                ?.toLowerCase()
                .startsWith(searchValue?.toLowerCase());
            })
            .map((f) => (
              <PixiButton
                key={f.value}
                size="xs"
                radius="xl"
                color="dark"
                variant="light"
                onClick={() => {
                  setFilter(
                    '$query',
                    [...(activeFilters.$query || []), { value: f.value }],
                    {
                      replace: true,
                    },
                  );
                }}
                leftSection={
                  f?.count ? (
                    <Badge size="xs" variant="filled" color="gray">
                      {f?.count}
                    </Badge>
                  ) : undefined
                }
              >
                {f.value}
              </PixiButton>
              // <PixiDropdown.Item
              //   key={f.value}
              //   onClick={() => {
              //     setFilter(
              //       '$query',
              //       [...(activeFilters.$query || []), { value: f.value }],
              //       {
              //         replace: true,
              //       },
              //     );
              //   }}
              //   leftSection={
              //     <Badge color="gray" variant="light" size="xs">
              //       {f.count}
              //     </Badge>
              //   }
              // >
              //   {f.value}
              // </PixiDropdown.Item>
            ))
            .slice(0, 20)}
        </Group>
      )}
    </PixiDropdown>
  );
}
