import {
  ActionIcon,
  Box,
  Card,
  Group,
  Notification,
  Stack,
  Text,
  ThemeIcon,
  Transition,
} from '@mantine/core';
import PixiIcon from '@pixi/elements/Icon';
import PixiText from '@pixi/elements/Text';
import {
  DataStoresList,
  getStore,
  useDataStoreCallback,
  useDataStoreListener,
} from '@pixi/store';
import { useEffect, useReducer } from 'react';

export default function AppToasts() {
  const toasts = useDataStoreCallback('APP_TOASTS', (data) => data);

  return (
    <Box pos="fixed" bottom={0} right={0} style={{ zIndex: 999 }} p="xl">
      <Stack>
        {toasts?.map((toast) => {
          return (
            <Transition
              key={toast.id}
              mounted={!!toast.visible}
              transition="pop-bottom-right"
              duration={400}
              timingFunction="ease"
            >
              {(style) => (
                <Card
                  style={style}
                  miw={300}
                  maw={500}
                  withBorder
                  p="lg"
                  radius="xl"
                >
                  <Group w="100%" gap="lg" align="center" wrap="nowrap">
                    <ThemeIcon
                      radius="xl"
                      color="gray"
                      variant="light"
                      size="lg"
                    >
                      <PixiIcon name={toast.icon || 'bell'} />
                    </ThemeIcon>
                    <Stack w="100%" gap="5">
                      {toast.title && (
                        <PixiText size="md">{toast.title}</PixiText>
                      )}
                      {toast.message && (
                        <PixiText
                          size="md"
                          c={toast.title ? 'dimmed' : undefined}
                        >
                          {toast.message}
                        </PixiText>
                      )}
                    </Stack>
                    <ActionIcon
                      onClick={() => {
                        getStore('APP_TOASTS').removeByKey(toast.id);
                      }}
                      color="dark"
                      variant="subtle"
                    >
                      <PixiIcon name="x" />
                    </ActionIcon>
                  </Group>
                </Card>
              )}
            </Transition>
          );
        })}
      </Stack>
    </Box>
  );
}
