import PixiIcon from '@pixi/elements/Icon';
import PixiPopup from '@pixi/elements/Popup';
import PixiTabs from '@pixi/elements/Tabs';
import { useEventContext } from 'contexts/Providers/EventProvider';
import { ToastService } from 'elements';
import { useUserContext } from 'hooks';
import useContextFromType from 'hooks/useContextFromType';
import useUserStatus from 'hooks/useUserStatus';
import { useEffect, useState } from 'react';
import { Face } from 'views/_Manage/Media/parts/Attributes';
import ApprovalFlows from '../pages/Approvals/parts/ApprovalFlows';
import {
  Badge,
  Box,
  Checkbox,
  Group,
  InputWrapper,
  NumberInput,
  Paper,
  Switch as PixiSwitch,
  Stack,
  Tabs,
  TagsInput,
  TextInput,
} from '@mantine/core';
import Field from '@pixi/elements/Field';
import PixiForm from '@pixi/elements/Form';
import PixiText from '@pixi/elements/Text';
import Sortable from '@pixi/elements/Sortable';
import PixiButton from '@pixi/elements/Button';
import PixiConfirm from '@pixi/elements/Confirm';
import PresetForm from '@pixi/components/AssetQuickForms/PresetForm';
import PixiTooltip from '@pixi/elements/Tooltip';
import { truncate } from 'utils';

export default function LibrarySettings({
  isOpen,
  libraryId,
  onClose,
  defaultSection,
}: {
  isOpen?: boolean;
  libraryId?: string;
  onClose?: () => void;
  defaultSection?: string;
}) {
  return (
    <PixiPopup
      title="Library settings"
      size={1000}
      opened={isOpen ?? false}
      fullHeight
      onClose={() => onClose?.()}
      bodyProps={{ p: 'lg' }}
      zIndex={10}
    >
      {libraryId && (
        <LibrarySettingsRender
          libraryId={libraryId}
          defaultTab={defaultSection}
        />
      )}
    </PixiPopup>
  );
}

function LibrarySettingsRender({
  libraryId,
  defaultTab,
}: {
  libraryId?: string;
  defaultTab?: string;
}) {
  const context = useContextFromType(libraryId);
  const [isSavingFilter, setIsSavingFilter] = useState<string | null>(null);
  const [filters, setFilters] = useState<any>([]);
  const [currentTab, setCurrentTab] = useState(defaultTab || '');
  const [goToSection, setGoToSection] = useState('');
  const Event = useEventContext();
  const User = useUserContext();
  const userStatus = useUserStatus();
  const CommunitySettingsFilters =
    User.data?.selectedCommunity?.settings?.filters?.find(
      (l: any) => l.library === context.type,
    )?.columns;

  useEffect(() => {
    setCurrentTab(defaultTab || '');
  }, [defaultTab]);

  const imageEditorPresets = (User.data?.selectedCommunity?.settings
    ?.imageEditorPresets ||
    []) as Pickit.CommunityInterface['settings']['imageEditorPresets'];
  const feedback = User.data.selectedCommunity.settings?.preferences
    ?.feedback ?? [{ library: 'media' }, { library: 'documents' }];

  const disableImageEditEndUser = User.data.selectedCommunity.libraries.find(
    (library: any) => library.nameId === 'media',
  ).preferences.disableImageEditEndUser
    ? User.data.selectedCommunity.libraries.find(
        (library: any) => library.nameId === 'media',
      ).preferences.disableImageEditEndUser
    : false;

  const feedbackPrefs = feedback?.find(
    (prefs: any) => prefs.library === context.type,
  );

  const library = User.data?.selectedCommunity?.libraries?.find(
    (l: any) => l.nameId === context.type,
  );
  const allFilters: Pickit.LibraryFilter['row']['view'][] =
    User.data?.filters?.all;
  const allFiltersNotActive = allFilters?.filter(
    (f) =>
      !library?.preferences?.filters?.enabled?.find(
        (ff: any) => ff.id === f.field,
      ),
  );

  useEffect(() => {
    const filters = library?.preferences?.filters;
    const enabledFilters =
      filters?.enabled?.filter(
        (f: any) => !!allFilters?.find((ff) => ff.field === f.id),
      ) || [];
    const orderedKeys = library?.preferences?.filters?.order || [];
    setFilters(() => [
      ...orderedKeys.map((key: string) =>
        enabledFilters.find((f: any) => f.id === key),
      ),
      ...enabledFilters.filter((f: any) => !orderedKeys.includes(f.id)),
    ]);
  }, [User.data?.selectedCommunity?.libraries]);

  return (
    <>
      <PixiTabs
        value={currentTab}
        onTabChange={(tabId) => {
          if (tabId) {
            setCurrentTab(tabId);
          }
        }}
      >
        <PixiTabs.Panel id="preferences" label="Preferences" icon="sliders">
          <Stack>
            <Paper p="lg" radius="md" withBorder>
              <InputWrapper description="End-users will be able to use global tags for searching">
                <PixiButton
                  style={{ display: 'block' }}
                  onClick={async () => {
                    await User.saveSettings({
                      preferences: {
                        enableGlobalTagsSearch:
                          !User.data.selectedCommunity.settings?.preferences
                            ?.enableGlobalTagsSearch,
                        enableTagsSearch: false,
                      },
                    });
                  }}
                  leftSection={
                    <PixiIcon
                      name={
                        !User.data.selectedCommunity.settings?.preferences
                          ?.enableGlobalTagsSearch
                          ? 'square'
                          : 'square-check'
                      }
                      variant={
                        User.data.selectedCommunity.settings?.preferences
                          ?.enableGlobalTagsSearch
                          ? 'filled'
                          : undefined
                      }
                    />
                  }
                  variant={
                    User.data.selectedCommunity.settings?.preferences
                      ?.enableGlobalTagsSearch
                      ? 'filled'
                      : 'light'
                  }
                >
                  Enable global tags on search
                </PixiButton>
              </InputWrapper>
            </Paper>
            {context.type === 'media' && (
              <>
                <Paper p="lg" withBorder>
                  <InputWrapper
                    description={
                      <>
                        In addition to the files metadata, we use our Vision AI
                        to tag your images.
                        <br />
                        You can disable this if you want only the image's
                        metadata to be added.
                      </>
                    }
                  >
                    <PixiButton
                      style={{ display: 'block' }}
                      onClick={async () => {
                        await User.saveSettings({
                          preferences: {
                            disableAutoTag:
                              !User.data.selectedCommunity.settings?.preferences
                                ?.disableAutoTag,
                          },
                        });
                      }}
                      leftSection={
                        <PixiIcon
                          name={
                            !User.data.selectedCommunity.settings?.preferences
                              ?.disableAutoTag
                              ? 'square'
                              : 'square-check'
                          }
                          variant={
                            User.data.selectedCommunity.settings?.preferences
                              ?.disableAutoTag
                              ? 'filled'
                              : undefined
                          }
                        />
                      }
                      variant={
                        User.data.selectedCommunity.settings?.preferences
                          ?.disableAutoTag
                          ? 'filled'
                          : 'light'
                      }
                    >
                      Disable auto-tagging
                    </PixiButton>
                  </InputWrapper>
                </Paper>
              </>
            )}
            {context.type === 'documents' && (
              <>
                <Paper p="lg" withBorder>
                  <InputWrapper
                    description={
                      <>
                        The Quickslides feature allow users to view and download
                        slides individually when previewing a PowerPoint file.
                        <br />
                        This setting can be overriden on a file per file basis.
                      </>
                    }
                  >
                    <PixiButton
                      style={{ display: 'block' }}
                      onClick={async () => {
                        await User.saveSettings({
                          preferences: {
                            enableQuickSlidePreview:
                              !User.data.selectedCommunity.settings?.preferences
                                ?.enableQuickSlidePreview,
                          },
                        });
                      }}
                      leftSection={
                        <PixiIcon
                          name={
                            !User.data.selectedCommunity.settings?.preferences
                              ?.enableQuickSlidePreview
                              ? 'square'
                              : 'square-check'
                          }
                          variant={
                            User.data.selectedCommunity.settings?.preferences
                              ?.enableQuickSlidePreview
                              ? 'filled'
                              : undefined
                          }
                        />
                      }
                      variant={
                        User.data.selectedCommunity.settings?.preferences
                          ?.enableQuickSlidePreview
                          ? 'filled'
                          : 'light'
                      }
                    >
                      Enable quickslides
                    </PixiButton>
                  </InputWrapper>
                </Paper>
              </>
            )}
            <Paper p="lg" withBorder>
              <InputWrapper
                description={
                  <>
                    Gives all users the ability to leave feedback on files in
                    this collection
                  </>
                }
              >
                <Group>
                  <PixiButton
                    style={{ display: 'block' }}
                    onClick={async () => {
                      await User.saveSettings({
                        preferences: {
                          feedback: feedback.map((oldFeedback: any) => {
                            if (oldFeedback.library === context.type) {
                              return {
                                ...oldFeedback,
                                allowFileFeedback:
                                  !feedbackPrefs?.allowFileFeedback,
                              };
                            }
                            return oldFeedback;
                          }),
                        },
                      });
                      ToastService.createToast({
                        message: 'Saved',
                      });
                    }}
                    leftSection={
                      <PixiIcon
                        name={
                          !feedbackPrefs?.allowFileFeedback
                            ? 'square'
                            : 'square-check'
                        }
                        variant={
                          feedbackPrefs?.allowFileFeedback
                            ? 'filled'
                            : undefined
                        }
                      />
                    }
                    variant={
                      feedbackPrefs?.allowFileFeedback ? 'filled' : 'light'
                    }
                  >
                    Allow user feedback on files
                  </PixiButton>
                  {feedbackPrefs?.allowFileFeedback && (
                    <PixiButton
                      style={{ display: 'block' }}
                      onClick={async () => {
                        await User.saveSettings({
                          preferences: {
                            feedback: feedback.map((oldFeedback: any) => {
                              if (oldFeedback.library === context.type) {
                                return {
                                  ...oldFeedback,
                                  allowFeedbackFromExternal:
                                    !feedbackPrefs?.allowFeedbackFromExternal,
                                };
                              }
                              return oldFeedback;
                            }),
                          },
                        });
                        ToastService.createToast({
                          message: 'Saved',
                        });
                      }}
                      leftSection={
                        <PixiIcon
                          name={
                            !feedbackPrefs?.allowFeedbackFromExternal
                              ? 'square'
                              : 'square-check'
                          }
                          variant={
                            feedbackPrefs?.allowFeedbackFromExternal
                              ? 'filled'
                              : undefined
                          }
                        />
                      }
                      variant={
                        feedbackPrefs?.allowFeedbackFromExternal
                          ? 'filled'
                          : 'light'
                      }
                    >
                      Allow for external users
                    </PixiButton>
                  )}
                </Group>
              </InputWrapper>
            </Paper>
          </Stack>
        </PixiTabs.Panel>
        <PixiTabs.Panel
          id="filters"
          label="Filters"
          icon="filter"
          w="100%"
          style={{ overflow: 'hidden' }}
        >
          <Tabs defaultValue="active_filters" variant="pills" color="gray">
            <Tabs.List mb="md">
              <Tabs.Tab value="active_filters">Active filters</Tabs.Tab>
              <Tabs.Tab
                value="inactive_filters"
                rightSection={
                  <Badge color="dark">{allFiltersNotActive.length}</Badge>
                }
              >
                Inactive filters
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="active_filters">
              <Stack gap="xs">
                <Sortable
                  initialData={filters.filter(
                    (f: Pickit.LibraryFilterDataRow) => {
                      return !!f;
                    },
                  )}
                  onRearrange={(newOrder) => {
                    setFilters(() => [...newOrder]);
                    User.saveLibrary(library._id, {
                      'preferences.filters.order': newOrder.map(
                        (f: any) => f.id,
                      ),
                    });
                  }}
                  render={({ dataRow, props, isDragging }) => {
                    const data = dataRow?.data;
                    const view = allFilters?.find((f) => f.field === data.id);
                    if (!view) {
                      return <></>;
                    }
                    return (
                      <Paper
                        p="lg"
                        bg="gray.1"
                        key={data.id}
                        {...props}
                        shadow={isDragging ? 'md' : undefined}
                        style={{
                          cursor: isDragging ? 'grabbing' : 'grab',
                          zIndex: isDragging ? 9999 : undefined,
                          ...props.style,
                        }}
                      >
                        <Stack>
                          <Group justify="space-between">
                            <Group gap="xs" wrap="nowrap">
                              <PixiIcon name={view.icon} />
                              <PixiText>
                                {truncate(view.name, 50, '...')}
                              </PixiText>
                              <PixiText c="dimmed">{view.description}</PixiText>
                            </Group>
                            <Group>
                              <PixiTooltip label="Inactivate filter">
                                <PixiButton
                                  px="xs"
                                  size="xs"
                                  color="primary"
                                  variant="light"
                                  onClick={async () => {
                                    await User.saveLibrary(library._id, {
                                      $pull: {
                                        'preferences.filters.enabled': {
                                          _id: data._id,
                                        },
                                        'preferences.filters.order': data.id,
                                      },
                                    });
                                  }}
                                >
                                  <PixiIcon name="xmark" />
                                </PixiButton>
                              </PixiTooltip>
                            </Group>
                          </Group>
                          <Group w="100%">
                            <PixiButton
                              size="xs"
                              variant={data.onlyAdmins ? 'light' : 'subtle'}
                              color={data.onlyAdmins ? 'primary' : 'dark'}
                              leftSection={
                                <PixiIcon
                                  size="xl"
                                  name={
                                    data.onlyAdmins
                                      ? 'toggle-large-on'
                                      : 'toggle-large-off'
                                  }
                                />
                              }
                              onClick={async () => {
                                await User.saveLibrary(
                                  library._id,
                                  {
                                    'preferences.filters.enabled.$[filter].onlyAdmins':
                                      !data.onlyAdmins,
                                  },
                                  [{ 'filter._id': data._id }],
                                );
                              }}
                            >
                              Only admins
                            </PixiButton>
                          </Group>
                        </Stack>
                      </Paper>
                    );
                  }}
                />
              </Stack>
            </Tabs.Panel>

            <Tabs.Panel value="inactive_filters">
              <Stack gap="xs">
                {allFiltersNotActive.map((view) => {
                  if (!view) {
                    return <></>;
                  }
                  return (
                    <Paper p="lg" bg="gray.1" withBorder key={view.field}>
                      <Stack>
                        <Group gap="xs">
                          <PixiIcon name={view.icon} />
                          <PixiText>{view.name}</PixiText>
                          <PixiText c="dimmed">{view.description}</PixiText>
                        </Group>
                        <Group w="100%">
                          <PixiButton
                            onClick={async () => {
                              await User.saveLibrary(library._id, {
                                $push: {
                                  'preferences.filters.enabled': {
                                    id: view.field,
                                    type: view.field.includes('attributes')
                                      ? 'attributes'
                                      : 'standard',
                                  },
                                },
                              });
                            }}
                          >
                            Activate filter
                          </PixiButton>
                        </Group>
                      </Stack>
                    </Paper>
                  );
                })}
              </Stack>
            </Tabs.Panel>
          </Tabs>
        </PixiTabs.Panel>
        <PixiTabs.Panel
          id="approval_flows"
          label="Approval workflows"
          icon="clipboard-check"
          hidden
        >
          <h1>Approval workflows</h1>
          <p>
            Set up a workflow for admins to approve files before they are
            published.
          </p>
          <ApprovalFlows />
        </PixiTabs.Panel>
        <PixiTabs.Panel
          id="downloads"
          label="Downloads"
          icon="arrow-down-to-line"
        >
          <Stack>
            {context.type !== 'documents' && (
              <Paper
                p="lg"
                radius="md"
                withBorder
                style={{ overflow: 'hidden' }}
              >
                <InputWrapper label="Custom cropping formats">
                  <Stack gap="5">
                    <Sortable
                      initialData={imageEditorPresets || []}
                      onRearrange={async (data) => {
                        await User.saveSettingsPartial({
                          imageEditorPresets: data,
                        });
                      }}
                      render={({ dataRow, props }) => {
                        const c = dataRow.data;
                        return (
                          <Paper
                            key={c._id}
                            p="xs"
                            pl="lg"
                            radius="md"
                            bg="gray.1"
                            style={{ cursor: 'grab' }}
                            {...props}
                          >
                            <Group>
                              <PixiIcon name="crop" />
                              <PixiText w="30%" size="sm" fw="500">
                                {c.name}
                              </PixiText>
                              <PixiText size="sm" fw="500" ml="xl">
                                {c.width}x{c.height}
                              </PixiText>
                              <Group gap="5" ml="auto">
                                <PresetForm
                                  data={c}
                                  target={
                                    <PixiButton
                                      size="xs"
                                      px="xs"
                                      variant="light"
                                      color="dark"
                                    >
                                      <PixiIcon name="pencil" />
                                    </PixiButton>
                                  }
                                />
                                <PixiConfirm
                                  title="Delete format"
                                  description="Are you sure? This can't be reverted"
                                  confirmLabel="Delete format"
                                  confirmProps={{
                                    color: 'red',
                                  }}
                                  onConfirm={async () => {
                                    await User.saveSettingsPartial({
                                      $pull: {
                                        imageEditorPresets: {
                                          _id: c._id,
                                        },
                                      },
                                    });
                                  }}
                                >
                                  <PixiButton
                                    size="xs"
                                    px="xs"
                                    color="red"
                                    variant="light"
                                  >
                                    <PixiIcon name="trash-can" />
                                  </PixiButton>
                                </PixiConfirm>
                              </Group>
                            </Group>
                          </Paper>
                        );
                      }}
                    />
                  </Stack>
                </InputWrapper>
                <Box mt="xs">
                  <PresetForm
                    target={
                      <PixiButton leftSection={<PixiIcon name="plus" />}>
                        Create format
                      </PixiButton>
                    }
                  />
                </Box>
              </Paper>
            )}
            {library?.nameId === 'media' && (
              <Paper p="lg" withBorder>
                <InputWrapper
                  label="Image editor"
                  description={
                    <>
                      By default, users can create variants and crop images.
                      Disabling the Image Editor removes this option for the
                      user.
                    </>
                  }
                >
                  <Group>
                    <PixiButton
                      style={{ display: 'block' }}
                      onClick={async () => {
                        await User.saveLibrary(library._id, {
                          'preferences.disableImageEditEndUser':
                            !disableImageEditEndUser,
                        });
                        ToastService.createToast({
                          message: 'Saved',
                        });
                      }}
                      leftSection={
                        <PixiIcon
                          name={
                            !disableImageEditEndUser ? 'square' : 'square-check'
                          }
                          variant={
                            disableImageEditEndUser ? 'filled' : undefined
                          }
                        />
                      }
                      variant={disableImageEditEndUser ? 'filled' : 'light'}
                    >
                      Disable image editor for users
                    </PixiButton>
                  </Group>
                </InputWrapper>
              </Paper>
            )}
            <Paper p="lg" withBorder>
              <InputWrapper
                label="Vector files"
                description={<>Enable additional download options</>}
              >
                <PixiButton
                  style={{ display: 'block' }}
                  onClick={async () => {
                    await User.saveSettings({
                      preferences: {
                        disableVectorDownloadRestriction:
                          !User.data.selectedCommunity.settings?.preferences
                            ?.disableVectorDownloadRestriction,
                      },
                    });
                  }}
                  leftSection={
                    <PixiIcon
                      name={
                        !User.data.selectedCommunity.settings?.preferences
                          ?.disableVectorDownloadRestriction
                          ? 'square'
                          : 'square-check'
                      }
                      variant={
                        User.data.selectedCommunity.settings?.preferences
                          ?.disableVectorDownloadRestriction
                          ? 'filled'
                          : undefined
                      }
                    />
                  }
                  variant={
                    User.data.selectedCommunity.settings?.preferences
                      ?.disableVectorDownloadRestriction
                      ? 'filled'
                      : 'light'
                  }
                >
                  Enable PNG
                </PixiButton>
              </InputWrapper>
            </Paper>
          </Stack>
        </PixiTabs.Panel>
        <PixiTabs.Panel
          id="facial_recognition"
          label="Facial recognition"
          icon="face-viewfinder"
          hidden={
            !(
              userStatus.activeFeatures?.pickit_vision_ai &&
              context.type === 'media'
            )
          }
        >
          <Face />
        </PixiTabs.Panel>
      </PixiTabs>
    </>
  );
}
