import { DjangoIdToName, useDjangoIdToEmail } from 'components/DjangoIdToName';
import { useAssetEditPanel } from '../../context';
import { useUserContext } from 'hooks';
import { Card, Group, Stack } from '@mantine/core';
import PixiText from '@pixi/elements/Text';
import { formatDistanceToNow } from 'date-fns';
import PixiTooltip from '@pixi/elements/Tooltip';
import PixiButton from '@pixi/elements/Button';
import PixiIcon from '@pixi/elements/Icon';
import { SectionCollapse } from '../../elements';

function FeedbackCard({
  feedback,
}: {
  feedback: Pickit.FileInterfaceFeedback;
}) {
  const { context, file } = useAssetEditPanel();
  const email = useDjangoIdToEmail(feedback?.createdBy);
  const User = useUserContext();

  return (
    <>
      <Card withBorder radius="sm" key={feedback.createdBy}>
        <Stack gap="xs">
          <PixiText size="sm">{feedback.message}</PixiText>
          <Group w="100%" justify="space-between">
            <PixiText size="xs" c="dimmed">
              <DjangoIdToName value={feedback.createdBy} />{' '}
            </PixiText>
            <PixiText size="xs" c="dimmed">
              {feedback.updatedAt &&
                formatDistanceToNow(new Date(feedback.updatedAt))}{' '}
              ago
            </PixiText>
          </Group>
          <Group wrap="nowrap" gap="5">
            <PixiTooltip label="Reply">
              <PixiButton
                component="a"
                href={`mailto:${email}`}
                color="dark"
                variant="light"
                style={{ flexShrink: 0 }}
                px="xs"
              >
                <PixiIcon name="envelope" />
              </PixiButton>
            </PixiTooltip>
            {!feedback.viewedBy?.length && (
              <PixiButton
                fullWidth
                size="xs"
                onClick={async () => {
                  await context.savePartial(
                    {
                      _id: file._id,
                      $push: {
                        'file.feedback.$.viewedBy': User.djangoProfile.id,
                      },
                    },
                    {
                      'file.feedback._id': feedback._id,
                    },
                  );
                }}
              >
                Mark as reviewed
              </PixiButton>
            )}
          </Group>
        </Stack>
      </Card>
    </>
  );
}

export default function AssetEditPanelFeedback() {
  const { file, focus } = useAssetEditPanel();
  return (
    <SectionCollapse label={<>Feedback</>} defaultOpen={focus === 'feedback'}>
      {file?.file?.feedback?.map((feedback) => (
        <FeedbackCard key={feedback._id} feedback={feedback} />
      ))}
    </SectionCollapse>
  );
}
