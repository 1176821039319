import {
  Anchor,
  Badge,
  Box,
  Checkbox,
  Chip,
  Divider,
  Group,
  Paper,
  Stack,
  Switch,
  TagsInput,
  Textarea,
  TextInput,
} from '@mantine/core';
import PixiIcon, { PixiIconName } from '@pixi/elements/Icon';
import { useAssetEditPanel } from '../../context';
import { Detail, SectionCollapse } from '../../elements';
import { format } from 'date-fns';
import { useUserContext } from 'hooks';
import PropertyValueForm from 'views/_Manage/Library/workflows/Properties/PropertyValueForm';
import PropertyValue from 'views/_Manage/Library/workflows/Properties/PropertyValue';

export default function AssetEditPanelProperties() {
  const User = useUserContext();
  const { file, files, context } = useAssetEditPanel();

  const libraries = (User.data?.selectedCommunity?.libraries ||
    []) as Pickit.CommunityLibraryInterface[];
  const properties = (User.data?.selectedCommunity?.properties?.data ||
    []) as Pickit.CommunityPropertyInterface[];
  const librariesInFile = files?.length
    ? files?.reduce((acc: string[], file) => {
        if (!file.libraries?.length) {
          file.libraries = [context.type];
        }
        return [...new Set([...acc, ...(file.libraries || [])])];
      }, [])
    : !file?.libraries?.length
      ? [context.type]
      : file?.libraries || [context.type];
  const activeLibraries = librariesInFile.map((libraryNameId: string) =>
    libraries.find((l) => l.nameId === libraryNameId),
  );
  const activeProperties = activeLibraries
    ?.reduce((acc: string[], library) => {
      return [...acc, ...(library?.preferences.properties || [])];
    }, [])
    ?.filter((p) => !!properties.find((l) => l._id === p))
    .map((p) => {
      return properties.find((l) => l._id === p);
    }) as Pickit.CommunityPropertyInterface[];
  return (
    <>
      <SectionCollapse label="Properties">
        {activeProperties.map((p) => {
          const value =
            file.properties?.[p._id as keyof typeof file.properties];

          // if (!value) {
          //   return <></>;
          // }

          return (
            <Detail
              key={p.updatedAt}
              left={p.name}
              right={
                !value ? <></> : <PropertyValue property={p} value={value} />
              }
              actions={[
                {
                  label: 'edit',
                  icon: 'pen',
                  wrapper: (target) => (
                    <PropertyValueForm
                      key={p.updatedAt}
                      onConfirm={async (data) => {
                        if (files?.length) {
                          await context.saveDocumentsWhere(
                            {
                              _id: { $in: files.map((file) => file._id) },
                            },
                            {
                              [`properties.${p._id}`]: data,
                            },
                          );
                          return;
                        }
                        await context.savePartial({
                          _id: file._id,
                          [`properties.${p._id}`]: data,
                        });
                      }}
                      property={p}
                      value={
                        file.properties?.[
                          p._id as keyof typeof file.properties
                        ] || {}
                      }
                      target={target}
                    />
                  ),
                },
              ]}
            />
          );
        })}
      </SectionCollapse>
    </>
  );
}
