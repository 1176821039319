import {
  ActionIcon,
  Box,
  BoxProps,
  Button,
  Grid,
  GridProps,
  Group,
  GroupProps,
  MantineColor,
  NavLink,
  Stack,
  StackProps,
  ThemeIcon,
  Transition,
  useMantineTheme,
} from '@mantine/core';
import * as React from 'react';
import PixiDropdown from '../Dropdown';
import PixiIcon, { PixiIconName } from '../Icon';

interface TabsProps extends Omit<BoxProps, 'children'> {
  children: React.ReactElement | React.ReactElement[];
  onTabChange?: (activeTabId?: string) => void;
  value?: string;
  actions?: (props: {
    next: () => void;
    isNextDisabled: boolean;
    previous: () => void;
    isPreviousDisabled: boolean;
    goTo: (tabId: string) => void;
  }) => React.ReactNode;
  panelStyle?: React.CSSProperties;
  horizontal?: boolean;
  panelCompleted?: Record<string, boolean>;
  menuProps?: BoxProps;
  style?: React.CSSProperties;
}
interface TabsPanelProps {
  label: string;
  disabled?: boolean;
  children: React.ReactNode;
  icon?: PixiIconName;
  isActive?: boolean;
  isCompleted?: boolean;
  id: string;
  actions?: React.ReactNode;
  color?: MantineColor;
  rightSection?: React.ReactNode;
  hidden?: boolean;
  keepMounted?: boolean;
}

const PixiTabs = ({
  children,
  onTabChange,
  value,
  actions,
  panelStyle,
  panelCompleted,
  horizontal,
  menuProps,
  style,
  ...rest
}: TabsProps) => {
  const panels = Array.isArray(children) ? children : [children];
  children = panels.filter((child) => !child.props?.hidden);
  const [active, setActive] = React.useState(value);

  React.useEffect(() => {
    setActive(value);
  }, [value]);

  React.useEffect(() => {
    onTabChange?.(active);
  }, [active]);

  const menuLinks = children.map((panel, key) => (
    <Button
      key={panel.key}
      leftSection={
        <>
          {panel.props?.isCompleted ? (
            <>
              <PixiIcon name="square-check" variant="filled" />
            </>
          ) : (
            <>
              {panel.props?.icon ? (
                <PixiIcon name={panel.props?.icon} size="lg" />
              ) : (
                ''
              )}
            </>
          )}
        </>
      }
      rightSection={
        panel.props?.rightSection ||
        (!!panelCompleted &&
        typeof panelCompleted?.[panel.props?.id] === 'boolean' ? (
          <>
            <PixiIcon
              name={
                panelCompleted?.[panel.props?.id]
                  ? 'square-check'
                  : 'square-xmark'
              }
              style={{ marginLeft: 'auto' }}
              color={panelCompleted?.[panel.props?.id] ? 'green' : 'red'}
              size="xl"
              variant="filled"
            />
          </>
        ) : undefined)
      }
      color={
        panel.props?.isCompleted || !!panel.props?.color
          ? panel.props?.color
          : 'dark'
      }
      style={{
        ...(panel.props?.disabled
          ? {
              background: 'transparent',
              opacity: 0.25,
              pointerEvents: 'none',
            }
          : {}),
      }}
      justify="flex-start"
      variant={panel?.props?.id === active ? 'light' : 'subtle'}
      // active={key === active}
      size="sm"
      mih={46}
      onClick={() => {
        setActive(panel.props?.id);
      }}
    >
      {panel.props?.label}
    </Button>
  ));

  const panel = children.map((child, key) => (
    <>
      {React.cloneElement(child, {
        isActive: child.props?.id === active,
      })}
    </>
  ));

  return (
    <Box
      w="100%"
      h="100%"
      style={{
        overflow: 'auto',
        ...(style || {}),
      }}
      p="sm"
      pos="relative"
      {...rest}
    >
      {horizontal ? (
        <Stack align="flex-start" pos="sticky" top={0}>
          <Box w="100%" {...(menuProps || {})} pos="sticky" top={0}>
            <Group w="100%" wrap="nowrap" gap={4}>
              {menuLinks}
            </Group>
          </Box>
          <Box w="100%">
            <Stack w="100%" gap={4} style={panelStyle}>
              {panel}
            </Stack>
          </Box>
        </Stack>
      ) : (
        <Group
          w="100%"
          h="100%"
          mih={1}
          wrap="nowrap"
          gap="md"
          align="flex-start"
        >
          <Box
            w="100%"
            h="100%"
            maw={280}
            style={{ flexShrink: 0 }}
            bg="gray.0"
            p="md"
            {...(menuProps || {})}
          >
            <Stack w="100%" gap={4}>
              {menuLinks}
            </Stack>
          </Box>
          <Box w="100%" h="100%" mih={1}>
            <Stack
              w="100%"
              h="100%"
              gap={4}
              style={{
                ...panelStyle,
                overflow: 'auto',
              }}
            >
              {panel}
            </Stack>
          </Box>
        </Group>
      )}
      {actions?.({
        next: () => {
          const currentPanel = panels?.findIndex(
            (panel) => panel.props.id === active,
          );
          if (currentPanel < panels?.length - 1) {
            setActive(panels?.[currentPanel + 1]?.props?.id);
          }
        },
        isNextDisabled:
          panels?.findIndex((panel) => panel.props.id === active) ===
          children.length - 1,
        previous: () => {
          const currentPanel = panels?.findIndex(
            (panel) => panel.props.id === active,
          );
          if (panels?.[currentPanel - 1]) {
            setActive(panels?.[currentPanel - 1]?.props?.id);
          }
        },
        isPreviousDisabled:
          panels?.findIndex((panel) => panel.props.id === active) === 0,
        goTo: (tabId: string) => setActive(tabId),
      })}
    </Box>
  );
};

const Panel = ({
  children,
  isActive,
  id,
  keepMounted,
  actions,
  ...rest
}: TabsPanelProps & BoxProps) => {
  if (!keepMounted && !isActive) {
    return <></>;
  }
  return (
    <Box
      {...rest}
      style={{
        width: '100%',
        zIndex: 5,
        display: !isActive ? 'none' : 'block',
        ...(rest.style || {}),
      }}
      id={id}
    >
      {children}
      {actions}
    </Box>
  );
};

PixiTabs.Panel = Panel;

export default PixiTabs;
