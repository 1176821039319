import { useState } from 'react';
import { useFlowsContext } from 'hooks';
import { Button, Confirm, Icon, List } from 'elements_v2';
import { FlowData, InterfaceFlowState } from 'services/api/Flows';
import EditFlow from './EditFlow';

type Props = {};

function ApprovalFlows(props: Props) {
  const Flows = useFlowsContext();
  const [editingFlow, setEditingFlow] = useState<FlowData | undefined>(
    undefined,
  );
  const [isOpen, setIsOpen] = useState(false);
  const editFlow = (flow: FlowData) => {
    setIsOpen(true);
    setEditingFlow(flow);
  };

  return (
    <>
      <EditFlow
        isOpen={isOpen}
        editingFlow={editingFlow}
        onClose={() => {
          setEditingFlow(undefined);
          setIsOpen(false);
        }}
      />
      <div style={{ width: 'fit-content', marginBottom: 10 }}>
        <Button pixi primary onClick={() => setIsOpen(true)}>
          <Icon name="plus-lg" />
          Create workflow
        </Button>
      </div>
      <List cards>
        {Flows?.flows.map((flow) => (
          <ListItemWrapper
            title={flow?.name}
            subtitle={`Steps in the workflow ${flow.steps.length}`}
            field={`Files currently in the workflow: ${
              flow.state
                ?.filter((state) => {
                  const latestChange = state.changes[state.changes.length - 1];
                  if (
                    latestChange?.action === 'rejected' ||
                    latestChange?.action === 'approved'
                  ) {
                    return false;
                  }
                  return true;
                })
                ?.reduce(
                  (
                    newChanges: InterfaceFlowState[],
                    nextState: InterfaceFlowState,
                  ) => {
                    if (
                      !newChanges.find(
                        (change) => change?.file === nextState?.file,
                      )
                    ) {
                      newChanges.push(nextState);
                    }
                    return newChanges;
                  },
                  [],
                )?.length ?? 0
            }`}
            actions={
              <>
                <Button
                  onClick={() => editFlow(flow)}
                  disabled={flow.isDefault}
                >
                  <Icon name="pen" style={{ margin: 0 }} />
                </Button>
                <Confirm
                  position="bottom-center"
                  title="Are you sure?"
                  text="This can't be reverted"
                  confirm="Delete"
                  onConfirm={() => {
                    if (flow._id) {
                      Flows.deleteFlow(flow._id);
                    }
                  }}
                >
                  <Button disabled={flow.isDefault}>
                    <Icon name="trash" style={{ margin: 0 }} />
                  </Button>
                </Confirm>
              </>
            }
            key={flow._id}
          />
        ))}
      </List>
    </>
  );
}

const ListItemWrapper = (props: any) => {
  const newList = List as any;
  return <newList.Item {...props} />;
};

export default ApprovalFlows;
