import { PixiIconName } from '@pixi/elements/Icon';

export const types: {
  icon: PixiIconName;
  label: string;
  value: Pickit.CommunityPropertyInterface['type'];
  description?: string;
}[] = [
  {
    icon: 'input-text',
    label: 'Text',
    value: 'text',
    description: 'Simple text field',
  },
  {
    icon: 'input-numeric',
    label: 'Number',
    value: 'number',
    description: 'Simple number field',
  },
  {
    icon: 'toggle-large-on',
    label: 'Checkbox',
    value: 'boolean',
    description: 'A toggle button',
  },
  {
    icon: 'calendar',
    label: 'Date',
    value: 'date',
    description: 'A calendar',
  },
  {
    icon: 'tag',
    label: 'Tags',
    value: 'tags',
    description: 'Multiple text values',
  },
  {
    icon: 'list-dropdown',
    label: 'Dropdown',
    value: 'dropdown',
    description: 'A dropdown with strict options',
  },
];
