import React, { useEffect, useReducer, useState } from 'react';
import {
  Button,
  Loader,
  Flex,
  Input,
  Popup,
  Switch,
  Form,
  Icon,
  Dropdown,
  Message,
  Tooltip,
} from 'elements_v2';
import Tags from 'elements_v2/Tags';
import useCloudStorage from 'hooks/useCloudStorage';
import EditLicense from 'views/_Manage/LicenseV2/Edit';
import File from 'components/ContributionsGridV2/File';
import Image from 'components/ContributionsGridV2/Image';
import asyncPool from 'utils/async-pool';
import deepmerge from 'deepmerge';
import SelectUsersDropdown from 'components/SelectUsersDropdown';
import ColorForm, {
  ColorVariantsHeader,
} from 'views/_Manage/BrandAssets/parts/forms/ColorForm';
import SelectCountry from 'components/SelectCountry';
import { useDocumentsContext, useMediaContext, useUserContext } from 'hooks';
import useUserStatus from 'hooks/useUserStatus';
import TextForm from 'views/_Manage/BrandAssets/parts/forms/TextForm';
import SelectGroupsDropdown from 'components/SelectGroupsDropdown';
import Badge from 'elements_v2/Badge';
import { useAppContext } from 'contexts/Providers/AppProvider';
import EditVariants from '../parts/EditFile/EditVariants';
import EditSlides from '../parts/EditFile/EditSlides';
import EditThumbnail from '../parts/EditFile/EditThumbnail';
import FileAttributes from './FileAttributes';
import InspectionTool from '../parts/EditFile/InspectionTool';
import EditTags from '../parts/EditFile/EditTags';
import Thumbnails from '../parts/EditFile/Thumbnails';
import VideoForm from './VideoForm';
import useContextFromFile from 'hooks/useContextFromFile';
import '../style.scss';
function parseTags(tags) {
  return clearDuplicates(tags.map((tag) => tag.toLowerCase()));
}
function clearDuplicates(array) {
  return array.filter((collection, pos) => array.indexOf(collection) === pos);
}

function FileForm(props) {
  const [metadata, setMetadata] = useReducer((state, action) => {
    if (!Object.keys(action)?.length) {
      return {};
    }
    if (action?.tags?.length) {
      action.tags = parseTags(action.tags);
    }
    return { ...state, ...action };
  }, {});
  const context = useContextFromFile(props.metadata) || props.context;
  const cloudStorage = useCloudStorage();
  const App = useAppContext();
  const User = useUserContext();
  const userStatus = useUserStatus();
  const [forceActiveTab, setForceActiveTab] = useState(props.defaultTab);
  const form = React.useRef();
  const [embedSize, setEmbedSize] = useState('source');
  const [isError, setIsError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isMultipleFilesShown, setIsMultipleFilesShown] = useState(false);
  const [updatedSRT, setUpdatedSRT] = useState('');
  const [isLicenseChanged, setIsLicenseChanged] = useState(false);
  const [licenseFilesToUpload, setLicenseFilesToUpload] = useReducer(
    (state, action) => ({ ...action }),
    [],
  );
  const [thumbnailToUpload, setThumbnailToUpload] = useReducer(
    (state, action) => action,
    {},
  );
  const [isMergeWithExistingTags, setIsMergeWithExistingTags] = useState(true);
  const [isMergeWithExistingCollections, setIsMergeWithExistingCollections] =
    useState(true);
  const { isBusinessAdmin, isBusinessOwner, isBusinessITadmin } =
    userStatus.product;
  const isBusinessStaff =
    isBusinessAdmin || isBusinessOwner || isBusinessITadmin;

  const Documents = useDocumentsContext();

  // OCR variables
  const [isLoadingOCR, setIsLoadingOCR] = useState(true);
  const [isOCRFullText, setIsOCRFullText] = useState(false);
  const [OCRContext, setOCRContext] = useState('');
  const [OCRContextStatus, setOCRContextStatus] = useState('processed');
  const [isGeneratingVideoPreview, setIsGeneratingVideoPreview] =
    useState(false);

  useEffect(() => {
    if (props.metadata) {
      setMetadata(props.metadata);

      if (isLoadingOCR) {
        loadOCRContext().then();
      }
    }
  }, [props.metadata?._id]);

  useEffect(() => {
    if (props.defaultTab) {
      setForceActiveTab(props.defaultTab);
    }
  }, [props.defaultTab]);

  useEffect(() => {
    (async () => {
      if (!isLoadingOCR) {
        setIsLoadingOCR(true);
        await loadOCRContext();
      }
    })();
  }, [isOCRFullText]);

  useEffect(() => {
    (async () => {
      if (!isLoadingOCR) {
        setIsLoadingOCR(true);
        await context.updateOCRContextStatusForFile(
          props.metadata._id,
          OCRContextStatus === 'disabled',
        );
        setIsLoadingOCR(false);
      }
    })();
  }, [OCRContextStatus]);

  const file = context.data.files?.find((file) => file?._id === metadata?._id);
  useEffect(() => {
    setMetadata({ file: file?.file });
  }, [file?.file]);

  async function loadOCRContext() {
    if (userStatus.activeFeatures?.ocr_and_fulltext_search) {
      const filename = props.metadata.file?.name;
      const processing_code =
        props.metadata?.processing?.indexing?.status?.code;
      if (
        filename &&
        ['WITH_OCR_CONTENT', 'EMPTY_OCR_CONTENT'].indexOf(processing_code) !==
          -1
      ) {
        const response = await context.getOCRContextForFile(
          props.metadata._id,
          isOCRFullText,
        );
        if (processing_code === 'WITH_OCR_CONTENT') {
          setOCRContext(response?.text || 'Nothing found');
          setOCRContextStatus(response?.status || 'processed');
        } else {
          setOCRContext('Nothing found');
          setOCRContextStatus(response?.status || 'processed');
        }
      }
      setIsLoadingOCR(false);
    }
  }

  function validateForm() {
    if (!metadata.name) {
      setForceActiveTab(0);
    }
  }

  async function uploadFiles(files, container) {
    const uploadedFiles = [];
    if (!files?.length) {
      return [];
    }
    for (let i = 0; i < files.length; i++) {
      const controller = new AbortController();
      const file = files[i];
      const uploadedFile = await context.uploadFile(file, container, {
        onProgress: () => {},
        abort: controller,
        onFinish: () => {},
        onError: () => {},
      });
      if (file.alias) {
        uploadedFile.alias = file.alias;
      }
      uploadedFiles.push(uploadedFile);
    }
    return uploadedFiles;
  }

  async function saveLicense(license) {
    const deletedFiles = [
      ...(metadata.license?.licenseFiles?.filter(
        (file) =>
          !licenseFilesToUpload.licenseFiles?.find((f) => f.id === file.id),
      ) || []),
      ...(metadata.license?.modelReleases?.filter(
        (file) =>
          !licenseFilesToUpload.modelReleases?.find((f) => f.id === file.id),
      ) || []),
      ...(metadata.license?.propertyReleases?.filter(
        (file) =>
          !licenseFilesToUpload.propertyReleases?.find((f) => f.id === file.id),
      ) || []),
    ];
    for (const file of deletedFiles) {
      await context.deleteFile(file.id, 'licenses');
    }
    license.licenseFiles = await uploadFiles(
      licenseFilesToUpload.licenseFiles,
      'licenses',
    );
    license.modelReleases = await uploadFiles(
      licenseFilesToUpload.modelReleases,
      'licenses',
    );
    license.propertyReleases = await uploadFiles(
      licenseFilesToUpload.propertyReleases,
      'licenses',
    );
    return license;
  }
  async function saveVariants() {
    if (!metadata?.file?.variants?.length && !props.metadata?.file?.variants) {
      return [];
    }
    const uploadedVariants = metadata?.file?.variants?.filter(
      (variant) => !variant.lastModifiedDate && !variant.lastModified,
    );
    const incompleteVariants = metadata?.file?.variants?.filter(
      (variant) => !!variant.lastModifiedDate || !!variant.lastModified,
    );
    const deletedVariants = props.metadata?.file?.variants?.filter(
      (variant) => !metadata.file?.variants?.find((v) => v.id === variant.id),
    );
    const newVariants = await uploadFiles(incompleteVariants, 'thumbnails');
    for (const file of deletedVariants) {
      await context.deleteFile(file.id, 'thumbnails');
    }
    return [...uploadedVariants, ...newVariants];
  }

  async function saveThumbnail() {
    if (!thumbnailToUpload) {
      return metadata?.file?.previews;
    }
    if (
      thumbnailToUpload &&
      !metadata?.file?.previews?.length &&
      props.metadata?.file?.previews?.length
    ) {
      for (let i = 0; i < props.metadata?.file?.previews.length; i++) {
        const file = props.metadata?.file?.previews[i];
        await context.deleteFile(file.id, 'thumbnails');
      }
    }
    const uploadedFiles = await uploadFiles([thumbnailToUpload], 'thumbnails');
    return uploadedFiles;
  }

  async function save(event) {
    setIsSaving(true);
    event.preventDefault();
    event.stopPropagation();
    let license = null;
    let variants;
    let previews;
    if (isLicenseChanged) {
      license = await saveLicense(metadata.license);
    }
    if (updatedSRT) {
      const blob = new Blob([updatedSRT], { type: 'application/x-subrip' });
      await context.uploadNewFileById(
        metadata?.file?.previewVideoTranscription?.id,
        'thumbnails',
        blob,
      );
    }
    if (metadata.file?.id) {
      variants = await saveVariants();
      previews = await saveThumbnail();
    }
    const newMetadata = {
      ...metadata,
      file: {
        ...(metadata.file || null),
        variants,
        previews,
        isCustomPreviews: !!thumbnailToUpload,
      },
    };
    if (newMetadata.file?.external_file) {
      delete newMetadata.file;
    }
    if (license || metadata.license) {
      newMetadata.license = license || metadata.license;
    }
    if (props.isMultiple) {
      asyncPool(10, props.files, async (selectedFile) => {
        const file = context.data.files?.find(
          (f) => f._id === selectedFile._id,
        );
        const newFile = {
          ...file,
          ...newMetadata,
          attributes: {
            ...(file.attributes || {}),
            ...(newMetadata.attributes || {}),
          },
          tags: parseTags(
            (isMergeWithExistingTags
              ? deepmerge(file.tags || [], newMetadata.tags || [])
              : newMetadata.tags) || [],
          ),
          collections: clearDuplicates(
            (isMergeWithExistingTags
              ? deepmerge(file.collections || [], newMetadata.collections || [])
              : newMetadata.collections) || [],
          ),
          file: file.file,
          ...(license ? { license } : {}),
        };
        if (newFile.share_to_anonymous !== file.share_to_anonymous) {
          if (newFile.share_to_anonymous) {
            newFile.share_to_anonymous_previews = [
              file.file.id,
              ...file.file.previews.map((p) => p.id),
            ];
            newFile.share_to_anonymous_variants = [
              ...(file?.file?.variants.map((v) => v.id) || []),
            ];
          } else {
            newFile.share_to_anonymous_previews = [];
            newFile.share_to_anonymous_variants = [];
          }
        }
        await context.saveDocument(newFile);
      });
      setIsSaving(false);
      return props.onClose();
    }
    await context.saveDocument(newMetadata);
    props.onClose();
    setIsSaving(false);
  }

  const embedUrl =
    metadata?.file?.embedVersion === 'v2'
      ? `https://embed.pickit.com/files/${metadata.communitySlug}/${metadata._id}/${metadata.file.name}`
      : `https://embed.pickit.com/files/${metadata.communitySlug}/${metadata._id}`;

  return (
    <Popup
      style={{
        maxWidth:
          metadata?.file?.ext === 'pptx' || props.isMultiple ? '90vw' : 900,
      }}
      isOpen={props.isOpen}
      zIndex={999}
      onClose={props.onClose}
      contentStyle={{ padding: 0 }}
      headerStyle={{ overflow: 'visible' }}
      title={
        props.isMultiple
          ? `Editing ${props.files.length} ${
              props.library === 'brandassets' ? 'assets' : 'files'
            }`
          : `Editing ${props.library === 'brandassets' ? 'asset' : 'file'} ${
              metadata.name
            }`
      }
      description={
        props.isMultiple && props.files?.length ? (
          <>
            Changes will apply to all selected files. Click to deselect.
            {isMultipleFilesShown && (
              <Flex
                style={{
                  width: '100%',
                  overflow: 'auto',
                  background: `rgba(0, 0, 0, 0.05)`,
                  padding: 15,
                  marginTop: 20,
                  borderRadius: 5,
                }}
              >
                {props.files.map((file, key) => (
                  <File
                    style={{
                      maxWidth: 140,
                      flexShrink: 0,
                      marginBottom: 0,
                      marginLeft: !key ? 3 : undefined,
                    }}
                    onClick={() =>
                      props.setSelectedFiles({
                        action: 'replace',
                        data: props.files.filter((f) => f._id !== file._id),
                      })
                    }
                    file={{ data: { ...file, ...metadata }, isSelected: true }}
                    key={file._id}
                    fileType={context.type}
                    context={context}
                  />
                ))}
              </Flex>
            )}
            <Button
              onClick={() => setIsMultipleFilesShown(!isMultipleFilesShown)}
              style={{ marginTop: isMultipleFilesShown ? 0 : 15 }}
            >
              <Icon name={`Chevron${isMultipleFilesShown ? 'Up' : 'Down'}`} />
              {!isMultipleFilesShown
                ? 'Show selected files'
                : 'Hide selected files'}
            </Button>
          </>
        ) : (
          ''
        )
      }
      actions={
        <Flex width="100%" justifyContent="space-between">
          {props.onClose && (
            <Button pixi inline as="div" onClick={props.onClose}>
              Cancel
            </Button>
          )}
          <Button
            pixi
            inline
            onClick={() => {
              validateForm();
              if (form?.current) {
                form.current.click();
              }
            }}
            disabled={isSaving}
            loading={isSaving}
            style={{ marginLeft: 'auto' }}
            primary
          >
            {!props.new ? 'Save changes' : 'Create collection'}
          </Button>
        </Flex>
      }
    >
      <Form style={{ width: '100%', height: '100%' }} onSubmit={save}>
        <button ref={form} type="submit" style={{ display: 'none' }} />
        {isError && (
          <Message error>
            <h1>Something went wrong.</h1>
          </Message>
        )}
        <Form.Tabs activeTabId={forceActiveTab}>
          <Form.Tab
            id="information"
            label={
              <>
                <Icon name="InfoCircle" />
                Information
              </>
            }
          >
            {!props.isMultiple && (
              <Input.Field
                label="Name"
                maxLength={65}
                value={metadata.name}
                onChange={(name) => setMetadata({ name })}
              />
            )}
            {metadata?.data?.type !== 'color' && (
              <>
                {context.type === 'documents' && (
                  <Input.Field
                    label="Country"
                    custom={
                      <SelectCountry
                        value={metadata.country}
                        onChange={(country) => setMetadata({ country })}
                      />
                    }
                  />
                )}
                <Input.Field
                  label="Description"
                  value={metadata.description}
                  textarea
                  onChange={(description) => setMetadata({ description })}
                />
                <Input.Field
                  label="Author"
                  value={metadata.author}
                  onChange={(author) => setMetadata({ author })}
                />
              </>
            )}

            <Input.Field
              label="Tags"
              field={
                <EditTags
                  file={props?.metadata}
                  context={context}
                  onlyInput
                  onChange={(tags) => {
                    setMetadata({ tags });
                  }}
                />
              }
            />
            {userStatus.activeFeatures?.ocr_and_fulltext_search &&
            metadata.file?.name &&
            ['WITH_OCR_CONTENT', 'EMPTY_OCR_CONTENT'].indexOf(
              props.metadata?.processing?.indexing?.status?.code,
            ) !== -1 ? (
              isLoadingOCR ? (
                <div style={{ marginTop: 20 }}>
                  {OCRContextStatus !== 'disabled'
                    ? context.type === 'media'
                      ? 'Getting OCR context and settings...'
                      : 'Getting document context settings...'
                    : context.type === 'media'
                      ? 'Disabling OCR search for this media file'
                      : 'Disabling full-text search for this document'}
                </div>
              ) : (
                <div>
                  <div
                    className="Pixi Pixi__InputWrapper"
                    style={{ marginTop: 20, marginBottom: 5 }}
                  >
                    <label className="Pixi Pixi__InputLabel">
                      {context.type === 'media'
                        ? 'OCR context'
                        : 'Document context'}
                    </label>
                    {[
                      'jpg',
                      'jpeg',
                      'bmp',
                      'pdf',
                      'tiff',
                      'tif',
                      'heic',
                      'png',
                      'ai',
                      'docx',
                      'pptx',
                    ].indexOf(
                      metadata.file?.name.toLowerCase().split('.').slice(-1)[0],
                    ) !== -1 ? (
                      <div>
                        {OCRContextStatus !== 'disabled' ? (
                          <div className="Pixi Pixi__InputRow">
                            <div className="Pixi Pixi__Input">
                              <div
                                className="Pixi Pixi__Input__field"
                                style={{ whiteSpace: 'break-spaces' }}
                              >
                                {OCRContext}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {OCRContextStatus !== 'disabled' &&
                        OCRContext.endsWith('...') ? (
                          <Button
                            size="small"
                            onClick={() => {
                              setIsOCRFullText(true);
                            }}
                            inline
                            primary
                            style={{ marginTop: 5 }}
                          >
                            {context.type === 'media'
                              ? 'Show full OCR context...'
                              : 'Show full document context'}
                          </Button>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <Switch
                    active={OCRContextStatus === 'disabled'}
                    label={
                      context.type === 'media'
                        ? 'Disable OCR search for this file'
                        : 'Disable full-text search for this file'
                    }
                    onChange={(val) => {
                      setOCRContextStatus(val ? 'disabled' : 'processed');
                    }}
                  />
                </div>
              )
            ) : (
              ''
            )}
          </Form.Tab>
          <Form.Tab
            hidden={
              (!metadata.file?.contentType?.includes('video') &&
                metadata.file?.ext?.toLowerCase() !== 'mxf' &&
                metadata.file?.ext?.toLowerCase() !== 'mkv') ||
              metadata?.file?.external_file
            }
            hasTabs
            onlyIconsWhenActive
            label={
              <>
                <Icon name="camera-video" />
                Video
                <Badge style={{ marginLeft: 'auto' }}>NEW</Badge>
              </>
            }
          >
            <VideoForm
              file={metadata}
              onChange={setMetadata}
              onGeneratePreview={setIsGeneratingVideoPreview}
              onSRTUpdate={(srt) => {
                setUpdatedSRT(srt);
              }}
            />
          </Form.Tab>
          <Form.Tab
            id="color"
            hidden={metadata?.data?.type !== 'color'}
            label={
              <>
                <Icon name="Palette2" />
                Color
              </>
            }
          >
            <ColorForm
              onlyForm
              metadata={{ name: metadata.name, data: metadata.data }}
              onChange={(data) => setMetadata({ data })}
            />
          </Form.Tab>
          <Form.Tab
            id="text"
            hidden={metadata?.data?.type !== 'text'}
            label={
              <>
                <Icon name="card-text" />
                Text
              </>
            }
          >
            <TextForm
              onlyForm
              metadata={metadata}
              onChange={({ name, value }) =>
                setMetadata({
                  name,
                  data: {
                    ...metadata.data,
                    value,
                  },
                })
              }
            />
          </Form.Tab>
          <Form.Tab
            id="color"
            hidden={metadata?.data?.type !== 'color'}
            label={
              <>
                <Icon name="Palette2" />
                Color Variants
              </>
            }
            header={ColorVariantsHeader}
          >
            <ColorForm
              onlyVariants
              metadata={{ name: metadata.name, data: metadata.data }}
              onChange={(variants) =>
                setMetadata({
                  data: {
                    ...metadata.data,
                    value: { ...metadata.data.value, variants },
                  },
                })
              }
            />
          </Form.Tab>
          <Form.Tab
            id="properties"
            label={
              <>
                <Icon name="code" />
                Metadata
              </>
            }
            hidden={!userStatus.activeFeatures?.attributes}
          >
            <FileAttributes
              metadata={metadata}
              onRemove={(key) => {
                delete metadata?.attributes[key];
                return setMetadata({
                  ...metadata,
                });
              }}
              onChange={(key, val) => {
                setMetadata({
                  ...metadata,
                  attributes: {
                    ...(metadata?.attributes || {}),
                    [key]: val,
                  },
                });
              }}
            />
          </Form.Tab>
          {(!props?.context?.isContributorUser?.(
            User.djangoProfile?.id,
            User.djangoProfile?.email,
          ) ||
            isBusinessStaff) && (
            <Form.Tab
              id="sharing"
              label={
                <>
                  <Icon
                    name={props.library === 'brandassets' ? 'Folder' : 'People'}
                  />
                  {props.library === 'brandassets' ? 'Library' : 'Sharing'}
                </>
              }
            >
              <Input.Field
                warning={
                  props.isMultiple &&
                  !isMergeWithExistingCollections &&
                  `${
                    props.library === 'brandassets'
                      ? 'Libraries'
                      : 'Collections'
                  } on all selected files will be replaced with these ${
                    props.library === 'brandassets'
                      ? 'libraries'
                      : 'collections'
                  }`
                }
                label={
                  <Flex
                    alignItems="center"
                    style={{ width: '100%', whiteSpace: 'nowrap' }}
                  >
                    Add to{' '}
                    {props.library === 'brandassets'
                      ? 'libraries'
                      : 'collections'}
                    {props.isMultiple && (
                      <Switch
                        active={isMergeWithExistingCollections}
                        inline
                        onChange={setIsMergeWithExistingCollections}
                        style={{ padding: 2, minHeight: 0, marginLeft: 'auto' }}
                        label={`Merge with existing ${
                          props.library === 'brandassets'
                            ? 'libraries'
                            : 'collections'
                        }`}
                      />
                    )}
                  </Flex>
                }
                custom={
                  <Dropdown
                    style={{ marginTop: 10 }}
                    value={metadata.collections}
                    keepOpen
                    noActive
                    options={[
                      ...(context.collections?.map((collection) => {
                        const thumbnailUrl =
                          context.getCollectionThumbnailUrl(collection);
                        return {
                          key: collection._id,
                          value: collection._id,
                          text: collection.name,
                          image: thumbnailUrl,
                        };
                      }) || []),
                    ]}
                    placeholder={`Select ${
                      props.library === 'brandassets'
                        ? 'libraries'
                        : 'collections'
                    }`}
                    onChange={(value) => {
                      setMetadata({ collections: value });
                    }}
                    multiple
                  />
                }
              />

              <>
                {(!props?.context?.isContributorUser?.(
                  User.djangoProfile?.id,
                  User.djangoProfile?.email,
                ) ||
                  isBusinessStaff) && (
                  <Input.Field
                    label="Share to"
                    style={{ marginTop: 15 }}
                    custom={
                      <>
                        <Dropdown
                          auto
                          noClear
                          placeholder="Placeholder"
                          icon="Lock"
                          noActive
                          value={metadata.share_to}
                          onChange={(share_to) => setMetadata({ share_to })}
                        >
                          <Dropdown.Option value="all">
                            All users
                          </Dropdown.Option>
                          <Dropdown.Option value="not-set">
                            Inherit
                          </Dropdown.Option>
                          <Dropdown.Option value="specific">
                            Specific users
                          </Dropdown.Option>
                          <Dropdown.Option value="none">
                            Admins only
                          </Dropdown.Option>
                        </Dropdown>
                        {metadata.share_to === 'specific' && (
                          <>
                            <SelectUsersDropdown
                              style={{ marginTop: 10 }}
                              placeholder="Select specific users"
                              value={metadata.share_to_users || []}
                              onChange={(users) => {
                                setMetadata({ share_to_users: [...users] });
                              }}
                            />
                            <SelectGroupsDropdown
                              value={metadata?.share_to_user_groups || []}
                              placeholder="Select groups"
                              /* communitySlug={child.slug } */
                              onChange={(groups) => {
                                setMetadata({
                                  share_to_user_groups: [...groups],
                                });
                              }}
                            />
                          </>
                        )}
                      </>
                    }
                  />
                )}
                <Switch
                  style={{ marginTop: 5 }}
                  active={metadata?.preferences?.show_preview_as_thumbnail}
                  label="Show preview as thumbnail"
                  onChange={(val) => {
                    setMetadata({
                      preferences: {
                        ...(metadata?.preferences || {}),
                        show_preview_as_thumbnail: val,
                      },
                    });
                  }}
                />
                {!!metadata.file?.content_tags?.length && (
                  <Input.Field
                    label="Content tags"
                    field={
                      <Tags
                        inputType="text"
                        isAddingDisabled
                        value={metadata.file?.content_tags}
                        onChange={(value) =>
                          setMetadata({
                            file: { ...metadata.file, content_tags: value },
                          })
                        }
                      />
                    }
                  />
                )}
                <Switch
                  active={metadata?.share_to_anonymous}
                  label="Anonymous access"
                  onChange={(val) => {
                    setMetadata({
                      share_to_anonymous: val,
                      share_to_anonymous_previews: props.isMultiple
                        ? []
                        : [
                            metadata.file.id,
                            ...metadata.file.previews.map((p) => p.id),
                          ],
                      share_to_anonymous_variants: props.isMultiple
                        ? []
                        : [
                            ...(metadata?.file?.variants.map((v) => v.id) ||
                              []),
                          ],
                    });
                  }}
                />
                {metadata?.share_to_anonymous && (
                  <Input.Field
                    disabled={props.isMultiple}
                    label="Anonymous url - landing page"
                    value={
                      props.isMultiple
                        ? 'Only accessible when viewing a single file'
                        : `https://share.pickit.com/file/${context.type}/${metadata._id}`
                    }
                  />
                )}
                {metadata?.share_to_anonymous &&
                  !!metadata.file?.previews?.length && (
                    <div
                      style={{
                        background: metadata?.share_to_anonymous_previews
                          ?.length
                          ? '#f3f3f4'
                          : 'transparent',
                        marginTop: '1rem',
                        borderRadius: 5,
                      }}
                    >
                      <Switch
                        active={!!metadata?.share_to_anonymous_previews?.length}
                        label="Anonymous access to sizes "
                        onChange={(val) => {
                          if (val === false) {
                            setMetadata({
                              share_to_anonymous_previews: [],
                            });
                            return;
                          }
                          setMetadata({
                            share_to_anonymous_previews: [
                              metadata.file.id,
                              ...metadata.file.previews.map((p) => p.id),
                            ],
                          });
                        }}
                      />

                      {!!metadata?.share_to_anonymous_previews?.length && (
                        <Flex style={{ marginLeft: '1rem' }}>
                          {[metadata.file, ...metadata.file.previews].map(
                            (file) => {
                              if (!file.url) {
                                file.url = URL.createObjectURL(file);
                              }
                              file.alias =
                                file?.name === metadata.file?.name
                                  ? 'Original'
                                  : file.name[0].toUpperCase() +
                                    file.name.substr(1);
                              return (
                                <div key={file.id}>
                                  <Switch
                                    label={file.alias}
                                    disabled={
                                      metadata?.share_to_anonymous_previews?.includes(
                                        file.id,
                                      ) &&
                                      metadata?.share_to_anonymous_previews
                                        ?.length === 1
                                    }
                                    active={metadata?.share_to_anonymous_previews?.includes(
                                      file.id,
                                    )}
                                    onChange={(val) => {
                                      if (val) {
                                        setMetadata({
                                          share_to_anonymous_previews: [
                                            ...metadata.share_to_anonymous_previews,
                                            file.id,
                                          ],
                                        });
                                        return;
                                      }
                                      setMetadata({
                                        share_to_anonymous_previews:
                                          metadata.share_to_anonymous_previews.filter(
                                            (id) => id !== file.id,
                                          ),
                                      });
                                    }}
                                  />
                                </div>
                              );
                            },
                          )}
                        </Flex>
                      )}
                    </div>
                  )}
                {metadata?.share_to_anonymous &&
                  !!metadata?.file?.variants?.length && (
                    <div
                      style={{
                        background: metadata?.share_to_anonymous_variants
                          ?.length
                          ? '#f3f3f4'
                          : 'transparent',
                        marginTop: '1rem',
                        borderRadius: 5,
                      }}
                    >
                      <Switch
                        active={!!metadata?.share_to_anonymous_variants?.length}
                        disabled={!metadata?.file?.variants?.length}
                        label="Anonymous access to variants "
                        style={{ marginTop: '1rem' }}
                        onChange={(val) => {
                          if (val === false) {
                            setMetadata({
                              share_to_anonymous_variants: [],
                            });
                            return;
                          }
                          setMetadata({
                            share_to_anonymous_variants: [
                              ...(metadata?.file?.variants.map((v) => v.id) ||
                                []),
                            ],
                          });
                        }}
                      />

                      {!!metadata?.share_to_anonymous_variants?.length && (
                        <Flex style={{ padding: '0 1rem' }}>
                          <Thumbnails
                            disableRearrange
                            disableDelete
                            thumbnails={metadata.file.variants.map((file) => {
                              if (!file.url) {
                                file.url = URL.createObjectURL(file);
                              }
                              return file;
                            })}
                            showAlias
                            CustomAction={({ file }) => (
                              <Switch
                                label="Allow access"
                                active={metadata?.share_to_anonymous_variants?.includes(
                                  file.id,
                                )}
                                disabled={
                                  metadata?.share_to_anonymous_variants?.includes(
                                    file.id,
                                  ) &&
                                  metadata?.share_to_anonymous_variants
                                    ?.length === 1
                                }
                                onChange={(val) => {
                                  if (val) {
                                    setMetadata({
                                      share_to_anonymous_variants: [
                                        ...metadata.share_to_anonymous_variants,
                                        file.id,
                                      ],
                                    });
                                    return;
                                  }
                                  setMetadata({
                                    share_to_anonymous_variants:
                                      metadata.share_to_anonymous_variants.filter(
                                        (id) => id !== file.id,
                                      ),
                                  });
                                }}
                              />
                            )}
                          />
                        </Flex>
                      )}
                    </div>
                  )}
                {metadata?.share_to_anonymous && (
                  <Input.Field
                    disabled={props.isMultiple}
                    label="Anonymous url - direct download"
                    value={
                      props.isMultiple
                        ? 'Only accessible when viewing a single file'
                        : metadata?.file?.download_url_anon
                    }
                  />
                )}
              </>
            </Form.Tab>
          )}
          <Form.Tab
            id="variants"
            hidden={
              context.type !== 'media' ||
              props.isMultiple ||
              !metadata?.file?.url ||
              !metadata?.file?.contentType?.includes('image')
            }
            label={
              <>
                <Icon name="Crop" />
                Image variants
              </>
            }
            header={
              <>
                <h1>Create variants</h1>
                <p>
                  Use our image editor to generate new variants of this image.
                </p>
              </>
            }
            headerStyle={{ marginBottom: 0 }}
          >
            <EditVariants
              isEditing
              context={context}
              file={metadata}
              value={metadata.file?.variants || []}
              onSort={(variants) => {
                setMetadata({
                  ...metadata,
                  file: { ...metadata.file, variants },
                });
              }}
              onChange={(variant) =>
                setMetadata({
                  ...metadata,
                  file: {
                    ...metadata.file,
                    variants: [...(metadata.file?.variants || []), variant],
                  },
                })
              }
            />
          </Form.Tab>
          <Form.Tab
            id="thumbnail"
            hidden={props.isMultiple || !props.metadata?.file?.id}
            label={
              <>
                <Icon name="Image" />
                Thumbnail
              </>
            }
          >
            {props.metadata?.processing?.thumbnail?.isProcessing ? (
              <Loader>Generating thumbnail...</Loader>
            ) : metadata?.file?.url ? (
              <Input.Field
                label="Thumbnail"
                hidden={metadata?.import?.from === 'pickit-market'}
                custom={
                  <>
                    {context.type === 'media' ? (
                      <Image
                        forceThumbnailUrl={
                          thumbnailToUpload?.name
                            ? URL.createObjectURL(thumbnailToUpload)
                            : undefined
                        }
                        style={{
                          minHeight: 200,
                        }}
                        photo={{
                          width: 300,
                          height: 'auto',
                          data: {
                            ...metadata,
                            processing: props?.metadata?.processing,
                          },
                        }}
                        file={{ data: metadata }}
                        fileType={context.type}
                        context={context}
                      />
                    ) : (
                      <File
                        forceThumbnailUrl={
                          thumbnailToUpload?.name
                            ? URL.createObjectURL(thumbnailToUpload)
                            : undefined
                        }
                        file={{ data: metadata }}
                        fileType={context.type}
                        context={context}
                      />
                    )}
                    <Flex>
                      <EditThumbnail
                        value={metadata?.file?.previews?.[0]}
                        file={metadata}
                        context={context}
                        library={props.library}
                        onRestore={() => {
                          setMetadata({
                            ...metadata,
                            file: {
                              ...metadata.file,
                              previews: props.metadata?.file?.previews || [],
                            },
                          });
                        }}
                        onChange={(file) => {
                          setThumbnailToUpload(file);
                        }}
                      />
                      {context.type === 'documents' && (
                        <>
                          {!!metadata?.file?.previews?.length && (
                            <Button
                              inline
                              pixi
                              style={{ marginTop: 15, marginLeft: 10 }}
                              onClick={() => {
                                setMetadata({
                                  ...metadata,
                                  file: {
                                    ...metadata.file,
                                    previews: [],
                                  },
                                });
                              }}
                            >
                              <Icon name="Trash" /> Delete thumbnail
                            </Button>
                          )}
                        </>
                      )}
                    </Flex>
                  </>
                }
              />
            ) : (
              <></>
            )}
          </Form.Tab>
          <Form.Tab
            id="slides"
            hidden={metadata.file?.ext !== 'pptx' || props.isMultiple}
            label={
              <>
                <Icon name="Stickies" />
                PowerPoint Slides
              </>
            }
            header={
              <>
                <h1>Quickslides</h1>
                <p>
                  Quickslides allows users to quickly and <br />
                  easily insert pre-designed slides from their <br />
                  PowerPoint files into their presentation.
                </p>
              </>
            }
            headerStyle={{ marginBottom: 0 }}
          >
            {({ isActive }) => {
              return isActive && metadata?.file?.url ? (
                <EditSlides
                  onSlidesPublished={(val) => {
                    setMetadata({
                      ...metadata,
                      file: {
                        ...metadata.file,
                        slides_published: val,
                      },
                    });
                  }}
                  isEditing
                  setSelectedFiles={props.setSelectedFiles}
                  context={context}
                  file={metadata}
                />
              ) : (
                <></>
              );
            }}
          </Form.Tab>
          <Form.Tab
            id="inspection-tool"
            hidden={
              metadata.file?.ext !== 'pptx' ||
              props.isMultiple ||
              !userStatus.activeFeatures?.inspection_tool
            }
            label={
              <>
                <Icon name="ui-checks-grid" />
                Inspection Tool
              </>
            }
            header={
              <>
                <h1>Inspection Tool</h1>
                <p>
                  Inspect embedded files within your documents and import them
                  to the media library to filter on which images are contained
                  in which documents
                </p>
              </>
            }
            headerStyle={{ marginBottom: 0 }}
          >
            {({ isActive }) => {
              return isActive && metadata?.file?.url ? (
                <InspectionTool
                  isEditing
                  context={context}
                  file={context.data.files?.find(
                    (file) => file._id === metadata._id,
                  )}
                />
              ) : (
                <></>
              );
            }}
          </Form.Tab>
          <Form.Tab
            id="embed"
            hidden={
              !metadata?.file?.contentType?.includes('image') &&
              metadata?.file?.ext !== 'pdf' &&
              !metadata?.file?.contentType?.includes('video')
            }
            disabled={
              isGeneratingVideoPreview ||
              (metadata?.file?.contentType?.includes('video') &&
                !metadata?.file?.previewVideo?.id)
            }
            disabledMessage={
              metadata?.file?.contentType?.includes('video') &&
              !metadata?.file?.previewVideo?.id
                ? 'Browser preview required. Go to "Video".'
                : 'Generating video preview...'
            }
            label={
              <>
                <Icon name="aspect-ratio" />
                Embed file
              </>
            }
          >
            {!context.fileIsPreviewable(metadata) ||
            (metadata?.file?.contentType?.includes('video') &&
              metadata?.file?.ext !== 'mp4' &&
              !metadata?.file?.previewVideo?.id &&
              metadata?.file?.ext !== 'pdf') ? (
              <Message error icon={<Icon name="x-circle" />}>
                <p>
                  {metadata?.file?.contentType?.includes('video')
                    ? `Unfortunately, the file format is not compatible with the
                    browser's preview capabilities. You need to generate a MP4 preview of the video. You can do this
                    under "Video".`
                    : `Unfortunately, the file format is not compatible with the
                  browser's preview capabilities. You may manually upload a
                  preview image to activate the embed option. You can do this
                  under "Thumbnail".`}
                </p>
              </Message>
            ) : (
              <>
                <Input
                  label="Enable embed code"
                  description="Once you have enabled the embed code and saved your settings, please be aware that it may take up to a minute for the URLs to become functional."
                  custom={
                    <Switch
                      label="Enabled"
                      active={metadata.file?.allowEmbedCode}
                      onChange={async (value) => {
                        setMetadata({
                          ...metadata,
                          file: {
                            ...metadata.file,
                            allowEmbedCode: value,
                            embedVersion: 'v2',
                          },
                        });
                      }}
                    />
                  }
                />
                {metadata.file?.allowEmbedCode &&
                  !metadata.file?.contentType?.includes('video') &&
                  metadata?.file?.ext !== 'pdf' && (
                    <Dropdown
                      useV2
                      label="Size"
                      value={embedSize}
                      onChange={setEmbedSize}
                      options={[
                        { value: 'source', text: 'Source file', key: 'source' },
                        ...(metadata?.file?.previews?.map((preview) => ({
                          value: preview.name,
                          text: preview.name,
                          key: preview.name,
                        })) || []),
                      ]}
                      style={{ marginBottom: 15 }}
                    />
                  )}
                {metadata.file?.allowEmbedCode && (
                  <>
                    <Input.Field
                      label="URL"
                      value={
                        metadata.file?.embedVersion === 'v2'
                          ? embedUrl
                          : `${
                              embedUrl +
                              (embedSize !== 'source'
                                ? `/${embedSize}`
                                : `.${
                                    metadata?.file?.previewVideo?.id
                                      ? 'mp4'
                                      : metadata?.file?.ext
                                  }`)
                            }`
                      }
                      style={{ marginBottom: 15 }}
                    />
                    <Input.Field
                      label="HTML embed code"
                      textarea
                      value={
                        metadata.file?.previewVideo?.url
                          ? `<video ${
                              metadata.file?.previews?.length
                                ? `poster="${window.pickit.config.MEDIA_URL}/${
                                    context.type
                                  }/file/embed/${file._id}/${
                                    metadata.file?.previews?.find(
                                      (p) =>
                                        p.name === 'medium' ||
                                        p.name === 'small',
                                    )?.name
                                  }" controls`
                                : ''
                            }><source src="${
                              metadata.file?.embedVersion === 'v2'
                                ? embedUrl
                                : `${
                                      embedUrl +
                                      (embedSize !== 'source'
                                        ? `/${embedSize}`
                                        : '')
                                    }.${metadata?.file?.previewVideo?.id}`
                                  ? 'mp4'
                                  : metadata?.file?.ext
                            }" type="video/mp4" /></video>`
                          : `<img src="${
                              metadata.file?.embedVersion === 'v2'
                                ? embedUrl
                                : `${
                                      embedUrl +
                                      (embedSize !== 'source'
                                        ? `/${embedSize}`
                                        : '')
                                    }.${metadata?.file?.previewVideo?.id}`
                                  ? 'mp4'
                                  : metadata?.file?.ext
                            }" />`
                      }
                      style={{ marginBottom: 15 }}
                    />
                    <Input.Field
                      label="Iframe embed code"
                      textarea
                      value={
                        metadata.file?.previewVideo?.url
                          ? `<iframe width="${
                              metadata?.file?.image_details?.width || 1200
                            }" height="${
                              metadata?.file?.image_details?.height || 800
                            }" border="0" src="${
                              metadata.file?.embedVersion === 'v2'
                                ? embedUrl
                                : `${
                                      embedUrl +
                                      (embedSize !== 'source'
                                        ? `/${embedSize}`
                                        : '')
                                    }.${metadata?.file?.previewVideo?.id}`
                                  ? 'mp4'
                                  : metadata?.file?.ext
                            }"></iframe>`
                          : `<iframe width="${
                              metadata?.file?.image_details?.width || 1200
                            }" height="${
                              metadata?.file?.image_details?.height || 800
                            }" src="${
                              embedUrl +
                              (embedSize !== 'source' ? `/${embedSize}` : '')
                            }.${
                              metadata?.file?.previewVideo?.id
                                ? 'mp4'
                                : metadata?.file?.ext
                            }"></iframe>`
                      }
                      style={{ marginBottom: 15 }}
                    />
                  </>
                )}
              </>
            )}
          </Form.Tab>
          {/* {props.file?.file?.id && ( */}
          {(context.type === 'media' || props.library === 'brandassets') && (
            <Form.Tab
              id="license"
              hidden={
                metadata?.data?.type === 'color' ||
                metadata?.import?.from === 'pickit-market' ||
                props.libraryView === 'brandassets' ||
                !userStatus?.activeServices?.license_management
              }
              label={
                <>
                  <Icon name="ShieldCheck" />
                  License
                </>
              }
            >
              <EditLicense
                file={metadata}
                onChange={(license, files) => {
                  setIsLicenseChanged(true);
                  setMetadata({
                    license,
                  });
                  setLicenseFilesToUpload(files);
                }}
                inline
              />
            </Form.Tab>
          )}
          <Form.Tab
            label={
              <>
                <Icon name="chat" />
                Feedback
              </>
            }
          >
            {/** ******************** */}
            {/** File feedback */}
            <Input
              label="Allow user feedback"
              description={
                <>
                  {User.data.selectedCommunity.settings?.preferences
                    ?.allowFileFeedback && (
                    <p style={{ color: 'orange' }}>
                      This option is disabled because the library setting to
                      allow feedback is enabled
                    </p>
                  )}
                  <p>Gives users the ability to leave feedback on this file</p>
                </>
              }
              custom={
                <Switch
                  label="Enabled"
                  active={metadata.file?.allowFeedback}
                  disabled={
                    User.data.selectedCommunity.settings?.preferences
                      ?.allowFileFeedback
                  }
                  onChange={async (value) => {
                    setMetadata({
                      ...metadata,
                      file: { ...metadata.file, allowFeedback: value },
                    });
                  }}
                />
              }
            />
            <Input
              label="Allow external user feedback"
              description={
                <>
                  {User.data.selectedCommunity.settings?.preferences
                    ?.allowFeedbackFromExternal && (
                    <p style={{ color: 'orange' }}>
                      This option is disabled because the library setting to
                      allow feedback is enabled
                    </p>
                  )}
                  <p>
                    Gives external users the ability to leave feedback on this
                    file
                  </p>
                </>
              }
              custom={
                <Switch
                  label="Enabled"
                  active={metadata.file?.allowFeedbackFromExternal}
                  disabled={
                    User.data.selectedCommunity.settings?.preferences
                      ?.allowFeedbackFromExternal
                  }
                  onChange={async (value) => {
                    setMetadata({
                      ...metadata,
                      file: {
                        ...metadata.file,
                        allowFeedbackFromExternal: value,
                      },
                    });
                  }}
                />
              }
            />
          </Form.Tab>
          <Form.Tab icon="amd" label="Developer" hidden={!App.isDevToolsOpen}>
            <Button
              inline
              onClick={async () => {
                await context.saveDocument({
                  _id: metadata._id,
                  processing: {},
                });
              }}
            >
              Force clear all processing
            </Button>
          </Form.Tab>
          {/* )} */}
        </Form.Tabs>
      </Form>
    </Popup>
  );
}

export default FileForm;
