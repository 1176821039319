import { TextProps } from '@mantine/core';
import { AssetActionIds } from './components/AssetActions';

export const getDefaultAssetToolbar: (
  file: Pickit.FileInterface,
  props?: {
    exclude?: AssetActionIds[];
    readOnly?: boolean;
  },
) => AssetActionIds[] = (file, { exclude, readOnly } = {}) => {
  return (
    [
      'download',
      'download_external',
      'open_external',
      'open_document',
      'open',
      'open_and_edit',
      'open_slides',
      'copy',
      'preview',
      'favorite',
      'add_to_bag',
      // 'libraries',
      ...(readOnly ? ['leave_feedback'] : []),
      ...(!readOnly ? ['transfers'] : []),
      ...(!readOnly ? ['settings'] : []),
      ...(file?.trash?.isTrash ? ['recover_from_trash'] : []),
    ] as AssetActionIds[]
  ).filter((v) => !exclude?.includes?.(v));
};

export const AssetPermissions: Record<
  string,
  {
    label: string;
    tooltip?: string;
    rightProps?: TextProps;
  }
> = {
  none: {
    label: 'Only admins',
    rightProps: {
      c: 'red',
      fw: 600,
    },
  },
  'not-set': {
    label: 'Inherit',
    tooltip:
      'Inherit permissions from parent asset such as collection or board',
  },
  specific: {
    label: 'Specific',
  },
  all: {
    label: 'All',
  },
};
