import { useUserContext } from 'hooks/useUserContext';
import { useEffect, useState, useRef } from 'react';
import Dialog from 'utils/Dialog';
import localStorageWrapper from 'utils/localStorageWrapper';

export default function useBoxAuth() {
  const User = useUserContext();
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const isRefreshingToken = useRef(false);
  const token = useRef({});

  useEffect(() => {
    const token_ = localStorageWrapper.getItem('userBoxToken');
    if (token_) {
      token.current = JSON.parse(token_);
    }
  }, []);

  useEffect(() => {
    if (token.current.access_token) {
      setIsConnected(true);
      setIsConnecting(false);
    }
  }, [token.current]);

  function connectToken(code) {
    getToken(code);
  }
  async function waitForRefreshToken() {
    return new Promise((resolve) => {
      const checkExist = setInterval(() => {
        if (!isRefreshingToken.current) {
          resolve(true);
          clearInterval(checkExist);
        }
      }, 100);
    });
  }

  async function request(
    path,
    options = {},
    scope,
    prefs = {},
    refreshedToken,
    signal,
  ) {
    let useToken = refreshedToken || token.current;
    if (isRefreshingToken.current) {
      await waitForRefreshToken();
    }
    if (!useToken) {
      return false;
    }
    if (!isConnected && !prefs.ignoreConnected) {
      return false;
    }
    const headers = {
      Authorization: `Bearer ${useToken?.access_token}`,
      ...(options?.headers || {}),
    };
    if (typeof options?.body === 'object') {
      options.body = JSON.stringify(options.body);
      headers['Content-Type'] = 'application/json';
    }
    try {
      const req = signal ? 
        await fetch(`https://api.box.com${path}`, {
          ...options,
          headers,
          signal,
        })
      :
        await fetch(`https://api.box.com${path}`, {
          ...options,
          headers,
        });

      if (req.status === 401) {
        if (refreshedToken) return;
        useToken = await refreshToken(scope);
        if (!useToken) {
          return disconnect(true);
        }
        return request(
          path,
          {
            ...options,
            headers: {
              ...headers,
              Authorization: `Bearer ${useToken?.access_token}`,
            },
          },
          scope,
        );
      }
      if (!req.ok && req.status !== 200) {
        throw req;
      }
      try {
        const data = await req.json();
        return data;
      } catch (e) {
        throw req;
      }
    } catch (e) {
      if(e.name === "AbortError") return "AbortError";
      console.error(e.status);
      if (!refreshedToken && e.status !== 404) {
        const newToken = await refreshToken(scope);
        if (newToken) return request(path, options, scope, prefs, newToken);

        return disconnect(true);
      }
      if (e.status === 401 || e.status === 403) {
        return disconnect(true);
      }
    }
  }

  async function refreshToken(scope) {
    if (isRefreshingToken.current) {
      await waitForRefreshToken();
      return token.current;
    }
    if (!isConnected) {
      return false;
    }
    isRefreshingToken.current = true;
    try {
      const data = await User.request.files.box.refreshToken(
        token.current?.refresh_token,
      );
      if (!data?.access_token) {
        disconnect(true);
        return false;
      }
      token.current = data;

      localStorageWrapper.removeItem('userBoxToken');
      localStorageWrapper.setItem('userBoxToken', JSON.stringify(data));
      isRefreshingToken.current = false;

      return data;
    } catch (e) {
      disconnect(true);
      return false;
    }
  }

  async function getToken(code) {
    localStorageWrapper.removeItem('userBoxToken');
    const data = await User.request.files.box.getToken(code);
    if (!data?.access_token) {
      return disconnect(true);
    }
    token.current = data;
    localStorageWrapper.setItem('userBoxToken', JSON.stringify(data));
    setIsConnecting(false);
  }

  function connect() {
    const baseUrl = 'https://account.box.com/api/oauth2/authorize';
    const authorizationUrl = `${baseUrl}?client_id=${window.pickit.keys.BOX_KEY}&response_type=code&redirect_uri=${window.location.origin}/genericoauth.html`;
    const dialog = new Dialog(authorizationUrl, 1000, 700);
    let isConnectImmediate = false;
    return new Promise((resolve, reject) => {
      dialog.open(async (authToken) => {
        if (
          authToken &&
          typeof authToken === 'string' &&
          !isConnecting &&
          !isConnectImmediate
        ) {
          setIsConnecting(true);
          getToken(authToken);
          isConnectImmediate = true;
        } else {
          reject(false);
          return false;
        }
      });
    });
  }

  function disconnect(/* automatic */) {
    localStorageWrapper.removeItem('userBoxToken');
    token.current = {};
    setIsConnected(false);
  }

  return {
    token: { ...token.current },
    isConnected,
    isConnecting,
    connect,
    request,
    disconnect,
    connectToken,
  };
}
