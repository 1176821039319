import SelectGroupsDropdown from 'components/SelectGroupsDropdown';
import SelectUsersDropdown from 'components/SelectUsersDropdown';
import { Button, Flex, Form, Icon, Popup, Switch } from 'elements_v2';
import CheckboxCards from 'elements_v2/CheckboxCards';
import Input from 'elements_v2/Input/index_ts';
import Tags from 'elements_v2/Tags';
import React, { useEffect, useReducer } from 'react';

export function ModuleMetadataInputGroup({ children, label }) {
  return (
    <div className="Board__ModuleMetadata__InputGroup">
      <div className="Board__ModuleMetadata__InputGroup__content">
        <h3>{label}</h3>
        <div className="Board__ModuleMetadata__InputGroup__content__inputs">
          {children}
        </div>
      </div>
    </div>
  );
}

export default function ModuleMetadata({
  Module,
  Form: FormRender,
  isActive,
  setIsActive,
}) {
  const className = ['Board__ModuleMetadata'];
  const [error, setError] = useReducer((state, action) => ({ ...action }), {});
  const [metadata, setMetadata] = useReducer((state, action) => {
    setError({});
    return {
      ...state,
      ...action,
    };
  }, {});
  const [moduleData, setModuleData] = useReducer(
    (state, action) => ({
      ...action,
    }),
    [],
  );

  const originalModuleData = Module.getModule();

  useEffect(() => {
    if (Module.metadata) {
      setMetadata(Module.metadata);
    }
  }, [Module.metadata]);

  if (isActive) {
    className.push('Board__ModuleMetadata--active');
  }

  return (
    <Popup
      isOpen={isActive}
      wrapperStyle={{ width: '100%', maxWidth: 1100 }}
      style={{ width: '100%', maxWidth: 1100 }}
      zIndex={99}
      contentStyle={{
        width: '100%',
        height: '60vh',
        padding: 0,
      }}
      onClose={() => setIsActive(false)}
      title={`Section settings: ${Module.name}`}
      actions={
        <Flex style={{ width: '100%' }} justifyContent="space-between">
          <Button inline pixi onClick={() => setIsActive(false)}>
            Cancel
          </Button>
          <Button
            primary
            inline
            pixi
            onClick={async () => {
              try {
                await Module.savePartialWithDraft([moduleData]);
                await Module.saveMetadata(metadata);
              } catch (e) {
                console.log(e);
                setError(e);
              }
              setIsActive(false);
            }}
          >
            Save
          </Button>
        </Flex>
      }
    >
      <Form.Tabs>
        <Form.Tab icon="toggles2" label="Metadata">
          <FormRender
            Module={Module}
            error={error}
            data={Module.data}
            metadata={metadata || {}}
            setMetadata={setMetadata}
          />
        </Form.Tab>
        <Form.Tab
          id="access"
          label={
            <>
              <Icon name="LockFill" />
              Access
            </>
          }
          header={
            <>
              <h1>Access</h1>
              <p>Control who can access the contents of this section</p>
            </>
          }
        >
          <CheckboxCards
            value={
              moduleData['access.share_to'] ||
              originalModuleData?.access?.share_to
            }
            onChange={(val) =>
              setModuleData({
                ...moduleData,
                'access.share_to': val,
              })
            }
            cards={[
              {
                value: 'all',
                title: 'Inherit access',
                description:
                  'Section will have same permission as the board/sub-guide',
              },
              {
                value: 'authenticated',
                title: 'Authenticated users',
                description:
                  'Only signed in users in your account can access this section.',
              },
              {
                value: 'unauthenticated',
                title: 'Unauthenticated users',
                description: 'Only unauthenticated users will see this section',
              },
            ]}
          />
          <CheckboxCards
            value={
              moduleData['access.share_to'] ||
              originalModuleData?.access?.share_to
            }
            style={{ marginTop: 5 }}
            onChange={(val) =>
              setModuleData({
                ...moduleData,
                'access.share_to': val,
              })
            }
            cards={[
              {
                value: 'specific',
                title: 'Specific users',
                description:
                  'Select specific users that can access this section. All admins can still access it.',
              },
              {
                value: 'none',
                title: 'Only admins',
                description: 'Only admins can access this section.',
              },
            ]}
          />
          {moduleData['access.share_to'] === 'specific' && (
            <div style={{ marginTop: 10 }}>
              <SelectUsersDropdown
                placeholder="Select specific users"
                value={
                  moduleData['access.share_to_users'] ||
                  originalModuleData?.access?.share_to_users ||
                  []
                }
                onChange={(users) =>
                  setModuleData({
                    ...moduleData,
                    'access.share_to_users': users,
                  })
                }
              />
              <SelectGroupsDropdown
                placeholder="Select specific groups"
                style={{ marginTop: 10 }}
                value={moduleData['access.share_to_user_groups'] || []}
                onChange={(groups) =>
                  setModuleData({
                    ...moduleData,
                    'access.share_to_user_groups': groups,
                  })
                }
              />
            </div>
          )}
        </Form.Tab>
        <Form.Tab
          id="information"
          label={
            <>
              <Icon name="info-circle-fill" />
              Information
            </>
          }
        >
          <Input.Field
            label="Searchable tags"
            description="If search is enabled and one of the tags is matched the section result will be placed in top."
            field={
              <Tags
                inputType="text"
                value={moduleData.tags || originalModuleData?.tags}
                placeholder="Add tag"
                style={{ padding: 0 }}
                onChange={(value) =>
                  setModuleData({
                    ...moduleData,
                    tags: value,
                  })
                }
              />
            }
          />
          <Input.Field
            label="Hide from search"
            labelStyle={{ margin: 0 }}
            custom={
              <Switch
                label="Hide"
                active={
                  typeof moduleData['preferences.hideInSearchResults'] ===
                  'boolean'
                    ? moduleData['preferences.hideInSearchResults']
                    : originalModuleData?.preferences?.hideInSearchResults
                }
                onChange={(value) =>
                  setModuleData({
                    'preferences.hideInSearchResults': value,
                  })
                }
                style={{ background: 'rgba(0, 0, 0, 0.05)' }}
              />
            }
            description={`If search is enabled this section or it's contents will not show up on search results.`}
          />
        </Form.Tab>
      </Form.Tabs>
    </Popup>
  );
}
