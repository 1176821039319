import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  closestCorners,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { Box } from '@mantine/core';
import { useEffect, useState } from 'react';
import { AssetIcon } from './components/AssetThumbnail';
import { getConfigStore, useDataStoreCallback } from './store';
import { AppConfigInterface } from './AppController';
import AppGlobalEvents from './AppGlobalEvents';
import CollectionThumbnail from './components/CollectionThumbnail';

export default function AppGlobalWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 50,
      },
    }),
  );
  const [draggingAsset, setDraggingAsset] = useState<
    AppConfigInterface['draggingAsset']
  >({});
  const draggingFile = useDataStoreCallback(
    'FILES',
    (data) => data.find((f) => f?._id === draggingAsset?.id),
    [draggingAsset],
  );

  useEffect(() => {
    getConfigStore('APP_CONFIG').setValue('draggingAsset', draggingAsset);
  }, [draggingAsset]);
  return (
    <DndContext
      onDragStart={(event) =>
        setDraggingAsset({
          type: event.active.data?.current?.type as 'file' | 'collection',
          id: event.active.id as string,
        })
      }
      onDragEnd={(event) => {
        AppGlobalEvents.dispatch(
          'assetDrop',
          event.over?.id as string,
          draggingAsset,
        );
        setDraggingAsset({});
      }}
      // collisionDetection={closestCorners}
      modifiers={[snapCenterToCursor]}
      sensors={sensors}
      onDragCancel={() => {}}
      autoScroll={false}
    >
      {children}
      <DragOverlay
        style={{
          background: 'transparent',
        }}
        dropAnimation={null}
      >
        {draggingAsset?.type ? (
          <Box
            w="auto"
            style={{
              display: 'flex',
              justifyContent: 'center',
              cursor: 'grabbing',
            }}
          >
            {draggingAsset?.type === 'file' && draggingFile?._id && (
              <AssetIcon file={draggingFile} />
            )}
            {draggingAsset?.type === 'collection' && (
              <>
                <CollectionThumbnail id={draggingAsset?.id} />
              </>
            )}
          </Box>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}
