import {
  Checkbox,
  Group,
  NumberInput,
  TagsInput,
  TextInput,
} from '@mantine/core';
import PixiForm from '@pixi/elements/Form';
import { useUserContext } from 'hooks';
import { ReactElement } from 'react';

export default function PresetForm({
  target,
  data,
}: {
  target: ReactElement;
  data?: Pickit.CommunityPresetInterface;
}) {
  const User = useUserContext();
  return (
    <PixiForm<{
      name: string;
      width: number;
      height: number;
      forced: boolean;
    }>
      type="dropdown"
      target={target}
      trigger="focus"
      title="Create custom format"
      submitLabel="Create format"
      submitProps={({ name, width, height }) => ({
        disabled: !name || !width || !height,
      })}
      onSubmit={async (newData) => {
        if (data?._id) {
          await User.saveSettingsPartial(
            {
              $set: {
                'imageEditorPresets.$': {
                  _id: data._id,
                  ...newData,
                },
              },
            },
            {
              'imageEditorPresets._id': data._id,
            },
          );
          return;
        }
        await User.saveSettingsPartial({
          $push: {
            imageEditorPresets: {
              name: newData.name,
              width: newData.width,
              height: newData.height,
              lockRatio: newData.forced,
            },
          },
        });
      }}
      form={{
        name: {
          key: 'name',
          value: data?.name || '',
          render: ({ value, setValue }) => (
            <TextInput
              label="Label"
              value={value}
              placeholder="E.g. Social media"
              onChange={(event) => {
                setValue(event.currentTarget.value);
              }}
            />
          ),
        },
        width: {
          key: 'width',
          value: data?.width || 0,
          render: ({ data, setData }) => (
            <Group wrap="nowrap" gap="5">
              <NumberInput
                label="Width"
                value={data.width}
                max={2000}
                min={1}
                onChange={(val) => {
                  setData({
                    width: parseInt(val as string),
                  });
                }}
              />
              <NumberInput
                label="Height"
                value={data.height}
                max={2000}
                min={1}
                onChange={(val) => {
                  setData({
                    height: parseInt(val as string),
                  });
                }}
              />
            </Group>
          ),
        },
        height: {
          key: 'height',
          value: data?.height || 0,
          render: () => <></>,
        },
        // forced: {
        //   key: 'forced',
        //   value: data?.lockRatio || false,
        //   render: ({ value, setValue }) => (
        //     <Checkbox
        //       label="Lock aspect ratio"
        //       checked={value}
        //       onChange={(event) => {
        //         setValue(event.currentTarget.checked);
        //       }}
        //     />
        //   ),
        // },
      }}
    />
  );
}
