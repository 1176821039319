export interface AppUserInterface {
  user: any;
  community: {
    data: any;
    prefs?: {
      isUsingUsersV2?: boolean;
    };
  };
}

export const AppUser: AppUserInterface = {
  user: null,
  community: {
    data: null,
    prefs: {
      isUsingUsersV2: false,
    },
  },
};
