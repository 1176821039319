import {
  Checkbox,
  Group,
  NumberInput,
  Paper,
  ScrollArea,
  Stack,
  TagsInput,
  TextInput,
} from '@mantine/core';
import PixiButton from '@pixi/elements/Button';
import PixiDropdown from '@pixi/elements/Dropdown';
import PixiForm from '@pixi/elements/Form';
import { useUserContext } from 'hooks';
import { ReactElement } from 'react';

export default function TagsForm({
  target,
  file,
  files,
  context,
}: {
  target: ReactElement;
  file?: Pickit.FileInterface;
  files?: Pickit.FileInterface[];
  context: any;
}) {
  const User = useUserContext();
  return (
    <PixiForm<{
      tags: string[];
      globalTags?: string[];
      globalTagsSearch?: string;
      tagToBeAdded?: string;
    }>
      type="dropdown"
      width={400}
      title="Edit tags"
      submitLabel="Save tags"
      zIndex={999}
      onSubmit={async (data) => {
        if (files?.length) {
          await context.saveDocumentsWhere(
            {
              _id: { $in: files.map((file) => file._id) },
            },
            {
              $addToSet: {
                tags: { $each: data.tags },
              },
            },
          );
          return;
        }
        await context.savePartial({
          _id: file?._id,
          tags: data.tags,
        });
      }}
      form={{
        tags: {
          key: 'tags',
          value: file?.tags || [],
          render: ({ value, setValue, data, setData }) => (
            <TagsInput
              value={value}
              searchValue={data.tagToBeAdded}
              onSearchChange={(value) => {
                setData({
                  tagToBeAdded: value,
                });
              }}
              onChange={(value) => {
                setValue(value);
              }}
              onBlur={() => {
                if (data.tagToBeAdded) {
                  setData({
                    tags: [...value, data.tagToBeAdded],
                    tagToBeAdded: '',
                  });
                }
              }}
            />
          ),
        },
        globalTags: {
          key: 'globalTags',
          value: [],
          render: ({ data, setData }) => (
            <>
              <PixiDropdown
                width={350}
                target={
                  <PixiButton size="xs" color="dark" variant="light">
                    Add from global tags
                  </PixiButton>
                }
                zIndex={1000}
              >
                {({ setIsOpen }) => (
                  <>
                    <Group justify="space-between">
                      <PixiDropdown.Label>Global tags</PixiDropdown.Label>
                    </Group>
                    <Stack maw="100%" gap="5">
                      <Group gap="2" wrap="nowrap">
                        <TextInput
                          w="100%"
                          placeholder="Search"
                          size="xs"
                          value={data?.globalTagsSearch}
                          autoFocus
                          onChange={(event) => {
                            setData({
                              globalTagsSearch: event.currentTarget.value,
                            });
                          }}
                        />
                        {typeof data?.globalTagsSearch === 'string' &&
                          !(
                            User?.data?.selectedCommunity.communityTags || []
                          ).filter((t: string) => {
                            if (data?.globalTagsSearch) {
                              return t
                                .toLowerCase()
                                .includes(data?.globalTagsSearch.toLowerCase());
                            }
                            return true;
                          })?.length && (
                            <PixiButton
                              size="xs"
                              color="primary"
                              variant="light"
                              style={{ flexShrink: 0 }}
                              onClick={async () => {
                                await User.saveSettingsPartial({
                                  $addToSet: {
                                    communityTags: data?.globalTagsSearch,
                                  },
                                });
                                setData({
                                  tags: data.tags.includes(
                                    data?.globalTagsSearch as string,
                                  )
                                    ? data.tags.filter(
                                        (tag) =>
                                          tag !==
                                          (data?.globalTagsSearch as string),
                                      )
                                    : [
                                        ...data.tags,
                                        data?.globalTagsSearch as string,
                                      ],
                                });
                              }}
                            >
                              Add & save as global tag
                            </PixiButton>
                          )}
                      </Group>
                      <Paper p="xs" withBorder>
                        <ScrollArea h={250} mah={250}>
                          <Group gap="5">
                            {(User?.data?.selectedCommunity.communityTags || [])
                              .filter((t: string) => {
                                if (data?.globalTagsSearch) {
                                  return t
                                    .toLowerCase()
                                    .includes(
                                      data?.globalTagsSearch.toLowerCase(),
                                    );
                                }
                                return true;
                              })
                              .map((t: string) => (
                                <PixiButton
                                  size="compact-xs"
                                  radius="xl"
                                  color={
                                    data?.tags.includes(t) ? 'primary' : 'dark'
                                  }
                                  onClick={() => {
                                    setData({
                                      tags: data.tags.includes(t)
                                        ? data.tags.filter((tag) => tag !== t)
                                        : [...data.tags, t],
                                    });
                                  }}
                                  variant={
                                    data?.tags.includes(t) ? 'filled' : 'light'
                                  }
                                  key={t}
                                >
                                  {t}
                                </PixiButton>
                              ))}
                          </Group>
                        </ScrollArea>
                      </Paper>
                      <PixiButton
                        variant="light"
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        Done
                      </PixiButton>
                    </Stack>
                  </>
                )}
              </PixiDropdown>
            </>
          ),
        },
      }}
      target={target}
    />
  );
}
