import useCloudStorage from 'hooks/useCloudStorage';
import { useUserContext } from 'hooks/useUserContext';
import { useEffect, useReducer, useState } from 'react';
import { sleep } from 'utils';
import Dialog from 'utils/Dialog';
import localStorageWrapper from 'utils/localStorageWrapper';

export default function useMicrosoftGraph() {
  const User = useUserContext();
  const [token, setToken] = useReducer((state, action) => ({ ...action }), {});
  const [isRefreshingToken, setIsRefreshingToken] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const cloudStorage = useCloudStorage();

  useEffect(() => {
    const token_ = localStorageWrapper.getItem('userMicrosoftGraphToken');
    if (token_) {
      setToken(JSON.parse(token_));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(token)?.length) {
      localStorageWrapper.setItem(
        'userMicrosoftGraphToken',
        JSON.stringify(token),
      );
    }
  }, [token]);

  useEffect(() => {
    if (token.access_token) {
      setIsConnected(true);
    }
  }, [token]);

  function connectToken(token, scope, redirectUri) {
    getToken(token, scope, redirectUri);
  }

  async function request(path, options = {}, scope, triedRefresh) {
    try {
      if (!token) {
        return false;
      }
      if (!isConnected) {
        return false;
      }
      const headers = {
        Authorization: `Bearer ${token?.access_token}`,
        ...(options?.headers || {}),
      };
      const req = await fetch(
        path.includes('http')
          ? path
          : `https://graph.microsoft.com/v1.0${path}`,
        {
          ...options,
          headers,
        },
      );
      if (req.status === 429) {
        await sleep(2000);
        return request(path, options, scope, triedRefresh);
      }
      if (req.status === 401) {
        try {
          const info = await req.json();
          if (info?.error?.code === 'InvalidAuthenticationToken') {
            if (triedRefresh) throw req;
            const token = await refreshToken(scope);
            if (!token) {
              return disconnect(true);
            }
            return request(
              path,
              {
                ...options,
                headers: {
                  ...headers,
                  Authorization: `Bearer ${token?.access_token}`,
                },
              },
              scope,
              true,
            );
          }
        } catch (e) {}
      }
      if (!req.ok) {
        const data = await req.json();
        throw data;
      }
      try {
        const data = await req.json();
        return data;
      } catch (e) {
        throw req;
      }
    } catch (e) {
      throw e;
    }
  }

  async function refreshToken(scope) {
    if (isRefreshingToken) {
      return false;
    }
    if (!isConnected) {
      return false;
    }
    setIsRefreshingToken(true);
    try {
      const data = await User.request.files.microsoft.refreshToken(
        token?.refresh_token,
        [scope],
        `${window.location.origin}/aadredirect.html`,
      );
      if (!data?.access_token) {
        disconnect(true);
        return false;
      }
      setToken(data);
      setIsRefreshingToken(false);
      return data;
    } catch (e) {
      disconnect(true);
      return false;
    }
  }

  async function getToken(code, scope, redirectUri) {
    const data = await User.request.files.microsoft.getToken(
      code,
      scope,
      redirectUri || `${window.location.origin}/aadredirect.html`,
    );
    if (!data?.access_token) {
      return disconnect(true);
    }
    setToken(data);
    setIsConnecting(false);
  }

  function connect(scope) {
    const dialog = new Dialog(
      `${window.location.origin}/aad.html?scope=${scope.join(
        '%20',
      )}%20offline_access`,
      1000,
      700,
    );
    return new Promise((resolve, reject) => {
      dialog.open(async (authToken) => {
        if (authToken && typeof authToken === 'string') {
          setIsConnecting(true);
          getToken(authToken, scope);
        } else {
          reject(false);
          return false;
        }
      });
    });
  }

  function disconnect(automatic) {
    setIsConnected(false);
    localStorageWrapper.removeItem('userMicrosoftGraphToken');
    if (!automatic) {
      cloudStorage.set('notifications.sharepointDisconnectWarning', true);
    } else {
      cloudStorage.set('notifications.sharepointDisconnectWarning', false);
    }
  }
  return {
    token,
    isConnected,
    isScopeConnected: (scope) => token?.scope?.includes(scope),
    isConnecting,
    connect,
    request,
    disconnect,
    connectToken,
  };
}
