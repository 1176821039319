import PixiButton from '@pixi/elements/Button';
import PixiIcon from '@pixi/elements/Icon';
import PixiTooltip from '@pixi/elements/Tooltip';
import { ToastService } from 'elements/Toast';
import { useBrand, useTextContext, useUserContext } from 'hooks';
import useNotifications from 'hooks/useNotifications';
import React, { useState } from 'react';
import { downloadUrl } from 'utils';
import { useMultiDataStoreCallback } from '@pixi/store';

export default function CollectionToolbar({
  collection,
  totalFiles,
  context,
  disableActions,
}) {
  const User = useUserContext();
  const Text = useTextContext();
  const Notifications = useNotifications();

  const [isFollowingCollection, setIsFollowingCollection] = useState(
    !!User.cloudStorage.following?.collections?.find(
      (item) => item.id === collection?._id,
    ),
  );
  return (
    <>
      {/* {!!downloadBagFiles.length && (
        <PixiDropdown
          width={250}
          target={
            <PixiTooltip label="Download files">
              <PixiButton px="sm" variant="filled" color="primary">
                <PixiIcon name="bag-shopping" />
              </PixiButton>
            </PixiTooltip>
          }
        >
          <AppDownloadBag />
        </PixiDropdown>
      )} */}
      {collection?.preferences?.enable_zip_download && (
        <PixiTooltip
          delay={0.3}
          label={Text.get('view/collection/download_as_zip')}
        >
          <PixiButton
            px="sm"
            size="sm"
            h="auto"
            variant="glass"
            color="dark"
            onClick={() => {
              downloadUrl(
                `https://collection-downloader.azurewebsites.net/download/collection?collection_id=${
                  collection._id
                }${
                  User.token?.jwt ? `&jwt=${User.token?.jwt}` : ''
                }&community_slug=${collection.communitySlug}&service=${
                  collection.libraryType
                }&context=${context}`,
              );
            }}
          >
            <PixiIcon name="cloud-arrow-down" />
          </PixiButton>
        </PixiTooltip>
      )}
      {!disableActions?.includes('favorite') && User.authenticated && (
        <PixiTooltip
          delay={0.3}
          label={
            isFollowingCollection
              ? Text.get('view/collection/unfollow')
              : Text.get('view/collection/follow')
          }
        >
          <PixiButton
            px="sm"
            size="sm"
            h="auto"
            variant="glass"
            color="dark"
            onClick={() => {
              setIsFollowingCollection(!isFollowingCollection);
              if (isFollowingCollection) {
                Notifications.unfollowCollection(collection);
                ToastService.createToast({
                  message: Text.get('view/collection/unfollowed'),
                });

                return;
              }
              Notifications.followCollection(collection, totalFiles);
              ToastService.createToast({
                message: Text.get('view/collection/following'),
              });
            }}
          >
            <PixiIcon
              variant={isFollowingCollection ? 'filled' : undefined}
              name="heart"
            />
          </PixiButton>
        </PixiTooltip>
      )}
    </>
  );
}
