import { FileThumbnailV2 } from 'components/FileThumbnail';
import { Tag } from 'elements';
import {
  Button,
  Dropdown,
  Flex,
  Icon,
  Input,
  Intro,
  List,
  Loader,
  ManageView,
  Popup,
  Progress,
  Form,
  Search,
  Popover,
  Confirm,
  Switch,
  Tooltip,
} from 'elements_v2';
import {
  Box,
  TextInput,
} from '@mantine/core';
import Badge from 'elements_v2/Badge';
import { useMediaContext, useUserContext } from 'hooks';
import useAttributeScanner from 'hooks/files/useAttributeScanner';
import { useGetFileIdFromLibrary } from 'hooks/files/useParsedFile';
import useStorageUrl from 'hooks/files/useStorageUrl';
import { useRouter } from 'hooks/useRouter';
import useUserStatus from 'hooks/useUserStatus';
import { useEffect, useReducer, useState } from 'react';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import { generatePath } from 'react-router-dom';
import { AttributeForm } from 'views/_Manage/Files/views/FileAttributes';
import Illustration from './attributes.svg';
import { openAssetPreview } from '@pixi/AppController';
import PixiDropdown from '@pixi/elements/Dropdown';
import AssetPreviewFacialRecognitionSelect from '@pixi/components/AssetPreview/views/AssetPreviewFacialRecognitionSelect';

function Toolbar(props) {
  const Router = useRouter();
  const Media = useMediaContext();
  const User = useUserContext();
  const userStatus = useUserStatus();

  useEffect(() => {
    if (User.authenticated && User.data?.selectedCommunity?.communitySlug) {
      Media.getAttributesEstimation();
    }
  }, [User.authenticated, User.data?.selectedCommunity?.communitySlug]);

  useEffect(() => {
    if (!Router.match.params.action) {
      if (Media.attributesEstimation?.amount) {
        Router.history.push(
          generatePath(Router.match.path, {
            page: 'properties',
            action: 'scanner',
          }),
        );
      } else {
        Router.history.push(
          generatePath(Router.match.path, {
            page: 'properties',
            action: 'configure',
          }),
        );
      }
    }
  }, [Router.match.path, Media.attributesEstimation]);

  return (
    <>
      {!!Media.attributesEstimation?.amount && (
        <Button
          inline
          pixi
          primary
          highlight
          onClick={() => {
            Router.history.push(
              generatePath(Router.match.path, {
                page: 'properties',
                action: 'scanner',
                id: Router.match.params.id,
              }),
            );
          }}
        >
          <Icon name="search" />
          Extract metadata
        </Button>
      )}
      {userStatus.activeFeatures?.attributes && (
        <Button
          inline
          pixi
          onClick={() => {
            Router.history.push(
              generatePath(Router.match.path, {
                page: 'properties',
                action: 'configure',
              }),
            );
          }}
        >
          Properties
        </Button>
      )}
      {/* {userStatus.activeFeatures?.pickit_vision_ai && (
        <Button
          inline
          pixi
          onClick={() => {
            Router.history.push(
              generatePath(Router.match.path, {
                page: 'attributes',
                action: 'faces',
              })
            );
          }}
        >
          Facial recognition
        </Button>
      )} */}
    </>
  );
}
Attributes.Toolbar = Toolbar;

export function Face() {
  const User = useUserContext();
  const Media = useMediaContext();
  const { generateUrl } = useStorageUrl();
  const getFile = useGetFileIdFromLibrary('media');
  const [selectedFile, setSelectedFile] = useReducer(
    (state, action) => ({ ...action }),
    {},
  );
  const [editFace, setEditFace] = useState(
    (state, action) => (!action?._id ? null : { ...action }),
    null,
  );
  const [faceName, setFaceName] = useState('');
  const [hasEditedFaceName, setHasEditedFaceName] = useState(false);
  const [savingFace, setSavingFace] = useState('');
  const [faces, setFaces] = useState([]);

  const [training, setTraining] = useReducer(
    (state, action) => ({ ...action }),
    {},
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [pageNr, setPageNr] = useState(1);
  const [previewImage, setPreviewImage] = useState({});
  const [mergeFace, setMergeFace] = useReducer(
    (state, action) => ({ ...action }),
    {},
  );

  useEffect(() => {
    setHasEditedFaceName(false);
    setFaceName('');
  }, [editFace?._id]);

  useEffect(() => {
    if (previewImage?.file) {
      openAssetPreview(previewImage?.file, {
        defaultView: 'facial_recognition',
        facialRecognition: {
          highlightFace: previewImage?.group?._id,
        },
        onClose: () => {
          getFaces();
        },
      });
    }
  }, [previewImage]);

  async function getFaces(page_nr = 1) {
    if (page_nr !== 1) {
      setIsLoadingMore(true);
    } else {
      setIsLoading(true);
    }
    const data = await Media.request.getFaces(page_nr);
    Media.setData({
      type: 'merge',
      data: data.files,
      key: '_id',
      store: 'files',
    });
    setIsLoading(false);
    if (page_nr !== 1) {
      setFaces((faces) => [...faces, ...data.faces]);
      setIsLoadingMore(false);
    } else {
      setFaces(data.faces);
      setIsLoading(false);
    }
  }

  async function getStatus() {
    const status = await Media.request.getFaceStatus();
    setTraining(status);
  }

  async function saveFace({ _id, name }) {
    setSavingFace(_id);
    const data = await Media.request.saveFace({ _id, name });
    setFaces((faces) =>
      faces.map((group) => (group._id === _id ? data : group)),
    );
    User.refreshProfile();
    setHasEditedFaceName(false);
    setFaceName('');
    setEditFace(false);
    setSavingFace('');
  }

  useEffect(() => {
    if (training?.status?.status === 'running') {
      const timeout = setTimeout(() => {
        getStatus();
      }, 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [training]);

  useEffect(() => {
    if (!pageNr) {
      setPageNr(1);
    } else {
      getFaces(pageNr);
    }
  }, [pageNr]);

  useEffect(() => {
    getStatus();
  }, []);

  if (isLoading) {
    return <Loader>Loading facial recognition</Loader>;
  }


  return (
    <BottomScrollListener
      onBottom={() => {
        if (isLoading || isLoadingMore) {
          return false;
        }
        setPageNr((pageNr) => pageNr + 1);
      }}
    >
      {(ref) => (
        <div ref={ref} style={{ maxHeight: 570, overflow: 'auto' }}>
          {selectedFile?._id && (
            <>
              Selected file {selectedFile?.name}
              <br />
              {/* <SelectFile
                context={Media}
                onChange={setSelectedFile}
                forceFilters={{
                  similarFace: selectedFile?._id,
                }}
                multiple
                trigger={(isOpen, setIsOpen) => (
                  <Button primary inline pixi onClick={async () => {
                    const files = await selectFile({
                      libraryId: 'media',
                    });
                    setIsOpen(true)
                    }}>
                    Find similar faces
                  </Button>
                )}
              /> */}
            </>
          )}
          <List cards>
            {faces.map((group, key) => {
              const name = group.name || `Person #${key}`;
              const images = group.faces
                .filter((face) => !!getFile(face.assetId)?._id)
                .map((face) => getFile(face.assetId));
              const faceList = group.faces.reduce((prev, item) => {
                if (prev.find((face) => face.assetId === item.assetId)) {
                  return prev;
                }
                prev.push(item);
                return prev;
              }, []);
              const profile =
                images.find((image) => image?.vision?.faces?.length === 1) ||
                images?.[0];
              const toName =
                mergeFace?.to?.name ||
                `Person #${faces?.findIndex(
                  (g) => g?._id === mergeFace?.to?._id,
                )}`;
              const fromName =
                mergeFace?.from?.name ||
                `Person #${faces?.findIndex(
                  (g) => g?._id === mergeFace?.from?._id,
                )}`;
              if (!profile?.file) {
                return <></>;
              }
              return (
                <Confirm
                  key={group?._id}
                  useV2
                  isForceOpen={mergeFace?.from?._id === group?._id}
                  overlay
                  forceClosed={mergeFace?.from?._id !== group?._id}
                  title={`Merge ${fromName} with ${toName}`}
                  description={`${fromName} is the same person as ${toName}. This can't be undone.`}
                  onClose={() => setMergeFace({})}
                  onCancel={() => setMergeFace({})}
                  onConfirm={async () => {
                    const { savedGroup, updatedFiles } =
                      await Media.request.mergeFaces(
                        mergeFace?.from?._id,
                        mergeFace?.to?._id,
                      );
                    setPageNr(0);
                    Media.setData({
                      type: 'merge',
                      store: 'files',
                      data: updatedFiles,
                      key: '_id',
                    });
                    setMergeFace({});
                  }}
                >
                  <List.Item
                    key={group._id}
                    iconStyle={{
                      maxWidth: 'none',
                      width: 'auto',
                    }}
                    icon={
                      <div
                        style={{
                          width: 70,
                          height: 70,
                          borderRadius: '50%',
                          overflow: 'hidden',
                        }}
                      >
                        <FileThumbnailV2
                          file={profile.file}
                          preferSize="small"
                          imageStyle={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                          noVideo
                        />
                      </div>
                    }
                    title={name}
                    subtitle={`${group._count} photos of this person`}
                    actions={
                      <>
                        {editFace?._id === group._id ? (
                          <form
                            onSubmit={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              saveFace({ _id: group._id, name: faceName });
                            }}
                          >
                            <Flex
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setEditFace(group);
                              }}
                            >
                              <Input.Field
                                placeholder="Name"
                                value={!hasEditedFaceName ? group.name : faceName}
                                onChange={(e) => {
                                  setHasEditedFaceName(true);
                                  setFaceName(e);
                                }}
                                autoFocus
                              />
                              <Button
                                primary
                                inline
                                onClick={() => {
                                  saveFace({ _id: group._id, name: faceName });
                                }}
                                loading={group._id === savingFace}
                                style={{ marginLeft: 5 }}
                              >
                                Save
                              </Button>
                            </Flex>
                          </form>
                        ) : (
                          <Button
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              setEditFace(group);
                            }}
                            inline
                          >
                            {!group.name ? 'Add name' : 'Change name'}
                          </Button>
                        )}
                        <AssetPreviewFacialRecognitionSelect
                          target={
                            <Button
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setEditFace(group);
                              }}
                              loading={
                                mergeFace?.from?._id === group._id ||
                                mergeFace?.to?._id === group._id
                              }
                              inline
                              rightIcon={
                                <Icon
                                  name="caret-down-fill"
                                  style={{ fontSize: 8, marginLeft: 10 }}
                                />
                              }
                              style={{ whiteSpace: 'nowrwap' }}
                            >
                              Merge with
                            </Button>
                          }
                          group={group}
                          onSubmit={getFaces}
                        />
                      </>
                    }
                  >
                    <Flex flexWrap="wrap" style={{ gap: 10 }}>
                      {faceList.map((face) => {
                        const file = getFile(face.assetId);
                        if (!file) {
                          return <></>;
                        }
                        return (
                          <div
                            style={{
                              width: 122,
                              height: 122,
                              display: 'inline-block',
                              cursor: 'pointer',
                              background: '#DADADA',
                            }}
                            key={file._id}
                            onClick={() => setPreviewImage({ file, group })}
                          >
                            <img
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                                objectPosition: 'center',
                              }}
                              alt={file.name}
                              title={file._id}
                              src={generateUrl(
                                file?.file?.previews?.[
                                  file?.file?.previews?.length - 1
                                ]?.url,
                              )}
                            />
                          </div>
                        );
                      })}
                    </Flex>
                  </List.Item>
                </Confirm>
              );
            })}
          </List>
          {isLoadingMore && <Loader />}
        </div>
      )}
    </BottomScrollListener>
  );
}
export function Scanner() {
  const Media = useMediaContext();
  const Router = useRouter();
  const scanner = useAttributeScanner(Media);

  useEffect(() => {
    if (
      scanner.isRunning &&
      scanner.latestMessage?.progress?.total &&
      scanner.latestMessage?.progress?.current &&
      scanner.latestMessage?.progress?.current ===
      scanner.latestMessage?.progress?.total
    ) {
      Media.getAttributesEstimation();
    }
  }, [
    scanner.latestMessage?.progress?.total,
    scanner.latestMessage?.progress?.current,
    scanner.isRunning,
  ]);

  if (
    scanner.isRunning &&
    scanner.latestMessage?.progress?.total &&
    scanner.latestMessage?.progress?.current &&
    scanner.latestMessage?.progress?.current ===
    scanner.latestMessage?.progress?.total
  ) {
    return (
      <Intro horizontal>
        <Intro.Illustration style={{ maxWidth: 300 }}>
          <img src={Illustration} alt="Illustration" />
        </Intro.Illustration>
        <Intro.Message>
          <h1>Scanner completed!</h1>
          <p>
            You can now get an overview of all your properties and organize it
            for search and preview.
            <br />
            <br />
            <Button
              size="large"
              onClick={() => {
                Router.history.push(
                  generatePath(Router.match.path, {
                    page: 'properties',
                    action: 'configure',
                    id: Router.match.params.id,
                  }),
                );
              }}
              primary
              inline
              pixi
              style={{ marginTop: 10 }}
            >
              Get started
            </Button>
          </p>
        </Intro.Message>
      </Intro>
    );
  }

  return (
    <Intro horizontal>
      <Intro.Illustration style={{ maxWidth: 300 }}>
        <img src={Illustration} alt="Illustration" />
      </Intro.Illustration>
      <Intro.Message>
        <h1>Extract metadata</h1>
        <>
          {scanner.isStarted ? (
            <>
              <Progress
                current={scanner.latestMessage?.progress?.current}
                currentLabel={scanner.latestMessage?.progress?.current}
                max={scanner.latestMessage?.progress?.total}
                maxLabel={`${scanner.latestMessage?.progress?.total} files`}
              />
              {!!scanner.latestMessage?.progress?.total && (
                <small style={{ marginTop: 15, display: 'block' }}>
                  <strong>
                    {scanner.latestMessage?.progress?.total -
                      scanner.latestMessage?.progress?.current}{' '}
                    files
                  </strong>{' '}
                  remaining
                </small>
              )}
            </>
          ) : (
            <>
              <p>
                To work with the existing metadata of your files we need to add
                it to your properties.
              </p>
              <p>
                We found{' '}
                <strong>{Media.attributesEstimation?.amount} files</strong> with
                metadata, extractor should take around{' '}
                <strong>
                  {Media.attributesEstimation?.estimationInMinutes ||
                    Media.attributesEstimation?.estimationInSeconds}{' '}
                  {Media.attributesEstimation?.estimationInMinutes
                    ? 'minute(s)'
                    : 'second(s)'}
                  .
                </strong>
              </p>
            </>
          )}
        </>
        {scanner.isRunning && scanner.isStarted ? (
          <Button
            size="large"
            onClick={() => {
              scanner.stop();
            }}
            style={{ marginTop: 15 }}
            primary
            inline
            pixi
          >
            <Icon name="pause-circle" />
            Pause
          </Button>
        ) : scanner.isStarted ? (
          <>
            <Button
              size="large"
              onClick={() => {
                scanner.runScan();
              }}
              style={{ marginTop: 15 }}
              primary
              inline
              pixi
            >
              <Icon name="play-circle" />
              Resume
            </Button>
          </>
        ) : (
          <>
            <Flex style={{ marginTop: 10 }}>
              <Button
                size="large"
                onClick={() => {
                  scanner.runScan();
                }}
                primary
                inline
                loading={scanner.isRunning}
                pixi
              >
                <Icon name="PlayFill" />
                Start extracting
              </Button>
              <Popover
                useV2
                noTriggerWrapper
                hover
                width={350}
                trigger={
                  <Button inline>
                    <Icon name="question-circle" style={{ marginRight: 0 }} />
                  </Button>
                }
                boxContentStyle={{ padding: 15 }}
              >
                Almost all images have hidden metadata such as camera model,
                tags, description, copyright etc.
                <br />
                <br />
                Our extractor will add this metadata to your properties so that
                you can do things such as adding them to your filters, make them
                searchable or display them on your files.
              </Popover>{' '}
            </Flex>
            <p style={{ fontSize: 14 }}>
              We only read and copy the values, no metadata will be removed. You
              can always pause the extractor and resume it later.
            </p>
          </>
        )}
      </Intro.Message>
    </Intro>
  );
}

export function Attributes(props) {
  const Router = useRouter();

  if (Router.match.params.action === 'scanner') {
    return <Scanner />;
  }
  if (Router.match.params.action === 'faces') {
    return <Face />;
  }
  if (Router.match.params.action === 'configure') {
    return <Configure />;
  }
  return <Configure />;
}
export function Configure(props) {
  const [attributes, setAttributes] = useReducer(
    (state, action) => [...action],
    [],
  );
  const [isLoading, setIsLoading] = useState(false);
  const [totalFiles, setTotalFiles] = useState(0);
  const [query, setQuery] = useState(null);
  const [type, setType] = useState('all');
  const [sortBy, setSortBy] = useState(null);
  const [customLabel, setCustomLabel] = useReducer(
    (state, action) => ({ ...action }),
    {},
  );
  const [savingField, setSavingField] = useState('');
  const [ignoreFilter, setIgnoreFilter] = useReducer(
    (state, action) => [...state, action],
    [],
  );
  const User = useUserContext();
  const Media = useMediaContext();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (customLabel.field && customLabel.label) {
        saveCustomLabel(customLabel);
      }
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [customLabel]);

  let { searchable, previewable, labels } = User.data.selectedCommunity
    ?.settings?.attributes || {
    searchable: [],
    previewable: [],
    labels: [],
  };

  if (!searchable) {
    searchable = [];
  }
  if (!previewable) {
    previewable = [];
  }
  if (!labels) {
    labels = [];
  }

  async function savePreviewable(key) {
    setSavingField(key);
    setIgnoreFilter(key);
    let { previewable } = User.data.selectedCommunity?.settings?.attributes || {
      searchable: [],
      previewable: [],
    };
    if (!previewable) {
      previewable = [];
    }
    await User.saveSettings({
      attributes: {
        previewable: previewable.includes(key)
          ? previewable.filter((value) => value !== key)
          : [...previewable, key],
      },
    });
    setSavingField(false);
  }

  async function saveSearchable(key) {
    setSavingField(key);
    setIgnoreFilter(key);
    let searchable =
      User.data.selectedCommunity?.settings?.attributes?.searchable || [];
    if (!searchable) {
      searchable = [];
    }
    await User.saveSettings({
      attributes: {
        searchable: searchable.includes(key)
          ? searchable.filter((value) => value !== key)
          : [...searchable, key],
      },
    });
    setSavingField(false);
  }

  async function saveCustomLabel({ label, field, isCustom }) {
    setSavingField(field);
    const labels =
      User.data.selectedCommunity?.settings?.attributes?.labels || [];
    const existingCustom = labels.find((data) => data.field === field);
    await User.saveSettings({
      attributes: {
        labels: (existingCustom && !label
          ? labels.filter((value) => value.field !== field)
          : existingCustom
            ? labels.map((value) =>
              value.field === field ? { field, label, isCustom } : value,
            )
            : [...labels, { field, label }]
        )
          ?.filter((data) => !!data.field && !!data.label)
          .filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.field === value.field),
          ),
      },
    });
    setCustomLabel({});
    setSavingField(false);
  }

  async function getAttributes() {
    setIsLoading(true);
    const data = await Media.request.getAttributes();
    setAttributes(data.attributes);
    setTotalFiles(data.filesWithAttributes);
    setIsLoading(false);
  }

  function renderTag(v) {
    return (
      <Tag style={{ maxWidth: '100%', wordWrap: 'break-word' }}>
        {Array.isArray(v) ? v.join(', ') : v}
      </Tag>
    );
  }

  useEffect(() => {
    getAttributes();
  }, []);

  const filteredAtttributes = attributes
    .sort((a, b) => {
      const labelA =
        labels.find((data) => data.field === a?._id)?.label || a?._id;
      const labelB =
        labels.find((data) => data.field === b?._id)?.label || b?._id;
      if (sortBy === 'a-z') {
        return labelA.localeCompare(labelB);
      }
      if (sortBy === 'z-a') {
        return labelB.localeCompare(labelA);
      }
      if (sortBy === 'least-files') {
        return a?.count - b?.count;
      }
      return b?.count - a?.count;
    })
    .filter((attribute) => {
      let keep = true;
      if (type !== 'all' && type) {
        if (type === 'list') {
          keep = Array.isArray(attribute?.value?.[0]);
        }
        if (type === 'number') {
          keep = !isNaN(parseInt(attribute?.value?.[0]));
        }
        if (type === 'text') {
          keep = isNaN(parseInt(attribute?.value?.[0]));
        }
      }
      if (
        query &&
        !attribute?._id?.toLowerCase()?.includes(query?.toLowerCase())
      ) {
        keep = false;
      }
      return keep;
    });

  function renderAttribute(attribute) {
    if (!attribute?._id) {
      return <></>;
    }
    const values = attribute.value.map((v) => renderTag(v));
    const isCustomAttribute =
      labels.find((data) => data.field === attribute?._id)?.isCustom || false;
    const isCustomLabel = !!labels.find(
      (data) => data.field === attribute?._id,
    );
    const currentCustomLabel = labels.find(
      (data) => data.field === attribute?._id,
    );
    return (
      <List.Item
        key={attribute?._id}
        title={
          <Flex alignItems="center">
            {currentCustomLabel?.label || attribute?._id}{' '}
            {currentCustomLabel?.isCustom && <Badge new>Custom</Badge>}
          </Flex>
        }
        subtitle={
          <>
            <>{attribute?._id} - </>
            {attribute?.count} files{' '}
          </>
        }
        actionsStyle={{ minWidth: 1 }}
        actions={
          <Flex style={{ width: '100%' }}>
            {savingField === attribute?._id && <Loader inline horizontal />}
            {(searchable.includes(attribute?._id) ||
              previewable.includes(attribute?._id) ||
              isCustomAttribute) && (
                <>
                  {!isCustomLabel &&
                    !currentCustomLabel?.label &&
                    !isCustomAttribute ? (
                    <Button
                      inline
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        saveCustomLabel({
                          label: attribute?._id,
                          field: attribute?._id,
                        });
                      }}
                    >
                      <Icon name="pencil" />
                      Add custom name
                    </Button>
                  ) : (
                    <Input.Field
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                      onChange={(value) => {
                        setCustomLabel({
                          field: attribute?._id,
                          label: value,
                          isCustom: isCustomAttribute,
                        });
                      }}
                      value={
                        currentCustomLabel?.label &&
                          currentCustomLabel?.label !== attribute?._id
                          ? customLabel?.field === attribute?._id
                            ? customLabel?.label
                            : currentCustomLabel?.label
                          : null
                      }
                      noClear
                      placeholder={attribute?._id}
                      style={{ width: 'auto', marginRight: 10 }}
                    />
                  )}
                </>
              )}
            <Popover
              width={250}
              trigger={
                <Button
                  inline
                  rightIcon={
                    <Icon
                      name="caret-down-fill"
                      style={{ fontSize: 8, marginLeft: 10 }}
                    />
                  }
                >
                  Settings
                </Button>
              }
              useV2
            >
              <Switch
                onChange={(active, event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  saveSearchable(attribute?._id);
                }}
                active={searchable.includes(attribute?._id)}
                label="Searchable"
              />
              <Switch
                onChange={(active, event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  savePreviewable(attribute?._id);
                }}
                active={previewable.includes(attribute?._id)}
                label="Show on file preview"
              />
              {(currentCustomLabel?.isCustom || !attribute?.count) && (
                <Button
                  inline
                  onClick={async () => {
                    const searchable =
                      User.data.selectedCommunity?.settings?.attributes
                        ?.searchable || [];
                    const previewable =
                      User.data.selectedCommunity?.settings?.attributes
                        ?.previewable || [];
                    const labels =
                      User.data.selectedCommunity?.settings?.attributes
                        ?.labels || [];
                    await User.saveSettings({
                      attributes: {
                        searchable: searchable.filter(
                          (key) => key !== attribute?._id,
                        ),
                        previewable: previewable.filter(
                          (key) => key !== attribute?._id,
                        ),
                        labels: labels.filter(
                          (label) => label.field !== attribute._id,
                        ),
                      },
                    });
                    await Media.saveDocumentsWhere(
                      {
                        [`attributes.${attribute._id}`]: {
                          $exists: true,
                        },
                      },
                      {
                        $unset: { [`attributes.${attribute._id}`]: '' },
                      },
                    );
                  }}
                >
                  <Icon name="trash" />
                  Delete
                </Button>
              )}
            </Popover>
          </Flex>
        }
      >
        {values?.length ? (
          <>
            Example values found in your account
            <Flex flexWrap="wrap" style={{ marginTop: 5 }}>
              {values}
            </Flex>
          </>
        ) : (
          false
        )}
      </List.Item>
    );
  }

  const activeAttributes = filteredAtttributes.filter(
    (attribute) =>
      previewable.includes(attribute._id) || searchable.includes(attribute._id),
  );

  labels
    .filter(
      (label) => !filteredAtttributes.find((row) => label.field === row._id),
    )
    .forEach((label) => {
      activeAttributes.push({
        _id: label.field,
        value: [],
        count: 0,
      });
    });

  const inactiveAttributes = filteredAtttributes.filter(
    (attribute) =>
      ignoreFilter.includes(attribute._id) ||
      (!previewable.includes(attribute._id) &&
        !searchable.includes(attribute._id)),
  );

  return (
    <>
      <Flex>
        <Search
          style={{ maxWidth: 195 }}
          placeholder="Search"
          value={query}
          onChange={setQuery}
        />
        <Dropdown
          style={{ maxWidth: 195, marginLeft: 10 }}
          placeholder="Type"
          value={type}
          icon="filter"
          onChange={setType}
        >
          <Dropdown.Option value="all">All types</Dropdown.Option>
          <Dropdown.Option value="number">Number</Dropdown.Option>
          <Dropdown.Option value="text">Text</Dropdown.Option>
          <Dropdown.Option value="list">List</Dropdown.Option>
        </Dropdown>
        <Dropdown
          style={{ maxWidth: 195, marginLeft: 10 }}
          placeholder="Sort by"
          value={sortBy}
          icon="sort-down"
          onChange={setSortBy}
        >
          <Dropdown.Option value="most-files">Most files</Dropdown.Option>
          <Dropdown.Option value="least-files">Least files</Dropdown.Option>
          <Dropdown.Option value="a-z">A-Z</Dropdown.Option>
          <Dropdown.Option value="z-a">Z-A</Dropdown.Option>
        </Dropdown>
      </Flex>
      <ManageView.Content.View>
        {/* <div style={{ marginBottom: 19, opacity: 0.5 }}>
          Found{' '}
          <strong style={{ margin: 0, display: 'inline' }}>
            {totalFiles} files
          </strong>{' '}
          with attributes
        </div> */}
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <List cards>
              <Flex
                style={{ marginBottom: 15, marginTop: 20 }}
                alignItems="center"
                justifyContent="space-between"
              >
                <h1 style={{ fontSize: 20 }}>Active properties</h1>
                <AttributeForm
                  popup
                  title="Create new property"
                  trigger={
                    <Button inline primary>
                      <Icon name="pencil-square" />
                      Create custom property
                    </Button>
                  }
                  onCancel={() => { }}
                  onSave={() => { }}
                />
              </Flex>
              {!activeAttributes?.length ? (
                <span>No saved properties found</span>
              ) : (
                ''
              )}
              {activeAttributes.map(renderAttribute)}
              <Flex
                style={{ marginBottom: 15, marginTop: 20 }}
                alignItems="center"
                justifyContent="space-between"
              >
                <h1 style={{ fontSize: 20 }}>All properties</h1>
              </Flex>
              {!inactiveAttributes?.length ? (
                <span>No files with properties found</span>
              ) : (
                ''
              )}
              {inactiveAttributes.map(renderAttribute)}
            </List>
          </>
        )}
      </ManageView.Content.View>
    </>
  );
}
