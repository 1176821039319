import { Box, Slider } from '@mantine/core';
import { AssetThumbnail } from '@pixi/components/AssetThumbnail';
import Droppable from '@pixi/components/Droppable';
import { useDataStoreCallback } from '@pixi/store';
import { useState } from 'react';
import { useAssetGridContext } from '../AssetGridContext';
import { useAssetGridShortcuts } from '../hooks/useAssetGridShortcuts';

export default function AssetGridSimilarity() {
  const AssetGrid = useAssetGridContext();
  const activeSimilarityFilter =
    AssetGrid.activeShortcutsFilter?.$similarity?.[0];
  const [droppedFile, setDroppedFile] = useState<null | string>(null);
  const file = useDataStoreCallback(
    'FILES',
    (_, state) => {
      if (!droppedFile) {
        return null;
      }
      return state.getByKey(droppedFile);
    },
    [droppedFile],
  );
  return (
    <Box
      w="100%"
      h={300}
      style={{ aspectRatio: '4/3' }}
      bg="gray.3"
      pos="relative"
    >
      {file && <AssetThumbnail size="medium" file={file} />}
      <Droppable
        accepts="file"
        label={() => 'Add to collection'}
        onDrop={async (data) => {
          setDroppedFile(data.id as string);
          AssetGrid.setActiveShortcuts([
            {
              id: 'ai.image_similarity',
              filter: {
                $similarity: [
                  { value: data.id as string, prefs: { threshold: 0.7 } },
                ],
              },
            },
          ]);
        }}
      />
      {file?._id && (
        <Slider
          color="blue"
          defaultValue={
            ((activeSimilarityFilter?.prefs?.threshold || 0) * 100) as number
          }
          key={activeSimilarityFilter?.prefs?.threshold}
          onChangeEnd={(value) => {
            AssetGrid.setActiveShortcuts([
              {
                id: 'ai.image_similarity',
                filter: {
                  $similarity: [
                    {
                      value: file._id as string,
                      prefs: { threshold: value / 100 },
                    },
                  ],
                },
              },
            ]);
          }}
          marks={[
            { value: 20, label: '20%' },
            { value: 50, label: '50%' },
            { value: 80, label: '80%' },
          ]}
        />
      )}
    </Box>
  );
}
