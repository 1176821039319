import React, { ReactNode, useContext, useReducer } from 'react';
import { useUserContext } from 'hooks/useUserContext';
import asyncPool from 'utils/async-pool';

export interface FoldersContextInterface {
  data: Pickit.FolderInterface[];
  findFolders: (library: string) => Pickit.FolderInterface[];
  getFolders: () => Promise<void>;
  findFolderById: (id: string) => Pickit.FolderInterface | undefined;
  findFoldersByParent: (parentId: string) => Pickit.FolderInterface[];
  saveFolder: (
    folderData: Partial<Pickit.FolderInterface>,
  ) => Promise<Pickit.FolderInterface>;
  deleteFolder: (folder: Pickit.FolderInterface) => Promise<void>;
  countFoldersInFolder: (id: string, alreadyCounted?: string[]) => number;
}

export const FoldersContext = React.createContext<FoldersContextInterface>(
  {} as FoldersContextInterface,
);
export const useFolders = (): FoldersContextInterface =>
  useContext(FoldersContext);
