import { Gallery, ThumbnailImageProps } from 'react-grid-gallery';
import { format, isToday, isYesterday, startOfDay } from 'date-fns';

import React, {
  createContext,
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PixiIcon, { PixiIconName } from '@pixi/elements/Icon';
import {
  Alert,
  Badge,
  Box,
  Card,
  Divider,
  Group,
  Loader,
  Overlay,
  Paper,
  Stack,
} from '@mantine/core';
import PixiTooltip from '@pixi/elements/Tooltip';
import PixiText, { PixiTitle } from '@pixi/elements/Text';
import PixiButton from '@pixi/elements/Button';
import fastDeepEqual from 'fast-deep-equal/es6';
import {
  getMultiStore,
  MultiDataStoreList,
  useConfigStoreValue,
  useDataStoreCallback,
  useDataStoreChangeListener,
  useMultiStoreData,
} from '@pixi/store';
import createDataStore from '@pixi/classes/DataStore';
import { useIntersection } from '@mantine/hooks';
import AssetCard from '@pixi/components/AssetGrid/components/AssetCard';
import { truncate } from '@pixi/helpers/utils';
import {
  AssetGridCenterTabs,
  AssetGridContextInterface,
  AssetGridDisplayAsTypes,
  AssetGridGroupByTypes,
  AssetGridProps,
  AssetGridSortByActive,
  AssetGridTableFields,
  filterRowValueIsActive,
  filterValueToString,
  ShortcutDataRow,
  ShortcutIds,
} from './Helpers';
import { AssetGridContext } from './AssetGridContext';
import AssetGridRightPanel from './AssetGridRightPanel';
import AssetGridLeftPanel from './AssetGridLeftPanel';
import useElementViewport from '@pixi/hooks/useElementViewport';
import {
  createAppToast,
  openAssetPreview,
  useColorScheme,
} from '@pixi/AppController';
import { AssetGridToolbar } from './components/AssetGridToolbar';
import AssetGridRender from './AssetGridRender';
import System from '@pixi/System';
import AssetGridTags from './components/CenterTabs/AssetGridTags';
import AssetGridStatusBar from './components/AssetGridStatusBar';
import { AssetGridUpload } from './components/AssetGridUpload';
import { AssetGridUploadProgress } from './components/AssetGridUploadProgress';
import { useUserContext } from 'hooks';
import AssetGridContributorToolbar from './components/AssetGridContributorToolbar';
import { useAssetGridShortcuts } from './hooks/useAssetGridShortcuts';
import { useAssetActions } from '../AssetActions/useAssetActions';
import PixiConfirm from '@pixi/elements/Confirm';
import useContextFromFile from 'hooks/useContextFromFile';
import _ from 'lodash';

export function AssetGrid(props: AssetGridProps) {
  // const [isAtBottom, setIsAtBottom] = useState(false);
  const User = useUserContext();
  const { ref, entry } = useIntersection({
    threshold: 1,
  });
  const colorScheme = useColorScheme();
  const { ref: containerRef, viewport } = useElementViewport();
  const [disable, setDisable] = useState<AssetGridProps['disable']>(
    props.defaultDisable || props.disable || [],
  );

  const [groupBy, setGroupBy] = useState<AssetGridContextInterface['groupBy']>(
    props.groupBy === null ? null : 'createdAt',
  );
  const [displayAs, setDisplayAs] = useState<AssetGridDisplayAsTypes>(
    props.displayAs || 'grid',
  );
  const [sortBy, setSortBy] = useState<AssetGridSortByActive | null>(null);
  const findSettings = useRef<
    Partial<{
      groupBy: AssetGridGroupByTypes | null;
      sortBy: AssetGridSortByActive | null;
    }>
  >({
    groupBy: null,
    sortBy: null,
  });
  const [activeShortcuts, setActiveShortcuts] = useState<
    {
      id: ShortcutIds;
      filter?: Pickit.LibraryActiveFilters;
    }[]
  >(
    props.forceFilterShortcuts?.map((f) => ({ id: f })) ||
      props.defaultFilterShortcuts?.map((f) => ({ id: f })) ||
      [],
  );
  const renderId = activeShortcuts?.find((i) => i.id.includes('trash'))
    ? 'trash_view'
    : props.id;
  const selectedFiles = useMultiStoreData('FILES_SELECTED', renderId, [
    renderId,
  ]);
  const [gridSize, setGridSize] = useState<'small' | 'medium' | 'large'>(
    'medium',
  );
  const [activeCenterTabId, setActiveCenterTabId] = useState<string>(
    props.defaultCenterTab || '',
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [availableFilters, setAvailableFilters] = useState<
    Record<string, Pickit.LibraryFilter>
  >({});
  const [frozenFilters, setFrozenFilters] = useState<
    Record<string, Pickit.LibraryFilter>
  >({});
  const isLoadingRef = useRef<boolean>(false);
  const [status, setStatus] = useState<AssetGridContextInterface['status']>({
    isLoading: false,
    isLoadingMore: false,
    isLoadingFilters: false,
    isError: false,
    isAllFilesLoaded: false,
    totalFiles: null,
    isReady: false,
  });
  const allFilesRef = useRef<Pickit.FileInterface[]>([]);
  const [_allFiles, setAllFiles] = useState<Pickit.FileInterface[]>([]);
  const [filters, setFilters] = useState<Pickit.LibraryActiveFilters>({
    ...(props.filters || {}),
    ...(props.onlyFileTypes ? { 'file.ext': props.onlyFileTypes } : {}),
    ...(props.defaultFilters || {}),
  });
  const preShortcutSettings = useRef<{
    shortcutId: ShortcutIds;
    displayAs?: AssetGridDisplayAsTypes;
    groupBy?: AssetGridGroupByTypes | null;
    sortBy?: AssetGridSortByActive | null;
    tableFields?: AssetGridTableFields[];
  } | null>(null);
  const [tableFields, setTableFields] = useState<AssetGridTableFields[]>(
    props.fields || [],
  );
  const [forceFilters, setForceFilters] = useState<Pickit.LibraryActiveFilters>(
    {
      ...(props.forceFilters || {}),
    },
  );

  const lastClickedIndex = useRef(-1);
  const lastClickedTime = useRef(0);

  const [params, setParams] = useState<Record<string, any>>({});

  const { data: shortcuts } = useAssetGridShortcuts();

  useEffect(() => {
    if (props.onStatusChange) {
      props.onStatusChange(status);
    }
  }, [status]);

  useDataStoreChangeListener(
    'FILES',
    (rows) => {
      const prependRows: Pickit.FileInterface[] = [];
      const removeRows: Pickit.FileInterface[] = [];
      if (props?.autoState && !status.isLoading && !status.isLoadingMore) {
        rows.forEach((row) => {
          const status = props.autoState?.(row.data, row.change, _allFiles);
          if (status === 'prepend') {
            prependRows.push(row.data);
          }
          if (status === 'remove') {
            removeRows.push(row.data);
          }
        });
        if (prependRows.length) {
          setAllFiles((_allFiles) => [...prependRows, ..._allFiles]);
        }
        if (removeRows.length) {
          setAllFiles((_allFiles) => [
            ..._allFiles.filter(
              (file) => !removeRows.find((r) => r._id === file._id),
            ),
          ]);
        }
      }
    },
    [_allFiles, props?.autoState, status?.isLoading, status?.isLoadingMore],
  );

  const shortCutsDataRows = shortcuts.reduce<ShortcutDataRow[]>(
    (acc, group) => [...acc, ...(group.data || [])],
    [],
  );
  const allFiles =
    useDataStoreCallback(
      'FILES',
      (data, store) => {
        return _allFiles
          .filter((file) => !!file?._id && !!store.getByKey(file?._id))
          .map((file) => store.getByKey(file._id) as Pickit.FileInterface)
          .filter((file) => {
            if (props.customFilter) {
              return props.customFilter(file);
            }
            return true;
          });
      },
      [_allFiles, props.customFilter],
    ) || _allFiles;

  const context = useContextFromFile(allFiles[0]); // Check with Jacob...

  const activeShortcutsData = shortCutsDataRows.filter(
    (f) => activeShortcuts.find((s) => s.id === f.id)?.id,
  );
  const activeShortcutsFilter = activeShortcutsData
    .filter(
      (f) => !!f.filter || !!activeShortcuts.find((s) => s.id === f.id)?.filter,
    )
    .map(
      (f) =>
        (activeShortcuts.find((s) => s.id === f.id)?.filter ||
          f.filter) as Pickit.LibraryActiveFilters,
    )
    .reduce((a, b) => ({ ...a, ...b }), {});

  const combinedFilters = {
    ...filters,
    ...(forceFilters || {}),
    ...activeShortcutsFilter,
  };

  function getSettings(clearCache?: boolean) {
    const color = filters.color || '';
    const clonedFilters = JSON.parse(JSON.stringify(filters));
    delete clonedFilters.query;
    delete clonedFilters.query_properties;
    delete clonedFilters.color;
    if (clonedFilters.collection) {
      clonedFilters.collections = clonedFilters.collection;
      delete clonedFilters.collection;
    }
    const settings: Record<string, any> = {
      ...params,
      page: clearCache ? 0 : currentPage || '',
      color,
      filterV2: JSON.stringify({
        ...clonedFilters,
        ...(forceFilters || {}),
        ...activeShortcutsFilter,
      }),
      ignore: JSON.stringify(props.ignore || {}),
      ...(props.forceSortBy
        ? {
            sort_by: props.forceSortBy.field,
            sort_direction: props.forceSortBy.direction,
          }
        : findSettings?.current?.sortBy?.field
          ? {
              sort_by: findSettings?.current?.sortBy?.field,
              sort_direction: findSettings?.current?.sortBy?.direction,
            }
          : {}),
      // ...(props.params || {}),
    };
    return settings;
  }

  async function getAvailableFilters(settings?: Record<string, any>) {
    if (!settings) {
      settings = {
        ...getSettings(true),
        ...(props.libraries ? { libraries: props.libraries } : {}),
      };
    }
    setStatus((status) => ({
      ...status,
      isLoadingFilters: true,
    }));
    const newFilters = await props.context.getFilters({
      settings,
      raw: true,
    });
    setAvailableFilters(newFilters);
    setFrozenFilters((oldFilters) => {
      const activeFields = Object.keys(filters);
      const frozenFilters = Object.keys(newFilters).reduce(
        (frozen: Record<string, Pickit.LibraryFilter>, key) => {
          if (activeFields.includes(newFilters[key]?.row?.field as string)) {
            frozen[key] = oldFilters[key] || newFilters[key];
          } else {
            frozen[key] = newFilters[key];
          }
          return frozen;
        },
        {},
      );
      return frozenFilters;
    });
    setStatus((status) => ({
      ...status,
      isLoadingFilters: false,
    }));
  }

  async function getFiles(clearCache?: boolean) {
    try {
      if (status.isAllFilesLoaded && !clearCache) {
        return false;
      }
      if (isLoadingRef?.current) {
        return false;
      }
      if (clearCache) {
        setAllFiles([]);
        setCurrentPage(0);
      }
      isLoadingRef.current = true;
      setStatus((status) => ({
        ...status,
        isLoading: clearCache || false,
        isLoadingMore: true,
      }));
      const settings = {
        ...getSettings(clearCache),
        ...(props.libraries ? { libraries: props.libraries } : {}),
      };
      const newData = await props.context.getFiles(settings);
      setCurrentPage((page) => page + 1);
      if (clearCache) {
        getAvailableFilters(settings);
      }
      const totalFiles = [...(clearCache ? [] : allFiles), ...newData.documents]
        .length;
      setAllFiles((files) => [
        ...(clearCache ? [] : files),
        ...newData.documents,
      ]);
      allFilesRef.current = [
        ...(clearCache ? [] : allFilesRef.current),
        ...newData.documents,
      ];
      setStatus((status) => ({
        ...status,
        isLoadingMore: false,
        isLoading: false,
        isAllFilesLoaded:
          !newData.documentsNextPage ||
          (props.maxResults ? props.maxResults < totalFiles : false),
        totalFiles: clearCache ? newData.totalResults : status.totalFiles,
        isError: false,
        isReady: true,
      }));
      isLoadingRef.current = false;
    } catch (e) {
      System.Report.logError(e as Error);
      isLoadingRef.current = false;
      setStatus((status) => ({
        ...status,
        isLoadingMore: false,
        isLoading: false,
        isError: true,
        isAllFilesLoaded: false,
        totalFiles: 0,
        isReady: true,
      }));
    }
  }

  useEffect(() => {
    if (
      entry?.isIntersecting &&
      !status.isError &&
      !status.isLoading &&
      !status.isLoadingMore
    ) {
      getFiles();
    }
  }, [entry?.isIntersecting, status]);

  useEffect(() => {
    setFilters({
      ...(props.filters || {}),
      ...(props.onlyFileTypes ? { 'file.ext': props.onlyFileTypes } : {}),
      ...(props.defaultFilters || {}),
    });
  }, [props.filters]);

  useEffect(() => {
    const isEqual = fastDeepEqual(props.forceFilters, forceFilters);
    if (!isEqual) {
      setForceFilters(props.forceFilters || {});
    }
  }, [props.forceFilters]);

  useEffect(() => {
    if (props.disable) {
      setDisable(props.disable);
    }
  }, [props.disable]);

  useEffect(() => {
    getFiles(true);
  }, [forceFilters]);

  useEffect(() => {
    getFiles(true);
  }, [filters, activeShortcuts]);

  function groupByParse(file: Pickit.FileInterface) {
    const key = groupBy || 'createdAt';
    if (!file?.[key]) {
      return;
    }
    return {
      key: startOfDay(new Date(file?.[key] as string)).getTime(),
      title: isToday(new Date(file?.[key] as string))
        ? 'Today'
        : isYesterday(new Date(file?.[key] as string))
          ? 'Yesterday'
          : format(
              file?.[key] ? new Date(file?.[key] as string) : new Date(),
              'LLLL do, yyyy',
            ),
    };
  }

  let renderFiles = [...allFiles];
  if (props.maxResults) {
    renderFiles = renderFiles.slice(0, props.maxResults);
  }

  const groups = useMemo(() => {
    if (groupBy) {
      let groups = [];
      for (let i = 0; i < renderFiles.length; i++) {
        const file = renderFiles[i];
        const parse = groupByParse(file);
        if (!parse) {
          continue;
        }
        const existingGroup = groups.find((group) => group.key === parse.key);
        if (!existingGroup) {
          groups.push({
            key: parse.key,
            title: parse.title,
            data: [file],
          });
        } else {
          existingGroup.data.push(file);
        }
      }
      if (sortBy?.field === 'createdAt' || !sortBy?.field) {
        groups = groups.map((group) => {
          group.data = group.data.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
          );
          if (sortBy?.direction === 'ascending') {
            group.data = group.data.reverse();
          }
          return {
            ...group,

            data: group.data,
          };
        });
      }
      if (sortBy?.field === 'createdAt' || !sortBy?.field) {
        groups = groups.sort((a, b) => b.key - a.key);
        if (sortBy?.direction === 'ascending') {
          groups = groups.reverse();
        }
      }
      return groups;
    }
    return null;
  }, [groupBy, renderFiles, viewport]);

  async function onFileClick(
    event: MouseEvent<HTMLElement>,
    file: Pickit.FileInterface,
    fileToolbar: ReturnType<typeof useAssetActions>,
  ) {
    event.preventDefault();
    event.stopPropagation();
    if (
      lastClickedTime &&
      new Date().getTime() - lastClickedTime.current < 200 &&
      props.onFileDoubleClick
    ) {
      await props.onFileDoubleClick(event, file, fileToolbar, renderFiles);
      return;
    }
    lastClickedTime.current = new Date().getTime();
    if (!props.onFileClick) {
      const isMultiSelect =
        props.selectBehaviour === 'multiple' ||
        event.metaKey ||
        event.ctrlKey ||
        event.shiftKey;
      const isOtherAssetsSelected =
        getMultiStore('FILES_SELECTED', renderId).state?.length > 1;
      const isSelectActive = !!getMultiStore('FILES_SELECTED', renderId)?.state
        ?.length;
      const isSelected = getMultiStore('FILES_SELECTED', renderId).getByKey(
        file._id,
      );

      if (props.multiSelect === false) {
        getMultiStore('FILES_SELECTED', renderId).replace([
          {
            _id: file._id,
            selectedAt: new Date().getTime(),
          },
        ]);
        return;
      }

      if (!event.shiftKey) {
        const currentIndex = allFiles.findIndex((f) => f._id === file._id);
        lastClickedIndex.current = currentIndex;
      }

      if (
        event.shiftKey &&
        !event.metaKey &&
        !event.ctrlKey &&
        isSelectActive
      ) {
        const items = allFiles; // Your array of all file items
        const currentIndex = items.findIndex((f) => f._id === file._id);

        // Shift is held down, find the nearest selected index and select everything between
        let nearestSelectedIndex = -1;
        const direction = currentIndex > lastClickedIndex.current ? -1 : 1;

        // Determine the range to search for the nearest selected index
        const start = Math.min(currentIndex, lastClickedIndex.current);
        const end = Math.max(currentIndex, lastClickedIndex.current);

        // Look for the nearest selected index in the direction from lastClickedIndex to currentIndex
        for (let i = start; i <= end; i += direction) {
          if (
            getMultiStore('FILES_SELECTED', renderId).getByKey(items[i]._id)
          ) {
            nearestSelectedIndex = i;
            break;
          }
        }

        // If no selected index is found in the direction, use the other end of the range
        if (nearestSelectedIndex === -1) {
          nearestSelectedIndex = lastClickedIndex.current;
        }

        // Select all files between the nearest selected index and the current index
        const rangeStart = Math.min(nearestSelectedIndex, currentIndex);
        const rangeEnd = Math.max(nearestSelectedIndex, currentIndex);
        const filesToSelect = items.slice(rangeStart, rangeEnd + 1);
        getMultiStore('FILES_SELECTED', renderId).addOrUpdate(
          filesToSelect.map((f) => ({
            _id: f._id,
            selectedAt: new Date().getTime(),
          })),
        );
        return;
      }

      if (!isMultiSelect && !isSelected) {
        getMultiStore('FILES_SELECTED', renderId).replace([
          {
            _id: file._id,
            selectedAt: new Date().getTime(),
          },
        ]);
        return;
      }
      if (!isMultiSelect && isSelected) {
        if (isOtherAssetsSelected) {
          getMultiStore('FILES_SELECTED', renderId).replace([
            {
              _id: file._id,
              selectedAt: new Date().getTime(),
            },
          ]);
          return;
        }
        getMultiStore('FILES_SELECTED', renderId).replace([]);
        return;
      }

      // Toggle = add or remove, replace = clear everything else
      getMultiStore('FILES_SELECTED', renderId)[
        isMultiSelect ? 'toggle' : 'replace'
      ]({
        _id: file._id,
      });
      return;
    }
    if (props.onFileDoubleClick) {
      setTimeout(() => {
        props.onFileClick?.(event, file, fileToolbar, renderFiles);
      }, 200);
    } else {
      props.onFileClick(event, file, fileToolbar, renderFiles);
    }
  }

  if (typeof props.mounted === 'boolean' && !props.mounted) {
    return <></>;
  }

  function setFilter(
    filterName: string,
    row: Pickit.LibraryActiveFilterRow[] | null,
    prefs?: { replace?: boolean },
  ) {
    setStatus((status) => ({
      ...status,
      isLoadingFilters: true,
    }));
    setFilters((filters) => {
      if (row === null) {
        delete filters[filterName];
        return { ...filters };
      }
      if (prefs?.replace) {
        filters[filterName] = row;
        return { ...filters };
      }
      row.forEach((r) => {
        const isActive = filterRowValueIsActive(filters[filterName], r.value);
        if (r.modifier === '$and') {
          const existingValue = filters[filterName]?.[0]?.value as string[];
          const valueIsAlreadySelected = existingValue?.includes(
            filterValueToString(r.value),
          );
          if (valueIsAlreadySelected && existingValue?.length === 1) {
            delete filters[filterName];
            return;
          }
          filters[filterName] = [
            {
              ...r,
              value: [
                ...((existingValue || []) as string[]).filter((val) => {
                  return !(r.value as string[]).includes(val);
                }),
                ...(valueIsAlreadySelected
                  ? []
                  : Array.isArray(r.value)
                    ? r.value
                    : [r.value]),
              ],
            },
          ];
        } else if (
          r.value === '' ||
          (isActive && filters[filterName]?.length === 1)
        ) {
          delete filters[filterName];
        } else if (isActive) {
          filters[filterName] = filters[filterName]?.filter(
            (val) =>
              filterValueToString(val.value) !== filterValueToString(r.value),
          );
        } else if (!filters[filterName]) {
          filters[filterName] = Array.isArray(r.value) ? r.value : [r];
        } else if (prefs?.replace) {
          filters[filterName] = Array.isArray(r.value) ? r.value : [r];
        } else {
          filters[filterName] = [...filters[filterName], r];
        }
      });
      return { ...filters };
    });
  }

  const centerTabs: AssetGridCenterTabs[] = [
    {
      id: 'tags',
      name: 'Tags',
      icon: 'tags',
      content: <AssetGridTags />,
      disabled:
        availableFilters?.tags?.data?.filter((d) => !!d.value)?.length === 0,
      disabledTooltip: 'No assets with tags found',
    },
    ...(props.customCenterTabs || []),
  ];

  const activeCenterTab = centerTabs.find((c) => c.id === activeCenterTabId);

  function applyShortcut(id: ShortcutIds) {
    const row = shortCutsDataRows.find((r) => r.id === id);

    if (row?.settings) {
      preShortcutSettings.current = {
        shortcutId: row.id,
        displayAs,
        sortBy: sortBy || null,
        groupBy,
        tableFields,
      };
      if (row?.settings?.displayAs) {
        setDisplayAs(row.settings.displayAs);
      }
      if (row?.settings?.groupBy === null || row?.settings?.groupBy) {
        findSettings.current.groupBy = row.settings.groupBy;
        setGroupBy(row.settings.groupBy || null);
      }
      if (row?.settings?.sortBy) {
        findSettings.current.sortBy = row.settings.sortBy;
        setSortBy(row.settings.sortBy || null);
      }
      if (row?.settings?.fields) {
        setTableFields(row.settings.fields);
      }
    }
  }

  function clearShortcut(id: ShortcutIds) {
    setActiveShortcuts(activeShortcuts.filter((s) => s.id !== id));
    const settings = preShortcutSettings.current;

    if (settings?.shortcutId === id) {
      if (settings?.sortBy !== undefined) {
        findSettings.current.sortBy = settings.sortBy || null;
        setSortBy(settings.sortBy);
      }
      if (settings?.displayAs) {
        setDisplayAs(settings.displayAs);
      }
      if (settings?.groupBy) {
        findSettings.current.groupBy = settings.groupBy;
        setGroupBy(settings.groupBy);
      }
      if (settings?.tableFields) {
        setTableFields(settings.tableFields);
      }
      preShortcutSettings.current = null;
    }
  }

  return (
    <AssetGridContext.Provider
      value={{
        id: renderId,
        context: props.context,

        allFiles,
        getFiles,

        viewport,

        disable,
        setDisable: (disable) => {
          setDisable(disable);
        },

        groupBy,
        setGroupBy: (groupBy) => {
          findSettings.current.groupBy = groupBy;
          setGroupBy(groupBy);
          getFiles(true);
        },

        displayAs,
        setDisplayAs,
        gridSize,
        setGridSize,

        sortBy,
        setSortBy: (sortBy) => {
          findSettings.current.sortBy = sortBy;
          setSortBy(sortBy);
          getFiles(true);
        },

        selectedFiles,

        availableFilters,
        frozenFilters,

        tableFields,

        onFileClick,
        fileToolbar: props.fileToolbar,
        fileToolbarCustom: props.fileToolbarCustom,

        activeFilters: filters,
        forceFilters: props.forceFilters,
        defaultFilters: props.defaultFilters,
        combinedFilters,
        setFilter,
        setFilters: (filters) => {
          setFilters({ ...filters });
        },

        setParam: (key, data) => {
          setParams((params) => ({
            ...params,
            [key]: data,
          }));
        },

        status,

        shortcuts,
        setActiveShortcuts: (
          shortcuts: {
            id: ShortcutIds;
            filter?: Pickit.LibraryActiveFilters;
          }[],
          prefs,
        ) => {
          const ids = shortcuts.map((s) => s.id);
          setActiveShortcuts([...shortcuts]);

          const settings = preShortcutSettings.current;

          if (settings?.shortcutId && !ids?.includes(settings?.shortcutId)) {
            if (settings?.sortBy !== undefined) {
              findSettings.current.sortBy = settings.sortBy || null;
              setSortBy(settings.sortBy);
            }
            if (settings?.displayAs) {
              setDisplayAs(settings.displayAs);
            }
            if (settings?.groupBy) {
              findSettings.current.groupBy = settings.groupBy;
              setGroupBy(settings.groupBy);
            }
            if (settings?.tableFields) {
              setTableFields(settings.tableFields);
            }
            preShortcutSettings.current = null;
          }

          ids.filter((id) => !!id).forEach((id) => applyShortcut(id));
        },
        toggleActiveShortcut: (shortcut) => {
          const { id } = shortcut;
          if (!activeShortcuts.find((s) => s.id === id) && id) {
            applyShortcut(id);
          }
          setActiveShortcuts((active) => {
            const ids = active.map((s) => s.id);
            const settings = preShortcutSettings.current;
            if (settings?.shortcutId === id && ids.includes(id)) {
              if (settings?.displayAs) {
                setDisplayAs(settings.displayAs);
              }
              if (settings?.sortBy !== undefined) {
                findSettings.current.sortBy = settings.sortBy || null;
                setSortBy(settings.sortBy);
              }
              if (settings?.groupBy) {
                findSettings.current.groupBy = settings.groupBy;
                setGroupBy(settings.groupBy);
              }
              if (settings?.tableFields) {
                setTableFields(settings.tableFields);
              }
              preShortcutSettings.current = null;
            }
            if (ids.includes(id)) {
              return active.filter((a) => a.id !== id);
            }
            return [...active, shortcut];
          });
        },
        activeShortcuts,
        activeShortcutIds: activeShortcuts.map((s) => s.id),
        activeShortcutsData,
        activeShortcutsFilter,
        forceFilterShortcuts: props.forceFilterShortcuts,

        leftPanelInjection: props.leftPanelInjection,

        isInPopup: props.isInPopup,

        viewportRef: props.viewportRef,

        readOnly: props.readOnly,

        multiSelect: props.multiSelect !== false,

        libraries: props.libraries,

        uploadProps: props.uploadProps || {},
      }}
    >
      <Group w="100%" gap="xs" wrap="nowrap" align="flex-start" pos="relative">
        {!disable?.includes('leftPanel') && (
          <AssetGridLeftPanel
            forceLeftPanelSection={props.forceLeftPanelSection}
            {...(props.panelProps?.leftPanel || {})}
          />
        )}
        {!disable?.includes('centerPanel') && (
          <Stack w="100%" gap="3" pos="relative">
            <Stack
              bg={colorScheme === 'dark' ? 'dark.6' : 'gray.1'}
              pos="sticky"
              top={-1}
              gap="3"
              style={{ zIndex: 10 }}
            >
              {props.toolbarTopInjection}
              {(!disable?.includes('toolbar') ||
                !!props.toolbarLeftInjection ||
                !!props.toolbarLeftAfterUploadInjection ||
                !!props.toolbarRightInjection) && (
                <Paper
                  p={viewport === 'xxs' || viewport === 'xs' ? 'md' : 'lg'}
                  pos="sticky"
                >
                  <Group w="100%" wrap="nowrap" align="stretch">
                    <Group w="100%">
                      {activeShortcuts?.find((f) =>
                        f?.id?.startsWith('trash'),
                      ) ? (
                        <>
                          <PixiButton
                            size="md"
                            color="dark"
                            variant="light"
                            leftSection={<PixiIcon name="chevron-left" />}
                            onClick={() => {
                              clearShortcut('trash.active');
                            }}
                          >
                            Go back
                          </PixiButton>
                          <PixiConfirm
                            title="Are you sure?"
                            description="This will permenently remove all files in the trash can. This can't be undone."
                            confirmLabel="Delete files"
                            confirmProps={{
                              color: 'red',
                            }}
                            onConfirm={async (close) => {
                              if (allFiles.length > 0) {
                                await context.deleteFiles(
                                  allFiles.map((file) => file._id),
                                );
                              }
                              close(true);
                              createAppToast({
                                id: `deleted_files${allFiles.map((file) => file._id).join('&')}`,
                                message: 'Files were deleted successfully',
                                icon: 'trash-can',
                              });
                            }}
                          >
                            <PixiButton
                              size="wide-md"
                              color="red"
                              variant="filled"
                              loading={false}
                              disabled={status.totalFiles === 0}
                              style={{
                                flexShrink: 1,
                                marginRight: 0,
                              }}
                              leftSection={
                                <PixiIcon
                                  name="trash-can"
                                  size="lg"
                                  variant="filled"
                                />
                              }
                            >
                              Empty trash can
                            </PixiButton>
                          </PixiConfirm>
                        </>
                      ) : (
                        <>
                          {props.toolbarLeftInjection ? (
                            props.toolbarLeftInjection
                          ) : (
                            <></>
                          )}

                          {!disable?.includes('upload') && !props.readOnly && (
                            <AssetGridUpload />
                          )}

                          {props.toolbarLeftAfterUploadInjection ? (
                            props.toolbarLeftAfterUploadInjection
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </Group>
                    <Group
                      align="stretch"
                      gap="5"
                      wrap="nowrap"
                      justify="flex-end"
                      ml="auto"
                      style={{ flexShrink: 0 }}
                    >
                      {props.toolbarRightInjection}
                      {!disable?.includes('toolbar') && <AssetGridToolbar />}
                    </Group>
                  </Group>
                </Paper>
              )}
              <AssetGridContributorToolbar />
              <AssetGridUploadProgress />
              {props.toolbarBelowInjection}
              {!disable?.includes('status') && (
                <Paper
                  p="sm"
                  px={viewport === 'xxs' || viewport === 'xs' ? 'md' : 'lg'}
                >
                  <AssetGridStatusBar />
                </Paper>
              )}
            </Stack>
            {props.withCenterTabs && !!centerTabs?.length && (
              <Paper
                p="sm"
                px={viewport === 'xxs' || viewport === 'xs' ? 'md' : 'lg'}
              >
                <Group gap="xs" mb={activeCenterTabId ? 'sm' : undefined}>
                  {centerTabs.map((tab) => (
                    <PixiTooltip
                      key={tab.id}
                      label={tab.disabledTooltip}
                      disabled={!tab.disabled || !tab.disabledTooltip}
                    >
                      <PixiButton
                        onClick={() => {
                          if (tab.disabled) {
                            return;
                          }
                          setActiveCenterTabId(
                            activeCenterTabId === tab.id ? '' : tab.id,
                          );
                        }}
                        styles={
                          tab.disabled
                            ? {
                                root: {
                                  opacity: 0.4,
                                  pointerEvents: 'auto',
                                },
                                inner: {
                                  cursor: 'auto',
                                },
                              }
                            : {}
                        }
                        size="xs"
                        variant={
                          activeCenterTabId === tab.id ? 'light' : 'light'
                        }
                        color={
                          activeCenterTabId === tab.id ? 'primary' : 'dark'
                        }
                        rightSection={
                          <PixiIcon
                            name={
                              activeCenterTabId === tab.id
                                ? 'chevron-up'
                                : 'chevron-down'
                            }
                            size={10}
                          />
                        }
                      >
                        {tab.name}
                      </PixiButton>
                    </PixiTooltip>
                  ))}
                </Group>
                {activeCenterTab && activeCenterTab?.content}
              </Paper>
            )}
            {!disable?.includes('assets') && (
              <Box w="100%" pos="relative">
                <Overlay
                  ref={containerRef}
                  style={{ pointerEvents: 'none', opacity: 0 }}
                />
                {(status.isLoading || !viewport) && !status.isLoadingMore ? (
                  <Paper w="100%" p="lg">
                    <Group w="100%" justify="center">
                      <Loader />
                    </Group>
                  </Paper>
                ) : status.isError ? (
                  <>
                    <Paper
                      w="100%"
                      p={viewport === 'xxs' || viewport === 'xs' ? 'md' : 'xl'}
                      {...props.panelProps?.centerPanel}
                    >
                      <Alert
                        color="red"
                        icon={<PixiIcon name="triangle-exclamation" />}
                        title="An unexpected error occured"
                      >
                        Something went wrong when trying to load the files. We
                        have been notified. Please try later or contact support.
                        <Group mt="md" color="red">
                          <PixiButton
                            onClick={async () => {
                              await getFiles(true);
                            }}
                            color="red"
                            leftSection={<PixiIcon name="arrow-rotate-right" />}
                          >
                            Try again
                          </PixiButton>
                        </Group>
                      </Alert>
                    </Paper>
                  </>
                ) : (
                  <>
                    {groupBy && sortBy?.field !== 'name' && groups?.length ? (
                      <Stack gap="3">
                        {groups
                          .filter((session) =>
                            props.customFilter
                              ? !!session.data.filter(props.customFilter)
                                  ?.length
                              : !!session.data?.length,
                          )
                          .map((session) => {
                            return (
                              <Card key={session.title}>
                                <Group justify="space-between" mb="sm">
                                  <PixiText>{session.title}</PixiText>
                                  {props.multiSelect !== false && (
                                    <Group>
                                      {selectedFiles?.filter(
                                        (selectedFile) =>
                                          !!session.data.find(
                                            (row) =>
                                              row._id === selectedFile._id,
                                          ),
                                      ).length === session.data.length ? (
                                        <PixiButton
                                          variant="light"
                                          color="gray"
                                          size="xs"
                                          onClick={() => {
                                            getMultiStore(
                                              'FILES_SELECTED',
                                              renderId,
                                            ).remove(
                                              session.data?.map((row) => ({
                                                _id: row._id,
                                              })),
                                            );
                                          }}
                                        >
                                          Deselect {session.data?.length} file
                                          {session.data?.length > 1 ? 's' : ''}
                                        </PixiButton>
                                      ) : (
                                        <PixiButton
                                          variant="subtle"
                                          color="gray"
                                          size="xs"
                                          onClick={() => {
                                            getMultiStore(
                                              'FILES_SELECTED',
                                              renderId,
                                            ).addOrUpdate(
                                              session.data?.map((row) => ({
                                                _id: row._id,
                                              })),
                                            );
                                          }}
                                        >
                                          Select {session.data?.length} file
                                          {session.data?.length > 1 ? 's' : ''}
                                        </PixiButton>
                                      )}
                                    </Group>
                                  )}
                                </Group>
                                {props.customFilter ? (
                                  <AssetGridRender
                                    files={session.data.filter(
                                      props.customFilter,
                                    )}
                                    containerRef={containerRef}
                                  />
                                ) : (
                                  <AssetGridRender
                                    files={session.data}
                                    containerRef={containerRef}
                                  />
                                )}
                              </Card>
                            );
                          })}
                      </Stack>
                    ) : (
                      <Paper
                        p={
                          viewport === 'xxs' || viewport === 'xs' ? 'sm' : 'lg'
                        }
                        {...props.panelProps?.centerPanel}
                      >
                        <AssetGridRender
                          files={renderFiles}
                          containerRef={containerRef}
                        />
                        {status.isAllFilesLoaded &&
                        !status.isLoadingMore &&
                        !status.isLoading &&
                        !Object.keys(filters || {})?.length ? (
                          props.emptyView
                        ) : (
                          <></>
                        )}
                        {status.isAllFilesLoaded &&
                        !status.isLoadingMore &&
                        !status.isLoading &&
                        status.totalFiles === 0 ? (
                          props.noResultsView
                        ) : (
                          <></>
                        )}
                        {status.isLoadingMore && (
                          <Group w="100%" justify="center">
                            <Loader />
                          </Group>
                        )}
                        {status.isAllFilesLoaded &&
                          !!renderFiles?.length &&
                          !props.maxResults && (
                            <PixiText c="dimmed" ta="center" mt="lg">
                              All assets loaded
                            </PixiText>
                          )}
                      </Paper>
                    )}
                  </>
                )}
                {!status.isLoadingMore &&
                  !status.isLoading &&
                  !status.isAllFilesLoaded && (
                    <>
                      <div
                        style={{
                          width: '100%',
                          height: 100,
                          marginBottom: 40,
                        }}
                        ref={ref}
                      />
                    </>
                  )}
              </Box>
            )}
          </Stack>
        )}
        {(!disable?.includes('rightPanel') ||
          props.panelProps?.rightPanel?.keepPlaceholder) && (
          <AssetGridRightPanel {...(props.panelProps?.rightPanel || {})} />
        )}
      </Group>
    </AssetGridContext.Provider>
  );
}
