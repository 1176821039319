import { Paper } from '@mantine/core';
import PixiDropdown from './elements/Dropdown';
import AppInformationDropdown from './components/AppInformationDropdown';

export default function AppPreviewBanner() {
  if (window.location.origin === 'pickit.com') {
    return <></>;
  }
  return (
    <PixiDropdown
      width={400}
      openDelay={1000}
      trigger="hover"
      target={
        <Paper
          w={400}
          h={40}
          bg="transparent"
          pos="fixed"
          style={{ zIndex: 9999, pointerEvents: 'none' }}
          bottom={0}
          p={5}
          fw="bold"
          right={0}
          fz={10}
        />
      }
      position="top"
    >
      <AppInformationDropdown />
    </PixiDropdown>
  );
}
