import { Box, Group, ScrollArea, Stack, TextInput } from '@mantine/core';
import { Flex, Icon, Loader } from 'elements_v2';
import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import PixiButton from './Button';
import PixiIcon from './Icon';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
};

export default function PDFViewer({ url }: { url: string }) {
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageInput, setCurrentPageInput] = useState(0);
  const [isFullWidth, setIsFullWidth] = useState(false);

  function onDocumentLoadSuccess({
    numPages: nextNumPages,
  }: {
    numPages: number;
  }) {
    setNumPages(nextNumPages);
    setCurrentPage(1);
  }

  useEffect(() => {
    setCurrentPageInput(currentPage);
  }, [currentPage]);

  return (
    <Stack w="100%" h="100%" bg="gray.0" p="xs">
      <ScrollArea h="100%">
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
          loading={<Loader />}
        >
          <Page
            width={970}
            key={`page_${currentPage}`}
            pageNumber={currentPage}
          />
        </Document>
      </ScrollArea>
      <Group gap="5">
        <PixiButton
          onClick={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1);
            }
          }}
          px="sm"
          color="dark"
          variant="light"
        >
          <PixiIcon name="chevron-left" />
        </PixiButton>
        <TextInput
          maw={50}
          variant="glass"
          value={currentPageInput}
          placeholder={currentPage.toString()}
          onChange={(event) => {
            setCurrentPageInput(parseInt(event.currentTarget.value));
            let page = parseInt(event.target.value);
            if (page < 0) {
              page = 1;
            }
            if (page > numPages) {
              page = numPages;
            }
            if (isNaN(page)) {
              page = 1;
            }
            setCurrentPage(page);
          }}
        />
        /{numPages}
        <PixiButton
          name="chevron-right"
          onClick={() => {
            if (currentPage < numPages) {
              setCurrentPage(currentPage + 1);
            }
          }}
          px="sm"
          color="dark"
          variant="light"
        >
          <PixiIcon name="chevron-right" />
        </PixiButton>
      </Group>
    </Stack>
  );
}
