import { useEffect } from 'react';
import { useUserContext } from 'hooks';
import { getStore, useDataStoreCallback } from '@pixi/store';
import { getUsersBatched } from './DjangoIdToImage';

export function DjangoIdToName(props) {
  const listUser = useDataStoreCallback(
    'LIST_USERS',
    (data) => {
      return data.find((user) => user.id === parseInt(props.value));
    },
    [props.value],
  );

  useEffect(() => {
    if (props.value && !listUser?.id) {
      getUsersBatched(props.value, props.fromCommunity);
    }
  }, [props.value, props.fromCommunity, listUser]);

  return listUser?.first_name ? (
    <>
      {listUser?.first_name} {listUser?.last_name}
    </>
  ) : listUser?.email ? (
    <>{listUser?.email}</>
  ) : (
    <>{props.fallback}</>
  );
}

export async function getDjangoIdToName(value, fallback) {
  const listUser = getStore('LIST_USERS').getByKey(value);
  const user = !listUser?.id ? await getUsersBatched(value) : listUser;
  return !user?.id
    ? fallback
    : user?.first_name
      ? user?.first_name + ' ' + user?.last_name
      : user?.email;
}

export function useDjangoIdToEmail(value) {
  const listUser = useDataStoreCallback(
    'LIST_USERS',
    (data) => {
      return data.find((user) => user.id === parseInt(value));
    },
    [value],
  );

  useEffect(() => {
    if (value && !listUser?.id) {
      getUsersBatched(value);
    }
  }, [value, listUser]);

  return listUser?.email;
}
