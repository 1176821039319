import { useUserContext } from 'hooks';
import { useAssetGridContext } from '../AssetGridContext';
import { Group, Paper } from '@mantine/core';
import { AssetGridUpload } from './AssetGridUpload';
import PixiText from '@pixi/elements/Text';
import PixiButton from '@pixi/elements/Button';
import PixiIcon from '@pixi/elements/Icon';
import PixiTooltip from '@pixi/elements/Tooltip';
import { getStore, useDataStoreCallback } from '@pixi/store';

export default function AssetGridContributorToolbar() {
  const {
    context,
    combinedFilters,
    disable,
    toggleActiveShortcut,
    activeShortcutIds,
    setActiveShortcuts,
  } = useAssetGridContext();
  const User = useUserContext();
  const isUserContributor = !combinedFilters?.collections?.[0]?.value
    ? false
    : context.isContributorUserInCollection(
        User.data?.user?.userId,
        User?.data?.user?.profile?.email,
        combinedFilters?.collections?.[0]?.value,
      );
  const currentCollection = useDataStoreCallback(
    'COLLECTIONS',
    () => {
      return getStore('COLLECTIONS').getByKey(
        combinedFilters?.collections?.[0]?.value as string,
      );
    },
    [combinedFilters?.collections?.[0]?.value],
  );

  const contributorInfo =
    currentCollection?.permissions?.contributor_users?.find(
      (c) => c.email === User.data?.user?.profile?.email,
    );

  if (!isUserContributor) {
    return <></>;
  }
  return (
    <Paper p="sm">
      <Paper p="lg" bg="primary.0" radius="md">
        <Group>
          <AssetGridUpload />
          <PixiTooltip label="An admin has given you access to upload to this collection">
            <PixiButton
              size="md"
              variant="transparent"
              leftSection={<PixiIcon name="lock-open" />}
            >
              Contributor access
            </PixiButton>
          </PixiTooltip>
          <Group ml="auto">
            <PixiButton
              variant={
                activeShortcutIds.includes('user_uploaded') ? 'filled' : 'light'
              }
              onClick={() => {
                toggleActiveShortcut({ id: 'user_uploaded' });
              }}
              ml="auto"
              leftSection={<PixiIcon name="loader" />}
            >
              Your uploaded assets
            </PixiButton>
            {contributorInfo?.uploads_need_approval ? (
              <PixiButton
                variant={
                  activeShortcutIds.includes('approvals.approved')
                    ? 'filled'
                    : 'light'
                }
                onClick={() => {
                  setActiveShortcuts(
                    activeShortcutIds?.includes('approvals.approved')
                      ? []
                      : [{ id: 'user_uploaded' }, { id: 'approvals.approved' }],
                  );
                }}
                ml="auto"
                leftSection={<PixiIcon name="badge-check" variant="filled" />}
              >
                Your approved assets
              </PixiButton>
            ) : (
              <></>
            )}
          </Group>
        </Group>
      </Paper>
    </Paper>
  );
}
