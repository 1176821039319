import {
  ActionIcon,
  Badge,
  Box,
  Divider,
  Group,
  Image,
  Kbd,
  Loader,
  NavLink,
  Paper,
  rem,
  ScrollArea,
  ScrollAreaAutosize,
  Stack,
  TagsInput,
  ThemeIcon,
} from '@mantine/core';
import { useColorScheme } from '@pixi/AppController';
import { SectionCollapse, Title } from '@pixi/components/AssetPanel/elements';
import PixiButton from '@pixi/elements/Button';
import PixiDropdown from '@pixi/elements/Dropdown';
import PixiIcon, { PixiIconName } from '@pixi/elements/Icon';
import PixiText from '@pixi/elements/Text';
import { DjangoIdToName, getDjangoIdToName } from 'components/DjangoIdToName';
import { useEffect, useState } from 'react';
import { useAssetGridContext } from '../AssetGridContext';
import {
  AssetGridFiltersProps,
  filterRowValueIsActive,
  filterValueToString,
} from '../Helpers';
import { useUserContext } from 'hooks';

function dataHasLabel(
  filter: Pickit.LibraryFilter['row'],
  data: Pickit.LibraryFilterDataRow,
  context: any,
) {
  return filter.field === 'file.uploaded_by'
    ? true
    : filter.field === 'collections' && !!data.value
      ? !!context.stores?.data?.collections?.find(
          (collection: Pickit.CollectionInterface) =>
            collection._id === data.value,
        )?.name
      : !data.value
        ? !!filter?.view?.emptyValue?.label
        : !!data.label || !!filterValueToString(data.value);
}

export async function filterDataToLabel({
  filter,
  data,
  onlyText,
  context,
}: {
  filter: Pickit.LibraryFilter['row'];
  data: Pickit.LibraryFilterDataRow;
  onlyText?: boolean;
  context?: any;
}) {
  const text =
    filter.field === 'file.uploaded_by'
      ? await getDjangoIdToName(data.value, filter?.view?.emptyValue?.label)
      : filter.field === 'collections' && !!data.value
        ? context.stores.data.collections.find(
            (collection: Pickit.CollectionInterface) =>
              collection._id === data.value,
          )?.name
        : !data.value
          ? filter?.view?.emptyValue?.label
          : filter.field === 'file.ext'
            ? (data.label || filterValueToString(data.value)).toUpperCase()
            : data.label || filterValueToString(data.value);
  return text as string;
}
export function FilterDataRender({
  filter,
  data,
  onlyText,
}: {
  filter: Pickit.LibraryFilter['row'];
  data: Pickit.LibraryFilterDataRow;
  onlyText?: boolean;
}) {
  const { context } = useAssetGridContext();
  const text = data.label || (
    <>
      {filter.field === 'file.uploaded_by' ? (
        <DjangoIdToName value={data.value} fallback="(Deleted user)" />
      ) : filter.field === 'collections' && !!data.value ? (
        context.stores.data.collections.find(
          (collection: Pickit.CollectionInterface) =>
            collection._id === data.value,
        )?.name
      ) : !data.value ? (
        filter?.view?.emptyValue?.label
      ) : filter.field === 'file.ext' ? (
        <>{(data.label || filterValueToString(data.value)).toUpperCase()}</>
      ) : (
        data.label || filterValueToString(data.value)
      )}
    </>
  );
  if (onlyText) {
    return text;
  }
  return (
    <PixiText
      size="sm"
      fs={!data.value ? 'italic' : 'normal'}
      c={!data.value || data.dimmed ? 'dimmed' : undefined}
      style={{
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        flexShrink: 0,
      }}
    >
      {text}
    </PixiText>
  );
}

const FilterSection = ({
  filter,
  isActive,
  row,
  preparedData,
  noBackground,
  view,
}: {
  filter: Pickit.LibraryFilter;
  isActive: boolean;
  row: Pickit.LibraryFilter['row'];
  preparedData: (Pickit.LibraryFilterDataRow & {
    onClick: () => void;
    isActive: boolean;
    count?: number;
    frozenCount?: number;
  })[];
  view: Pickit.LibraryFilter['row']['view'];
  noBackground?: boolean;
}) => {
  const colorScheme = useColorScheme();
  const { defaultFilters, activeFilters, status, context } =
    useAssetGridContext();

  // State for sorting
  const [sortOrder, setSortOrder] = useState('Most hits');
  const [labels, setLabels] = useState<Record<string, string>>({});

  async function prepareLabels() {
    const newData: Record<string, string> = {};
    await Promise.all(
      preparedData.map(async (d) => {
        const label = await filterDataToLabel({
          filter: row,
          data: d,
          context,
        });
        newData[d.value?.toString()] = label;
      }),
    );
    setLabels(newData);
  }

  useEffect(() => {
    prepareLabels();
  }, [activeFilters]);

  // Sorting the preparedData based on the selected sortOrder
  const sortedData = preparedData
    .map((s) => ({
      ...s,
      count: s.count || 0,
    }))
    .filter((s) => !s.isActive)
    .sort((a, b) => {
      if (sortOrder === 'Most hits') {
        return b.count - a.count; // Sort by most hits
      }
      const aLabel = labels[a.value?.toString()];
      const bLabel = labels[b.value?.toString()];
      console.log(aLabel, bLabel);
      return aLabel?.localeCompare(bLabel || ''); // Sort alphabetically
    });

  return (
    <SectionCollapse
      mah={350}
      key={row.key}
      label={view.name}
      defaultOpen={!!defaultFilters?.[row.field as keyof typeof defaultFilters]}
      padding="xs"
      noDivider={noBackground}
      rightSection={
        <PixiDropdown
          target={
            <PixiButton size="compact-xs" mr="xs" color="gray" variant="subtle">
              <PixiIcon
                name={
                  sortOrder === 'Most hits'
                    ? 'arrow-down-9-1'
                    : 'arrow-down-z-a'
                }
              />
            </PixiButton>
          }
        >
          {({ setIsOpen }) => (
            <>
              <PixiDropdown.Label>Sort by</PixiDropdown.Label>
              <PixiDropdown.Item
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setSortOrder('Most hits');
                  setIsOpen(false);
                }}
                color={sortOrder === 'Most hits' ? 'primary' : undefined}
              >
                Hits
              </PixiDropdown.Item>
              <PixiDropdown.Item
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setSortOrder('Alphabetical');
                  setIsOpen(false);
                }}
                color={sortOrder === 'Alphabetical' ? 'primary' : undefined}
              >
                Name
              </PixiDropdown.Item>
            </>
          )}
        </PixiDropdown>
      }
      rightSectionOnlyOnOpen
      isRightSectionBeforeChevron
      headerProps={{
        ...(isActive
          ? {
              c: 'primary',
              active: true,
              color: 'primary',
            }
          : {}),
        p: 'sm',
      }}
    >
      <Stack w="100%" gap="3" mt={isActive ? 'xs' : 0}>
        {sortedData.map(({ isActive, onClick, ...dataRow }) => {
          return (
            <NavLink
              w="100%"
              key={dataRow.value}
              color={isActive ? 'primary' : 'dark'}
              c={
                isActive ? 'primary' : colorScheme === 'dark' ? '#FFF' : 'dark'
              }
              active={isActive}
              onClick={() => onClick()}
              leftSection={
                <PixiIcon
                  name={isActive ? 'square-check' : 'square'}
                  variant={isActive ? 'filled' : undefined}
                />
              }
              disabled={status.isLoadingFilters}
              rightSection={
                <Badge
                  color={
                    isActive
                      ? 'primary'
                      : colorScheme === 'dark'
                        ? 'gray'
                        : 'dark'
                  }
                  variant={isActive ? 'filled' : 'light'}
                >
                  {dataRow.count}
                </Badge>
              }
              label={<FilterDataRender filter={row} data={dataRow} />}
            />
          );
        })}
      </Stack>
    </SectionCollapse>
  );
};
export default function AssetGridFilters({
  onClose,
  disable,
  noBackground,
  specificFilters,
  asDropdown,
}: AssetGridFiltersProps) {
  const User = useUserContext();
  const colorScheme = useColorScheme();

  // Used when asDropdown is true, its basically the "dropdown page"
  const [openFilterSection, setOpenFilterSection] = useState('');

  const {
    availableFilters,
    frozenFilters,
    defaultFilters,
    setFilter,
    setFilters,
    activeFilters,
    status,
    context,
  } = useAssetGridContext();

  function dataRowValueToString(dataRowValue: any) {
    return typeof dataRowValue === 'object'
      ? JSON.stringify(dataRowValue)
      : dataRowValue?.toString();
  }

  const library = User.data?.selectedCommunity?.libraries?.find(
    (l: any) => l.nameId === context.type,
  );
  const orderedKeys = library?.preferences?.filters?.order || [];
  const filterKeys: string[] = [];

  orderedKeys.forEach((key: string) => {
    if (Object.keys(availableFilters).includes(key)) {
      filterKeys.push(key);
    }
  });

  Object.keys(availableFilters)
    .sort((a, b) => b.localeCompare(a))
    .forEach((key) => {
      if (!filterKeys.includes(key)) {
        filterKeys.push(key);
      }
    });

  const preparedFilters = filterKeys
    .filter((key) => {
      const field = availableFilters?.[key]?.row?.field as string;
      const isActive = !!activeFilters[field];
      const { row } =
        isActive && field !== 'tags'
          ? frozenFilters[key] || availableFilters[key]
          : availableFilters[key];

      if (!row.visible && !specificFilters?.length) {
        return false; // Remove filter
      }
      if (specificFilters?.length && !specificFilters.includes(key)) {
        return false; // Remove filter
      }

      return true; // keep filter
    })
    .map((key) => {
      const field = availableFilters?.[key]?.row?.field as string;
      const isActive = !!activeFilters[field];
      const activeFiltersValue = activeFilters[field];
      const { row, data } =
        isActive && field !== 'tags'
          ? frozenFilters[key] || availableFilters[key]
          : availableFilters[key];
      const { view } = row;

      const preparedData = data
        .filter((dataRow) => dataHasLabel(row, dataRow, context))
        .sort((a, b) => {
          const isAActive = filterRowValueIsActive(
            activeFilters[row.field as string],
            a.value,
          );
          const isBActive = filterRowValueIsActive(
            activeFilters[row.field as string],
            b.value,
          );

          // When both items are either active or not active, sort by .count in descending order.
          if (isAActive === isBActive) {
            // Subtracting counts to sort in descending order. For ascending, flip `b.count` and `a.count`.
            return (b.count as number) - (a.count as number);
          }

          // If only one item is active, it should come first.
          if (isAActive && !isBActive) {
            return -1;
          }
          if (!isAActive && isBActive) {
            return 1;
          }
          return 0;
        })
        .map((dataRow) => {
          const isDataRowActive = filterRowValueIsActive(
            activeFilters[row.field as string],
            dataRow.value,
          );

          const latestCount = availableFilters[key]?.data?.find(
            (filter) => filter.value === dataRow.value,
          )?.count;
          const frozenCount = frozenFilters[key]?.data?.find(
            (frozenFilter) => frozenFilter.value === dataRow.value,
          )?.count;

          return {
            ...dataRow,
            isActive: isDataRowActive,
            count: isDataRowActive ? latestCount || 0 : dataRow.count,
            frozenCount,
            onClick: () => {
              setFilter(row.field as string, [
                {
                  value: dataRow.value as string,
                  modifier: row.field === 'tags' ? '$and' : '',
                },
              ]);
            },
          };
        });

      return {
        key,
        view,
        data,
        preparedData,
        row,
        activeFiltersValue,
        isActive,
        field,
      };
    });

  if (asDropdown) {
    return preparedFilters.map((filter) => {
      const isOpen = openFilterSection === filter.key;
      return (
        <>
          <PixiDropdown.Item
            key={filter.key}
            onClick={() => {
              setOpenFilterSection(isOpen ? '' : filter.key);
            }}
            mih={0}
            py={0}
            h={42}
            opacity={!openFilterSection || isOpen ? 1 : 0}
            mah={!openFilterSection || isOpen ? 42 : 0}
            style={{
              overflow: 'hidden',
              transition: 'max-height .15s',
              transformOrigin: 'top',
            }}
            pos="sticky"
            top={0}
            withChevron={!filter.isActive}
            color={filter.isActive ? 'primary' : undefined}
            bg={filter.isActive ? 'primary.0' : isOpen ? 'gray.1' : undefined}
            rightSection={
              filter.isActive ? (
                <PixiButton
                  size="compact-xs"
                  px={4}
                  onClick={() => {
                    setFilter(filter.row.field as string, null);
                  }}
                >
                  <PixiIcon name="xmark" size="sm" />
                </PixiButton>
              ) : undefined
            }
            styles={{
              itemSection: {
                transition: 'transform .15s',
                transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
              },
            }}
          >
            {filter.view.name}
          </PixiDropdown.Item>
          {isOpen && (
            <>
              {filter.preparedData.map(({ isActive, onClick, ...dataRow }) => (
                <PixiDropdown.Item
                  key={dataRow.key}
                  color={isActive ? 'primary' : undefined}
                  onClick={() => {
                    onClick();
                  }}
                  disabled={status.isLoadingFilters}
                  leftSection={
                    isActive ? (
                      <PixiIcon name="square-check" variant="filled" />
                    ) : (
                      <Badge size="xs" color="gray" variant="light">
                        {dataRow.count}
                      </Badge>
                    )
                  }
                >
                  <FilterDataRender filter={filter.row} data={dataRow} />
                </PixiDropdown.Item>
              ))}
            </>
          )}
        </>
      );
    });
  }

  return (
    <Stack w="100%" gap={noBackground ? '5' : 0}>
      {preparedFilters.map((filter) => (
        <FilterSection
          key={filter.key}
          filter={filter}
          isActive={filter.isActive}
          row={filter.row}
          preparedData={filter.preparedData}
          view={filter.view}
          noBackground={noBackground}
        />
      ))}
    </Stack>
  );
}
