import {
  Box,
  BoxProps,
  Collapse,
  Divider,
  Group,
  GroupProps,
  NavLink,
  NavLinkProps,
  Paper,
  PaperProps,
  ScrollArea,
  ScrollAreaAutosize,
  Stack,
  StackProps,
  TextProps,
  rem,
} from '@mantine/core';
import { useColorScheme } from '@pixi/AppController';
import PixiButton from '@pixi/elements/Button';
import PixiIcon, { PixiIconName } from '@pixi/elements/Icon';
import PixiText from '@pixi/elements/Text';
import PixiTooltip from '@pixi/elements/Tooltip';
import { ReactElement, ReactNode, useState } from 'react';

export function Section({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Box w="100%" px="lg" py="lg">
        {children}
      </Box>
      <Divider />
    </>
  );
}

export function SectionCollapse({
  children,
  label,
  icon,
  defaultOpen,
  forceOpen,
  leftSection,
  rightSection,
  isRightSectionBeforeChevron,
  rightSectionOnlyOnOpen,
  noChevron,
  noDivider,
  padding,
  onToggle,
  headerProps,
  isFocused,
  ...rest
}: {
  children?: React.ReactNode;
  label?: ReactNode;
  icon?: PixiIconName;
  defaultOpen?: boolean;
  forceOpen?: boolean;
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  isRightSectionBeforeChevron?: boolean;
  rightSectionOnlyOnOpen?: boolean;
  noChevron?: boolean;
  noDivider?: boolean;
  onToggle?: (isOpen: boolean) => void;
  padding?: StackProps['px'];
  headerProps?: Partial<NavLinkProps>;
  isFocused?: boolean;
} & BoxProps) {
  const [_isOpen, setIsOpen] = useState(defaultOpen ?? false);
  const isOpen = forceOpen || _isOpen;
  const [isTriggerHover, setIsTriggerHover] = useState(false);
  const colorScheme = useColorScheme();
  return (
    <>
      <Box w="100%" {...rest}>
        <Paper pos="sticky" style={{ zIndex: 2 }} top={0}>
          <NavLink
            px="lg"
            py="md"
            fw="500"
            label={label}
            onMouseOver={() => {
              setIsTriggerHover(true);
            }}
            onMouseLeave={() => {
              setIsTriggerHover(false);
            }}
            bg={
              isOpen
                ? colorScheme === 'dark'
                  ? 'dark.5'
                  : 'gray.0'
                : undefined
            }
            color="dark"
            c={colorScheme === 'dark' ? 'white' : 'dark'}
            leftSection={
              (icon || leftSection) && (
                <Group gap="xs">
                  {leftSection}
                  {!!icon && <PixiIcon name={icon} />}
                </Group>
              )
            }
            rightSection={
              <Group gap="0">
                {isRightSectionBeforeChevron ? (
                  !rightSectionOnlyOnOpen || isOpen ? (
                    rightSection
                  ) : (
                    <></>
                  )
                ) : noChevron ? (
                  <></>
                ) : (
                  !forceOpen && (
                    <>
                      <PixiIcon
                        name={isOpen ? 'chevron-down' : 'chevron-right'}
                        size="sm"
                        color="gray"
                      />
                    </>
                  )
                )}
                {!isRightSectionBeforeChevron ? (
                  !rightSectionOnlyOnOpen || isOpen ? (
                    <></>
                  ) : (
                    rightSection
                  )
                ) : (
                  <PixiIcon
                    name={isOpen ? 'chevron-down' : 'chevron-right'}
                    size="sm"
                    color="gray"
                  />
                )}
              </Group>
            }
            styles={{
              label: {
                fontSize: 'var(--mantine-font-size-sm)',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: '100%',
              },
              body: {
                // overflow: 'visible',
              },
            }}
            onClick={() => {
              onToggle?.(!isOpen);
              setIsOpen(!isOpen);
            }}
            {...headerProps}
          />
        </Paper>
        <Collapse w="100%" in={isOpen}>
          <ScrollArea
            mah={rest.mah}
            styles={
              rest.mah
                ? {
                    root: {
                      marginRight: rest.mah
                        ? 'var(--mantine-spacing-xs)'
                        : undefined,
                    },
                    viewport: {
                      maxHeight: rem(rest.mah),
                    },
                  }
                : undefined
            }
            pr={rest.mah ? 'xs' : undefined}
          >
            <Stack
              w="100%"
              miw={1}
              py="lg"
              pt="5"
              px={padding || 'lg'}
              bg={
                isTriggerHover || isOpen
                  ? colorScheme === 'dark'
                    ? 'dark.5'
                    : 'gray.0'
                  : undefined
              }
              style={{ overflow: 'hidden' }}
            >
              {isOpen ? children : <></>}
            </Stack>
          </ScrollArea>
        </Collapse>
      </Box>
      {!noDivider && <Divider />}
    </>
  );
}

export function Detail({
  left,
  right,
  rightProps,
  icon,
  tooltip,
  actions,
  column,
  noDivider,
}: {
  left: ReactNode;
  icon?: PixiIconName | ReactNode;
  right?: ReactNode;
  rightProps?: TextProps;
  tooltip?: ReactNode;
  column?: boolean;
  noDivider?: boolean;
  actions?: {
    label: string;
    icon: PixiIconName;
    asButton?: boolean;
    tooltip?: ReactNode;
    onClick?: () => void;
    wrapper?: (target: ReactElement) => ReactElement;
  }[];
}) {
  const leftRender = (
    <PixiText
      size="sm"
      c="dimmed"
      maw={!column ? 160 : 200}
      miw={1}
      style={{
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        flexShrink: 0,
      }}
    >
      {left}
    </PixiText>
  );
  const rightRender = !right ? undefined : (
    <PixiText
      w="100%"
      maw={column ? '100%' : 160}
      size="sm"
      ta={column ? 'left' : 'right'}
      style={
        !column
          ? {
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              flexShrink: 0,
            }
          : {
              wordBreak: 'break-word',
            }
      }
      {...rightProps}
    >
      {right}
    </PixiText>
  );
  return (
    <Stack w="100%" gap="0">
      <Group
        w="100%"
        miw={1}
        justify="space-between"
        align="center"
        wrap="nowrap"
      >
        <Group miw={1} gap="xs" justify="flex-start" wrap="nowrap">
          {icon && typeof icon === 'string' ? (
            <PixiIcon name={icon as PixiIconName} size="sm" />
          ) : (
            icon
          )}
          {leftRender}
        </Group>
        {((!column && rightRender) || actions) && (
          <Group
            miw={1}
            gap="xs"
            justify="flex-end"
            align="center"
            wrap="nowrap"
          >
            {rightRender && !column && (
              <>
                {tooltip ? (
                  <PixiTooltip label={tooltip}>{rightRender}</PixiTooltip>
                ) : (
                  rightRender
                )}
              </>
            )}
            {actions ? (
              <Group gap="5" wrap="nowrap" align="flex-start" miw="unset">
                {actions.map((action) => {
                  let target = (
                    <PixiButton
                      px={action.asButton ? undefined : '5'}
                      size={action.asButton ? 'compact-xs' : 'compact-sm'}
                      color="dark"
                      radius={action.asButton ? 'xl' : undefined}
                      variant={action.asButton ? 'outline' : 'light'}
                      key={action.icon}
                      onClick={() => {
                        action.onClick?.();
                      }}
                    >
                      {!action.asButton && (
                        <PixiIcon size="sm" name={action.icon} />
                      )}
                      {!action.asButton ? null : <>{action.label}</>}
                    </PixiButton>
                  );
                  if (action.tooltip) {
                    target = (
                      <PixiTooltip label={action.tooltip} key={action.icon}>
                        {target}
                      </PixiTooltip>
                    );
                  }
                  if (action.wrapper) {
                    return action.wrapper(target);
                  }
                  return target;
                })}
              </Group>
            ) : (
              <></>
            )}
          </Group>
        )}
      </Group>
      {column && rightRender ? (
        <Box w="100%" mt="xs">
          {tooltip ? (
            <PixiTooltip label={tooltip}>{rightRender}</PixiTooltip>
          ) : (
            rightRender
          )}
          {!noDivider && <Divider mt="xs" />}
        </Box>
      ) : (
        <></>
      )}
    </Stack>
  );
}

export function Title({
  title,
  icon,
  onClose,
  reversed,
}: {
  title: string;
  icon?: PixiIconName | ReactNode;
  onClose?: () => void;
  reversed?: boolean;
}) {
  const hideButton = (
    <PixiButton
      px="xs"
      size="xs"
      color="dark"
      variant="subtle"
      onClick={() => {
        onClose?.();
      }}
      style={{ flexShrink: 0 }}
    >
      <PixiIcon name={`arrow-${reversed ? 'right' : 'left'}-from-line`} />
    </PixiButton>
  );
  return (
    <Group w="100%" justify="space-between" p="md" wrap="nowrap">
      {reversed && hideButton}
      <Group w="100%" wrap="nowrap">
        {typeof icon === 'string' ? <PixiIcon name="filter" /> : icon}
        <PixiText fw={400} fz="sm">
          {title}
        </PixiText>
      </Group>
      {!reversed && hideButton}
    </Group>
  );
}
