import React, { useEffect, useState } from 'react';
import './style.scss';
import { MediaContext } from 'contexts';
import { Icon } from 'elements_v2';
import useStorageUrl from 'hooks/files/useStorageUrl';
import { useMediaContext, useUserContext } from 'hooks';
import { PinkToolbar } from 'elements_v2/PinkToolbar';
import useContextFromFile from 'hooks/useContextFromFile';
import { fileIsPreviewable, truncate } from 'utils';
import FileIcon from 'elements_v2/IconFile';
import SRTViewer from 'views/_Manage/Files/views/SRTViewer';

export function fileToIconName({ file }) {
  if (file.data?.type === 'font-family') {
    return 'file-earmark-font';
  }
  if (file.data?.type === 'color') {
    return 'droplet-fill';
  }
  if (file?.file?.contentType?.includes?.('image')) {
    return 'file-earmark-image';
  }
  if (file?.data?.type === 'text') {
    return 'file-earmark-text';
  }
  return 'file-earmark';
}

export function FileThumbnailV2(props) {
  const Media = useMediaContext();
  let { file } = props;
  const context = useContextFromFile(file);
  if (file?.file) {
    file = file?.file;
  }
  const isMedia = file?.url?.includes('media');
  const { generateUrl } = useStorageUrl(isMedia ? Media : context);
  const [icon, setIcon] = React.useState(null);
  const thumbRef = React.useRef();
  const wrapperRef = React.useRef();
  const [error, setError] = useState(false);
  React.useEffect(() => {
    if (context?.storageTokens) {
      setError(false);
    }
  }, [context?.storageTokens]);

  async function setImages() {
    let ext =
      props.file?.ext ||
      props.file?.file?.ext ||
      props.file?.name?.split('.')?.pop();
    try {
      if (ext === 'pptxslide') {
        ext = 'pptx';
      }
      setIcon(ext);
    } catch (e) {}
  }

  useEffect(() => {
    setImages();
  }, [props.file]);

  let element;
  if (
    file?.contentType?.includes('audio') &&
    !props.context?.fileIsPreviewable?.(file, false, true, true)
  ) {
    element = (
      <div className="FileThumbnail__icon" ref={thumbRef}>
        <Icon name="MusicNote" />
      </div>
    );
  }
  if (
    file?.ext === 'zip' &&
    !props.context?.fileIsPreviewable?.(file) &&
    !context?.fileIsPreviewable?.(file)
  ) {
    element = (
      <div
        className="FileThumbnail__icon"
        style={{ fontSize: 42, opacity: 0.75, ...(props.style || {}) }}
        ref={thumbRef}
      >
        <Icon name="file-zip" />
      </div>
    );
  }

  if (file?.contentType?.includes('audio/') && props.audioPreview) {
    element = (
      <audio
        controls
        poster={generateUrl(props.file?.previews?.[0]?.url)}
        preload
        ref={thumbRef}
      >
        <source src={generateUrl(file?.url)} mimetype={props.file?.type} />
      </audio>
    );
  }

  if (
    (file?.ext === 'mp4' || file?.ext === 'webm' || file?.previewVideo?.url) &&
    !props.noVideo
  ) {
    element = (
      <>
        <video
          controls={props.videoControls}
          poster={generateUrl(props.file?.previews?.[0]?.url)}
          muted={!props.videoControls}
          autoPlay={!props.videoControls}
          loop={!props.videoControls}
          playsInline={!props.videoControls}
          preload
          style={{ zIndex: 2, ...(props.imageStyle || {}) }}
          ref={thumbRef}
          {...(props.videoProps || {})}
        >
          <source
            src={
              file?.previewVideo?.url
                ? generateUrl(file?.previewVideo?.url)
                : generateUrl(file?.url)
            }
            mimetype={file?.previewVideo?.url ? 'video/mp4' : props.file?.type}
          />
        </video>
        {file?.previewVideoTranscription?.id ? (
          <>
            <SRTViewer
              url={generateUrl(file?.previewVideoTranscription?.url)}
              videoRef={thumbRef}
            />
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  if (file?.url && !error && !element && !props.onlyIcon) {
    if (!generateUrl(file.url)) {
      return <></>;
    }
    let src;
    let thumbnailFile = null;
    const pickitPreviewNames = ['tiny', 'small', 'medium', 'large', 'original'];
    const customPreviews = file?.previews?.reduce((result, preview) => {
      if (pickitPreviewNames.includes(preview.name)) {
        return [preview, ...result];
      }
      return result;
    }, []);
    const availablePreviewSizes = pickitPreviewNames.filter((name) =>
      file?.previews?.find((f) => f.name === name),
    );
    if (
      customPreviews?.length > 0 &&
      props.preferSize &&
      !file?.contentType?.includes('gif')
    ) {
      if (
        !customPreviews?.find((f) => f?.name === props.preferSize) &&
        (file?.ext === 'jpg' || file?.ext === 'jpeg' || file?.ext === 'png') &&
        file?.image_details?.width &&
        file?.image_details?.width < 1920 &&
        file?.image_details?.height < 1080
      ) {
        src = generateUrl(file?.url);
      } else {
        let defaultPreviewSize = 'large';
        if (!availablePreviewSizes.includes('large')) {
          if (availablePreviewSizes.includes('original')) {
            defaultPreviewSize = 'original';
          } else {
            defaultPreviewSize = 'medium';
          }
        }
        thumbnailFile =
          customPreviews?.find((f) => f?.name === props.preferSize) ||
          customPreviews?.find((f) => f?.name === defaultPreviewSize) ||
          customPreviews[0];
        src = generateUrl(thumbnailFile?.url);
      }
    }
    if (props.sizeAscending && !src) {
      pickitPreviewNames.some((size) => {
        if (props.sizeAscending?.skip?.find((key) => key === size))
          return false;
        const prev = file?.previews?.find((prev) => prev.name === size);
        if (prev) {
          src = generateUrl(prev?.url);
          return true;
        }
        return false;
      });
    }

    if (
      !context?.fileIsPreviewable(file, true, props.noVideo, props.noAudio) &&
      file?.previews?.length &&
      !src
    ) {
      thumbnailFile = file?.previews?.[0];
      src = generateUrl(file?.previews?.[0]?.url);
    } else if (
      context?.fileIsPreviewable(file, true, props.noVideo, props.noAudio) &&
      !file?.external_file &&
      !src
    ) {
      if (file?.contentLength > 7000000 && !!file?.previews?.length) {
        thumbnailFile = file?.previews?.[0];
        src = generateUrl(file?.previews?.[0]?.url);
      } else {
        thumbnailFile = file;
        src = generateUrl(file?.url);
      }
    }
    // No sense causing load time when getting a document file
    // if (!src && !isMedia) {
    //   setForceIcon(true);
    // }

    if (
      !src &&
      file?.contentLength > 2500000 &&
      !file?.previews?.length &&
      !props.ignoreFileSize
    ) {
      element = null;
    } else if (src || fileIsPreviewable(file)) {
      const width =
        thumbnailFile?.image_details?.width ||
        thumbnailFile?.width ||
        thumbnailFile?.previews?.[0]?.width;
      const height =
        thumbnailFile?.image_details?.height ||
        thumbnailFile?.height ||
        thumbnailFile?.previews?.[0]?.height;

      element = (
        <img
          width={width}
          height={height}
          src={src || generateUrl(file.url)}
          style={{
            height: 'auto',
            '-webkit-user-drag': 'none',
            ...(props.imageStyle || {}),
          }}
          ref={thumbRef}
          onError={() => {
            setError(true);
          }}
          draggable="false"
          className={props.className}
          alt={file.name}
        />
      );
    }
  }

  if (error) {
    element = (
      <div
        style={{
          ...(props.style || {}),
          width: '100%',
          minHeight: 0,
          background: 'rgba(0, 0, 0, 0.05)',
          paddingTop: '40%',
        }}
        ref={thumbRef}
      />
    );
  }
  if (
    file?.contentLength > 20000000 &&
    !file?.previews?.length &&
    context?.fileIsPreviewable(file, false, true, true)
  ) {
    element = (
      <div
        className="FileThumbnail__icon"
        style={{ flexDirection: 'column', alignItems: 'center' }}
        ref={thumbRef}
      >
        <Icon name="image" style={{ marginBottom: 20, fontSize: 24 }} />
        {truncate(file?.name, 20, '...')}
      </div>
    );
  } else if (
    file?.contentLength > 2500000 &&
    !file?.previews?.length &&
    context?.fileIsPreviewable(file, false, true, true) &&
    !props.ignoreFileSize
  ) {
    element = (
      <div
        className="FileThumbnail__icon"
        style={{ flexDirection: 'column', alignItems: 'center' }}
        ref={thumbRef}
      >
        <Icon name="image" style={{ fontSize: 24 }} />
      </div>
    );
  } else if (file?.external_file && file?.previews?.length && !element) {
    element = (
      <img
        width={file?.previews?.[0]?.width}
        height={file?.previews?.[0]?.height}
        src={generateUrl(file?.previews?.[0]?.url)}
        style={{
          height: 'auto',
          '-webkit-user-drag': 'none',
          ...(props.imageStyle || {}),
        }}
        ref={thumbRef}
        onError={() => {
          setError(true);
        }}
        draggable="false"
        className={props.className}
        alt={file.name}
      />
    );
  } else if (
    !element &&
    ((((!file?.url && icon && !props.noIcon) ||
      !context?.fileIsPreviewable(file)) &&
      !props.noIcon) ||
      (props.ignoreFileSize && file?.contentLength > 2500000) ||
      (!props.ignoreFileSize &&
        !file?.previews?.length &&
        file?.contentLength > 2500000))
  ) {
    element = (
      <div className="FileThumbnail__icon" style={props.style} ref={thumbRef}>
        <FileIcon
          name={icon}
          style={{ width: '40%', ...(props.iconStyle || {}) }}
        />
      </div>
    );
  }

  if (props.forceThumbnailUrl) {
    element = (
      <img src={props.forceThumbnailUrl} style={props.imageStyle} alt="" />
    );
  }

  if (!props.cover) {
    return (
      <>
        {element || <></>}
        {props.toolbar &&
        (!props.hideToolbarIfIcon ||
          (props.hideToolbarIfIcon &&
            element?.props?.className !== 'FileThumbnail__icon')) ? (
          <PinkToolbar parentRef={thumbRef} {...props.toolbarProps}>
            {props.toolbar}
          </PinkToolbar>
        ) : (
          <></>
        )}
      </>
    );
  }

  if (!element && props.emptyView) {
    return (
      <div className="FileThumbnail FileThumbnail--empty" ref={wrapperRef}>
        {props.emptyView}
      </div>
    );
  }

  return (
    <div className="FileThumbnail" ref={wrapperRef}>
      {props.toolbar ? (
        <PinkToolbar parentRef={wrapperRef} {...props.toolbarProps}>
          {props.toolbar}
        </PinkToolbar>
      ) : (
        <></>
      )}
      {element}
    </div>
  );
}

export default function FileThumbnail(props) {
  const Media = React.useContext(MediaContext);
  const context = props.media ? Media : props.context;
  const { generateUrl } = useStorageUrl(props.context);
  const [thumbnail, setThumbnail] = React.useReducer(
    (_state, action) => ({ ...action }),
    null,
  );
  const [icon, setIcon] = React.useState(null);
  const [url, setUrl] = React.useState(false);
  const [forceUrl, setForceUrl] = React.useState(false);
  const file = context
    ? context.getThumbnail(thumbnail || props?.file, {}, props.prefs || {})
    : thumbnail || props?.file;
  const User = useUserContext();

  React.useEffect(() => {
    if (file.url) {
      if (context.sasToken?.main) {
        setUrl(file.url?.split('?')?.[0]);
      } else {
        setUrl(file.url);
      }
    }
  }, [file]);

  React.useEffect(() => {
    if (props.file?.url && props.forceFile) {
      setForceUrl(props.file.url?.split('?')?.[0]);
    }
  }, [props.file, props.forceFile]);

  React.useEffect(() => {
    if (props.file?.id) {
      setImages();
    }
  }, [props.file?.id]);

  React.useEffect(() => {
    if (props.file?.previews) {
      setImages();
    }
  }, [props.file?.previews]);

  async function setImages() {
    let ext =
      props.file?.ext ||
      props.file?.file?.ext ||
      props.file?.name?.split('.')?.pop();
    try {
      if (ext === 'pptxslide') {
        ext = 'pptx';
      }
      setIcon(ext);
    } catch (e) {}
  }

  let element = null;

  if (file?.contentType?.includes('audio')) {
    element = (
      <div className="FileThumbnail__icon">
        <Icon name="MusicNote" />
      </div>
    );
  }

  if (file?.ext === 'mp4' || file?.ext === 'webm') {
    element = (
      <video
        controls={props.videoControls}
        poster={generateUrl(props.file?.previews?.[0]?.url)}
        muted={!props.videoControls}
        autoPlay={!props.videoControls}
        loop={!props.videoControls}
        playsInline={!props.videoControls}
        style={props.imageStyle}
      >
        <source src={generateUrl(file?.url)} mimetype={props.file?.type} />
      </video>
    );
  }

  if (
    (((!file?.url && !thumbnail && icon) || (icon && props.onlyIcon)) &&
      !props.noIcon) ||
    ((file?.contentType?.includes('video') || file?.ext === 'mxf') &&
      !element &&
      !props.noIcon)
  ) {
    element = (
      <div className="FileThumbnail__icon" style={props.style}>
        <FileIcon
          name={icon}
          style={{ width: '40%', ...(props.iconStyle || {}) }}
        />
      </div>
    );
  }

  if (
    props.file?.ext === 'svg' &&
    props?.file?.url &&
    !props?.file?.external_file &&
    !props.noIcon
  ) {
    element = (
      <div className="FileThumbnail__icon" style={props.style}>
        <img
          alt=""
          src={generateUrl(props?.file?.url)}
          style={{
            ...(props.iconStyle || {}),
            maxWidth: '65%',
          }}
        />
      </div>
    );
  }

  if (props.forceThumbnailUrl) {
    element = (
      <img src={props.forceThumbnailUrl} style={props.imageStyle} alt="" />
    );
  }

  if (forceUrl) {
    if (forceUrl && url && !User.authenticated) {
      element = <img src={url} style={props.imageStyle} alt={props.alt} />;
    } else {
      const generatedUrl = generateUrl(forceUrl);
      element = (
        <img src={generatedUrl} style={props.imageStyle} alt={props.alt} />
      );
      if (!generatedUrl) {
        return <></>;
      }
    }
  }

  if (!element) {
    const generatedUrl = generateUrl(url);
    element = (
      <img src={generatedUrl} style={props.imageStyle} alt={props.alt} />
    );
    if (!generatedUrl) {
      return <></>;
    }
  }

  if (!file?.url && !element && !url) {
    return <></>;
  }

  if (!props.cover) {
    return element;
  }

  return <div className="FileThumbnail">{element}</div>;
}
