import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { diff } from 'deep-object-diff';
import { isEqual } from 'lodash';
import EventEmitter from '@pixi/helpers/EventEmitter';
import createDataStore from '@pixi/classes/DataStore';
import { SystemErrorInterface } from '..';
import { deepEqual } from '@pixi/helpers/utils';
import { createConfigStore } from '@pixi/classes/ConfigStore';
import { PixiIconName } from '@pixi/elements/Icon';
import {
  AppConfig,
  AppConfigInterface,
  AppView,
  AppViewInterface,
} from '@pixi/AppController';
import { AppUser, AppUserInterface } from '@pixi/AppUser';

const StoreEventEmitter = new EventEmitter<{
  change: [
    typeof DataStores,
    keyof typeof DataStores,
    'added' | 'removed' | 'updated',
  ];
  itemChange: [
    keyof typeof DataStores,
    {
      change: 'added' | 'removed' | 'updated';
      data: (typeof DataStores)[keyof typeof DataStores][number];
    }[],
  ];
  multiStoreChange: [
    ReturnType<
      typeof createDataStore<
        MultiDataStoreList[keyof MultiDataStoreList]['storeData'],
        string
      >
    >,
    keyof MultiDataStoreList,
    string,
    'added' | 'removed' | 'updated',
  ];
  multiStoreItemChange: [
    string,
    {
      change: 'added' | 'removed' | 'updated';
      data: MultiDataStoreList[keyof MultiDataStoreList]['storeData'];
    }[],
  ];
  configChange: [any, any, keyof typeof ConfigStores];
}>();

export type DataStoresList = {
  BOARDS: Pickit.BoardInterface[];
  BOARD_MODULES: Pickit.BoardSectionInterface[];
  LIST_USERS: Pickit.UserListInterface[];
  SYSTEM_ERRORS: SystemErrorInterface[];
  FOLDERS: Pickit.FolderInterface[];
  FILES: Pickit.FileInterface[];
  FILES_UPLOADING: {
    id: string;
    size: number;
    name: string;
    status?: {
      bytesUploaded: number;
    };
  }[];
  COLLECTIONS: Pickit.CollectionInterface[];
  APP_TOASTS: {
    id: string;
    title?: string;
    message?: string;
    icon?: PixiIconName;
    type?: 'error' | 'success' | 'info' | 'warning';
    visible?: boolean;
  }[];
};

const DataStores: DataStoresList = {
  BOARDS: [],
  BOARD_MODULES: [],
  LIST_USERS: [],
  SYSTEM_ERRORS: [],
  FILES: [],
  COLLECTIONS: [],
  APP_TOASTS: [],
  FOLDERS: [],
  FILES_UPLOADING: [],
};

type MultiStores = 'FILES_SELECTED' | 'TEST_TEMP' | 'FILES_STATUS';

export interface MultiDataStoreList
  extends Record<
    MultiStores,
    {
      key: string;
      storeData: unknown;
    }
  > {
  FILES_SELECTED: {
    key: string;
    storeData: { _id: string; selectedAt?: number };
  };
  FILES_STATUS: {
    key: string;
    storeData: Pickit.FileInterface;
  };
  TEST_TEMP: {
    key: string;
    storeData: Pickit.FileInterface;
  };
}

const MultiDataStores: Record<
  keyof MultiDataStoreList,
  {
    key: string;
    stores: Record<string, any>;
  }
> = {
  FILES_STATUS: {
    key: '_id',
    stores: {},
  },
  FILES_SELECTED: {
    key: '_id',
    stores: {},
  },
  TEST_TEMP: {
    key: '_id',
    stores: {},
  },
};

interface ConfigStoresInterface {
  APP_CONFIG: AppConfigInterface;
  APP_VIEW: AppViewInterface;
  APP_USER: AppUserInterface;
}

const ConfigStores: ConfigStoresInterface = {
  APP_CONFIG: AppConfig,
  APP_VIEW: AppView,
  APP_USER: AppUser,
};

const APP_CONFIG = createConfigStore<ConfigStoresInterface['APP_CONFIG']>(
  ConfigStores.APP_CONFIG,
  (data, oldData, newState) => {
    ConfigStores.APP_CONFIG = newState;
    StoreEventEmitter.dispatch('configChange', data, oldData, 'APP_CONFIG');
  },
);
const APP_VIEW = createConfigStore<ConfigStoresInterface['APP_VIEW']>(
  ConfigStores.APP_VIEW,
  (data, oldData, newState) => {
    ConfigStores.APP_VIEW = newState;
    StoreEventEmitter.dispatch('configChange', data, oldData, 'APP_VIEW');
  },
);
const APP_USER = createConfigStore<ConfigStoresInterface['APP_USER']>(
  ConfigStores.APP_USER,
  (data, oldData, newState) => {
    ConfigStores.APP_USER = newState;
    StoreEventEmitter.dispatch('configChange', data, oldData, 'APP_USER');
  },
);

const FILES_UPLOADING = createDataStore<
  (typeof DataStores)['FILES_UPLOADING'][0],
  string
>({
  name: 'FILES_UPLOADING',
  key: 'id',
  defaultData: [],
  _dispatch: (data, change) => {
    DataStores.FILES_UPLOADING = data;
    StoreEventEmitter.dispatch('change', DataStores, 'FILES_UPLOADING', change);
  },
  _dispatchItemChange: (rows) => {
    StoreEventEmitter.dispatch('itemChange', 'FILES_UPLOADING', rows);
  },
});

const APP_TOASTS = createDataStore<
  (typeof DataStores)['APP_TOASTS'][0],
  string
>({
  name: 'APP_TOASTS',
  key: 'id',
  defaultData: [],
  _dispatch: (data, change) => {
    DataStores.APP_TOASTS = data;
    StoreEventEmitter.dispatch('change', DataStores, 'APP_TOASTS', change);
  },
  _dispatchItemChange: (rows) => {
    StoreEventEmitter.dispatch('itemChange', 'FILES_UPLOADING', rows);
  },
});

const FOLDERS = createDataStore<(typeof DataStores)['FOLDERS'][0], string>({
  name: 'FOLDERS',
  key: '_id',
  defaultData: [],
  _dispatch: (data, change) => {
    DataStores.FOLDERS = data;
    StoreEventEmitter.dispatch('change', DataStores, 'FOLDERS', change);
  },
  _dispatchItemChange: (rows) => {
    StoreEventEmitter.dispatch('itemChange', 'FOLDERS', rows);
  },
});

const BOARDS = createDataStore<(typeof DataStores)['BOARDS'][0], string>({
  name: 'BOARDS',
  key: '_id',
  defaultData: [],
  _dispatch: (data, change) => {
    DataStores.BOARDS = data;
    StoreEventEmitter.dispatch('change', DataStores, 'BOARDS', change);
  },
  _dispatchItemChange: (rows) => {
    StoreEventEmitter.dispatch('itemChange', 'BOARDS', rows);
  },
});
const BOARD_MODULES = createDataStore<
  (typeof DataStores)['BOARD_MODULES'][0],
  string
>({
  name: 'BOARD_MODULES',
  key: '_id',
  defaultData: [],
  _dispatch: (data, change) => {
    DataStores.BOARD_MODULES = data;
    StoreEventEmitter.dispatch('change', DataStores, 'BOARD_MODULES', change);
  },
  _dispatchItemChange: (rows) => {
    StoreEventEmitter.dispatch('itemChange', 'BOARD_MODULES', rows);
  },
});
const FILES = createDataStore<(typeof DataStores)['FILES'][0], string>({
  name: 'FILES',
  key: '_id',
  defaultData: [],
  _dispatch: (data, change) => {
    DataStores.FILES = data;
    StoreEventEmitter.dispatch('change', DataStores, 'FILES', change);
  },
  _dispatchItemChange: (rows) => {
    StoreEventEmitter.dispatch('itemChange', 'FILES', rows);
  },
  // objectUpdated: (data, newData) => {
  //   if (!data.updatedAt && !newData.updatedAt) {
  //     return (
  //       new Date(data.createdAt || '')?.getTime() !==
  //       new Date(newData.createdAt || '')?.getTime()
  //     );
  //   }
  //   return (
  //     new Date(data.updatedAt || '')?.getTime() !==
  //     new Date(newData.updatedAt || '')?.getTime()
  //   );
  // },
});
const COLLECTIONS = createDataStore<
  (typeof DataStores)['COLLECTIONS'][0],
  string
>({
  name: 'COLLECTIONS',
  key: '_id',
  defaultData: [],
  _dispatch: (data, change) => {
    DataStores.COLLECTIONS = data;
    StoreEventEmitter.dispatch('change', DataStores, 'COLLECTIONS', change);
  },
  _dispatchItemChange: (rows) => {
    StoreEventEmitter.dispatch('itemChange', 'COLLECTIONS', rows);
  },
});

const LIST_USERS = createDataStore<
  (typeof DataStores)['LIST_USERS'][0],
  string
>({
  name: 'LIST_USERS',
  key: 'id',
  defaultData: [],
  _dispatch: (data, change) => {
    DataStores.LIST_USERS = data;
    StoreEventEmitter.dispatch('change', DataStores, 'LIST_USERS', change);
  },
  _dispatchItemChange: (rows) => {
    StoreEventEmitter.dispatch('itemChange', 'LIST_USERS', rows);
  },
});

const SYSTEM_ERRORS = createDataStore<
  (typeof DataStores)['SYSTEM_ERRORS'][0],
  string
>({
  name: 'SYSTEM_ERRORS',
  key: 'id',
  defaultData: [],
  _dispatch: (data, change) => {
    DataStores.SYSTEM_ERRORS = data;
    StoreEventEmitter.dispatch('change', DataStores, 'SYSTEM_ERRORS', change);
  },
  _dispatchItemChange: (rows) => {
    StoreEventEmitter.dispatch('itemChange', 'SYSTEM_ERRORS', rows);
  },
});

const dataStores = {
  BOARDS,
  BOARD_MODULES,
  LIST_USERS,
  FOLDERS,
  SYSTEM_ERRORS,
  FILES,
  FILES_UPLOADING,
  COLLECTIONS,
  APP_TOASTS,
};
const configStores: {
  [K in keyof ConfigStoresInterface]: ReturnType<
    typeof createConfigStore<ConfigStoresInterface[K]>
  >;
} = {
  APP_CONFIG,
  APP_VIEW,
  APP_USER,
};

export function useKeySelector<T extends keyof typeof dataStores>(
  storeKey: T,
  key: string,
  deps?: unknown[],
) {
  const currentKey = useRef<string | null>();
  const [data, setData] = useReducer(
    (
      state: undefined | (typeof DataStores)[T][0],
      newState: undefined | (typeof DataStores)[T][0],
    ) => (!newState ? undefined : { ...newState }),
    undefined,
  );
  useDataStoreListener<T>(
    storeKey,
    (rows, store) => {
      const newData = store.getByKey(key);

      if (currentKey.current === key) {
        const diffResult = Object.keys(
          diff(newData as object, data || {}),
        )?.length;
        if (diffResult) {
          setData(newData);
        }
      } else if (newData) {
        setData(newData);
      }
      currentKey.current = store.getKeyByKey(key);
    },
    [data, key],
  );

  useEffect(() => {
    setData(getStore(storeKey).getByKey(key));
  }, [key]);

  return data;
}

export function useDataStoreCallback<Data, T extends keyof typeof DataStores>(
  key: T,
  callback: (
    data: (typeof DataStores)[T],
    store: (typeof dataStores)[T],
  ) => Data,
  deps?: unknown[],
) {
  const memoizedCallback = useCallback(callback, deps || []);
  const store = getStore(key);
  const [data, setData] = useState<Data>(
    memoizedCallback(store.state as (typeof DataStores)[T], store),
  );

  useEffect(() => {
    const store = getStore(key);
    const newDataCallback = memoizedCallback(
      store.state as (typeof DataStores)[T],
      store,
    );
    if (!deepEqual(newDataCallback, data)) {
      setData(newDataCallback);
    }
  }, [key, memoizedCallback, ...(deps || [])]);

  useEffect(() => {
    const listenerId = StoreEventEmitter.on(
      'change',
      (newData, storeKey, change) => {
        if (key === storeKey) {
          const newDataCallback = memoizedCallback(
            newData[key],
            dataStores[storeKey] as (typeof dataStores)[T],
          );
          setData(newDataCallback);
        }
      },
    );

    return () => {
      StoreEventEmitter.off('change', listenerId);
    };
  }, [key, memoizedCallback, data, ...(deps || [])]);

  return data;
}

function getMultiDataStore<T extends keyof MultiDataStoreList>(
  key: T,
  storeKey: string,
): ReturnType<
  typeof createDataStore<MultiDataStoreList[T]['storeData'], string>
> {
  const multiStore = MultiDataStores[key];
  if (multiStore.stores[storeKey]) {
    return multiStore.stores[storeKey];
  }
  const STORE = createDataStore<MultiDataStoreList[T]['storeData'], string>({
    name: storeKey,
    key: multiStore.key,
    defaultData: [],
    _dispatch: (data, change) => {
      StoreEventEmitter.dispatch(
        'multiStoreChange',
        MultiDataStores[key].stores[storeKey],
        key,
        storeKey,
        change,
      );
    },
    _dispatchItemChange: (rows) => {
      StoreEventEmitter.dispatch('multiStoreItemChange', multiStore.key, rows);
    },
  });
  MultiDataStores[key].stores[storeKey] = STORE;
  return STORE;
}

export function useMultiStoreData<T extends keyof MultiDataStoreList>(
  key: T,
  storeKey: string,
  deps?: unknown[],
) {
  const [data, setData] = useState<MultiDataStoreList[T]['storeData'][]>([]);

  useEffect(() => {
    const store = getMultiDataStore(key, storeKey);
    setData(store.state);
  }, [key, ...(deps || [])]);

  useEffect(() => {
    const listenerId = StoreEventEmitter.on(
      'multiStoreChange',
      (store, _key, _storeKey) => {
        if (_key === key && storeKey === _storeKey) {
          setData(store.state);
        }
      },
    );

    return () => {
      StoreEventEmitter.off('change', listenerId);
    };
  }, [key, data, ...(deps || [])]);

  return data;
}

export function useMultiDataStoreListener<T extends keyof MultiDataStoreList>(
  key: T,
  callback: (
    storeKey: string,
    store: ReturnType<
      typeof createDataStore<MultiDataStoreList[T]['storeData'], string>
    >,
    change: 'added' | 'removed' | 'updated',
  ) => void,
  deps?: unknown[],
) {
  return useEffect(() => {
    const listenerId = StoreEventEmitter.on(
      'multiStoreChange',
      (store, _key, storeKey, change) => {
        if (_key === key) {
          callback(storeKey, store, change);
        }
      },
    );

    return () => {
      StoreEventEmitter.off('change', listenerId);
    };
  }, deps || []);
}

export function useMultiDataStoreCallback<
  T extends keyof MultiDataStoreList,
  A extends unknown,
>(
  key: T,
  storeKey: string,
  callback: (
    store: ReturnType<
      typeof createDataStore<MultiDataStoreList[T]['storeData'], string>
    >,
  ) => A,
  deps?: unknown[],
) {
  const store = getMultiDataStore(key, storeKey);
  const [data, setData] = useState<ReturnType<typeof callback>>(
    callback(store),
  );

  const memoizedCallback = useCallback(callback, deps || []);

  useEffect(() => {
    const store = getMultiDataStore(key, storeKey);
    const newDataCallback = memoizedCallback(store);
    if (!isEqual(newDataCallback, data) && !deepEqual(newDataCallback, data)) {
      setData(newDataCallback);
    }
  }, [key, memoizedCallback, ...(deps || [])]);

  useEffect(() => {
    const listenerId = StoreEventEmitter.on(
      'multiStoreChange',
      (store, _key, _storeKey) => {
        if (_key === key && storeKey === _storeKey) {
          const newDataCallback = memoizedCallback(store);
          if (
            !isEqual(newDataCallback, data) &&
            !deepEqual(newDataCallback, data)
          ) {
            setData(newDataCallback);
          }
        }
      },
    );

    return () => {
      StoreEventEmitter.off('change', listenerId);
    };
  }, [key, memoizedCallback, data, ...(deps || [])]);

  return data as ReturnType<typeof callback>;
}

export function useDataStoreChangeListener<T extends keyof typeof DataStores>(
  key: T,
  callback: (
    rows: {
      change: 'added' | 'removed' | 'updated';
      data: (typeof DataStores)[T][number];
    }[],
    store: (typeof dataStores)[T],
  ) => void,
  deps?: unknown[],
) {
  return useEffect(() => {
    const listenerId = StoreEventEmitter.on('itemChange', (storeKey, rows) => {
      if (key === storeKey) {
        callback(rows, dataStores[key]);
      }
    });
    return () => {
      StoreEventEmitter.off('itemChange', listenerId);
    };
  }, deps || []);
}

export function useDataStoreListener<T extends keyof typeof DataStores>(
  key: T,
  callback: (
    data: (typeof DataStores)[T],
    store: (typeof dataStores)[T],
    change: 'added' | 'removed' | 'updated',
  ) => void,
  deps?: unknown[],
) {
  return useEffect(() => {
    const listenerId = StoreEventEmitter.on(
      'change',
      (data, storeKey, change) => {
        if (key === storeKey) {
          callback(data[key], dataStores[key], change);
        }
      },
    );
    return () => {
      StoreEventEmitter.off('change', listenerId);
    };
  }, deps || []);
}

function useSelector(initialData: typeof DataStores, watchKeys?: string[]) {
  const [data, setData] = useReducer(
    (state: typeof DataStores, newState: typeof DataStores) => {
      return isEqual(state, newState) ? state : { ...newState };
    },
    initialData,
  );
  useEffect(() => {
    const listenerId = StoreEventEmitter.on('change', (data, storeKey) => {
      if (watchKeys?.length && watchKeys.includes(storeKey)) {
        setData(data);
      } else if (watchKeys?.length) {
        // empty for now
      } else {
        setData(data);
      }
    });
    return () => {
      StoreEventEmitter.off('change', listenerId);
    };
  }, []);
  return data;
}

export function getStore<T extends keyof typeof dataStores>(type: T) {
  return dataStores[type];
}

export function getMultiStore<T extends MultiStores>(
  type: T,
  storeKey: string,
) {
  return getMultiDataStore(type, storeKey);
}

export function useDataStore<K extends keyof typeof dataStores>(key: K) {
  const data = useSelector(DataStores, [key]);
  return {
    data: data[key],
    ...dataStores[key],
  };
}

export function getConfigStore<T extends keyof typeof configStores>(type: T) {
  return configStores[type];
}

export function useConfigStoreValue<
  K extends keyof ConfigStoresInterface,
  T extends keyof ConfigStoresInterface[K],
>(storeKey: K, key: T): ConfigStoresInterface[K][T] {
  const [data, setData] = useState(configStores[storeKey].getValue(key as any));
  useEffect(() => {
    const listenerId = StoreEventEmitter.on(
      'configChange',
      (newValue: any, oldValue: any) => {
        if (!isEqual(oldValue, newValue) && !deepEqual(oldValue, newValue)) {
          setData(configStores[storeKey].getValue(key as any));
        }
      },
    );
    return () => {
      StoreEventEmitter.off('configChange', listenerId);
    };
  }, [data]);
  return data as ConfigStoresInterface[K][T];
}

export function useConfigStoreCallback<
  K extends keyof ConfigStoresInterface,
  T extends keyof ConfigStoresInterface[K],
  R, // Generic type parameter R for the callback's return type
>(
  storeKey: K,
  key: T,
  callback: (newValue: ConfigStoresInterface[K][T]) => R,
  deps: React.DependencyList = [],
): R {
  // Use the existing hook to get the current value and subscribe to changes
  const value = useConfigStoreValue(storeKey, key);

  // State to hold the callback's return value
  const [callbackResult, setCallbackResult] = useState<R>(() =>
    callback(value),
  );

  // Ref to store the previous callback result for comparison
  const prevCallbackResultRef = useRef<R>(callbackResult);

  // Use useEffect to call the callback whenever the value or deps change
  useEffect(() => {
    const newCallbackResult = callback(value);

    // Only update the state (and potentially trigger a re-render) if the result is different
    if (newCallbackResult !== prevCallbackResultRef.current) {
      setCallbackResult(newCallbackResult);
      prevCallbackResultRef.current = newCallbackResult; // Update the ref with the new callback result
    }
  }, [value, callback, ...deps]); // Include the deps array in the dependency list

  return callbackResult;
}
