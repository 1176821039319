import React, { cloneElement, useEffect } from 'react';
import './style.scss';

import { Icon } from 'elements_v2';

import useStorageUrl from 'hooks/files/useStorageUrl';
import useUserStatus from '../../hooks/useUserStatus';

export function Item({
  context,
  index,
  groupIsOpen,
  moveCollection,
  data,
  onSort,
  overlay,
  group,
  id,
  title,
  onClick,
  thumbnail,
  customThumbnail,
  toolbar,
  background,
}) {
  const ref = React.useRef(null);
  const { generateUrl } = useStorageUrl(context);

  if (!title) {
    return <></>;
  }

  const className = ['Pixi__CollectionGroup__content__item'];
  return (
    <div
      style={{ width: '100%' }}
      onClick={onClick}
      className={className.join(' ')}
    >
      {toolbar ? (
        <div className="Pixi__CollectionGroup__content__item__toolbar">
          {cloneElement(toolbar, {
            className: 'brand__color__bg',
          })}
        </div>
      ) : (
        ''
      )}
      <div
        className="Pixi__CollectionGroup__content__item__wrapper"
        ref={ref}
        style={{ background }}
      >
        <div className="Pixi__CollectionGroup__content__item__wrapper__content">
          {overlay || ''}
          {customThumbnail && (
            <div className="Pixi__CollectionGroup__content__item__thumbnail">
              <div className="Pixi__CollectionGroup__content__item__thumbnail__image">
                {customThumbnail}
              </div>
            </div>
          )}
          {thumbnail && groupIsOpen && (
            <div className="Pixi__CollectionGroup__content__item__thumbnail">
              <div
                className="Pixi__CollectionGroup__content__item__thumbnail__image"
                style={{
                  backgroundImage: `url(${
                    context ? generateUrl(thumbnail) : thumbnail
                  })`,
                }}
              />
            </div>
          )}
          <h1>{title}</h1>
        </div>
      </div>
    </div>
  );
}

export default function CollectionGroup({
  id,
  index,
  data,
  sortable,
  locked,
  moveGroup,
  onSort,
  saveGroupOrder,
  loading,
  title,
  collections,
  style,
  toolbar,
  background,
  dropZone,
  overlay,
  openInitially,
  collectionOverlay,
  onOpen,
  context,
}) {
  const [open, setOpen] = React.useState(
    openInitially !== undefined ? openInitially : false,
  );
  const [collectionsList, setCollectionsList] = React.useState(
    (state, action) => (Array.isArray(action) ? [...action] : []),
    [],
  );
  const ref = React.useRef(null);

  // const [{ isDragging }, connectDrag] = useDrag({
  //   type: 'CollectionGroup',
  //   item: { id, type: 'CollectionGroup', data, index },
  //   collect: (monitor) => {
  //     return {
  //       isDragging: monitor.isDragging(),
  //     };
  //   },
  //   canDrag: () => {
  //     return draggable && !open; // TODO: Implement turn on/off
  //   },
  // });

  React.useEffect(() => {
    setCollectionsList(collections);
  }, [collections]);

  let className = 'Pixi__CollectionGroup brand__color__border__before';
  className += open && !locked ? ' Pixi__CollectionGroup--open' : '';
  className += loading ? ' Pixi__CollectionGroup--loading' : '';

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  const moveCollection = (sourceId, destinationId) => {
    const sourceIndex = collectionsList.findIndex(
      (item) => item.id === sourceId,
    );
    const destinationIndex = collectionsList.findIndex(
      (item) => item.id === destinationId,
    );

    // If source/destination is unknown, do nothing.
    if (sourceId === -1 || destinationId === -1) {
      return;
    }

    const newList = [
      ...array_move(collectionsList, sourceIndex, destinationIndex),
    ];
    setCollectionsList(newList);
  };

  const userStatus = useUserStatus();
  if (userStatus?.product?.isExternalUser && !collectionsList.length) {
    return <></>;
  }
  return (
    <div className={className} style={style}>
      <div className="Pixi__CollectionGroup__wrapper">
        {dropZone || null}
        {loading && <div className="LoadingOverlay" />}
        <div
          className="Pixi__CollectionGroup__header"
          onClick={() => {
            onOpen?.(!open);
            setOpen(!open);
          }}
        >
          {overlay}
          {toolbar && (
            <div className="Pixi__CollectionGroup__toolbar">{toolbar}</div>
          )}
          <div
            className="Pixi__CollectionGroup__header__content"
            ref={ref}
            style={{ background }}
          >
            <h1>{title || 'Unknown'}</h1>
            <Icon name={open && !locked ? 'ChevronDown' : 'ChevronRight'} />
          </div>
        </div>
        <div className="Pixi__CollectionGroup__content">
          {collectionsList.map((props, index) => (
            <Item
              {...props}
              key={props?.id || props?.title}
              index={index}
              moveCollection={moveCollection}
              groupId={id}
              onSort={() => {
                if (onSort) {
                  onSort(
                    collectionsList
                      .filter((col) => !!col.id)
                      .map((col) => col.id),
                  );
                }
              }}
              overlay={collectionOverlay?.(props.data)}
              context={context}
              groupIsOpen={open}
              group={data}
              draggable={sortable}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
