import {
  useDocumentsContext,
  useFlowsContext,
  useMediaContext,
  useUserContext,
} from 'hooks';
import React, { createContext, useEffect, useState, useReducer } from 'react';
import { uniqueId } from 'utils';
import clone from 'clone';
import { differenceInDays, isBefore, isYesterday } from 'date-fns';
import useFiles from 'hooks/files/useFiles';
import useUserStatus from 'hooks/useUserStatus';
import asyncPool from 'utils/async-pool';
import { NotificationContext } from 'contexts/Notifications';

class Notification {
  constructor(values, newDate) {
    Object.assign(this, values);
    if (!this.createdOn || newDate) this.createdOn = new Date();
  }

  updateNotification(values, newDate) {
    Object.assign(this, values);
    if (!this.createdOn || newDate) this.createdOn = new Date();
  }
}

export const NotificationProvider = ({ children }) => {
  const User = useUserContext();
  const UserStatus = useUserStatus();
  const {
    setHasNewNotifications,
    setNotifications,
    setNotificationFollows,
    setApproval,
    spaces,
  } = User;
  const {
    following,
    notifications: cloudNotifications,
    hasNewNotifications,
    approval,
  } = User.cloudStorage;
  const slug = spaces?.selected?.slug;
  const libraries = {
    media: useMediaContext(),
    documents: useDocumentsContext(),
  };
  const Flows = useFlowsContext();

  const [checkForChanges, setCheckForChanges] = useState(false);
  const [initializedCloud, setInitializedCloud] = useState(false);
  const [approvalFiles, setApprovalFiles] = useState({
    media: 0,
    documents: 0,
  });

  const [feedbackFiles, setFeedbackFiles] = useState({
    media: 0,
    documents: 0,
  });

  const removeDupes = (base, additions) => {
    const parsedItems = [...base];
    additions.forEach((notification) => {
      const existingNotification = parsedItems.find(
        (item) =>
          item.source.id + item.reason ===
          notification.source.id + notification.reason,
      );
      if (existingNotification) {
        delete notification.uuid;
        notification.createdOn = isBefore(
          new Date(notification.createdOn),
          new Date(existingNotification.createdOn),
        )
          ? existingNotification.createdOn
          : notification.createdOn;
        existingNotification.updateNotification(notification);
      } else {
        parsedItems.push(new Notification(notification));
      }
    });
    return parsedItems;
  };

  const [notifications, registerNotificationChange] = useReducer(
    (state, action) => {
      let newState;
      switch (action.key) {
        case 'add':
          newState = removeDupes(state, [
            new Notification(
              {
                uuid: uniqueId(),
                source: action.source,
                reason: action.reason,
              },
              action.newDate,
            ),
          ]);
          break;
        case 'addMultiple':
          if (!action.entries || action.entries.length === 0) return state;
          newState = removeDupes(
            state,
            action.entries.map(
              (entry) =>
                new Notification(
                  {
                    uuid: uniqueId(),
                    source: entry.source,
                    reason: entry.reason,
                  },
                  action.newDate,
                ),
            ),
          );

          break;
        case 'addMultipleNotifications':
          if (!action.entries || action.entries.length === 0) return state;
          newState = removeDupes(state, action.entries);
          break;
        case 'remove':
          newState = [
            ...state.filter(
              (notification) => notification.uuid !== action.uuid,
            ),
          ];
          break;
        case 'replace':
          newState = [...action.value];
          break;
        default:
          newState = [...state];
          break;
      }

      return newState
        .filter(
          (item) => differenceInDays(new Date(), new Date(item.createdOn)) < 1,
        )
        .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
    },
    [],
  );

  useEffect(() => {
    setTimeout(() => setCheckForChanges(true), 3000);
  }, []);

  useEffect(() => {
    if (checkForChanges) {
      checkFollowedCollections();
      checkApprovalChanges();
      checkApprovalFilesAdded();
      checkFileFeedback();
    }
  }, [checkForChanges]);
  const checkApprovalFilesAdded = async () => {
    if (
      UserStatus?.product?.isBusinessAdmin &&
      UserStatus?.policies?.access?.services?.approval
    ) {
      const counts = await Flows.getSplitUserTasksCount();
      setApprovalFiles(counts);
    }
  };

  useEffect(() => {
    if (notifications?.length > 0) {
      setNotifications([...notifications]);
    }
  }, [notifications]);
  useEffect(() => {
    if (!initializedCloud && cloudNotifications?.length > 0) {
      registerNotificationChange({
        key: 'addMultipleNotifications',
        entries: [...cloudNotifications],
        keepDate: true,
      });
      setInitializedCloud(true);
    }
  }, [cloudNotifications]);

  const checkFollowedCollections = async () => {
    if (
      !following ||
      !following.collections ||
      following.collections.length === 0
    )
      return;

    const responses = await Promise.all(
      following.collections.map(async (collection) => {
        const response = await libraries[collection.libraryType].getFiles(
          `?page=0&sort_by=updatedAt&sort_direction=descending&limit=1&filter=${JSON.stringify(
            { collections: [collection.id] },
          )}`,
          collection.communitySlug,
        );
        response.collection = collection;
        return response;
      }),
    );
    const changes = responses
      .map((response) => {
        let change;
        if (response.collection.communitySlug !== slug) return;

        if (
          response.documents.length > 0 &&
          response?.documents[0]._id !== response.collection.newestFileId
        ) {
          if (response.totalResults > response.collection.files) {
            change = { source: response.collection, reason: 'filesAdded' };
          }
          if (response.totalResults < response.collection.files) {
            change = { source: response.collection, reason: 'filesRemoved' };
          }
          if (response.totalResults === response.collection.files) {
            change = { source: response.collection, reason: 'filesChanged' };
          }
          change.source.newestFileId = response?.documents[0]?._id;
        }
        return change;
      })
      .filter((item) => item);
    registerNotificationChange({
      key: 'addMultiple',
      entries: changes,
      newDate: true,
    });
    const newFollowing = clone(User.cloudStorage.following);
    newFollowing.latestCheck = new Date();
    if (changes.length > 0) {
      setHasNewNotifications(true);
      newFollowing.collections = newFollowing.collections.map((collection) => {
        collection.files =
          responses.find((response) => response.collection.id === collection.id)
            ?.totalResults ?? collection.files;
        return collection;
      });
    }
    User.setCloudStorage('following', newFollowing);
  };

  const followCollection = async (
    { libraryType, _id, communitySlug },
    files,
  ) => {
    const response = await libraries[libraryType].getFiles(
      `?page=0&sort_by=createdAt&sort_direction=descending&limit=1&filter=${JSON.stringify(
        { collections: [_id] },
      )}`,
      communitySlug,
    );
    const _following = clone(following);
    if (!_following.collections) {
      _following.collections = [];
    }
    _following.collections.push({
      libraryType,
      id: _id,
      files,
      communitySlug,
      newestFileId: response.documents[0]?._id,
    });
    setNotificationFollows(_following);
  };

  const unfollowCollection = ({ _id }) => {
    const _following = clone(following);
    _following.collections = _following.collections.filter(
      (item) => item.id !== _id,
    );
    setNotificationFollows(_following);
    let _cloudNotifications = clone(cloudNotifications);
    _cloudNotifications = _cloudNotifications.filter(
      (item) => item.source.id !== _id,
    );
    setNotifications(_cloudNotifications);
    const existingNotification = notifications.find(
      (item) => item.source.id === _id,
    )?.uuid;
    if (existingNotification)
      registerNotificationChange({ key: 'remove', uuid: existingNotification });
  };
  const checkApprovalChanges = async () => {
    if (
      !libraries.media.isContributorUser(
        User.djangoProfile?.id,
        User.djangoProfile?.email,
      ) &&
      !libraries.documents.isContributorUser(
        User.djangoProfile?.id,
        User.djangoProfile?.email,
      )
    )
      return;
    const approvalStatusPerCollection = {};
    const changes = [];
    const collections = [
      ...libraries.media.collections,
      ...libraries.documents.collections,
    ];
    for (const collection of collections) {
      if (
        collection?.permissions?.contributor_users?.find(
          (user) =>
            user.user_id === User?.djangoProfile?.id ||
            user.email === User.djangoProfile?.email,
        )
      ) {
        // Get current state
        const files = await getContributorFiles(collection);
        approvalStatusPerCollection[collection._id] = files.reduce(
          (totals, file) => {
            totals[file?.approval?.status]++;
            return totals;
          },
          {
            rejected: 0,
            approved: 0,
            none: 0,
            undefined: 0,
          },
        );

        // Compare last check to current approval state
        const { approved, rejected } =
          approvalStatusPerCollection[collection._id];
        const {
          approved: approved_ = 0,
          rejected: rejected_ = 0,
          // lastChange,
        } = approval.latestState[collection._id] ?? {};

        if (approved > approved_) {
          changes.push({
            source: collection,
            reason: 'approvalApprovedIncreased',
          });
          approvalStatusPerCollection[collection._id].lastChange = new Date();
        }
        if (rejected > rejected_) {
          changes.push({
            source: collection,
            reason: 'approvalRejectedIncreased',
          });
          approvalStatusPerCollection[collection._id].lastChange = new Date();
        }
      }
    }
    if (changes.length > 0) {
      setHasNewNotifications(true);
      registerNotificationChange({
        key: 'addMultiple',
        entries: changes,
        newDate: true,
      });
    }

    setApproval({
      latestState: approvalStatusPerCollection,
      latestCheck: new Date(),
    });
  };

  const getContributorFiles = async ({ libraryType, _id, communitySlug }) => {
    const response = await libraries[libraryType].getFiles(
      {
        page: 0,
        sort_by: 'createdAt',
        sort_direction: 'descending',
        limit: 50,
        filter: { collections: [_id], createdBy: User.djangoProfile.id },
      },
      communitySlug,
    );
    return response.documents;
  };
  const checkFileFeedback = async () => {
    if (!UserStatus.product.isBusinessAdmin) return;
    const feedbackFiles = {};
    await asyncPool(2, ['media', 'documents'], async (libraryType) => {
      feedbackFiles[libraryType] = await getFileFeedback(libraryType);
    });
    setFeedbackFiles(feedbackFiles);
  };

  const getFileFeedback = async (libraryType) => {
    const response = await libraries[libraryType].getFiles(
      {
        page: 0,
        sort_by: 'createdAt',
        sort_direction: 'descending',
        limit: 50,
        count: true,
        filter: {
          'file.feedback': {
            $exists: true,
            $not: {
              $size: 0,
            },
          },
          $or: [
            { 'file.feedback.viewedBy': { $size: 0 } },
            { 'file.feedback.viewedBy': { $exists: false } },
          ],
        },
      },
      User.spaces.selected.slug,
    );
    return response.documents;
  };

  return (
    <NotificationContext.Provider
      value={{
        hasNewNotifications:
          !!hasNewNotifications?.find?.((slug_) => slug_ === slug) &&
          hasNewNotifications,
        setHasNewNotifications,
        notifications,
        followCollection,
        unfollowCollection,
        hasApprovalFiles: approvalFiles.documents + approvalFiles.media,
        hasApprovalDocs: approvalFiles.documents,
        hasApprovalMedia: approvalFiles.media,
        checkApprovalFiles: checkApprovalFilesAdded,
        hasFeedbackFiles: feedbackFiles.documents + feedbackFiles.media,
        hasFeedbackDocs: feedbackFiles.documents,
        hasFeedbackMedia: feedbackFiles.media,
        checkFileFeedback,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
