import PixiIcon from '@pixi/elements/Icon';
import { format } from 'date-fns';

export default function PropertyValue({
  value,
  property,
}: {
  value: Pickit.PropertyValue;
  property: Pickit.CommunityPropertyInterface;
}) {
  const printValue =
    property.type === 'boolean' && typeof value?.boolean === 'boolean' ? (
      <PixiIcon
        size={23}
        name={value?.boolean ? 'toggle-large-on' : 'toggle-large-off'}
      />
    ) : property.type === 'number' ? (
      value.number
    ) : property.type === 'text' ? (
      <>{value?.text}</>
    ) : property.type === 'dropdown' &&
      !property.typeProps?.dropdown?.multiSelect ? (
      <>{value?.dropdown?.[0]}</>
    ) : property.type === 'dropdown' &&
      property.typeProps?.dropdown?.multiSelect ? (
      <>{value?.dropdown?.join(', ')}</>
    ) : value?.boolean ? (
      'true'
    ) : property.type === 'tags' ? (
      <>{value?.tags?.join(', ')}</>
    ) : property.type === 'date' && value?.date ? (
      <>{format(new Date(value?.date), 'yyyy-MM-dd')}</>
    ) : (
      <></>
    );

  return printValue;
}
