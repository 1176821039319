import React from 'react';
import { useRequest } from 'hooks';
import useUserStatus from 'hooks/useUserStatus';
import { UserContext } from 'contexts/User';
import { ManageContext } from 'contexts/Manage';

/**
 * Wrapper function for Manage UI. Will be extended later.
 */
export function ManageProvider(props) {
  const User = React.useContext(UserContext);
  const userStatus = useUserStatus();
  const [contributions, setContributions] = React.useState({});
  const [collections, setCollections] = React.useState([]);
  const [totalUsers, setTotalUsers] = React.useState(0);

  async function refreshTotalUsers() {
    if (
      User.request.proxy.communitySlug &&
      userStatus.product.isBusinessAdmin
    ) {
      const users = await User.request.proxy.getUsersV2({
        page_nr: 1,
        page_limit: 1,
      });
      setTotalUsers(users?.total_count);
    }
  }

  React.useEffect(() => {
    refreshTotalUsers();
  }, [User.request.proxy.communitySlug]);

  const getContribution = useRequest(async (hook) => {
    const slug = hook.requestProps;
    if (contributions[slug]) {
      return contributions[slug];
    }
    let contribution = await User.request.manage.getContribution(slug);

    /** 401 and 403 are sent as 200 OK so we have to manually rotateToken.
      - Tell me not, in mournful numbers,
        Life is but an empty dream!
        For the soul is dead that slumbers,
        And things are not what they seem.
  */
    if (
      typeof contribution === 'string' &&
      contribution.indexOf('No access to image') > -1
    ) {
      await User.rotateToken();
      contribution = await User.request.manage.getContribution(slug);
    }

    setContributions({
      ...contributions,
      [slug]: contribution,
    });
    return contribution;
  });

  const getCollection = useRequest(async (hook) => {
    const { slug } = hook.requestProps;
    const { userId } = hook.requestProps;
    const collection = await User.request.manage.getCollection(userId, slug, {
      page_limit: hook.requestProps.page_limit || 20,
    });
    return collection;
  });

  const getCollections = async function (cache) {
    if (cache && collections.length) {
      return collections;
    }
    let data = [];
    let res = await User.request.manage.getCollections(1);
    data = [...data, ...res.data];
    setCollections(data);
    while (res.data.length === res.per_page) {
      res = await User.request.manage.getCollections(parseInt(res.page) + 1);
      data = [...data, ...res.data];
      setCollections(data);
    }
    return data;
  };

  const setCollection = (key, update) => {
    if (collections[key]) {
      const updatedCollection = {
        ...collections[key],
        ...update,
      };
      collections.splice(key, 1, updatedCollection);
      setCollections([...collections]);
    }
  };

  const getMission = async (missionSlug) => {
    return User.request.manage.getMission(missionSlug);
  };

  const removeLicenseFile = async (contributionSlug, fileId, type) => {
    return User.request.license.removeLicenseFile(
      contributionSlug,
      fileId,
      type,
    );
  };

  const getImageRights = async (slugs) => {
    return User.request.license.getImageRights(slugs);
  };

  const addToCollection = async (userId, collectionSlug, contributionSlug) => {
    await User.request.manage.addToCollection(
      userId,
      collectionSlug,
      contributionSlug,
    );
    getCollections(false);
  };
  const removeFromCollection = async (
    userId,
    collectionSlug,
    contributionSlug,
  ) => {
    await User.request.manage.removeFromCollection(
      userId,
      collectionSlug,
      contributionSlug,
    );
    getCollections(false);
  };
  const buyContribution = async (contributionSlug) => {
    await User.request.manage.buyContribution(contributionSlug);
  };
  const updateContribution = async (contribution, data) => {
    User.request.manage.updateContribution(contribution.slug, data);
    return {
      ...contribution,
      ...data,
    };
  };

  const updateLicense = async (contributionSlug, data) => {
    await User.request.license.updateLicense(contributionSlug, data);
  };

  const replaceLicense = async (contributionSlug, data) => {
    await User.request.license.replaceLicense(contributionSlug, data);
  };

  const createLicense = async (contributionSlug, data) => {
    await User.request.license.createLicense(contributionSlug, data);
  };

  const getLicense = async (contributionSlug) => {
    return User.request.license.getLicense(contributionSlug);
  };

  const removeContributionPermanent = async (userId, contributionSlug) => {
    await User.request.manage.removeContributionPermanent(
      userId,
      contributionSlug,
    );
  };
  const getContributionCollections = useRequest(async (hook) => {
    const { userId, contributionSlug } = hook.requestProps;
    const res = await User.request.manage.contributionCollections(
      userId,
      contributionSlug,
    );
    return res.collections;
  });
  const updateCommunity = async (data) => {
    await User.request.manage.updateCommunity(User.spaces?.selected.id, data);
  };

  return (
    <ManageContext.Provider
      value={{
        getContribution,
        contributions,
        removeLicenseFile,
        getImageRights,
        updateLicense,
        createLicense,
        getLicense,
        getCollections,
        collections,
        replaceLicense,
        setCollection,
        addToCollection,
        removeFromCollection,
        getContributionCollections: getContributionCollections.request,
        updateContribution,
        getMission,
        buyContribution,
        getCollection: getCollection.request,
        removeContributionPermanent,
        updateCommunity,
        totalUsers,
        refreshTotalUsers,
      }}
    >
      {props.children}
    </ManageContext.Provider>
  );
}
