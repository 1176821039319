import { useUserContext } from 'hooks';
import { AssetGridContextInterface, ShortcutIds } from '../Helpers';
import { useAssetGridContext } from '../AssetGridContext';
import useUserStatus from 'hooks/useUserStatus';

export function useAssetGridShortcuts(): {
  data: AssetGridContextInterface['shortcuts'];
  getSpecificShortcut: (
    id: ShortcutIds,
  ) => AssetGridContextInterface['shortcuts'][0] | undefined;
} {
  const { setFilter } = useAssetGridContext();
  const User = useUserContext();
  const userStatus = useUserStatus();
  const data: AssetGridContextInterface['shortcuts'] = [
    ...(userStatus?.activeServices?.license_management
      ? ([
          {
            label: 'License',
            icon: 'file-certificate',
            id: 'license',
            data: [
              {
                id: 'license_expired',
                icon: 'file-circle-xmark',
                label: 'License expired',
                description: 'Show assets with expired license',
                filter: {
                  'license.expirationDate': [{ value: { $lte: new Date() } }],
                },
                revert: () => {
                  setFilter('license.expirationDate', null);
                },
              },
              {
                id: 'license_missing',
                icon: 'file-circle-xmark',
                label: 'License missing',
                description: 'Show assets with no license',
                filter: { 'license.license': [{ value: { $exists: false } }] },
                revert: () => {
                  setFilter('license.license', null);
                },
              },
            ],
          },
        ] as AssetGridContextInterface['shortcuts'])
      : []),
    {
      label: 'Feedback',
      icon: 'comment',
      id: 'feedback',
      data: [
        {
          id: 'feedback_needed',
          icon: 'comment-question',
          label: 'Review needed',
          description: 'Show assets with feedback that needs to be reviewed',
          filter: {
            'file.feedback': [
              {
                value: [{ $exists: true }, { $not: { $size: 0 } }],
                modifier: '$and',
              },
            ],
            'file.feedback.viewedBy': [
              { value: { $size: 0 } },
              { value: { $exists: false } },
            ],
          },
          revert: () => {
            setFilter('file.feedback', null);
            setFilter('file.feedback.viewedBy', null);
          },
        },
        {
          id: 'feedback_reviewed',
          icon: 'comment-question',
          label: 'Already reviewed',
          description: 'Show assets that has been reviewed',
          filter: {
            'file.feedback.viewedBy': [
              {
                value: [{ $exists: true }, { $not: { $size: 0 } }],
                modifier: '$and',
              },
            ],
          },
          revert: () => {
            setFilter('file.feedback.viewedBy', null);
          },
        },
      ],
    },
    {
      label: 'Approvals',
      icon: 'shield-check',
      id: 'approvals',
      data: [
        {
          id: 'approvals.approval_needed',
          icon: 'shield-exclamation',
          label: 'Approval needed',
          description: 'Show assets in need of approval',
          settings: {
            displayAs: 'table',
            groupBy: null,
          },
          filter: {
            'approval.status': [
              { value: 'approval-needed' },
              { value: 'none' },
            ],
          },
          revert: () => {
            setFilter('approval.status', null);
          },
        },
        {
          id: 'approvals.approved',
          icon: 'shield-check',
          label: 'Approved',
          settings: {
            displayAs: 'table',
            groupBy: null,
          },
          description: 'Show approved assets',
          filter: {
            'approval.status': [{ value: 'approved' }],
          },
          revert: () => {
            setFilter('approval.status', null);
          },
        },
        {
          id: 'approvals.rejected',
          icon: 'shield-xmark',
          label: 'Rejected',
          settings: {
            displayAs: 'table',
            groupBy: null,
          },
          description: 'Show rejected assets',
          filter: {
            'approval.status': [{ value: 'rejected' }],
          },
          revert: () => {
            setFilter('approval.status', null);
          },
        },
      ],
    },
    {
      label: 'User',
      icon: 'user',
      id: 'user',
      data: [
        {
          id: 'user_uploaded',
          icon: 'upload',
          label: 'Your uploaded assets',
          description: 'Show only assets that you have uploaded',
          settings: {
            displayAs: 'table',
            groupBy: null,
          },
          filter: {
            ['file.uploaded_by']: [{ value: User.data?.user?.userId }],
          },
          revert: () => {
            setFilter('file.uploaded_by', null);
          },
        },
      ],
    },
    {
      label: 'Trash',
      icon: 'trash-can',
      id: 'trash',
      hidden: true,
      data: [
        {
          id: 'trash.active',
          icon: 'trash-can',
          label: 'Trash can',
          description: 'Show assets in trash can',
          buttonLabel: 'Show deleted assets',
          settings: {
            displayAs: 'table',
            groupBy: null,
            sortBy: {
              field: 'trash.addedAt',
              direction: 'descending',
            },
            fields: [
              'file.previews',
              'file.name',
              'trash.addedAt',
              'trash.addedBy',
            ],
          },
          filter: {
            ['trash.isTrash']: [{ value: true }],
          },
          revert: () => {
            setFilter('trash.isTrash', null);
          },
        },
        {
          id: 'trash.user',
          icon: 'trash-can',
          label: 'Assets deleted by you',
          description: 'Show assets in trash can that you have deleted',
          buttonLabel: 'Show',
          // settings: {
          //   displayAs: 'table',
          //   groupBy: null,
          // },
          filter: {
            ['trash.removedById']: [{ value: User.data?.user?.userId }],
            ['trash.isTrash']: [{ value: true }],
          },
          revert: () => {
            setFilter('trash.isTrash', null);
            setFilter('trash.removedById', null);
          },
        },
      ],
    },
    {
      label: 'Artifical intelligence',
      icon: 'microchip-ai',
      id: 'ai',
      hidden: true,
      data: [
        {
          id: 'ai.image_similarity',
          icon: 'microchip-ai',
          label: 'Image similarity',
          description: 'Show assets that are similar to the selected asset',
          settings: {
            sortBy: {
              field: '_cosineSimilarty',
              direction: 'descending',
            },
            groupBy: null,
          },
          revert: () => {
            setFilter('$similarity', null);
          },
        },
      ],
    },
  ];
  return {
    data,
    getSpecificShortcut: (id: ShortcutIds) => {
      return data.find((s) => s.data.find((d) => d.id === id));
    },
  };
}
