import { useEffect, useReducer, useState } from 'react';
import Tags from 'elements_v2/Tags';
import { useUserContext } from 'hooks';
import { getStore } from '@pixi/store';

export default function SelectUsersDropdown(props) {
  const {
    value,
    communitySlug,
    includeAPIUsers,
    placeholder,
    onChange,
    style,
  } = props;
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const [users, setUsers] = useReducer((state, action) => [...action], []);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [selectedUsersData, setSelectedUsersData] = useReducer(
    (state, action) => [...action],
    []
  );
  const User = useUserContext();

  async function getSelectedUserData(value) {
    const cachedUsers = getStore('LIST_USERS').getByKeys(value);
    const searchValue = value?.filter(
      (v) => !getStore('LIST_USERS').getByKey(v) && !!v
    );

    if (!searchValue?.length) {
      setSelectedUsersData([...cachedUsers]);
      return cachedUsers;
    }
    const users = await User.request.proxy.getUsersV2({
      search: searchValue,
      include_api_users: 1,
    });
    getStore('LIST_USERS').addOrUpdate(users.data);
    setSelectedUsersData([...users?.data, ...cachedUsers]);
    return users;
  }

  async function getUsers(query, clearCache) {
    if (isLoading) {
      return false;
    }
    setIsLoading(true);
    if (clearCache) {
      setUsers([]);
    }
    try {
      const options = {
        search: query,
        page_nr: clearCache ? 1 : page + 1,
        page_limit: 10,
        sort_field: 'name',
        sort_order: 'asc',
        ...(includeAPIUsers
          ? {
              include_api_users: 1,
            }
          : {}),
      };
      if (props.adminsOnly) {
        options.role = 'admin';
      }
      const newUsers = await User.request.proxy.getUsersV2(
        options,
        communitySlug
      );
      getStore('LIST_USERS').addOrUpdate(newUsers.data);
      setUsers(!clearCache ? [...users, ...newUsers.data] : newUsers.data);
      setIsEmpty(newUsers.data?.length === 0);
      setIsAtEnd(newUsers.data?.length < newUsers.page_limit);
      if (clearCache) {
        setPage(0);
      } else {
        setPage(page + 1);
      }
    } catch (e) {
      setIsEmpty(true);
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  function userToName(user) {
    if (!user?.id) {
      return user;
    }
    return user.first_name
      ? `${user.first_name} ${user.last_name}`
      : user.email.split('@')?.[0];
  }

  useEffect(() => {
    if (value) {
      const filteredUsers = value
        .map((userId) => users.find((usr) => usr.id === userId))
        .filter((user) => !!user?.id);
      const filtered = value.filter(
        (userId) =>
          !selectedUsersData.includes(userId) &&
          !filteredUsers.find((usr) => usr.id === userId)
      );
      setSelectedUsersData([...selectedUsersData, ...filteredUsers]);
      if (filtered.length) {
        getSelectedUserData(value);
      }
    }
  }, [value]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getUsers(query, true);
    }, 500);
    return () => {
      clearTimeout(searchTimeout);
    };
  }, [query]);
  const useUsers =
    users
      ?.filter((user) => !value?.includes(user.id))
      ?.map((user) => {
        return {
          label: userToName(user),
          value: user.id,
          text: (
            <>
              {userToName(user)} <span>{user.email}</span>
            </>
          ),
        };
      }) || [];

  return (
    <Tags
      onRemove={(tag) =>
        value.includes(tag)
          ? onChange(
              value.filter((t) => t !== tag),
              tag
            )
          : onChange([...value, tag], tag)
      }
      isDeletingDisabled={props.disabled}
      isAddingDisabled={props.disableAdding}
      inputType="text"
      value={value}
      placeholder={placeholder}
      tagLabel={(tag) =>
        userToName(selectedUsersData.find((user) => user.id === parseInt(tag)))
      }
      isDropdownOnly
      pixi
      style={style}
      onInputValue={(value) => {
        setQuery(value ?? '');
      }}
      dropdownProps={{
        disabled: props.disabled,
        useV2: true,
        triggerStyle: props.triggerStyle,
        onChange: (users) => {
          onChange(users);
        },
        isLoadingOptions: isLoading,
        onBottomScroll: () => {
          if (!isAtEnd) {
            getUsers(query, false);
          }
        },
        width: 400,
        value,
        zIndex: 9999999,
        multiple: true,
        keepOpen: true,
        options:
          isEmpty || useUsers.length === 0
            ? [
                {
                  label: 'No results',
                  value: 'N/A',
                  text: 'No users found',
                  disabled: true,
                },
              ]
            : useUsers,
      }}
    />
  );
}
