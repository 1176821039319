import {
  Box,
  Combobox,
  Input,
  InputBase,
  TextInput,
  useCombobox,
} from '@mantine/core';
import System from '@pixi/System';
import PixiDropdown, { PixiDropdownProps } from '@pixi/elements/Dropdown';
import PixiIcon from '@pixi/elements/Icon';
import { useBoards } from 'contexts/Providers/BoardsProvider';
import { useUserContext } from 'hooks';
import React, { ReactElement, useEffect, useState } from 'react';

export default function SelectBoard({
  target,
  onBoardClick,
  onSearch,
  value,
  onlyDropdown,
  asInput,
  ...rest
}: {
  target?: React.ReactElement;
  value?: string;
  onSearch?: (query: string) => void | Promise<void>;
  onlyDropdown?: boolean;
  onBoardClick: (folder: Pickit.BoardInterface) => void | Promise<void>;
  asInput?: boolean;
} & Partial<PixiDropdownProps>) {
  const Boards = useBoards();
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(!!onlyDropdown);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  useEffect(() => {
    if (isOpen) {
      Boards.api.getBoards();
    }
  }, [isOpen]);

  const boards = Boards.data.filter((b: Pickit.BoardInterface) => {
    return !query ? true : b.name.toLowerCase().includes(query.toLowerCase());
  }) as Pickit.BoardInterface[];

  const items = (
    <>
      <PixiDropdown.Label>Add to folder</PixiDropdown.Label>
      <Box p="xs">
        <TextInput
          placeholder="Search boards"
          value={query}
          onChange={(event) => {
            setQuery(event.currentTarget.value);
          }}
        />
      </Box>
      {boards.map((f) => (
        <PixiDropdown.Item
          icon="layer-group"
          key={f._id}
          onClick={async () => {
            await onBoardClick(f);
          }}
          disabled={value?.includes(f._id)}
          color={value?.includes(f._id) ? 'gray' : undefined}
          rightSection={
            value?.includes(f._id) ? <PixiIcon name="check" /> : undefined
          }
        >
          {f.name}
        </PixiDropdown.Item>
      ))}
    </>
  );
  if (onlyDropdown) {
    return items;
  }
  if (asInput) {
    return (
      <Combobox
        store={combobox}
        onOptionSubmit={(val) => {
          onBoardClick(
            boards.find((b) => b._id === val) as Pickit.BoardInterface,
          );
          combobox.closeDropdown();
        }}
        withinPortal={false}
      >
        <Combobox.Target>
          <InputBase
            label="Select board"
            component="button"
            type="button"
            pointer
            rightSection={<Combobox.Chevron />}
            rightSectionPointerEvents="none"
            onClick={() => combobox.toggleDropdown()}
          >
            {Boards?.data?.find((b: Pickit.BoardInterface) => b._id === value)
              ?.name || <Input.Placeholder>Select board</Input.Placeholder>}
          </InputBase>
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options>
            {boards.map((b) => (
              <Combobox.Option value={b?._id} key={b._id}>
                {b.name}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    );
  }
  return (
    <PixiDropdown
      width={300}
      height={350}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      isFrozen={!!query}
      target={target as ReactElement}
      {...rest}
    >
      {items}
    </PixiDropdown>
  );
}
