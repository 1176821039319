import {
  Badge,
  Box,
  Checkbox,
  Group,
  Radio,
  Stack,
  Table,
  ThemeIcon,
  Tooltip,
} from '@mantine/core';
import PixiIcon from '@pixi/elements/Icon';
import PixiText from '@pixi/elements/Text';
import { getValueByPath } from '@pixi/helpers/utils';
import { getDefaultAssetToolbar } from '@pixi/Vars';
import { DjangoIdToName } from 'components/DjangoIdToName';
import { formatDistanceToNow } from 'date-fns';
import { useUserContext } from 'hooks';
import useStorageUrl from 'hooks/files/useStorageUrl';
import useContextFromFile from 'hooks/useContextFromFile';
import useUserStatus from 'hooks/useUserStatus';
import { MouseEvent, ReactNode } from 'react';
import { bytesToSize, truncate } from 'utils';
import { AssetIcon } from '../../AssetThumbnail';
import PixiTooltip from '@pixi/elements/Tooltip';
import PixiButton from '@pixi/elements/Button';
import { useAssetGridContext } from '../AssetGridContext';
import PixiDropdown from '@pixi/elements/Dropdown';
import { AssetDropdownRender } from '@pixi/components/AssetActions';
import { useAssetActions } from '@pixi/components/AssetActions/useAssetActions';
import { getMultiStore, useMultiDataStoreCallback } from '@pixi/store';
import { TableFields } from '../Helpers';

export default function AssetTableRow({
  file,
  fields,
  isHighlight,
  fileToolbarHook,
}: {
  file: Pickit.FileInterface;
  fields?: string[];
  isHighlight?: boolean;
  onClick?: (event: MouseEvent<HTMLTableRowElement>) => void;
  fileToolbarHook: ReturnType<typeof useAssetActions>;
}) {
  const AssetGrid = useAssetGridContext();
  const userStatus: any = useUserStatus();
  const { generateUrl } = useStorageUrl();
  const User = useUserContext();
  const context = useContextFromFile(file);
  const isSelected = useMultiDataStoreCallback(
    'FILES_SELECTED',
    AssetGrid.id || 'general',
    (data) => {
      return !!data.getByRow(file);
    },
    [AssetGrid.id, file._id],
  );
  const userCanEditFile =
    userStatus?.product?.isBusinessAdmin ||
    file?.file?.uploaded_by === User.djangoProfile?.id;
  return (
    <>
      {!AssetGrid.disable?.includes('selection') && (
        <Table.Td w={32} key="selection" id="selection" pl="xs">
          <Checkbox
            checked={isSelected}
            key={isSelected?.toString()} // Needed to re-render checkbox, something is broken at @mantine
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              if (!AssetGrid.multiSelect) {
                getMultiStore('FILES_SELECTED', AssetGrid.id).replace(
                  isHighlight ? [] : [{ _id: file._id }],
                );
                return;
              }
              getMultiStore('FILES_SELECTED', AssetGrid.id)[
                isSelected ? 'remove' : 'addOrUpdate'
              ]({
                _id: file._id,
              });
            }}
          />
        </Table.Td>
      )}
      {fields?.map((field) => {
        if (
          field === 'latestUserTransferDate' ||
          field === 'latestUserTransferTitle'
        ) {
          const transfersByUser = file?.transfers?.filter(
            (t) => t.createdBy === User.djangoProfile.id,
          );
          if (!transfersByUser?.length) {
            return <Table.Td key={field} id={field} />;
          }
          const latestTransfer = transfersByUser?.sort((a, b) => {
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
          });
          return (
            <Table.Td key={field} id={field}>
              <Group align="center" gap="xs">
                {field === 'latestUserTransferTitle' ? (
                  transfersByUser?.[0]?.title || (
                    <PixiText fs="italic" size="sm" c="dimmed">
                      {file?.name}
                    </PixiText>
                  )
                ) : (
                  <>
                    {formatDistanceToNow(
                      new Date(latestTransfer?.[0]?.createdAt),
                    )}{' '}
                    ago
                  </>
                )}
              </Group>
            </Table.Td>
          );
        }
        if (field === 'file.previews') {
          return (
            <Table.Td width={74} key={field} id={field}>
              <Stack h="100%" align="center" p="5">
                <AssetIcon
                  file={file}
                  size={64}
                  variant="transparent"
                  style={{ overflow: 'hidden' }}
                  previewSize="small"
                  imageProps={{ fit: 'cover', h: '100%' }}
                />
              </Stack>
            </Table.Td>
          );
        }
        if (field === 'name') {
          return (
            <Table.Td w={400} key={field} id={field}>
              <Group w="100%" align="center" gap="xs" wrap="nowrap">
                {file?.file?.external_file && (
                  <Box pos="relative" style={{ zIndex: 10 }}>
                    <PixiTooltip label="External file">
                      <PixiIcon name="link-horizontal" mr="5" />
                    </PixiTooltip>
                  </Box>
                )}
                <PixiText
                  w="100%"
                  size="sm"
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {truncate(file?.name, 30, '...')}
                </PixiText>
              </Group>
            </Table.Td>
          );
        }
        if (field === 'file.ext') {
          return (
            <Table.Td key={field} id={field}>
              <PixiText>
                <Badge variant="outline" color="dark">
                  {file?.file?.ext}
                </Badge>
              </PixiText>
            </Table.Td>
          );
        }
        if (field === 'license.license') {
          return (
            <Table.Td key={field} id={field}>
              {file.license?.license ? (
                <b>{file.license?.license}</b>
              ) : (
                <p>License missing</p>
              )}
            </Table.Td>
          );
        }
        if (field === 'feedback.createdBy') {
          return (
            <Table.Td key={field} id={field}>
              <DjangoIdToName value={file.file?.feedback?.[0]?.createdBy} />
            </Table.Td>
          );
        }
        if (field === 'feedback.message') {
          return (
            <Table.Td key={field} id={field}>
              {truncate(file.file?.feedback?.[0]?.message, 125, '...', true)}
            </Table.Td>
          );
        }
        if (field === 'trash.addedBy') {
          return (
            <Table.Td key={field} id={field}>
              <DjangoIdToName value={file.trash?.addedBy} />
            </Table.Td>
          );
        }
        if (field === 'trash.addedAt' && file.trash?.addedAt) {
          return (
            <Table.Td key={field} id={field}>
              {formatDistanceToNow(new Date(file.trash?.addedAt))} ago
            </Table.Td>
          );
        }
        if (field === 'approval.status') {
          return (
            <Table.Td w={150} key={field} id={field}>
              <PixiText>
                {file?.approval?.status === 'none' ? (
                  'Pending'
                ) : file?.approval?.status === 'rejected' ? (
                  'Rejected'
                ) : file?.approval?.status === 'approved' ? (
                  'Approved'
                ) : (
                  <></>
                )}
              </PixiText>
            </Table.Td>
          );
        }
        if (field === 'approval.message') {
          return (
            <Table.Td key={field} id={field}>
              {!userStatus.product.isBusinessAdmin &&
              file.file?.uploaded_by !== User?.djangoProfile.id ? (
                ''
              ) : file?.approval?.message ? (
                <p>{file?.approval?.message}</p>
              ) : (
                <p style={{ opacity: 0.6, fontStyle: 'italic' }}>No Message</p>
              )}
            </Table.Td>
          );
        }
        if (field === 'license.releases') {
          return (
            <Table.Td key={field} id={field}>
              {file.license?.propertyReleases?.length ||
              file.license?.modelReleases?.length ? (
                <div>
                  {file.license?.modelReleases?.map((release, index) => {
                    if (!release) {
                      return <></>;
                    }
                    // Could not make this work inline in the jsx...
                    const suffix =
                      file.license?.modelReleases?.length &&
                      file.license?.modelReleases?.length > 1
                        ? `${index + 1}`
                        : '';
                    return (
                      <>
                        <a
                          className="Files___Files__releases--release"
                          rel="noopener noreferrer"
                          href={generateUrl(release.url)}
                          target="_BLANK"
                          download
                          title={release.metadata?.name}
                        >
                          Model release {suffix}
                        </a>
                        <br />
                      </>
                    );
                  })}
                  {file.license?.propertyReleases?.map((release, index) => {
                    // Could not make this work inline in the jsx...
                    const suffix =
                      file.license?.propertyReleases?.length &&
                      file.license?.propertyReleases?.length > 1
                        ? `${index + 1}`
                        : '';
                    return (
                      <>
                        <a
                          className="Files___Files__releases--release"
                          rel="noopener noreferrer"
                          href={generateUrl(release.url)}
                          target="_BLANK"
                          download
                          title={release.metadata.name}
                        >
                          Property release {suffix}
                        </a>
                        <br />
                      </>
                    );
                  })}
                </div>
              ) : (
                'No releases'
              )}
            </Table.Td>
          );
        }
        if (field === 'collections') {
          return (
            <Table.Td key={field} id={field}>
              {!!file.collections?.length && (
                <PixiIcon name="rectangle-history" />
              )}
            </Table.Td>
          );
        }
        if (field === 'share_to') {
          return (
            <Table.Td key={field} id={field}>
              {file.share_to === 'not-set' && 'Inherit'}
              {file.share_to === 'all' && 'All users'}
              {file.share_to === 'specific' && 'Specific users'}
              {file.share_to === 'none' && 'Admins only'}
            </Table.Td>
          );
        }
        if (field === 'file.uploaded_by') {
          return (
            <Table.Td key={field} id={field} style={{ whiteSpace: 'nowrap' }}>
              {context.isContributorUser(file.file?.uploaded_by) && (
                <Tooltip label="Contributor">
                  <PixiIcon name="face-viewfinder" />
                </Tooltip>
              )}
              <DjangoIdToName value={file.file?.uploaded_by} />
            </Table.Td>
          );
        }
        if (field === 'file.uploaded_at') {
          return (
            <Table.Td key={field} id={field} style={{ whiteSpace: 'nowrap' }}>
              {formatDistanceToNow(
                new Date(file.file?.uploaded_at || file.createdAt),
              )}{' '}
              ago
            </Table.Td>
          );
        }
        if (field === 'file.contentLength') {
          return (
            <Table.Td key={field} id={field}>
              {bytesToSize(file.file?.contentLength)}
            </Table.Td>
          );
        }
        if (field === 'menu') {
          return (
            <Table.Td
              key={field}
              id={field}
              style={{ position: 'relative', zIndex: 15 }}
            >
              <PixiDropdown
                width={240}
                target={
                  <PixiButton variant="subtle" size="xs" px="xs" color="dark">
                    <PixiIcon name="ellipsis" />
                  </PixiButton>
                }
                zIndex={150}
                customRender={({ isOpen, setIsOpen }) => (
                  <AssetDropdownRender
                    isOpen={isOpen}
                    rootzIndex={150}
                    onClose={() => setIsOpen(false)}
                    file={file}
                    actions={fileToolbarHook?.specificActions(
                      getDefaultAssetToolbar(file),
                      false,
                    )}
                  />
                )}
              />
            </Table.Td>
          );
        }
        if (field === 'fileToolbar') {
          if (!userCanEditFile) {
            return <Table.Td key={field} id={field} />;
          }
          const deleteAction = fileToolbarHook?.getAction('delete');
          const editAction = fileToolbarHook?.getAction('advanced_settings');
          return (
            <Table.Td
              key={field}
              id={field}
              align="right"
              style={{ zIndex: 6 }}
              pos="relative"
              pr="xs"
            >
              <Group gap="5" justify="flex-end">
                <PixiButton
                  size="xs"
                  px="xs"
                  variant="light"
                  color="dark"
                  onClick={(event) => {
                    editAction?.onClick?.(event);
                  }}
                >
                  <PixiIcon name="pencil" />
                </PixiButton>
                {deleteAction &&
                  deleteAction?.customRender?.(
                    <PixiButton size="xs" px="xs" color="red" variant="light">
                      <PixiIcon name={deleteAction?.icon || 'trash-can'} />
                    </PixiButton>,
                    {
                      isOpen: false,
                      setIsFreezeDropdown: () => {},
                      onClose: () => {},
                    },
                  )}
              </Group>
            </Table.Td>
          );
        }
        const fallbackField = TableFields[field as keyof typeof TableFields];
        if (fallbackField?.label) {
          return (
            <Table.Td key={field} id={field}>
              {getValueByPath(file, fallbackField.path)?.toString()}
            </Table.Td>
          );
        }
        return <>MISSING_FIELD_{field}</>;
      })}
    </>
  );
}
