import { bytesToSize, randomString } from 'utils';
import useRouterQuery from 'hooks/useRouterQuery';
import useCloudStorage from 'hooks/useCloudStorage';
import asyncPool from 'utils/async-pool';
import { FileDrop } from 'react-file-drop';
import useUserStatus from 'hooks/useUserStatus';
import { useDocumentsContext } from 'hooks/useDocumentsContext';
import { useUserContext } from 'hooks/useUserContext';
import PixiButton from '@pixi/elements/Button';
import {
  Alert,
  Group,
  Progress,
  Stack,
  Switch,
  ThemeIcon,
  Title,
} from '@mantine/core';
import PixiDropdown from '@pixi/elements/Dropdown';
import useSimpleDataStore from '@pixi/hooks/useSimpleStore';
import Field from '@pixi/elements/Field';
import { useEffect, useReducer, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { getStore, useDataStore, useDataStoreCallback } from '@pixi/store';
import PixiIcon from '@pixi/elements/Icon';
import PixiTooltip from '@pixi/elements/Tooltip';
import { AssetGridUploadProps } from '../Helpers';
import { useAssetGridContext } from '../AssetGridContext';
import {
  BrandCategories,
  CategoryToAction,
  CategoryToIcon,
} from 'views/_Manage/BrandAssets/helpers/Categories';
import ColorForm from 'views/_Manage/BrandAssets/parts/forms/ColorForm';
import FontForm from 'views/_Manage/BrandAssets/parts/forms/FontForm';
import TextForm from 'views/_Manage/BrandAssets/parts/forms/TextForm';
import { openAssetPreview } from '@pixi/AppController';

interface UploadFile extends File {
  id?: string;
}

function AssetGridBrandAssetItems({
  metadata,
  onPopupForm,
}: {
  metadata?: Partial<Pickit.FileInterface>;
  onPopupForm: (form: string) => void;
}) {
  const AssetGrid = useAssetGridContext();
  const { context, libraries } = AssetGrid;
  const [addData, setAddData] = useState('');

  return (
    <>
      {BrandCategories.map((category) => (
        <PixiDropdown.Item
          key={category}
          onClick={() => {
            if (category === 'color') {
              onPopupForm('color');
            }
            if (category === 'text') {
              onPopupForm('text');
            }
            if (category === 'logotype') {
              context.triggerUpload(
                {
                  categories: ['logotype'],
                  libraries,
                  ...(metadata || {}),
                },
                addData,
              );
            }
            if (category === 'icon') {
              context.triggerUpload(
                {
                  categories: ['icon'],
                  libraries,
                  ...(metadata || {}),
                },
                addData,
              );
            }
            if (category === 'font') {
              onPopupForm('font');
            }
            if (category === 'image') {
              context.triggerUpload(
                {
                  categories: ['image'],
                  libraries,
                  ...(metadata || {}),
                },
                addData,
              );
            }
            if (category === 'package') {
              context.triggerUpload(
                {
                  categories: ['package'],
                  libraries,
                  ...(metadata || {}),
                },
                addData,
              );
            }
          }}
          leftSection={<PixiIcon name={CategoryToIcon(category) || '00'} />}
        >
          {CategoryToAction(category)}
        </PixiDropdown.Item>
      ))}
    </>
  );
}

export function AssetGridUpload() {
  const AssetGrid = useAssetGridContext();
  const { context, uploadProps: props } = AssetGrid;
  const User = useUserContext();
  const Documents = useDocumentsContext();
  const cloudStorage = useCloudStorage();
  const [isUseAI, setIsUseAI] = useState(
    cloudStorage.get('media.use_ai_on_upload') !== false,
  );
  const [activePopupForm, setActivePopupForm] = useState('');
  const upload = useRef<HTMLInputElement>(null);
  const uploadFolder = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    cloudStorage.set('media.use_ai_on_upload', isUseAI);
  }, [isUseAI]);

  const [files, setFiles] = useReducer(
    (
      state: File[],
      action: {
        type: string;
        data?: File[] | File;
        id?: string;
      },
    ) => {
      if (action.type === 'reset') {
        return [];
      }
      if (action.type === 'replace' && Array.isArray(action.data)) {
        return [...action.data];
      }
      if (Array.isArray(action?.data)) {
        return state;
      }
      if (!action.data?.name) {
        return state;
      }
      if (
        action.type === 'push' &&
        !Array.isArray(action.data) &&
        !state?.find((file) => file.name === (action.data as File)?.name)
      ) {
        return [...state, action.data];
      }
      if (action.type === 'clear') {
        const filtered = [
          ...state.filter((file) => file.name !== (action.data as File).name),
        ];
        return filtered;
      }
      if (action.type === 'remove') {
        return [
          ...state.filter((file) => file.name !== (action.data as File).name),
        ];
      }
      return state;
    },
    [],
  );

  useEffect(() => {
    if (upload?.current) {
      context.setTriggerUpload(() => (metadata: any) => {
        upload.current?.click();
      });
    }
  }, [upload?.current]);

  async function uploadFile(
    file: UploadFile,
    key: number,
  ): Promise<Pickit.FileInterface> {
    return new Promise((resolve, reject) => {
      const controller = new AbortController();
      const contextStorage = context;
      try {
        contextStorage.uploadFile(
          file,
          contextStorage.mainContainerId,
          {
            onProgress: (loaded: { loadedBytes: number }) => {
              getStore('FILES_UPLOADING').addOrUpdate({
                id: file.id as string,
                name: file.name,
                size: file.size,
                status: {
                  bytesUploaded: loaded.loadedBytes,
                },
              });
            },
            abort: controller,
            onFinish: (file: Pickit.FileInterface) => {
              resolve(file);
            },
            onError: () => {
              reject();
              setFiles({ type: 'remove', data: file });
            },
          },
          {
            use_ai: isUseAI,
            ignoreAlreadyUploaded: false,
            returnResults: true,
          },
          {
            ...(props.metadata ? { ...props.metadata } : {}),
            ...(AssetGrid.libraries
              ? {
                  libraries: Array.isArray(AssetGrid.libraries)
                    ? AssetGrid.libraries
                    : [AssetGrid.libraries],
                }
              : {}),
          },
        );
      } catch (e) {
        setFiles({ type: 'remove', id: file.id });
      }
    });
  }

  async function startUpload() {
    setIsUploading(true);
    let filesToUpload = [...files] as UploadFile[];

    const filesForProcessing = [];
    const filesProcessed = [];
    const contextStorage = context;
    /**
     * Upload the rest files
     */
    filesToUpload = contextStorage.filterFileType(
      filesToUpload,
      context.fileTypesAllowed,
      context.extensionsAllowed,
    );
    filesToUpload = await contextStorage.filterFilesAlreadyUploaded(
      filesToUpload,
      props?.metadata?.collections?.length &&
        !contextStorage.isContributorUser(
          User.djangoProfile.id,
          User.djangoProfile.email,
        )
        ? (file: Pickit.FileInterface) => {
            getStore('SYSTEM_ERRORS').addOrUpdate({
              id: file?._id,
              title: 'File added to collection',
              icon: 'file-exclamation',
              message:
                'The file you tried to upload already exists, that file has been added to this collection.',
              actions: [
                {
                  label: 'Show file',
                  id: 'preview_file',
                  variant: 'light',
                  leftSection: <PixiIcon name="eye" />,
                  onClick: () => {
                    openAssetPreview(file);
                  },
                },
              ],
              onClose: () => {
                getStore('SYSTEM_ERRORS').removeByKey(file?._id);
              },
            });
            contextStorage.savePartial({
              _id: file._id,
              $addToSet: {
                collections: props.metadata?.collections,
              },
            });
          }
        : props.onAlreadyExists,
    );

    setFiles({ type: 'replace', data: filesToUpload });
    if (!filesToUpload.length) {
      setIsUploading(false);
      setFiles({ type: 'reset' });
      // if (upload?.current?.value) {
      //   upload.current.value = null;
      // }
    } else {
      for (const _f of filesToUpload) {
        filesForProcessing.push(_f);
      }
    }
    filesToUpload = filesToUpload.map((file) => {
      file.id = randomString(10);
      return file;
    });
    const uploadedFiles: Pickit.FileInterface[] = [];
    getStore('FILES_UPLOADING').addOrUpdate(
      filesToUpload.map((file) => {
        return {
          id: file.id as string,
          size: file.size,
          name: file.name,
          status: {
            bytesUploaded: 0,
          },
        };
      }),
    );
    await asyncPool(5, filesToUpload, async (file: UploadFile) => {
      const uploadedFile = await uploadFile(file, filesToUpload.indexOf(file));
      uploadedFiles.push(uploadedFile);
    });
    await asyncPool(5, uploadedFiles, async (file: Pickit.FileInterface) => {
      filesProcessed.push(file);
      const uploadedFile = await contextStorage.getUploadedDocument(
        file,
        {
          use_ai: isUseAI,
          ignoreAlreadyUploaded: !contextStorage.isContributorUser(
            User.djangoProfile.id,
            User.djangoProfile.email,
          ),
          returnStore: true,
        },
        {
          ...(props.metadata ? { ...props.metadata } : {}),
          ...(AssetGrid.libraries
            ? {
                libraries: Array.isArray(AssetGrid.libraries)
                  ? AssetGrid.libraries
                  : [AssetGrid.libraries],
              }
            : {}),
        },
      );
      props?.onUpload?.(uploadedFile?.data);
    });
    setFiles({ type: 'reset' });
    getStore('FILES_UPLOADING').replace([]);
    return false;
  }

  useEffect(() => {
    if (!files.length) {
      setIsUploading(false);
    } else if (files?.length && !isUploading) {
      startUpload();
    }
  }, [files]);

  const dropdownItems = (
    <>
      <PixiDropdown.Label>From device</PixiDropdown.Label>
      <Group gap="0" wrap="nowrap">
        <PixiDropdown.Item
          onClick={() => {
            upload.current?.click();
          }}
          icon="file-arrow-up"
        >
          Upload files
        </PixiDropdown.Item>
      </Group>
      <PixiDropdown.Item
        onClick={() => {
          uploadFolder.current?.click();
        }}
        icon="folder-arrow-up"
      >
        Upload folder
      </PixiDropdown.Item>
      {props.withBrandAssets ? (
        <>
          <PixiDropdown.Divider />
          <PixiDropdown.Label>Brand assets</PixiDropdown.Label>
          <AssetGridBrandAssetItems
            metadata={props.metadata}
            onPopupForm={setActivePopupForm}
          />
        </>
      ) : null}
      <PixiDropdown.Divider />
      {context.type === 'media' &&
      !User.data.selectedCommunity.settings?.preferences?.disableAutoTag ? (
        props.isImage && (
          <PixiDropdown.Item
            icon={isUseAI ? 'square-check' : 'square'}
            iconProps={{
              variant: isUseAI ? 'filled' : 'regular',
            }}
            onClick={(event) => setIsUseAI(!isUseAI)}
          >
            Activate auto-tag for upload
          </PixiDropdown.Item>
        )
      ) : (
        <></>
      )}
    </>
  );

  return (
    <>
      {props.withBrandAssets && (
        <>
          <ColorForm
            context={context}
            libraries={['brandassets']}
            isOpen={activePopupForm === 'color'}
            toFolder={props.metadata?.folders || []}
            onClose={() => setActivePopupForm('')}
          />
          <FontForm
            context={context}
            libraries={['brandassets']}
            isOpen={activePopupForm === 'font'}
            toFolder={props.metadata?.folders || []}
            onClose={() => setActivePopupForm('')}
          />
          <TextForm
            context={context}
            libraries={['brandassets']}
            toFolder={props.metadata?.folders || []}
            isOpen={activePopupForm === 'text'}
            onClose={() => setActivePopupForm('')}
          />
        </>
      )}
      <input
        type="file"
        style={{ display: 'none' }}
        ref={uploadFolder}
        {...{ webkitdirectory: '', mozdirectory: '', directory: '' }}
        onChange={(event) => {
          const files = Array.from(event.target.files as FileList);
          if (!files?.length) {
            return;
          }
          if (files?.length > 200) {
            getStore('SYSTEM_ERRORS').addOrUpdate({
              id: 'max_files',
              title: 'Max file limit reached',
              icon: 'file-exclamation',
              message:
                'The amount of files you can upload at once are limited to 200. Please upload in sessions or contact us for a import.',
              onClose: () => {
                getStore('SYSTEM_ERRORS').removeByKey('max_files');
              },
            });
            return;
          }
          setFiles({ type: 'replace', data: files?.slice(0, 200) });
        }}
        multiple
      />
      <input
        type="file"
        style={{ display: 'none' }}
        ref={upload}
        onChange={(event) => {
          const files = Array.from(event.target.files as FileList);
          if (!files?.length) {
            return;
          }
          if (files?.length > 200) {
            getStore('SYSTEM_ERRORS').addOrUpdate({
              id: 'max_files',
              title: 'Max file limit reached',
              icon: 'file-exclamation',
              message:
                'The amount of files you can upload at once are limited to 200. Please upload in sessions or contact us for a import.',
              onClose: () => {
                getStore('SYSTEM_ERRORS').removeByKey('max_files');
              },
            });
            return;
          }
          setFiles({ type: 'replace', data: files?.slice(0, 200) });
        }}
        multiple
      />
      {createPortal(
        <FileDrop
          onDrop={(files) => {
            const items = Array.from(files as FileList);
            setFiles({ type: 'replace', data: items?.slice(0, 200) });
          }}
        >
          <></>
        </FileDrop>,
        document.body,
      )}
      <>
        <Group>
          <PixiDropdown
            width={360}
            closeOnItemClick
            target={
              <PixiButton
                size="wide-md"
                color="primary"
                variant="filled"
                loading={isUploading}
                disabled={isUploading}
                style={{
                  flexShrink: 1,
                  marginRight: 0,
                }}
                leftSection={
                  <PixiIcon name="circle-plus" size="lg" variant="filled" />
                }
              >
                {props.label || AssetGrid.context.uploadLabel}
              </PixiButton>
            }
          >
            {dropdownItems}
          </PixiDropdown>
        </Group>
      </>
    </>
  );
}
