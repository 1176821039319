import React, { cloneElement, lazy, useState } from 'react';

interface InterfacePixiFileIcon extends React.ComponentPropsWithoutRef<'div'> {
  /** File extension */
  name: string;
}

const IconFile = React.forwardRef<HTMLDivElement, InterfacePixiFileIcon>(
  ({ name, ...rest }) => {
    const [isError, setIsError] = useState(false);
    if (isError) {
      return (
        <img
          alt=""
          {...rest}
          src="https://cdn.pickit.com/images/file-icons/blank.svg"
        />
      );
    }
    return (
      <img
        alt=""
        {...rest}
        src={`https://cdn.pickit.com/images/file-icons/${name}.svg`}
        onError={() => setIsError(true)}
      />
    );
  }
);

export default IconFile;
