import { ToastService } from 'elements/Toast';
import './style.scss';
import { Button, Icon, Tooltip } from 'elements_v2';
import { useTextContext } from 'hooks';
import { inAdobeAddon } from 'utils/platform';

function CollectionShare(props) {
  const Text = useTextContext();
  const { url, text, type } = props;
  const copyUrl = () => {
    ToastService.createToast({
      message: 'URL Copied',
    });
    const el = document.createElement('textarea');
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };
  const openURLinAdobe = (url) => {
    window.parent.postMessage(
      {
        url: `${window.location.origin}/redirect.html?redirect=${url}`,
        type: 'url',
      },
      '*'
    );
  };

  return (
    <>
      <div className="CollectionShare__icons">
        <Tooltip
          message={Text.get('share/to_facebook')}
          tooltipStyle={{ zIndex: 999999 }}
        >
          <Button
            as="a"
            transparent
            href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
            target="_BLANK"
            rel="noreferrer noopener"
          >
            <Icon name="facebook" />
          </Button>
        </Tooltip>
        <Tooltip
          message={Text.get('share/to_linkedin')}
          tooltipStyle={{ zIndex: 999999 }}
        >
          <Button
            as="a"
            transparent
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`}
            target="_BLANK"
            rel="noreferrer noopener"
          >
            <Icon name="linkedin" />
          </Button>
        </Tooltip>
        <Tooltip
          message={Text.get('share/to_twitter')}
          tooltipStyle={{ zIndex: 999999 }}
        >
          <Button
            as="a"
            transparent
            href={`http://twitter.com/share?text=${text}&url=${url}`}
            target="_BLANK"
            rel="noreferrer noopener"
          >
            <Icon name="twitter" />
          </Button>
        </Tooltip>
        <Tooltip
          message={Text.get('share/as_link')}
          tooltipStyle={{ zIndex: 999999 }}
        >
          <Button
            as="a"
            transparent
            onClick={copyUrl}
            rel="noreferrer noopener"
          >
            <Icon name="link-45deg" />
          </Button>
        </Tooltip>

        <Tooltip
          message={Text.get('share/email_link')}
          tooltipStyle={{ zIndex: 999999 }}
        >
          {inAdobeAddon() ? (
            <Button
              transparent
              onClick={() =>
                openURLinAdobe(
                  `mailto:?subject=${Text.get('share/access').replace(
                    'XX',
                    text
                  )}&body=${
                    type === 'file'
                      ? Text.get('share/email_copy_file')
                      : Text.get('share/email_copy_collection')
                  }: \n\r${url}`
                )
              }
            >
              <Icon
                inline
                bubble
                button
                name="EnvelopeFill"
                style={{ margin: 0 }}
              />
            </Button>
          ) : (
            <Button
              as="a"
              transparent
              href={`mailto:?subject=${Text.get('share/access').replace(
                'XX',
                text
              )}&body=${
                type === 'file'
                  ? Text.get('share/email_copy_file')
                  : Text.get('share/email_copy_collection')
              }: \n\r${url}`}
            >
              <Icon
                inline
                bubble
                button
                name="EnvelopeFill"
                style={{ margin: 0 }}
              />
            </Button>
          )}
        </Tooltip>
      </div>
    </>
  );
}

export default CollectionShare;
