import {
  MenuProps,
  Menu,
  MenuDropdownProps,
  Group,
  Text,
  MenuItemProps,
  ThemeIcon,
  FocusTrap,
  Box,
  PolymorphicFactory,
  polymorphicFactory,
  CompoundStylesApiProps,
  BoxProps,
  useProps,
  MantineColor,
  Loader,
  ScrollArea,
  ScrollAreaAutosize,
  GroupProps,
} from '@mantine/core';
import { PolymorphicComponentProps } from '@mantine/core/lib/core/factory/create-polymorphic-component';
import React, {
  cloneElement,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  isValidElement,
  MouseEvent,
  ReactElement,
  RefAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useClickOutside } from '@pixi/hooks/useClickOutside';
import PixiIcon, { PixiIconName, PixiIconProps } from '../Icon';
import {
  BottomCenterTransition,
  BottomRightTransition,
  TopCenterTransition,
  TopLeftTransition,
  TopRightTransition,
} from '@pixi/Theme';
import { useFocusTrap, useMergedRef } from '@mantine/hooks';
import {
  MenuItemFactory,
  MenuItemStylesNames,
} from '@mantine/core/lib/components/Menu/MenuItem/MenuItem';
import { isPromise } from '@pixi/helpers/utils';
import PixiTooltip from '../Tooltip';
import {
  PixiDropdownContext,
  PixiDropdownRenderProps,
  useDropdownContext,
} from './useDropdownContext';
import { rest } from 'lodash';
import { PixiTextProps } from '../Text';
import { useConfigStoreValue } from '@pixi/store';

export type PixiDropdownProps<C = 'div'> = Omit<
  PolymorphicComponentProps<
    C,
    Omit<MenuProps, 'children' | 'onClose' | 'trigger'>
  >,
  'children'
> & {
  trigger?: 'focus' | 'hover' | 'click';
  height?: number | string;
  forceOpen?: boolean;
  isFrozen?: boolean;
  closeOnClassNameClick?: string;
  classNameDropdown?: string;
  children?:
    | React.ReactNode
    | ((prefs: PixiDropdownRenderProps) => React.ReactNode);
  customRender?: (prefs: {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    setIsFreezeOpen: (isFreezeOpen: boolean) => void;
    isFreezeOpen: boolean;
  }) => React.ReactNode;
  onClose?: (
    reason:
      | 'CLICK_OUTSIDE'
      | 'TARGET_TOGGLE'
      | 'HOVER_OUTSIDE'
      | 'CHILDREN_CALLBACK',
  ) => void;
  alwaysCloseOnClickOutside?: boolean;
  target:
    | React.ReactElement
    | ((prefs: {
        isOpen: boolean;
        setIsOpen: (isOpen: boolean) => void;
        setIsFreezeOpen: (isFreezeOpen: boolean) => void;
        isFreezeOpen: boolean;
      }) => React.ReactElement);
  boxProps?: MenuDropdownProps;
  ref?: ForwardedRef<HTMLDivElement>;
};

const _PixiDropdown = (
  props: PixiDropdownProps,
  _ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    width,
    children,
    target,
    forceOpen,
    boxProps,
    height,
    customRender,
    isFrozen,
    ref: triggerRef,
    alwaysCloseOnClickOutside,
    closeOnClassNameClick,
    classNameDropdown,
    trigger,
    ...rest
  } = props;
  const viewport = useConfigStoreValue('APP_VIEW', 'viewport');
  const [isForceOpen, setIsForceOpen] = useState(forceOpen);
  const [isOpen, setIsOpen] = useState(rest.opened || false);
  const focusTrapRef = useFocusTrap(isOpen && rest.trapFocus);
  const [isFreezeOpen, setIsFreezeOpen] = useState(isFrozen || false);
  const menuRef = useRef<HTMLDivElement>(null);
  const viewportRef = useRef<HTMLDivElement>(null);

  const mergedTargetRefs = useMergedRef(triggerRef, menuRef, _ref);

  useEffect(() => {
    setIsFreezeOpen(isFrozen || false);
  }, [isFrozen]);
  useEffect(() => {
    if (forceOpen && !isOpen) {
      setIsOpen(true);
    }
    setIsForceOpen(forceOpen || false);
  }, [forceOpen]);

  useEffect(() => {
    setIsOpen(rest?.opened || false);
  }, [rest?.opened]);

  const ref = useClickOutside((_event, node) => {
    if (node === menuRef.current || menuRef.current?.contains(node)) return;
    if (node) {
      if (closeOnClassNameClick) {
        if (node.closest(`.${closeOnClassNameClick}`)) {
          setIsOpen(false);
          rest?.onClose?.('CLICK_OUTSIDE');
          return;
        }
      }
      const isDropdownOrChild = node.closest(
        '.pixi-Menu-dropdown, .pixi-Popover-dropdown',
      );
      if (!isDropdownOrChild || alwaysCloseOnClickOutside) {
        setIsOpen(false);
        rest?.onClose?.('CLICK_OUTSIDE');
      }
    } else {
      setIsOpen(false);
      rest?.onClose?.('CLICK_OUTSIDE');
    }
  });

  const childrenRender =
    typeof children === 'function'
      ? children({
          isOpen,
          setIsOpen: (isOpen) => {
            setIsOpen(isOpen);
            if (!isOpen) {
              rest?.onClose?.('CHILDREN_CALLBACK');
            }
          },
          setIsFreezeOpen,
          isFreezeOpen,
          viewportRef,
        })
      : children;
  const customRenderRendered = customRender?.({
    isOpen,
    setIsOpen: (isOpen) => {
      setIsOpen(isOpen);
      if (!isOpen) {
        rest?.onClose?.('CHILDREN_CALLBACK');
      }
    },
    setIsFreezeOpen,
    isFreezeOpen,
  });

  const content = (
    <>
      {rest.trapFocus ? (
        <Box w="100%" h="100%" ref={focusTrapRef}>
          {customRenderRendered}
          {childrenRender}
        </Box>
      ) : (
        <PixiDropdownContext.Provider
          value={{
            isOpen,
            setIsOpen,
            setIsFreezeOpen,
            isFreezeOpen,
            viewportRef,
            closeOnItemClick: rest.closeOnItemClick,
          }}
        >
          {customRenderRendered}
          {childrenRender}
        </PixiDropdownContext.Provider>
      )}
    </>
  );

  return (
    <Menu
      {...rest}
      width={width}
      transitionProps={{
        transition:
          rest?.position === 'top-end'
            ? BottomRightTransition
            : rest?.position === 'bottom-end'
              ? TopRightTransition
              : rest?.position === 'bottom'
                ? TopCenterTransition
                : rest?.position === 'left-start'
                  ? TopRightTransition
                  : TopLeftTransition,
      }}
      closeOnClickOutside={false}
      opened={isForceOpen || isFreezeOpen || isOpen}
      onOpen={() => {
        rest?.onOpen?.();
        setIsOpen(true);
      }}
      middlewares={{
        shift: {
          crossAxis: !!(
            viewport === 'xxs' ||
            viewport === 'xs' ||
            viewport === 'sm' ||
            viewport === 'md'
          ),
        },
      }}
      zIndex={rest.zIndex || 25}
      trigger={trigger === 'hover' ? 'hover' : undefined}
      onClose={() => {
        if (trigger === 'hover') {
          setIsOpen(false);
          rest?.onClose?.('HOVER_OUTSIDE');
        }
        // setIsOpen(false);
      }}
      trapFocus={rest.trapFocus || false}
    >
      <Menu.Target ref={mergedTargetRefs}>
        {typeof target === 'function'
          ? target({
              isOpen,
              setIsOpen: (isOpen) => {
                setIsOpen(isOpen);
                if (!isOpen) {
                  rest?.onClose?.('CHILDREN_CALLBACK');
                }
              },
              isFreezeOpen,
              setIsFreezeOpen,
            })
          : cloneElement(isValidElement(target) ? target : <></>, {
              onClick: (event: MouseEvent) => {
                if (!trigger) {
                  event.preventDefault();
                  event.stopPropagation();
                  if (!isOpen) {
                    rest?.onOpen?.();
                  } else {
                    rest?.onClose?.('TARGET_TOGGLE');
                  }
                  setIsOpen(!isOpen);
                }
              },
              onFocus: (event: KeyboardEvent) => {
                if (trigger === 'focus') {
                  setIsOpen(true);
                  rest?.onOpen?.();
                  event.preventDefault();
                  event.stopPropagation();
                }
              },
            })}
      </Menu.Target>

      <Menu.Dropdown
        ref={ref}
        w={width || 250}
        {...(boxProps || {})}
        style={{ ...(boxProps?.style || {}) }}
        onClick={() => {
          if (rest.closeOnItemClick) {
            setIsOpen(false);
          }
        }}
        className={classNameDropdown}
      >
        {!height ? (
          content
        ) : (
          <ScrollAreaAutosize
            w="100%"
            maw={width || undefined}
            h="auto"
            mah={height || undefined}
            scrollbars="y"
            ref={viewportRef}
            pos="relative"
          >
            {content}
          </ScrollAreaAutosize>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

export interface DropdownItemProps
  extends BoxProps,
    CompoundStylesApiProps<MenuItemFactory> {
  /** Item label */
  children?: React.ReactNode;

  /** Key of `theme.colors` or any valid CSS color */
  color?: MantineColor;

  /** Determines whether the menu should be closed when the item is clicked, overrides `closeOnItemClick` prop on the `Menu` component */
  closeMenuOnClick?: boolean;

  /** Section displayed on the left side of the label */
  leftSection?: React.ReactNode;

  /** Section displayed on the right side of the label */
  rightSection?: React.ReactNode;

  /** Disables item */
  disabled?: boolean;

  hovered?: boolean;

  icon?: PixiIconName;
  iconProps?: Partial<PixiIconProps>;
  withChevron?: boolean;

  dropDownOpen?: boolean;

  onMouseEnter?: (event: MouseEvent) => void;
  onMouseLeave?: (event: MouseEvent) => void;

  onClick?: (event: MouseEvent) => unknown | Promise<unknown>;
}

export type DropdownItemFactory = PolymorphicFactory<{
  props: DropdownItemProps;
  defaultRef: HTMLButtonElement;
  defaultComponent: 'button';
  stylesNames: MenuItemStylesNames;
  vars: any;
  variant: any;
}>;

const Item = polymorphicFactory<DropdownItemFactory>((props, ref) => {
  const {
    children,
    hovered,
    icon,
    iconProps,
    withChevron,
    dropDownOpen,
    onClick,
    ...rest
  } = props;
  const Dropdown = useDropdownContext();
  const [isHover, setIsHover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const loadingRef = useRef(false);

  useEffect(() => {
    setIsLoading(loadingRef.current);
  }, [loadingRef.current]);

  const handleClick = async (event: MouseEvent<HTMLButtonElement>) => {
    if (onClick instanceof Function) {
      const onClickEvent = onClick(event);
      const isAsync = isPromise(onClickEvent);

      if (Dropdown.closeOnItemClick) {
        Dropdown.setIsOpen(false);
      }

      if (isAsync) {
        try {
          loadingRef.current = true;
          await onClickEvent;
        } catch (error) {
          // Handle error appropriately
        } finally {
          // Ensure loading state is reset
          loadingRef.current = false;
        }
      }
    }
  };

  // Cleanup effect to ensure loading state is not stuck on component unmount
  useEffect(() => {
    return () => {
      loadingRef.current = false;
    };
  }, []);

  return (
    <Menu.Item
      mih={42}
      {...rest}
      ref={ref}
      onMouseEnter={(event) => {
        setIsHover(true);
        rest?.onMouseEnter?.(event);
      }}
      onMouseLeave={(event) => {
        setIsHover(false);
        rest?.onMouseLeave?.(event);
      }}
      onClick={handleClick}
      fz="sm"
      styles={{
        ...props.styles,
        itemLabel: {
          ...props.styles?.itemLabel,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      }}
      mod={{ hovered: hovered || isHover }}
      leftSection={
        isLoading ? (
          <ThemeIcon
            size="md"
            variant="transparent"
            color={rest.color || 'dark'}
          >
            <Loader color={rest.color || 'dark'} size="xs" />
          </ThemeIcon>
        ) : rest.leftSection ? (
          rest.leftSection
        ) : (
          icon && (
            <ThemeIcon
              size="sm"
              variant="transparent"
              color={rest.color || 'dark'}
            >
              {typeof icon === 'string' ? (
                <PixiIcon name={icon} size="lg" {...iconProps} />
              ) : (
                icon
              )}
            </ThemeIcon>
          )
        )
      }
      rightSection={
        withChevron ? (
          <>
            {dropDownOpen ? (
              <PixiIcon name="chevron-down" size="xs" />
            ) : (
              <PixiIcon name="chevron-right" size="xs" />
            )}
          </>
        ) : (
          rest.rightSection
        )
      }
    >
      {children}
    </Menu.Item>
  );
});
const Label = function ({
  children,
  icon,
  info,
}: {
  children: React.ReactNode;
  icon?: PixiIconName;
  info?: React.ReactNode;
}) {
  return (
    <Group align="center" gap="5" py="5" px="sm">
      {icon && <PixiIcon name={icon} c="dimmed" size="xs" />}
      <Text size="xs" c="gray.5">
        {children}
      </Text>
    </Group>
  );
};

const Title = function ({
  children,
  icon,
  large,
  ...rest
}: {
  children: React.ReactNode;
  icon?: PixiIconName;
  large?: boolean;
} & Partial<GroupProps>) {
  return (
    <Group gap="xs" justify={large ? 'center' : 'flex-start'} {...rest}>
      {icon && (
        <PixiIcon
          name={icon}
          size="xl"
          variant="filled"
          c={!large ? 'dimmed' : undefined}
        />
      )}
      <Text
        size={large ? 'xl' : 'sm'}
        fw="500"
        c={!large ? 'dimmed' : undefined}
      >
        {children}
      </Text>
    </Group>
  );
};
const { Divider } = Menu;

interface PixiDropdownType
  extends ForwardRefExoticComponent<
    PixiDropdownProps & RefAttributes<HTMLDivElement>
  > {
  Item: typeof Item;
  Label: typeof Label;
  Divider: typeof Divider;
  Title: typeof Title;
}

const PixiDropdown = forwardRef(_PixiDropdown) as PixiDropdownType;
PixiDropdown.Item = Item;
PixiDropdown.Label = Label;
PixiDropdown.Divider = Divider;
PixiDropdown.Title = Title;
export default PixiDropdown;
