import Bugsnag from '@bugsnag/js';
import { isAfter } from 'date-fns';
import { diff } from 'deep-object-diff';
import { useDeepState, useDocumentsContext, useMediaContext } from 'hooks';
import useDispatch from 'hooks/useDispatch';
import useRouterQuery from 'hooks/useRouterQuery';
import { useUserContext } from 'hooks/useUserContext';
import useUserStatus from 'hooks/useUserStatus';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { getStore, useDataStoreCallback, useKeySelector } from '@pixi/store';
import { copyTextToClipboard, randomString, sleep, uniqueArray } from 'utils';
import boardModuleFields from 'utils/boardModuleFields';
import { isEmbed } from 'utils/platform';
import Schemas from 'views/_Manage/Boards/modules/';
import * as yup from 'yup';
import { AppContext } from 'contexts/App';
import { BoardsContext } from 'contexts/Boards';

export const Modules = {
  INTERFACES: {},
  INTERFACES_METADATA: {},
  SCHEMAS: {},
  MENU: {
    // brand: [
    //   {
    //     icon: 'Palette',
    //     name: 'Colors',
    //     modules: ['COLORS_WITH_TEXT', 'COLORS_GRID', 'COLORS_WITH_IMAGE'],
    //   },
    //   {
    //     icon: 'Fonts',
    //     name: 'Typography',
    //     modules: [],
    //   },
    //   {
    //     icon: 'CheckCircle',
    //     name: 'Rules',
    //     modules: [],
    //   },
    //   {
    //     icon: 'CollectionFill',
    //     name: 'Guides',
    //     modules: ['BOARDS', 'BRAND_GUIDES'],
    //   },
    // ],
    standard: [
      {
        icon: 'FileTextFill',
        name: 'Text',
        modules: ['CONTENT_ONE', 'CONTENT_GRID', 'CONTENT_IFRAME'],
      },
      {
        icon: 'Image',
        name: 'Media',
        modules: [
          'CONTENT_MEDIA',
          'CONTENT_HEADER',
          // 'CONTENT_WITH_IMAGE',
          'CONTENT_COLUMNS_MEDIA',
          'CONTENT_GRID_MEDIA',
          // 'FILES_MEDIA',
          // 'IMAGE',
        ],
      },
      // {
      //   icon: 'Image',
      //   name: 'Image',
      //   modules: [
      //     'IMAGE',
      //     'HEADER_IMAGE_TITLE',
      //     'CONTENT_WITH_IMAGE',
      //     'FILES_MEDIA',
      //   ],
      // },
      {
        icon: 'FileEarmarkTextFill',
        name: 'Files',
        modules: [
          // 'CONTENT_WITH_FILE',
          'CONTENT_COLUMNS_FILES',
          'CONTENT_GRID_FILES',
        ],
      },
      {
        icon: 'CollectionFill',
        name: 'Collections',
        modules: [
          // 'CONTENT_WITH_COLLECTION',
          'CONTENT_COLUMNS_COLLECTIONS',
          'CONTENT_GRID_COLLECTIONS',
        ],
      },
      {
        icon: 'CollectionFill',
        name: 'Boards/guides',
        modules: [
          'BOARD_MENU',
          'ENTRYPOINT_MENU',
          // 'CONTENT_WITH_BOARD',
          'CONTENT_COLUMNS_BOARDS',
          'CONTENT_GRID_BOARDS',
        ],
      },
      // {
      //   icon: 'Film',
      //   name: 'Video',
      //   modules: ['CONTENT_WITH_VIDEO', 'VIDEO'],
      // },
      {
        icon: 'PaletteFill',
        name: 'Colors',
        modules: [
          // 'CONTENT_WITH_COLOR',
          'CONTENT_GRID_COLORS',
          'CONTENT_COLUMNS_COLORS',
        ],
      },
      {
        icon: 'DistributeVertical',
        name: 'Separator',
        modules: [
          'SEPARATOR',
          'SEPARATOR_TALL',
          'SEPARATOR_LINE',
          'SEPARATOR_ANGLED',
        ],
      },
    ],
  },
};

function parseSchema(Schema) {
  Modules.SCHEMAS[Schema.moduleId] = Schema;
  Modules.INTERFACES[Schema.moduleId] = Schema.moduleInterface;
  Modules.INTERFACES_METADATA[Schema.moduleId] = yup
    .object()
    .shape({
      ...Schema.moduleMetadataInterface,
    })
    .noUnknown(true);

  // Go through all menu modules and switch their key to the correct data from the module. string -> object
  Object.keys(Modules.MENU).forEach((menuId) => {
    Modules.MENU[menuId].forEach((group, groupIndex) => {
      const moduleIndex = group.modules.indexOf(Schema.menuContent.key);
      if (moduleIndex > -1) {
        Modules.MENU[menuId][groupIndex].modules[moduleIndex] = {
          ...Schema.menuContent,
          moduleId: Schema.moduleId,
        };
      }
    });
  });
}

Schemas.forEach((Schema) => {
  if (Array.isArray(Schema)) {
    Schema.forEach(parseSchema);
  } else {
    parseSchema(Schema);
  }
});

// DUMP SCHEMA TO BACKEND
// console.log(
//   Schemas.reduce((obj, schema) => {
//     return {
//       ...obj,
//       [schema.moduleId]: schema.moduleInterface,
//     };
//   }, {})
// );
export const useBoard = (prefs = { menuType: 'standard' }) => {
  const {
    menuType,
    type: _type,
    openBoard,
    activeBoard,
    isEditing: _isEditing,
    data: _data,
  } = prefs;
  const [type, setType] = useState(_type || 'standard');
  const [data, setData] = useReducer((state, action) => {
    return { ...action };
  }, _data || {});
  const [theme, setTheme] = useState('');
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(_isEditing ?? false);
  const [isSorting, setIsSorting] = useState(false);
  const [socket, setSocket] = useState(null);
  const [selectedFiles, setSelectedFiles] = useReducer((state, action) => {
    if (action.remove) {
      return state.filter((f) => f._id !== action.remove);
    }
    if (action.add) {
      return [...state, action.add];
    }
    if (action.clear) {
      return [];
    }
  }, []);
  const [activeClients, setActiveClients] = useReducer(
    (state, action) => [...action],
    [],
  );

  const Boards = useBoards();
  const App = React.useContext(AppContext);
  const User = useUserContext();
  const isDraftModeEnabled =
    User.data?.selectedCommunity?.settings?.boards?.draftModeEnabled;
  const useDraftMode = Boards.useDraft || (isEditing && isDraftModeEnabled);
  const [forceDisableDraft, setForceDisableDraft] = useState(false);
  const Event = useDispatch();
  const location = useRouterQuery();
  const [state, setState] = useReducer((state, request) => {
    if (request === 'reset') {
      return {};
    }
    const parseAction = (action) => {
      if (!state[action.key]) {
        state[action.key] = [];
      }
      if (action.method === 'add' && !state[action.key].includes(action.id)) {
        state[action.key].push(action.id);
      }
      if (action.method === 'remove') {
        state[action.key].splice(state[action.key].indexOf(action.id), 1);
      }
      if (action.method === 'replace') {
        state[action.key] = [action.id];
      }
      if (action.method === 'clear') {
        state[action.key] = [];
      }
    };
    if (Array.isArray(request)) {
      request.map((action) => parseAction(action));
    } else {
      parseAction(request);
    }
    return {
      ...state,
    };
  }, {});

  useEffect(() => {
    if (prefs?.type) {
      setType(prefs?.type);
    }
  }, [prefs?.type]);

  useEffect(() => {
    if (
      data?._id &&
      location.params.section &&
      !state.highlight_module?.includes(location.params.section)
    ) {
      setState({
        key: 'highlight_module',
        id: location.params.section,
        method: 'replace',
      });
    }
  }, [location.params.section, data?._id]);

  useEffect(() => {
    if (App.isDarkMode && !isEditing && data?.preferences?.theme === 'auto') {
      setTheme('dark');
    }
    if (!App.isDarkMode && !isEditing && data?.preferences?.theme === 'auto') {
      setTheme('light');
    }
  }, [data?.preferences?.theme]);

  useEffect(() => {
    const newBoard = Boards.data.find((b) => b?._id === data?._id);
    if (newBoard && data && Object.keys(diff(newBoard, data))?.length) {
      setData({ ...newBoard });
    }
  }, [Boards.data]);

  useEffect(() => {
    if (data?.type) {
      setType(data.type);
    }
  }, [data]);

  useEffect(() => {
    if (data._id && isEditing && !socket) {
      connect();
    }
  }, [data]);

  function reset() {
    setTheme(false);
    setData({});
    // setIsEditing(false);
    setState('reset');
    setActiveClients([]);

    if (socket) {
      socket.socket.close();
      setSocket(null);
    }
  }

  async function connect() {
    const socket = await Boards.api.connect(data, {
      onClose: () => {
        setSocket(null);
      },
    });
    setSocket(socket);
  }

  async function save(newData, noPush, ignoreDraft) {
    const modulesWithoutManipulation = newData?.modules || data?.modules;
    const modules = newData?.modules || data?.modules;
    if (useDraftMode && !ignoreDraft) {
      delete newData.draft;
    }
    const changes =
      useDraftMode && !ignoreDraft
        ? {
            ...data,
            draft: {
              schema: {
                ...newData,
                modules,
              },
              createdBy: User.djangoProfile?.id,
              lastUpdated: new Date(),
            },
            modules,
            type,
          }
        : {
            ...newData,
            modules,
            type,
          };
    if (useDraftMode && !ignoreDraft && data?._id) {
      await prepareDraft(data?._id);
    }
    if (!noPush) {
      if (useDraftMode && newData?._id) {
        const savedBoard = await savePartial([
          {
            $set: {
              draft: {
                schema: {
                  ...newData,
                  modules,
                },
                createdBy: User.djangoProfile?.id,
                lastUpdated: new Date(),
              },
            },
          },
        ]);
        setData(savedBoard);
        return savedBoard;
      }
      const savedBoard = await Boards.api.saveBoard(changes);
      setData(savedBoard);
      return savedBoard;
    }
    setData({ ...changes, modules: modulesWithoutManipulation });
  }

  async function savePartial(updates, findQuery) {
    const savedBoard = await Boards.api.saveBoardPartial(
      data._id,
      updates,
      findQuery,
    );
    setData(savedBoard);
    return savedBoard;
  }

  async function addModule(module, pos) {
    const defaultSectionSettings =
      User.data?.selectedCommunity?.settings?.brand?.globalDefaults?.sections;
    const newBoardData = await prepareDraft(data._id);
    const modules = useDraftMode
      ? newBoardData.draft?.schema?.modules || newBoardData.modules || []
      : newBoardData.modules || [];
    const placeholder = {
      isLoadingPlaceholder: true,
    };
    modules.splice(pos ?? modules.length, 0, placeholder);
    const placeholderIndex = modules.findIndex((i) => i.isLoadingPlaceholder);
    const moduleSchema = Modules.SCHEMAS[module.type];
    const newModule = await Boards.api.modules.saveModule({
      boardId: newBoardData._id,
      type: module.type,
      data: moduleSchema.getModuleDefaultData
        ? moduleSchema.getModuleDefaultData()
        : {},
      metadata: {
        ...(defaultSectionSettings?.spaceBelow
          ? { spaceBelow: defaultSectionSettings?.spaceBelow }
          : {}),
        ...(defaultSectionSettings?.contentLayout
          ? {
              contentLayout: defaultSectionSettings?.contentLayout,
              contentSection: {
                contentLayout: defaultSectionSettings?.contentLayout,
              },
            }
          : {}),
      },
      ...(defaultSectionSettings?.access
        ? { access: { share_to: defaultSectionSettings?.access } }
        : {}),
    });
    modules.splice(placeholderIndex, 1, newModule);
    savePartial([
      {
        $push: {
          [useDraftMode ? 'draft.schema.modules' : 'modules']: {
            $each: [newModule._id],
            $position: pos,
          },
        },
        ...(useDraftMode ? { $set: { 'draft.lastUpdated': new Date() } } : {}),
      },
    ]);
    setState({
      key: 'highlight_module',
      id: newModule._id,
      method: 'replace',
    });
    return newModule;
  }

  const prepareDraft = async (boardId) => {
    return Boards.api.prepareBoardDraft(boardId || data?._id);
  };
  async function moveModule(id, newIndex) {
    const modules = useDraftMode
      ? data.draft?.schema?.modules || data.modules
      : data.modules;
    await prepareDraft();
    const module = modules.find((m) => m._id === id);
    const currentIndex = modules.findIndex((m) => m._id === id);
    modules.splice(currentIndex, 1);
    modules.splice(newIndex, 0, module);
    await savePartial([
      {
        $pull: {
          [useDraftMode ? 'draft.schema.modules' : 'modules']: id,
        },
        ...(useDraftMode ? { $set: { 'draft.lastUpdated': new Date() } } : {}),
      },
      {
        $push: {
          [useDraftMode ? 'draft.schema.modules' : 'modules']: {
            $each: [id],
            $position: newIndex,
          },
        },
      },
    ]);
  }
  async function removeModule(module) {
    await prepareDraft();
    if (!useDraftMode) {
      if (!useDraftMode) {
        Boards.api.modules.deleteModule(module._id);
      }
      getStore('BOARD_MODULES').remove(module);
    }
    const board = Boards.data.find((b) => b._id === module.boardId);
    const isDeleted = !board?.modules?.find((m) => m._id === module._id);
    if (isDeleted) {
      getStore('BOARD_MODULES').remove(module);
    }
    await savePartial([
      {
        $pull: {
          [useDraftMode ? 'draft.schema.modules' : 'modules']: module._id,
        },
        ...(useDraftMode ? { $set: { 'draft.lastUpdated': new Date() } } : {}),
      },
    ]);
  }

  async function getById(id, reset) {
    setIsFullyLoaded(false);
    setIsLoading(true);
    if (Boards.data.find((board) => board._id === id) && !reset) {
      setData(Boards.data.find((board) => board._id === id));
    } else {
      const data = await Boards.api.getBoard(id);
      setData(data);
    }
    setIsLoading(false);
    setIsFullyLoaded(true);
  }

  function hasMenuItem(moduleId) {
    return !!data.menu.find((i) => i.moduleId === moduleId);
  }

  async function removeMenuItem(item) {
    await prepareDraft();
    const updatedMenu = data.menu.filter(
      (i) => i.moduleId !== (item?.moduleId || item),
    );
    setData({
      ...data,
      menu: updatedMenu,
    });
    savePartial([
      {
        $pull: {
          [`${useDraftMode ? 'draft.schema.' : ''}menu`]: {
            moduleId: item?.moduleId || item,
          },
        },
      },
    ]);
    // save({
    //   _id: data._id,
    //   menu: updatedMenu,
    // });
  }

  async function saveMenuItem(item, skipIfExists) {
    await prepareDraft();
    const modules = useDraftMode
      ? data.draft?.schema?.modules || data.modules
      : data.modules;
    let menuToEdit = useDraftMode
      ? data.draft?.schema?.menu || data.menu
      : data.menu;
    const menuIndex = menuToEdit.findIndex((i) => i.moduleId === item.moduleId);
    if (menuIndex > -1) {
      menuToEdit = menuToEdit.map((i) =>
        i.moduleId === item.moduleId ? { ...i, ...item } : i,
      );
    } else {
      menuToEdit.push(item);
    }
    const updatedMenu = modules
      .map((module) => {
        return menuToEdit.find((item) => item.moduleId === module._id);
      })
      .filter((item) => !!item);
    // setData({
    //   ...data,
    //   menu: updatedMenu,
    // });
    if (menuIndex > -1) {
      savePartial(
        [
          {
            [`${useDraftMode ? 'draft.schema.' : ''}menu.$`]: item,
            ...(useDraftMode
              ? { $set: { 'draft.lastUpdated': new Date() } }
              : {}),
          },
        ],
        {
          [`${useDraftMode ? 'draft.schema.' : ''}menu.moduleId`]:
            item.moduleId,
        },
      );
    } else {
      await savePartial([
        {
          $push: {
            [`${useDraftMode ? 'draft.schema.menu' : 'menu'}`]: item,
          },
          ...(useDraftMode
            ? { $set: { 'draft.lastUpdated': new Date() } }
            : {}),
        },
      ]);
      setState({
        key: 'editing_menu',
        id: item.moduleId,
        method: 'add',
      });
    }
    // save({
    //   _id: data._id,
    //   menu: updatedMenu,
    // });
  }

  async function search(query) {
    const results = await User.request.files.boards.search(data._id, query);
    return results;
  }
  return {
    isFullyLoaded,
    setIsFullyLoaded,
    useDraftMode: forceDisableDraft ? false : useDraftMode,
    setForceDisableDraft,
    forceDisableDraft,
    data:
      useDraftMode &&
      !!data.draft?.schema &&
      Boards.boardHasDraft(data) &&
      data?.draft?.lastUpdated &&
      !forceDisableDraft
        ? {
            ...data,
            ...data.draft.schema,
            preferences:
              data.draft.schema?.preferences || data?.preferences || {},
            modules:
              data.draft.schema.modules?.filter((module) => !!module?.type) ||
              [],
          }
        : {
            ...(data || {}),
            modules: data.modules?.filter((module) => !!module?.type) || [],
            preferences: {
              ...(data.preferences || {}),
              fullscreen: data.preferences?.fullscreen,
            },
          },
    activeClients,
    theme: prefs.theme || isEditing ? 'light' : theme,
    type,
    openBoard,
    menuType,
    search,
    getById,
    setData,
    setState,
    state,
    setIsEditing,
    isLockedDraft:
      data?.draft?.createdBy &&
      data?.draft?.createdBy !== User.djangoProfile?.id,
    isEditing:
      data?.draftPublish?.isReady ||
      (data?.draft?.createdBy &&
        data?.draft?.createdBy !== User.djangoProfile?.id)
        ? false
        : isEditing,
    isLoading,
    isSorting,
    setIsSorting,
    save,
    savePartial,
    addModule,
    moveModule,
    removeModule,
    saveMenuItem,
    removeMenuItem,
    hasMenuItem,
    reset,
    connect,
    setSelectedFiles,
    selectedFiles,
    activeBoard: activeBoard || (() => false),
    Event,
    aggregateModules: Boards.aggregateModules,
  };
};

export const useBoards = () => useContext(BoardsContext);
export const useModule = (
  ModuleData,
  Board,
  _forceDisableDraft,
  _forceDisableEdit,
  _forceDraft,
) => {
  const User = useUserContext();
  const Boards = useBoards();
  const [forceDisableDraft, setForceDisableDraft] = useState(
    false,
    _forceDisableDraft,
  );
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setForceDisableDraft(_forceDisableDraft);
  }, [_forceDisableDraft]);
  const isDraftModeEnabled =
    User.data?.selectedCommunity?.settings?.boards?.draftModeEnabled &&
    !forceDisableDraft;
  const useDraftMode =
    Boards.useDraft ||
    (Board.useDraftMode && isDraftModeEnabled) ||
    (isDraftModeEnabled && _forceDraft);
  const ModuleSchema = Modules.SCHEMAS[ModuleData.type];
  const ModuleInterface = Modules.INTERFACES[ModuleData.type];
  const ModuleMetadataInterface = Modules.INTERFACES_METADATA[ModuleData.type];
  const [_isEditing, setIsEditing] = useState(Board.isEditing);
  const isEditing = _forceDisableEdit ? false : _isEditing;
  const [isEmpty, setIsEmpty] = useState(false);
  const [renderId, setRenderId] = useState(randomString(10));
  const [isDraft, setIsDraft] = useState(useDraftMode ?? false);
  const [isControlsDisabled, setIsControlsDisabled] = useState(false); // Hide placeholder, toolbar etc
  const module = useKeySelector('BOARD_MODULES', ModuleData?._id) || ModuleData;
  const moduleHasDraft = module?.draft?.schema && !!module?.draft?.lastUpdated;

  useEffect(() => {
    setIsEditing(Board.isEditing);
  }, [Board.isEditing]);

  async function saveData(data, existingModuleData) {
    setIsSaving(true);
    if (useDraftMode) {
      await prepareDraft();
      delete data.draft;
      await savePartial([
        {
          [useDraftMode ? 'draft.schema.data' : 'data']: {
            ...(existingModuleData || {}),
            ...(data?.data || {}),
          },
        },
      ]);
    } else {
      await savePartial([
        {
          data: data?.data,
          blueprint: ModuleInterface,
        },
      ]);
    }
    setIsSaving(false);
  }

  // TODO: Write function to verify key + data
  function validateData(key, value) {
    const dataType = ModuleInterface[key];
    const schema = boardModuleFields[dataType];
    schema.validateSync(value);
    return true;
  }

  // TODO: Write function to verify key + data
  function validateMetadata(data) {
    const schema = ModuleMetadataInterface;
    schema.validateSync(data);
    return true;
  }

  function cleanData(key, value) {
    validateData(key, value);
    return {
      [key]: value,
    };
  }

  function showPublished() {
    setIsEditing(false);
    setIsDraft(false);
  }

  function showDraft() {
    setIsEditing(false);
    setIsDraft(true);
  }

  function exitPreview() {
    setIsEditing(true);
    if (moduleHasDraft) {
      setIsDraft(true);
    }
  }

  function parseData(key, value, moduleData) {
    let rows = {};
    if (typeof key !== 'object') {
      rows = { [key]: value };
    } else {
      rows = key;
    }
    const newData = Object.keys(rows).reduce(
      (obj, key) => {
        const data = {
          key,
          value: rows[key],
        };
        // if (!data.value) {
        //   delete obj[data.key];
        //   return obj;
        // }
        return {
          ...obj,
          ...cleanData(data.key, data.value),
        };
      },
      {
        ...(moduleData || {}),
      },
    );
    return newData;
  }

  const data = forceDisableDraft
    ? module.data
    : isDraft && moduleHasDraft
      ? {
          ...module.data,
          ...module.draft.schema.data,
        }
      : module.data;

  const forceReRender = () => {
    getStore('BOARD_MODULES').addOrUpdate({
      ...module,
      renderId: randomString(10),
    });
  };

  async function prepareDraft() {
    const latestModule = getStore('BOARD_MODULES').getByKey(module._id);
    await Boards.api.prepareBoardDraft(module.boardId);
    if (useDraftMode && !latestModule.draft?.lastUpdated) {
      return savePartial([
        {
          $set: {
            draft: {
              schema: {
                ...(latestModule || {}),
              },
              lastUpdated: new Date(),
            },
          },
        },
      ]);
    }
    return data;
  }

  async function savePartial(updates, findQuery) {
    await Boards.api.saveBoardModulePartial(module._id, updates, findQuery);
  }

  async function savePartialWithDraft(updates, findQuery) {
    if (useDraftMode) {
      await prepareDraft();
    } else {
      await Boards.api.saveBoardModulePartial(module._id, updates, findQuery);
      return;
    }
    updates = updates.map((update) => {
      if (useDraftMode) {
        const keys = Object.keys(update);
        // loop the keys
        keys.forEach((key) => {
          update[`draft.schema.${key}`] = update[key];
          delete update[key];
        });
      }
      return update;
    });
    await Boards.api.saveBoardModulePartial(module._id, updates, findQuery);
  }

  async function saveMetadata(newMetadata) {
    if (useDraftMode) {
      await prepareDraft();
    }
    await savePartial([
      {
        $set: {
          [useDraftMode ? 'draft.schema.metadata' : 'metadata']: newMetadata,
        },
      },
    ]);
  }
  return {
    savePartial,
    saveMetadata,
    savePartialWithDraft,
    setData: (key, value) => {
      try {
        const newModule = {
          ...module,
          data: parseData(key, value, data),
        };
        getStore('BOARD_MODULES').addOrUpdate(newModule);
        saveData(newModule, data);
      } catch (e) {
        
        console.error(e);
      }
    },
    setInitData: (data) => {
      getStore('BOARD_MODULES').addOrUpdate(data);
    },
    module,
    getModule: () => {
      if (useDraftMode && moduleHasDraft && !forceDisableDraft) {
        return JSON.parse(
          JSON.stringify({
            ...module,
            ...(module.draft.schema || {}),
          }),
        );
      }
      return module;
    },
    schema: ModuleSchema,
    name: ModuleSchema?.menuContent?.name,
    exitPreview,
    renderId: module.renderId || module._id,
    data,
    forceReRender,
    metadata: forceDisableDraft
      ? module.metadata || {}
      : isDraft && moduleHasDraft
        ? module.draft.schema.metadata || {}
        : module.metadata || {},
    setTotalData: (input) => {
      try {
        const metadata = {
          ...(module.metadata || {}),
          ...input.metadata,
        };
        Object.keys(metadata).map((key) => {
          if (!metadata[key]) {
            delete metadata[key];
          }
        });
        validateMetadata(metadata);
        const newModule = {
          ...module,
          data: {
            ...(module.data || {}),
            ...parseData(input.data),
          },
          metadata,
        };
        getStore('BOARD_MODULES').addOrUpdate(newModule);
        saveData(newModule, data);
      } catch (e) {
        
        console.error(e);
      }
    },
    setMetadata: (newMetadata, skipSave) => {
      try {
        const metadata = {
          ...(module.metadata || {}),
          ...newMetadata,
        };
        validateMetadata(metadata);
        getStore('BOARD_MODULES').addOrUpdate(
          useDraftMode
            ? {
                ...module,
                draft: {
                  ...module.draft,
                  schema: {
                    ...module.draft.schema,
                    metadata,
                  },
                },
              }
            : {
                ...module,
                metadata,
              },
        );
        // if (!skipSave) {
        //   const newModule = {
        //     ...module,
        //     metadata,
        //   };
        //   getStore('BOARD_MODULES').addOrUpdate(newModule);
        //   saveData(newModule, data);
        // }
        return metadata;
      } catch (e) {}
    },
    setModuleData: (data) => {
      getStore('BOARD_MODULES').addOrUpdate(data);
    },
    preferences: ModuleSchema?.preferences || {},
    setIsEditing,
    setIsDraft,
    isEditing,
    moduleHasDraft,
    saveData,
    setIsEmpty,
    setIsControlsDisabled,
    isControlsDisabled,
    isSaving,
    showDraft,
    removeDraft: async () => {
      setIsSaving(true);
      const newData = await Boards.api.modules.saveModule({
        _id: module._id,
        draft: null,
        blueprint: ModuleInterface,
      });
      getStore('BOARD_MODULES').addOrUpdate({
        ...newData,
        renderId: randomString(10),
      });
      setIsSaving(false);
    },
    showPublished,
    publishDraft: async () => {
      const newModule = await Boards.publishModuleDraft(module);
      getStore('BOARD_MODULES').addOrUpdate({
        ...newModule,
        renderId: randomString(10),
      });
    },
    onFileShare: (file) => {
      let href = window.location.origin;
      if (isEmbed) {
        href = document.referrer;
      }
      copyTextToClipboard(
        `${href}#${Board.openBoard(
          Board.data._id,
          module?._id,
          Board.data?.type,
        )}${file?._id ? `&file=${file._id}` : ''}`,
      );
    },
    setForceDisableDraft,
    status: {
      isEmpty: ModuleSchema?.status?.isEmpty(data),
    },
  };
};

export function BoardsProvider(props) {
  const Media = useMediaContext();
  const Documents = useDocumentsContext();
  const User = useUserContext();
  const userStatus = useUserStatus();
  const Brandassets = useMediaContext();
  const [previewDraft, setPreviewDraft] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [useDraft, setUseDraft] = useState(false);
  const [globalSettings, setGlobalSettings] = useReducer(
    (state, action) => ({ ...action }),
    {},
  );
  const Libraries = {
    media: Media,
    documents: Documents,
    brandassets: Brandassets,
  };
  const draftIsEnabled =
    User.data?.selectedCommunity?.settings?.boards?.draftModeEnabled;
  const data =
    useDataStoreCallback('BOARDS', (data) => {
      return data;
    }) || [];
  const [isLocked, setIsLocked] = useState(false);

  function addBoardsToStore(_boards) {
    if (!_boards) {
      return;
    }
    const { boards, modules } = _boards.reduce(
      (data, board) => {
        if (board?.modules?.length) {
          board.modules = board.modules.filter((m) => !!m?._id);
        }
        if (board?.draft?.schema?.modules?.length) {
          board.draft.schema.modules = board.draft.schema.modules.filter(
            (m) => !!m?._id,
          );
        }
        data.boards.push(board);
        data.modules.push(
          ...[
            ...(board.modules || []),
            ...(board?.draft?.schema?.modules || []),
          ],
        );
        return data;
      },
      { boards: [], modules: [] },
    );
    getStore('BOARDS').addOrUpdate(boards);
    getStore('BOARD_MODULES').addOrUpdate(modules);
  }

  function addBoardToStore(board) {
    if (board?.modules?.length) {
      board.modules = board.modules.filter((m) => !!m?._id);
    }
    if (board?.draft?.schema?.modules?.length) {
      board.draft.schema.modules = board.draft.schema.modules.filter(
        (m) => !!m?._id,
      );
    }
    getStore('BOARDS').addOrUpdate(board);
    getStore('BOARD_MODULES').addOrUpdate([
      ...board.modules,
      ...(board?.draft?.schema?.modules || []),
    ]);
  }

  useEffect(() => {
    if (User.data?.selectedCommunity?.settings?.brand) {
      setGlobalSettings(User.data?.selectedCommunity?.settings?.brand);
    }
  }, [User.data?.selectedCommunity?.settings?.brand]);

  useEffect(() => {
    if (
      userStatus.product.isBusinessAdmin &&
      window.location.hash.includes('preview=1') &&
      User.data?.selectedCommunity?.settings?.boards?.draftModeEnabled
    ) {
      setPreviewDraft(true);
      setUseDraft(true);
    }
  }, [User, User.data?.selectedCommunity?.settings?.boards?.draftModeEnabled]);

  useEffect(() => {
    Object.keys(Libraries).map((key) => {
      Libraries[key].request.setDefaultHeader(
        'pickit-request-context-nested',
        data.map((board) => `board_${board._id}`).join(','),
      );
    });
  }, [data]);

  async function saveModule(newModule) {
    const module = await User.request.files.boards.saveModule(newModule);
    getStore('BOARD_MODULES').addOrUpdate(module);
    // setData(
    //   data.map((b) =>
    //     b._id === module.boardId
    //       ? {
    //           ...b,
    //           modules: [
    //             ...b.modules,
    //             ...(!b.modules.find((m) => m._id === module._id)
    //               ? [module]
    //               : []),
    //           ].map((m) => (m._id === module._id ? module : m)),
    //         }
    //       : b
    //   )
    // );
    parseModules([newModule], module.boardId);
    return module;
  }

  async function cloneBoard(boardId, newName) {
    const newBoard = await User.request.files.boards.cloneBoard(
      boardId,
      newName,
    );
    const boardIndex = data.findIndex((b) => b._id === boardId);
    data.splice(boardIndex + 1, 0, newBoard);
    addBoardToStore(data);
    return newBoard;
  }

  async function saveBoardPartial(boardId, updates, findQuery) {
    setIsSaving(true);
    const board = await User.request.files.boards.saveBoardPartial(
      boardId,
      updates,
      findQuery,
    );
    addBoardToStore(board);
    setIsSaving(false);
    return board;
  }
  async function saveBoardModulePartial(moduleId, updates, findQuery) {
    const module = await User.request.files.boards.saveBoardModulePartial(
      moduleId,
      updates,
      findQuery,
    );
    getStore('BOARD_MODULES').addOrUpdate(module);
    return module;
  }

  async function saveBoard(newBoard) {
    setIsSaving(true);
    if (newBoard?.modules?.[0]?._id) {
      newBoard.modules = newBoard.modules.map((m) => m._id);
    }
    const board = await User.request.files.boards.saveBoard(newBoard);
    addBoardToStore(board);
    setIsSaving(false);
    return board;
  }

  async function searchBoard(id, query, params) {
    const results = await User.request.files.boards.search(id, query, params);
    return results;
  }

  async function deleteModule(id) {
    getStore('BOARD_MODULES').remove({ _id: id });
    await User.request.files.boards.deleteModule(id);
    return true;
  }

  async function deleteBoard(id) {
    getStore('BOARDS').remove({ _id: id });
    User.request.files.boards.deleteBoard(id);
    getStore('BOARD_MODULES').remove(
      getStore('BOARD_MODULES').data.filter((m) => m.boardId === id),
    );
    return true;
  }

  function bindAssetStates(assets = {}, onlyBoards) {
    if (onlyBoards) {
      addBoardsToStore(assets.boards);
      return false;
    }
    const libraries = {
      media: {},
      documents: {},
      brandassets: {},
    };
    Object.keys(assets.files || {}).forEach((library) => {
      assets.files[library].forEach((file) => {
        if (!libraries[library]?.files) {
          libraries[library].files = [file];
        } else {
          libraries[library].files.push(file);
        }
      });
    });
    addBoardsToStore(assets.boards?.filter((b) => !!b?._id) || []);
    assets.collections?.forEach((collection) => {
      if (!libraries[collection.libraryType]?.collections) {
        libraries[collection.libraryType].collections = [collection];
      } else {
        libraries[collection.libraryType].collections.push(collection);
      }
    });
    Object.keys(Libraries).forEach((libraryKey) => {
      const context = Libraries[libraryKey];
      if (libraries[libraryKey].files?.length) {
        context.setData({
          store: `files`,
          type: 'merge',
          key: '_id',
          data: libraries[libraryKey].files,
        });
      }
      if (libraries[libraryKey].collections?.length) {
        context.setData({
          store: `collections`,
          type: 'merge',
          key: '_id',
          data: libraries[libraryKey].collections,
        });
      }
    });
  }

  async function getBoard(
    id,
    skipParse = false,
    includeBoards,
    returnAssets,
    parentBoard,
    body,
  ) {
    const response = await User.request.files.boards.getBoard(
      id,
      {
        include_assets: !skipParse ? 1 : '',
        ...(includeBoards
          ? { include_boards: JSON.stringify(includeBoards) }
          : {}),
      },
      parentBoard,
      body,
    );
    const { board, assets, globalSettings, fonts } = response;
    if (globalSettings) {
      setGlobalSettings(globalSettings);
    }
    if (fonts?.length) {
      Media.setData({
        type: 'merge',
        data: fonts,
        key: '_id',
        store: 'files',
      });
    }
    if (!board) {
      return response;
    }
    if (!skipParse && assets) {
      bindAssetStates(assets);
      Object.keys(Libraries).forEach((libraryKey) => {
        const context = Libraries[libraryKey];
        context.request.setDefaultHeader(
          'pickit-request-context',
          `board_${id}`,
        );
      });
    }
    if (board?._id) {
      addBoardToStore(board);
    }
    if (returnAssets) {
      return response;
    }
    return board;
  }

  async function getBoardsById(ids) {
    const boards = await User.request.files.boards.getBoardsByIds(ids);
    return boards;
  }
  async function getBoards(type, reset, includeAssets) {
    const { boards, assets } = await User.request.files.boards.getBoards(
      type,
      includeAssets,
    );
    bindAssetStates(assets);
    // boards = await Promise.all(
    //   boards.map(async (board) => {
    //     try {
    //       board.modules = await parseModules(board.modules, board._id);
    //     } catch (e) {}
    //     return board;
    //   })
    // );
    addBoardsToStore(boards);
    return boards;
  }

  // Note: Don't run this before parseModules.
  function aggregateModule(module) {
    module = JSON.parse(JSON.stringify(module)); // Clone module. Weird bug. This works, and Im out.
    const moduleInterface = Modules.INTERFACES[module.type];
    for (const fieldKey in module.data) {
      const value = module.data?.[fieldKey];
      const fieldType = moduleInterface?.[fieldKey];

      if (!fieldType) {
        return false;
      }
      if (!value) {
        return false;
      }
      if (fieldType === 'COLORS') {
        module.data[fieldKey] = value.map((color) => {
          return Libraries.brandassets.data.files.find(
            (f) => f._id === color.colorId,
          );
        });
      }
      if (fieldType === 'FILE') {
        module.data[fieldKey] = Libraries[value.library].data.files.find(
          (file) => file._id === value.fileId,
        );
      }
      if (fieldType === 'FILES') {
        module.data[fieldKey] = value.map((file) => {
          return Libraries[file.library].data.files.find(
            (f) => f._id === file.fileId,
          );
        });
      }
      if (fieldType === 'COLLECTIONS') {
        module.data[fieldKey] = value.map((collection) => {
          return Libraries[collection.library].data.collections.find(
            (f) => f._id === collection.collectionId,
          );
        });
      }
      if (fieldType === 'BOARDS') {
        module.data[fieldKey] = value.map((board) => {
          return data.find((f) => f._id === board.boardId);
        });
      }
    }
    return module;
  }
  function aggregateModules(modules) {
    return modules.map(aggregateModule);
  }

  function getAssetsFromBoard(board, prefs = {}) {
    const libraries = {
      media: {
        files: [],
        collections: [],
      },
      documents: {
        files: [],
        collections: [],
      },
      brandassets: {
        files: [],
        collections: [],
      },
    };
    const boards = [];
    board.modules
      .filter((module) => !!Modules.INTERFACES[module.type])
      .forEach((module) => {
        const moduleInterface = Modules.INTERFACES[module.type];
        for (const fieldKey in module.data) {
          const value = module.data[fieldKey];
          const fieldType = moduleInterface[fieldKey];
          if (fieldType === 'COLORS') {
            value.forEach((color) => {
              if (libraries.brandassets.files.includes(color?.colorId)) {
                return false;
              }
              libraries.brandassets.files.push(color?.colorId);
            });
          }
          if (
            fieldType === 'FILE' &&
            value?.library &&
            !prefs.onlyDownloadable
          ) {
            libraries[value.library].files.push(value.fileId);
          }
          if (fieldType === 'FILES' && !prefs.onlyDownloadable) {
            value.forEach((file) => {
              if (libraries[file.library].files.includes(file.fileId)) {
                return false;
              }
              libraries[file.library].files.push(file.fileId);
            });
          }
          if (fieldType === 'CONTENT') {
            value.data.forEach((value) => {
              value.assets?.forEach((link) => {
                if (
                  (link.type === 'file' &&
                    libraries[link.library].files.includes(link.value)) ||
                  (prefs.onlyDownloadable && link.preferences?.disableDownload)
                ) {
                  return false;
                }
                if (link.type === 'file') {
                  libraries[link.library].files.push(link.value);
                }
                if (link.type === 'collection') {
                  libraries[link.library]?.collections?.push(link.value);
                }
                if (link.type === 'board') {
                  boards.push(link.value);
                }
              });
              value.links
                ?.filter((l) => !!l)
                .forEach((link) => {
                  if (
                    link.type === 'file' &&
                    libraries[link.library].files.includes(link.value)
                  ) {
                    return false;
                  }
                  if (link.type === 'file') {
                    libraries[link.library].files.push(link.value);
                  }
                  if (link.type === 'collection') {
                    libraries[link.library]?.collections?.push(link.value);
                  }
                  if (link.type === 'board') {
                    boards.push(link.value);
                  }
                });
            });
          }
          if (fieldType === 'IMAGE_TITLE_TEXT_BLOCKS') {
            value.forEach((block) => {
              if (!block?.file?.library) {
                return false;
              }
              if (
                libraries[block?.file?.library]?.files.includes(
                  block.file?.fileId,
                )
              ) {
                return false;
              }
              libraries[block.file.library].files.push(block.file.fileId);
            });
          }
          if (fieldType === 'COLLECTION') {
            libraries[value.library].collections.push(value.collectionId);
          }
          if (fieldType === 'COLLECTIONS') {
            value.forEach((collection) => {
              if (
                libraries[collection.library].collections.includes(
                  collection.collectionId,
                )
              ) {
                return false;
              }
              libraries[collection.library].collections.push(
                collection.collectionId,
              );
            });
          }
          if (fieldType === 'BOARDS') {
            value.forEach((board) => {
              if (data.includes(board.boardId)) {
                return false;
              }
              boards.push(board.boardId);
            });
          }
        }
        return module;
      });
    return {
      libraries,
      boards,
    };
  }

  async function parseModules(modules, boardId) {
    const libraries = {
      media: {
        files: [],
        collections: [],
      },
      documents: {
        files: [],
        collections: [],
      },
      brandassets: {
        files: [],
        collections: [],
      },
    };
    const boards = [];
    modules = await Promise.all(
      modules
        .filter((module) => !!Modules.INTERFACES[module.type])
        .map(async (module) => {
          const moduleInterface = Modules.INTERFACES[module.type];
          for (const fieldKey in module.data) {
            const value = module.data[fieldKey];
            const fieldType = moduleInterface[fieldKey];
            if (fieldType === 'COLORS') {
              value.forEach((color) => {
                if (libraries.brandassets.files.includes(color?.colorId)) {
                  return false;
                }
                libraries.brandassets.files.push(color?.colorId);
              });
            }
            if (fieldType === 'FILE' && value?.library) {
              libraries[value.library].files.push(value.fileId);
            }
            if (fieldType === 'FILES') {
              value.forEach((file) => {
                if (libraries[file.library].files.includes(file.fileId)) {
                  return false;
                }
                libraries[file.library].files.push(file.fileId);
              });
            }
            if (fieldType === 'CONTENT') {
              value.data.forEach((value) => {
                value.assets?.forEach((link) => {
                  if (
                    link.type === 'file' &&
                    libraries[link.library].files.includes(link.value)
                  ) {
                    return false;
                  }
                  if (link.type === 'file') {
                    libraries[link.library].files.push(link.value);
                  }
                  if (link.type === 'collection') {
                    libraries[link.library]?.collections?.push(link.value);
                  }
                  if (link.type === 'board') {
                    boards.push(link.value);
                  }
                });
                value.links
                  ?.filter((l) => !!l)
                  ?.forEach((link) => {
                    if (
                      link.type === 'file' &&
                      libraries[link.library].files.includes(link.value)
                    ) {
                      return false;
                    }
                    if (link.type === 'file') {
                      libraries[link.library].files.push(link.value);
                    }
                    if (link.type === 'collection') {
                      libraries[link.library]?.collections?.push(link.value);
                    }
                    if (link.type === 'board') {
                      boards.push(link.value);
                    }
                  });
              });
            }
            if (fieldType === 'IMAGE_TITLE_TEXT_BLOCKS') {
              value.forEach((block) => {
                if (!block?.file?.library) {
                  return false;
                }
                if (
                  libraries[block?.file?.library]?.files.includes(
                    block.file?.fileId,
                  )
                ) {
                  return false;
                }
                libraries[block.file.library].files.push(block.file.fileId);
              });
            }
            if (fieldType === 'COLLECTION') {
              libraries[value.library].collections.push(value.collectionId);
            }
            if (fieldType === 'COLLECTIONS') {
              value.forEach((collection) => {
                if (
                  libraries[collection.library].collections.includes(
                    collection.collectionId,
                  )
                ) {
                  return false;
                }
                libraries[collection.library].collections.push(
                  collection.collectionId,
                );
              });
            }
            if (fieldType === 'BOARDS') {
              value.forEach((board) => {
                if (data.includes(board.boardId)) {
                  return false;
                }
                boards.push(board.boardId);
              });
            }
          }
          return module;
        }),
    );

    await Promise.all(
      Object.keys(libraries).map(async (library) => {
        const Library = Libraries[library];
        // Library.request.setDefaultHeader(
        //   'pickit-request-context',
        //   `board_${boardId}`
        // );
        const { files, collections } = libraries[library];
        const fileIds = files.filter(
          (file) =>
            !Libraries[library].data?.files?.find((f) => f._id === file),
        );
        if (fileIds?.length) {
          await Library.getDocumentsFromArrayId(fileIds);
        }
        const collectionIds = collections.filter(
          (collection) =>
            !Libraries[library].data?.collections?.find(
              (f) => f._id === collection,
            ),
        );
        if (collectionIds?.length) {
          await Library.getCollectionsFromArrayId(collectionIds);
        }
      }),
    );
    if (boards.length) {
      await getBoardsById(boards);
    }
    return modules;
  }

  const moduleHasDraft = (module) => {
    return !!module?.draft?.schema && !!module?.draft?.lastUpdated;
  };

  async function publishModuleDraft(module) {
    const newData = await saveModule({
      _id: module._id,
      ...(module.draft.schema || {}),
      draft: null,
      blueprint: Modules.INTERFACES[module.type],
    });
    return newData;
  }

  function boardHasDirectDraft(board) {
    if (!draftIsEnabled) {
      return false;
    }
    const boardHasDraft = !!board?.draft?.schema && !!board?.draft?.lastUpdated;
    return boardHasDraft;
  }

  function boardHasDraft(board) {
    if (!draftIsEnabled) {
      return false;
    }
    const boardHasDraft = board?.draft?.schema && board?.draft?.lastUpdated;
    return (
      boardHasDraft ||
      !!board.modules?.filter((module) => moduleHasDraft(module)).length
    );
  }

  const values = {
    globalSettings,
    api: {
      isLockedDraft: (board) =>
        board?.draft?.createdBy &&
        board?.draft?.createdBy !== User.djangoProfile?.id,
      isDraftReadyForPublish: (board) => board?.draftPublish?.isReady,
      searchBoard,
      saveBoard,
      saveBoardPartial,
      saveBoardModulePartial,
      prepareBoardDraft: async (boardId) => {
        const latestBoard = getStore('BOARDS').getByKey(boardId);
        if (latestBoard && draftIsEnabled && !latestBoard?.draft?.lastUpdated) {
          return saveBoardPartial(boardId, [
            {
              $set: {
                draft: {
                  schema: {
                    ...(latestBoard || {}),
                    modules: latestBoard?.modules?.[0]?._id
                      ? latestBoard?.modules?.map((m) => m._id)
                      : latestBoard.modules || [],
                  },
                  createdBy: User?.djangoProfile?.id,
                  lastUpdated: new Date(),
                },
              },
            },
          ]);
        }
        return latestBoard;
      },
      publishBoard: async (board) => {
        if (board?.draft?.lastUpdated) {
          await saveBoard(
            {
              ...board,
              ...(board.draft?.schema || {}),
              draftPublish: null,
              draft: null,
            },
            false,
            true,
          );
        } else {
          await saveBoardPartial(board._id, [
            {
              draftPublish: null,
              draft: null,
            },
          ]);
        }
        const _modules = [
          ...(board?.modules || []),
          ...(board?.draft?.schema?.modules || []),
        ];
        const modules = getStore('BOARD_MODULES').getByKeys(
          _modules.map((m) => m?._id || m),
        );
        for (const module of modules) {
          const isDeleted =
            board?.draft?.lastUpdated &&
            !board?.draft?.schema?.modules?.find((m) => m._id === module._id);
          if (isDeleted) {
            getStore('BOARD_MODULES').remove(module);
          }
          if (moduleHasDraft(module)) {
            await publishModuleDraft({
              ...module,
            });
          }
        }
      },
      cloneBoard,
      getBoards,
      getBoard,
      deleteBoard,
      modules: {
        saveModule,
        deleteModule,
        publishModuleDraft,
        removeDraft: async (module) => {
          const newModule = await saveModule({
            _id: module._id,
            draft: null,
            blueprint: Modules.INTERFACES[module.type],
          });
          getStore('BOARD_MODULES').addOrUpdate({
            ...newModule,
            renderId: randomString(10),
          });
        },
      },
      connect: User.request.files.boards.connect,
    },
    isLocked,
    setIsLocked,
    // boardHasDraft also check modules, this one only check the actual board.
    boardHasDirectDraft,
    boardHasDraft,
    boardIsReadyForPublish: (board) => {
      if (!draftIsEnabled) {
        return false;
      }
      return boardHasDraft(board) && board.draftPublish?.isReady;
    },
    userCanPublish: () => {
      if (
        !User.data?.selectedCommunity?.settings?.boards?.draftModeRestricted
      ) {
        return userStatus?.product?.isBusinessAdmin;
      }
      return (
        User.data?.selectedCommunity?.settings?.boards?.draftModePublishingUsers?.includes(
          User?.data?.user?.djangoProfile?.profile?.id,
        ) || userStatus?.product?.isBusinessOwner
      );
    },
    moduleHasDraft,
    bindAssetStates,
    data,
    useDraft,
    draftModeEnabled:
      User.data?.selectedCommunity?.settings?.boards?.draftModeEnabled,
    previewDraft, // Preview banner is activated
    isSaving,
    aggregateModule,
    aggregateModules,
    parseModules,
    getAssetsFromBoard,
    moduleSchema: Modules.SCHEMAS,
  };
  return (
    <BoardsContext.Provider value={values}>
      {props.children}
    </BoardsContext.Provider>
  );
}
