import { useUserContext } from 'hooks/useUserContext';
import { useEffect, useReducer, useRef, useState } from 'react';
import localStorageWrapper from 'utils/localStorageWrapper';
import useIntegrationHelpers from '../useIntegrationHelpers';

export default function useHubspotApi() {
  const userContext = useUserContext();
  const IntegrationHelpers = useIntegrationHelpers();
  const [token, setToken] = useReducer((state, action) => ({ ...action }), {});
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const isRefreshingToken = useRef(false);
  const refreshingToken = useRef(null);
  const hasTriedRefreshingToken = useRef(false);

  const LOCAL_STORAGE_KEY = 'userHubspotToken';

  useEffect(() => {
    const token = localStorageWrapper.getItem(LOCAL_STORAGE_KEY);
    if (token) {
      setToken(JSON.parse(token));
    }
  }, []);

  useEffect(() => {
    if (localStorageWrapper.getItem(LOCAL_STORAGE_KEY)) {
      setIsConnected(true);
    }
  }, [localStorageWrapper.getItem(LOCAL_STORAGE_KEY)]);

  useEffect(() => {
    if (Object.keys(token)?.length) {
      localStorageWrapper.setItem(LOCAL_STORAGE_KEY, JSON.stringify(token));
    }
  }, [token]);

  useEffect(() => {
    if (token.access_token) {
      setIsConnected(true);
    }
  }, [token]);

  const search = async (value, cursor, foldersOnly) => {
    const items = await getServiceItems(
      foldersOnly ? 'folders' : 'files',
      false,
      cursor,
      value,
    );
    const result = {
      hasMore: !!items?.paging?.next ?? false,
      cursor: items?.paging?.next?.after,
      matches: items.results.map((item) =>
        IntegrationHelpers.createFileObject(
          item.id,
          item.name,
          null,
          null,
          item.path,
          null,
          item.id,
        ),
      ),
    };
    return result;
  };

  const hubspotProxy = async (request) => {
    const authToken = JSON.parse(
      localStorageWrapper.getItem(LOCAL_STORAGE_KEY),
    );
    request.auth = `Bearer ${authToken.accessToken}`;
    const res = await userContext.request.files.hubspot.proxy({ request });
    if (res.status === 401) {
      if (refreshingToken.current) {
        await refreshingToken.current;
      } else {
        refreshingToken.current = new Promise(async (resolve) => {
          await getRefreshToken(authToken.refreshToken);
          resolve();
        });
        await refreshingToken.current;
        refreshingToken.current = null;
      }
      return hubspotProxy(request);
    }
    const result = await res.json();
    return result.data;
  };

  const getServiceItems = async (
    itemType,
    parentFolderId,
    cursor,
    value,
    fetchMax,
  ) => {
    const hubspotReq = {
      method: 'get',
      path: `/files/v3/${itemType}/search?sort=name`,
    };
    if (parentFolderId && !isNaN(parentFolderId)) {
      hubspotReq.path += `&parentFolderId=${parentFolderId}`;
    } else if (parentFolderId) {
      const folder = await getFolderItem(parentFolderId);
      hubspotReq.path += `&parentFolderId=${folder.id}`;
    } else if (parentFolderId === null) {
      hubspotReq.path += `&parentFolderId=${0}`;
    }
    if (value) {
      hubspotReq.path += `&name=${value}`;
    }
    if (cursor) {
      hubspotReq.path += `&after=${cursor}`;
    }
    if (fetchMax) {
      hubspotReq.path += `&limit=${100}`;
    }
    return hubspotProxy(hubspotReq);
  };

  const getProcessedFiles = async (
    parentFolderId,
    cursor,
    refresh,
    skipCache,
    itemType,
  ) => {
    const items = await getServiceItems(
      itemType,
      parentFolderId === '' ? null : parentFolderId,
      cursor,
    );
    if (items.error && items.status === 401) {
      throw new Error('NO_ACCESS');
    } else if (items.error) {
      console.error(items);
      throw new Error();
    }
    const result = {
      hasMore: !!items?.paging?.next ?? false,
      cursor: items?.paging?.next?.after,
      data: items.results.map((item) =>
        IntegrationHelpers.createFileObject(
          item.id,
          item.name,
          null,
          null,
          item.path,
          null,
          item.id,
        ),
      ),
    };
    return result;
  };

  const getRefreshToken = async (refreshToken) => {
    const res = await userContext.request.files.hubspot.refreshToken(
      refreshToken,
      `${window.location.origin}/genericoauth.html`,
    );
    if (!res.ok) {
      disconnect();
      return;
    }
    const result = await res.json();
    const hubspot = {
      accessToken: result.access_token,
      refreshToken: result.refresh_token,
      expires: result.expires_in * 1000 + Date.now(),
    };
    localStorageWrapper.setItem(LOCAL_STORAGE_KEY, JSON.stringify(hubspot));
    setIsConnected(true);
    return hubspot;
  };

  const getRefreshTokenFromCode = async (code) => {
    const result = await userContext.request.files.hubspot.getToken(
      code,
      `${window.location.origin}/genericoauth.html`,
    );
    const hubspot = {
      accessToken: result.access_token,
      refreshToken: result.refresh_token,
      expires: result.expires_in * 1000 + Date.now(),
    };
    localStorageWrapper.setItem(LOCAL_STORAGE_KEY, JSON.stringify(hubspot));
    setIsConnected(true);
  };

  async function uploadFile(folderId, fileUrl, fileName, replace) {
    // Send the initial request to upload the file from URL
    const uploadReq = {
      method: 'post',
      path: `/files/v3/files/import-from-url/async`,
      body: {
        folderId,
        fileUrl,
        fileName,
        replace: !!replace,
      },
    };

    try {
      const response = await hubspotProxy(uploadReq);
      if (!response || !response.links || !response.links.status) {
        throw new Error(
          'Failed to initiate upload or status URL missing from the response',
        );
      }

      const statusUrl = response.links.status;
      const url = new URL(statusUrl);

      // Function to check the status of the upload using the provided URL
      const checkStatus = async () => {
        const statusReq = {
          method: 'get',
          path: url.pathname, // Adjust based on the actual URL received
        };

        return await hubspotProxy(statusReq);
      };

      // Poll the status endpoint every 5 seconds until the file is fully uploaded
      return new Promise((resolve, reject) => {
        const intervalId = setInterval(async () => {
          try {
            const status = await checkStatus();
            if (status.status === 'COMPLETE') {
              clearInterval(intervalId);
              resolve(status.result);
            } else if (status.status === 'FAILED') {
              clearInterval(intervalId);
              reject(new Error('Upload failed.'));
            }
          } catch (error) {
            clearInterval(intervalId);
            reject(new Error(`Error checking upload status: ${error.message}`));
          }
        }, 1000);
      });
    } catch (error) {
      throw new Error(
        `An error occurred during the upload process: ${error.message}`,
      );
    }
  }

  async function checkUploadedStatus(url) {
    const auth = `Bearer ${await getToken()}`;
    const result = await userContext.request.files.hubspot.uploadStatus(
      url,
      auth,
    );
    if (result?.data?.status === 'PENDING') {
      // loop this function using a setTimeout
      return new Promise((resolve) => {
        setTimeout(async () => {
          const status = await checkUploadedStatus(url);
          resolve(status);
        }, 1000);
      });
    }
    return result;
  }

  async function getAllFilesFromFolder(itemId, fetchMax) {
    let allItems = [];
    let items = await getServiceItems('files', itemId, null, null, fetchMax);
    if (items.error && items.status === 401) {
      throw new Error('NO_ACCESS');
    } else if (items.error) {
      console.error(items);
      throw new Error();
    }

    allItems = [
      ...items.results.map((item) =>
        IntegrationHelpers.createFileObject(
          item.id,
          item.name,
          null,
          null,
          item.path,
          null,
          item.id,
          null,
          null,
          item.updatedAt,
          item.parentFolderId,
        ),
      ),
    ];

    while (items?.paging?.next) {
      items = await getServiceItems(
        'files',
        itemId,
        items?.paging?.next?.after,
        null,
        fetchMax,
      );

      if (items.error && items.status === 401) {
        throw new Error('NO_ACCESS');
      } else if (items.error) {
        console.error(items);
        throw new Error();
      }
      allItems = [
        ...allItems,
        ...items.results.map((item) =>
          IntegrationHelpers.createFileObject(
            item.id,
            item.name,
            null,
            null,
            item.path,
            null,
            item.id,
            null,
            null,
            item.updatedAt,
            item.parentFolderId,
          ),
        ),
      ];
    }

    return allItems;
  }

  async function getFolderItem(id) {
    const hubspotReq = {
      method: 'get',
      path: `/files/v3/folders/${id}`,
    };
    return hubspotProxy(hubspotReq);
  }

  async function createFolder(parentFolderId, name) {
    let path;
    if (
      isNaN(parentFolderId) ||
      (typeof parentFolderId === 'string' && !parentFolderId?.length)
    ) {
      path = parentFolderId;
    }
    const ILLIGAL_CHARS_REGEX = /[|&;$%@"<>()+*?[\]_\\""!,]/g;
    let newName = name.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      '',
    );
    newName = name.replace(ILLIGAL_CHARS_REGEX, '').trim();
    const hubspotReq = {
      method: 'post',
      path: `/files/v3/folders`,
      body: {
        name: newName,
      },
    };
    if (path || (path != null && !path?.length)) {
      hubspotReq.body.parentPath = path;
    } else {
      hubspotReq.body.parentFolderId = parentFolderId;
    }
    return hubspotProxy(hubspotReq);
  }

  async function renameFolder(id, name) {
    const ILLIGAL_CHARS_REGEX = /[|&;$%@"<>()+*?[\]_\\""!,]/g;
    const newName = name.replace(ILLIGAL_CHARS_REGEX, '').trim();
    const hubspotReq = {
      method: 'post',
      path: `/files/v3/folders/update/async`,
      body: {
        name: newName,
        id,
      },
    };
    return hubspotProxy(hubspotReq);
  }

  async function deleteItem(id) {
    const hubspotReq = {
      method: 'delete',
      path: `/files/v3/files/${encodeURIComponent(id)}`,
    };
    return hubspotProxy(hubspotReq);
  }

  async function restoreFolder(id) {
    const hubspotReq = {
      method: 'post',
      path: `/filemanager/api/v2/folders/${encodeURIComponent(
        id,
      )}/versions/restore`,
    };
    return hubspotProxy(hubspotReq);
  }

  const getToken = async () => {
    if (JSON.parse(localStorageWrapper.getItem(LOCAL_STORAGE_KEY))) {
      const { expires, accessToken } = JSON.parse(
        localStorageWrapper.getItem(LOCAL_STORAGE_KEY),
      );
      const isExpired = Date.now() > expires;
      if (isExpired) {
        const result = await getRefreshToken();
        return result.access_token;
      }
      return accessToken;
    }
  };

  function disconnect() {
    setIsConnected(false);
    localStorageWrapper.removeItem(LOCAL_STORAGE_KEY);
    window.location.reload();
  }

  async function handleOAuthCode(code) {
    try {
      await getRefreshTokenFromCode(code);
      const hubspot = JSON.parse(
        localStorageWrapper.getItem(LOCAL_STORAGE_KEY),
      );
      setIsConnected(true);
      return hubspot.refreshToken;
    } catch (e) {
      console.error(e);
    }
  }

  return {
    token,
    handleOAuthCode,
    LOCAL_STORAGE_KEY,
    isConnected,
    setIsConnected,
    deleteItem,
    restoreFolder,
    getProcessedFiles,
    search,
    checkUploadedStatus,
    getToken,
    uploadFile,
    getFolderItem,
    getRefreshToken,
    renameFolder,
    createFolder,
    isScopeConnected: (scope) => token?.scope?.includes(scope),
    isConnecting,
    disconnect,
    getAllFilesFromFolder,
  };
}
