import { getConfigStore, useConfigStoreValue } from '@pixi/store';
import {
  closeCollectionPreview,
  closeFileBrowser,
  closeFileImageEditor,
  closeAssetPreview,
  closeFileSettingsPopup,
  closeLibrarySettings,
} from '@pixi/AppController';
import AssetPreview from '@pixi/components/AssetPreview';
import CollectionPreview from '@pixi/components/PreviewCollection';
import LibrarySettings from 'views/_Manage/Files/views/LibrarySettings';
import AppToasts from '@pixi/components/AppToasts';
import SelectFile from '@pixi/components/SelectFile';
import AppViewSetter from '@pixi/AppViewSetter';
import FileForm from 'views/_Manage/Files/views/FileForm';
import ImageEditor from 'components/ImageEditor';
import { randomString } from 'utils';
import slugify from 'slugify';
import useStorageUrl from 'hooks/files/useStorageUrl';
import FileBrowserPopup from './components/FileBrowserPopup';
import AppPreviewBanner from './AppPreviewBanner';

export default function AppGlobals() {
  const filePreview = useConfigStoreValue('APP_CONFIG', 'filePreview');
  const collectionPreview = useConfigStoreValue(
    'APP_CONFIG',
    'collectionPreview',
  );
  const selectFile = useConfigStoreValue('APP_CONFIG', 'selectFile');
  const librarySettings = useConfigStoreValue('APP_CONFIG', 'librarySettings');
  const fileSettings = useConfigStoreValue('APP_CONFIG', 'fileSettings');
  const fileImageEditor = useConfigStoreValue('APP_CONFIG', 'fileImageEditor');
  const fileBrowser = useConfigStoreValue('APP_CONFIG', 'fileBrowser');

  const { generateUrl } = useStorageUrl();

  return (
    <>
      <AppPreviewBanner />
      {fileImageEditor?.file?._id && (
        <>
          <ImageEditor
            visible
            onComplete={async (file: File, design: any) => {
              const uploadFile: any = new File(
                [file],
                `${fileImageEditor.file?.file?.name.split('.')?.[0]}-${slugify(
                  `${file?.name}-variant`,
                )}.jpg`,
                {
                  type: file.type,
                },
              );
              uploadFile.id = randomString(8);
              await fileImageEditor?.prefs?.onComplete?.(uploadFile, {
                design,
              });
              closeFileImageEditor();
            }}
            preferences={{
              ...(fileImageEditor?.prefs?.forceCropping
                ? {
                    utils: ['crop'],
                    cropEnableSelectPreset: false,
                    cropEnableButtonFlipHorizontal: false,
                    cropEnableButtonFlipVertical: false,
                    imageCrop: {
                      x: 0,
                      y: 0,
                      width: fileImageEditor?.prefs?.forceCropping?.width,
                      height: fileImageEditor?.prefs?.forceCropping?.height,
                    },
                    // imageCropMinSize: {
                    //   width: fileImageEditor?.prefs?.forceCropping?.width,
                    //   height: fileImageEditor?.prefs?.forceCropping?.height,
                    // },
                    // imageCropMaxSize: {
                    //   width: fileImageEditor?.prefs?.forceCropping?.width,
                    //   height: fileImageEditor?.prefs?.forceCropping?.height,
                    // },
                    imageCropAspectRatio:
                      fileImageEditor?.prefs?.forceCropping?.width /
                      fileImageEditor?.prefs?.forceCropping?.height,
                    imageTargetSize: {
                      width: fileImageEditor?.prefs?.forceCropping?.width,
                      height: fileImageEditor?.prefs?.forceCropping?.height,
                    },
                    cropEnableInfoIndicator: false,
                    cropEnableImageSelection: false,
                  }
                : {
                    cropSelectPresetOptions: [
                      [
                        'Crop',
                        [
                          [undefined, 'Custom'],
                          [1, 'Square'],
                          [1.5, 'Landscape'],
                          [0.7, 'Portrait'],
                        ],
                      ],
                      [
                        'Size',
                        [
                          [[180, 180], 'Profile Picture'],
                          [[1200, 600], 'Header Image'],
                          [[800, 400], 'Timeline Photo'],
                        ],
                      ],
                    ],
                  }),
            }}
            onHide={() => {
              closeFileImageEditor();
            }}
            src={generateUrl(
              fileImageEditor?.file?.file?.previews?.[0]?.url ||
                fileImageEditor?.file?.file?.url,
            )}
          />
        </>
      )}
      {fileSettings?.file?._id && (
        <FileForm
          isOpen
          onClose={() => closeFileSettingsPopup()}
          onCancel={() => closeFileSettingsPopup()}
          onSave={() => closeFileSettingsPopup()}
          metadata={fileSettings.file}
          defaultTab={fileSettings.prefs?.defaultTab}
        />
      )}
      <AppViewSetter />
      <SelectFile
        assetGridProps={{
          disable: ['status', 'rightPanel', 'fullscreen'],
          ...(selectFile.assetGridProps || {}),
        }}
        libraryId={selectFile.libraryId}
        onSelect={selectFile.callback}
        multiple={selectFile.multiple}
        onClose={() => {
          selectFile.onClose?.();
          getConfigStore('APP_CONFIG').setValue('selectFile', {});
        }}
        selectButtonLabel={selectFile.selectButtonLabel}
        {...selectFile?.popupProps}
      />
      <AppToasts />
      <AssetPreview
        metadata={filePreview?.file}
        onClose={() => {
          filePreview?.prefs?.onClose?.();
          closeAssetPreview();
        }}
        keys={filePreview?.prefs?.keys}
        defaultView={filePreview?.prefs?.defaultView}
        preferences={filePreview?.prefs}
        extraActions={filePreview?.prefs?.extraActions}
        zIndex={
          collectionPreview?.collection?._id || fileBrowser?.isOpen
            ? collectionPreview?.prefs?.popupProps?.zIndex
              ? (parseInt(
                  collectionPreview?.prefs?.popupProps?.zIndex?.toString(),
                ) || 20) + 1
              : 20
            : 19
        }
        {...filePreview?.prefs?.previewFileProps}
      />
      <CollectionPreview
        collection={collectionPreview?.collection}
        onClose={() => {
          closeCollectionPreview();
        }}
        openAssetPreviewPrefs={collectionPreview?.prefs?.openAssetPreviewPrefs}
        popupProps={collectionPreview?.prefs?.popupProps}
        assetGridProps={collectionPreview?.prefs?.assetGridProps}
        zIndex={filePreview?.file?._id ? 11 : 10}
      />
      <FileBrowserPopup
        type={fileBrowser?.type}
        isOpen={fileBrowser?.isOpen}
        onClose={() => {
          closeFileBrowser();
        }}
        popupProps={fileBrowser?.prefs?.popupProps}
        assetGridProps={fileBrowser?.prefs?.assetGridProps}
        zIndex={filePreview?.file?._id ? 11 : 10}
      />
      <LibrarySettings
        isOpen={librarySettings.isOpen}
        libraryId={librarySettings.libraryId}
        defaultSection={librarySettings.defaultSection}
        onClose={() => {
          closeLibrarySettings();
        }}
      />
    </>
  );
}
