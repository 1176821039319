import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useUserContext } from 'hooks';
import { isNative } from 'utils/platform';
import { analytics, insert } from 'services';
import {
  Badge,
  Box,
  Card,
  Group,
  Kbd,
  LoadingOverlay,
  Overlay,
  Paper,
  Stack,
  Tabs,
  TextInput,
  Tooltip,
} from '@mantine/core';
import PixiPopup from '@pixi/elements/Popup';
import useContextFromFile from 'hooks/useContextFromFile';
import PixiIcon from '@pixi/elements/Icon';
import PixiText from '@pixi/elements/Text';
import AssetEditPanel, { AssetEditPanelProps } from '../AssetPanel';
import { useAppContext } from 'contexts/Providers/AppProvider';
import { useColorScheme } from '@pixi/AppController';
import { useConfigStoreValue, useDataStoreCallback } from '@pixi/store';
import { useWindowEvent } from '@mantine/hooks';
import PropertyValue from 'views/_Manage/Library/workflows/Properties/PropertyValue';
import { AssetPreviewSlides } from './views/AssetPreviewSlides';
import { AssetPreviewThumbnail } from './views/AssetPreviewThumbnail';
import { AssetPreviewActions } from './views/AssetPreviewActions';
import { AssetPreviewFacialRecognition } from './views/AssetPreviewFacialRecognition';
import { AssetPreviewProps } from './types';
import { AssetPreviewDetails } from './views/AssetPreviewDetails';
import PixiButton from '@pixi/elements/Button';

function Wrapper({ children }: { children: ReactNode }) {
  return <>{children}</>;
}
const AssetPreview = ({
  metadata: _metadata,
  onClose,
  includeDownloadBag,
  disableDownload,
  defaultView,
  preferences,
  downloadWrapper,
  editPanelProps,
  extraActions,
  keys,
  skipPopup,
  zIndex,
}: AssetPreviewProps) => {
  const App = useAppContext();
  const colorScheme = useColorScheme();
  const User = useUserContext();
  const viewport = useConfigStoreValue('APP_VIEW', 'viewport');
  const [isOpen, setIsOpen] = useState(false);
  const metadata = useDataStoreCallback(
    'FILES',
    (data, store) => {
      return {
        ...(data?.find((file) => file?._id === _metadata?._id) || _metadata),
      };
    },
    [_metadata?._id],
  );

  useEffect(() => {
    if (metadata?._id) {
      setIsOpen(true);
    }
  }, [metadata?._id]);

  const [view, setView] = useState('image');

  useWindowEvent(
    'keydown',
    (event) => {
      const keysToListen = keys?.filter(
        (key) => key.autoCapture && key.eventKey,
      );
      keysToListen?.forEach((key) => {
        if (key.eventKey && event.key === key.eventKey) {
          event.preventDefault();
          event.stopPropagation();
          key.onClick();
        }
      });
    },
    {
      capture: true,
    },
  );

  useEffect(() => {
    if (metadata) {
      analytics.trackEvent('File previewed', {
        'File Name': metadata.name,
        'File Id': metadata._id,
      });
    }
  }, []);

  useEffect(() => {
    if (defaultView) {
      setView(defaultView);
    } else {
      setView('preview');
    }
  }, [defaultView]);

  const showSlides =
    metadata?.file?.slides_published ??
    User?.data?.selectedCommunity?.settings?.preferences
      ?.enableQuickSlidePreview;
  const hasSlides = !!metadata?.file?.slides?.length && showSlides !== false;

  const isMobile =
    viewport === 'xxs' ||
    viewport === 'xs' ||
    viewport === 'sm' ||
    viewport === 'md';
  return (
    <>
      <PixiPopup
        zIndex={zIndex}
        size={isNative() ? 'fullscreen' : isMobile ? 'md' : '100%'}
        opened={isOpen}
        onClose={() => {
          onClose();
          setIsOpen(false);
        }}
        fullHeight={!isMobile}
        id={metadata?._id}
        scrollAreaComponent={Wrapper}
        noScroll
        styles={{
          header: {
            display: 'none',
          },
        }}
        trapFocus={false}
        actions={
          metadata?._id && (
            <>
              {extraActions ? (
                <Group style={{ flexShrink: 0 }}>{extraActions}</Group>
              ) : (
                ''
              )}
              <AssetPreviewActions
                keys={keys}
                file={metadata as Pickit.FileInterface}
                disableDownload={disableDownload}
                view={view}
                onView={setView}
              />
            </>
          )
        }
      >
        {metadata?._id && (
          <Tabs
            h="100%"
            color="dark"
            style={{ display: 'flex', flexDirection: 'column' }}
            styles={{
              panel: { minHeight: 1, overflow: 'auto' },
            }}
            value={view}
            onChange={(value) => setView(value as string)}
            variant="pills"
            defaultValue={defaultView || 'preview'}
            keepMounted={false}
            autoFocus={false}
          >
            <Group
              w="100%"
              wrap="nowrap"
              p={isMobile ? 'lg' : 'xl'}
              py="lg"
              bg={colorScheme === 'dark' ? 'dark.8' : 'gray.0'}
              align="flex-start"
            >
              <Stack
                w="100%"
                style={{
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: isMobile ? 'flex-start' : 'space-between',
                }}
              >
                <PixiText
                  w="100%"
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {metadata?.name}
                </PixiText>
                <Group
                  w="100%"
                  align="center"
                  wrap="nowrap"
                  justify={!isMobile ? 'flex-end' : 'flex-start'}
                >
                  {!hasSlides && !metadata?.vision?.faces?.length ? (
                    <></>
                  ) : (
                    <Tabs.List m="0">
                      <Tabs.Tab
                        value="preview"
                        leftSection={<PixiIcon name="image" />}
                      >
                        Preview
                      </Tabs.Tab>
                      {isMobile && (
                        <Tabs.Tab
                          value="information"
                          leftSection={<PixiIcon name="circle-info" />}
                        >
                          Asset details
                        </Tabs.Tab>
                      )}
                      {hasSlides && (
                        <Tabs.Tab
                          value="slides"
                          leftSection={<PixiIcon name="notes" />}
                        >
                          Slides
                        </Tabs.Tab>
                      )}
                      {!!metadata?.vision?.faces?.length && App.isManage && (
                        <Tabs.Tab
                          value="facial_recognition"
                          leftSection={<PixiIcon name="face-viewfinder" />}
                        >
                          Facial Recognition
                        </Tabs.Tab>
                      )}
                    </Tabs.List>
                  )}
                </Group>
              </Stack>
              <Group w={isMobile ? 'auto' : '100%'} justify="flex-end">
                <PixiButton
                  px="xs"
                  size="xs"
                  color="dark"
                  variant="subtle"
                  style={{ flexShrink: 0, overflow: 'visible' }}
                  onClick={() => {
                    setIsOpen(false);
                    onClose?.();
                  }}
                >
                  <PixiIcon name="xmark" />
                </PixiButton>
              </Group>
            </Group>
            <Group
              w="100%"
              h="100%"
              wrap="nowrap"
              align="stretch"
              gap="0"
              p="0"
              pb="0"
              style={{ overflow: 'hidden' }}
            >
              <Box w="100%" miw={1}>
                <Tabs.Panel value="preview" p="md" pos="relative" h="100%">
                  <AssetPreviewThumbnail
                    file={metadata as Pickit.FileInterface}
                  />
                </Tabs.Panel>
                <Tabs.Panel value="slides" p="md">
                  <AssetPreviewSlides file={metadata as Pickit.FileInterface} />
                </Tabs.Panel>
                <Tabs.Panel
                  h="100%"
                  value="facial_recognition"
                  p="md"
                  pos="relative"
                >
                  {!!metadata?.vision?.faces?.length && (
                    <AssetPreviewFacialRecognition
                      file={metadata as Pickit.FileInterface}
                      preferences={preferences?.facialRecognition}
                    />
                  )}
                </Tabs.Panel>
                <Tabs.Panel value="information" p="md">
                  <AssetPreviewDetails
                    file={metadata as Pickit.FileInterface}
                    view={view}
                  />
                </Tabs.Panel>
              </Box>
              <Box
                w="100%"
                h="100%"
                maw={350}
                className="hide-mobile"
                miw={1}
                style={{ flexShrink: 0 }}
              >
                <Box h="100%" style={{ overflow: 'auto' }}>
                  <Paper h="100%">
                    {App.isManage ? (
                      <AssetEditPanel
                        files={[metadata as Pickit.FileInterface]}
                        disable={['header']}
                        {...editPanelProps}
                      />
                    ) : (
                      <AssetPreviewDetails
                        file={metadata as Pickit.FileInterface}
                        view={view}
                      />
                    )}
                  </Paper>
                </Box>
              </Box>
            </Group>
          </Tabs>
        )}
      </PixiPopup>
    </>
  );
};

export default AssetPreview;
