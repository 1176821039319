import EventEmitter from '@pixi/helpers/EventEmitter';
import objectPath from 'object-path';
import fastDeepEqual from 'fast-deep-equal/es6';

export default function createDataStore<Interface, KeyType>({
  name,
  key,
  defaultData,
  _dispatch,
  _dispatchItemChange,
  sanitizer,
  objectUpdated,
}: {
  name: string;
  key: string;
  defaultData: Interface[];
  _dispatch: (
    data: Interface[],
    change: 'added' | 'removed' | 'updated',
  ) => void;
  _dispatchItemChange: (
    rows: {
      data: Interface;
      change: 'added' | 'removed' | 'updated';
    }[],
  ) => void;
  sanitizer?: (data: Interface[]) => Interface[];
  objectUpdated?: (data: Interface, newData: Interface) => boolean;
}) {
  const eventEmitter = new EventEmitter<{
    change: [Interface[], Interface[]];
    itemChange: [Interface, Interface];
  }>();
  let state: Interface[] = [];
  let _state: Interface[] = [...defaultData]; // Cloned to ensure no direct mutations
  type S = Interface[];

  function setState(newState: S) {
    _state = [...newState];
  }

  function getState() {
    return [..._state];
  }

  function isUpdated(data: Interface, newData: Interface) {
    if (objectUpdated) {
      return objectUpdated(data, newData);
    }
    return !fastDeepEqual(data, newData);
  }

  function dispatch(
    data: Interface[],
    change: 'added' | 'removed' | 'updated',
  ) {
    eventEmitter.dispatch('change', data, _state);
    setState(data);
    _dispatch([...data], change);
  }
  function dispatchItemChange(
    rows: {
      data: Interface;
      change: 'added' | 'removed' | 'updated';
    }[],
  ) {
    _dispatchItemChange(rows);
  }

  function getKey(fromData: Interface) {
    return objectPath.get(fromData as object, key) as KeyType;
  }

  function getByKey(key: KeyType) {
    return state.find((row) => getKey(row) === key);
  }

  function getKeyByKey(key: KeyType) {
    return getByKey(key) ? getKey(getByKey(key) as Interface) : null;
  }

  function getByRow(row: Interface, providedData?: S) {
    return (providedData || state).find(
      (r: Interface) => getKey(r) === getKey(row),
    );
  }
  function add(rows: S, prepend?: boolean) {
    const newData = rows.filter((d) => !getByRow(d));
    if (prepend) {
      state.unshift(...newData);
    } else {
      state.push(...newData);
    }
    dispatchItemChange(
      rows.map((row) => ({
        data: row,
        change: 'added',
      })),
    );
    dispatch(state, 'added');
  }
  function update(rows: S) {
    const existingData = rows.filter((d) => !!getByRow(d, state));
    state = state.map((row) => getByRow(row, existingData) || row);
    dispatchItemChange(
      existingData.map((row) => ({ data: row, change: 'updated' })),
    );
    dispatch(state, 'updated');
  }
  function remove(rows: S) {
    state = state.filter((row) => !getByRow(row, rows));
    dispatchItemChange(rows.map((row) => ({ data: row, change: 'removed' })));
    dispatch(state, 'removed');
  }
  function clear() {
    state = [];
    dispatch(state, "removed" );
  }
  function removeByKey(keys: KeyType[]) {
    state = state.filter((row) => !keys.includes(getKey(row)));
    dispatchItemChange(
      keys.map((key) => ({
        data: getByKey(key) as Interface,
        change: 'removed',
      })),
    );
    dispatch(state, 'removed');
  }
  function addOrUpdate(rows: S, prepend?: boolean) {
    const updatedRows: Interface[] = [];
    const addedRows: Interface[] = [];
    rows.forEach((row) => {
      if (!row) {
        return;
      }
      const existingRow = getByKey(getKey(row));
      if (existingRow) {
        if (isUpdated(existingRow, row)) {
          updatedRows.push(Object.assign(existingRow, row) as Interface);
          Object.assign(existingRow, row);
        }
      } else if (prepend) {
        addedRows.push(row);
        state.unshift(row);
      } else {
        addedRows.push(row);
        state.push(row);
      }
    });
    dispatchItemChange([
      ...addedRows.map((row) => ({ data: row, change: 'added' })),
      ...updatedRows.map((row) => ({ data: row, change: 'updated' })),
    ] as any);
    dispatch(state, 'updated');
  }
  function toggle(rows: S) {
    const rowsToAdd = rows.filter((d) => !getByRow(d));
    const rowsToRemove = rows.filter((d) => !!getByRow(d));
    remove(rowsToRemove);
    add(rowsToAdd);
    dispatchItemChange([
      ...rowsToAdd.map((row) => ({ data: row, change: 'added' })),
      ...rowsToRemove.map((row) => ({ data: row, change: 'removed' })),
    ] as any);
    dispatch(state, rowsToRemove?.length ? 'removed' : 'added');
  }

  return {
    get state() {
      return getState();
    },
    getByRow,
    add: (data: Interface | Interface[]) => {
      data = sanitizer ? sanitizer(Array.isArray(data) ? data : [data]) : data;
      add(Array.isArray(data) ? data : [data]);
    },
    update: (data: Interface | Interface[]) => {
      data = sanitizer ? sanitizer(Array.isArray(data) ? data : [data]) : data;
      update(Array.isArray(data) ? data : [data]);
    },
    addOrUpdate: (data: Interface | Interface[]) => {
      data = sanitizer ? sanitizer(Array.isArray(data) ? data : [data]) : data;
      addOrUpdate(Array.isArray(data) ? data : [data]);
    },
    remove: (data: Interface | Interface[]) => {
      data = sanitizer ? sanitizer(Array.isArray(data) ? data : [data]) : data;
      remove(Array.isArray(data) ? data : [data]);
    },
    clear: () => {
      clear();
    },
    removeByKey: (data: KeyType | KeyType[]) => {
      removeByKey(Array.isArray(data) ? data : [data]);
    },
    replace: (data: Interface | Interface[]) => {
      data = sanitizer ? sanitizer(Array.isArray(data) ? data : [data]) : data;
      state = Array.isArray(data) ? data : [data];
      dispatch(state, 'added');
    },
    toggle: (data: Interface | Interface[]) => {
      data = sanitizer ? sanitizer(Array.isArray(data) ? data : [data]) : data;
      toggle(Array.isArray(data) ? data : [data]);
    },
    getKey: (data: Interface) => {
      data = sanitizer ? sanitizer([data])[0] : data;
      return getKey(data);
    },
    getByKey: (key: KeyType) => {
      return getByKey(key);
    },
    getByKeys: (key: KeyType[]) => {
      return key
        .filter((key) => !!getByKey(key))
        .map((key) => getByKey(key) as Interface);
    },
    getKeyByKey: (key: KeyType) => {
      return getKeyByKey(key);
    },
    getMissingKeys: (keys: KeyType[]) => {
      return keys.filter((key) => !getByKey(key));
    },
    reset: () => {
      state = defaultData || [];
      dispatch(state, 'removed');
    },
    onChange: (
      dispatch: (data: Interface[], oldData: Interface[]) => unknown,
    ) => {
      const toggleId = eventEmitter.on('change', dispatch);
      return toggleId;
    },
    key,
  };
}
