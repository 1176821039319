import useDelayedState from 'hooks/useDelayedState';
import { RefObject, useEffect, useRef, useState } from 'react';

export default function useElementViewport(): {
  ref: RefObject<HTMLDivElement>;
  viewport: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
} {
  const ref = useRef<HTMLDivElement>(null);
  const [viewport, setViewport] = useDelayedState(
    getViewport(window.innerWidth),
    200,
  );

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const currentWidth = element.getBoundingClientRect()?.width;
    if (currentWidth) {
      const newViewport = getViewport(currentWidth);
      if (newViewport !== viewport) {
        setViewport(newViewport);
      }
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width } = entry.contentRect;
        if (width) {
          const newViewport = getViewport(width);
          if (newViewport !== viewport) {
            setViewport(newViewport);
          }
        }
      }
    });

    resizeObserver.observe(element);

    // Clean up observer on unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, [viewport, ref?.current]);

  function getViewport(
    width: number,
  ): 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxs' {
    if (width < 360) {
      return 'xxs';
    }
    if (width < 460) {
      return 'xs';
    }
    if (width < 500) {
      return 'sm';
    }
    if (width < 768) {
      return 'md';
    }
    if (width < 1250) {
      return 'lg';
    }
    return 'xl';
  }

  return {
    ref,
    viewport,
  };
}
