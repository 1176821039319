import React, { useReducer, useState } from 'react';
import { useFlowsContext, useUserContext } from 'hooks';
import {
  Button,
  Popup,
  Dropdown,
  Flex,
  Switch,
  Icon,
  Input,
} from 'elements_v2';
import List from 'elements_v2/List';
import Loader from 'elements_v2/Loader';
import useUserStatus from 'hooks/useUserStatus';
import Info from 'elements/Info';
// import "./style.scss";
function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function SelectContributorUsersPixi(props) {
  const userStatus = useUserStatus();
  const Flows = useFlowsContext();
  // search and autocomplete variables
  const [searchQuery, setSearchQuery] = React.useState('');
  const [searchQueryTimeout, setSearchQueryTimeout] = React.useState(null);
  const [isSearching, setIsSearching] = React.useState(false);
  const [autoComplete, setAutoComplete] = React.useState([]);
  const [newEmail, setNewEmail] = useState('');

  // Keeping proxy and selected members
  const [members, setMembers] = React.useState([]);
  const [selectedMembers, setSelectedMembers] = useReducer((state, action) => {
    if (!action.init && props.onlyForm) {
      props.onSave([...action.data]);
    }
    return [...action.data];
  }, []);
  const [externalUsers, setExternalUsers] = React.useState([]);

  const [isEmptyResults, setIsEmptyResults] = useState(false);
  const [isInviting, setIsInviting] = useState(false);

  const [error, setError] = React.useState(undefined);
  const User = useUserContext();

  // Getting users from proxy + preparing specific author options
  async function getUsers(values, replace) {
    setIsSearching(true);
    const userIDs = values ? values.map((value) => value.user_id) : [];

    if (!userIDs.length && !searchQuery) {
      return;
    }

    try {
      const params = {
        search: searchQuery || `${userIDs.join(',')},`,
        role: 'user',
      };
      const users = await User.request.proxy.getUsersV2(params);

      const options = [...users.data].map((option) => {
        const contributor_user = props.contributor_users.filter(
          (contributor_user) =>
            contributor_user.user_id === option[props.keyValue],
        );
        option.default_upload_sharing = contributor_user.length
          ? contributor_user[0].default_upload_sharing
          : 'none';
        option.uploads_need_approval =
          !!contributor_user?.[0]?.uploads_need_approval;
        option.type = 'user';
        option.uploads_enters_flow = contributor_user.length
          ? contributor_user[0].uploads_enters_flow
          : undefined;
        return option;
      });

      setMembers(replace ? [...options] : [...members, ...options]);
      setIsSearching(false);
    } catch (e) {}
  }

  async function getAutoComplete() {
    /** Getting users from proxy with search */
    setIsSearching(true);
    setIsEmptyResults(false);

    const externalUsersResult = externalUsers.filter((user) =>
      user?.email?.includes(searchQuery),
    );

    try {
      setAutoComplete([]);
      const users = await User.request.proxy.getUsersV2({
        search: searchQuery,
        exclude: `${selectedMembers
          .map((_selectedMember) => _selectedMember[props.keyValue])
          .join(',')},`,
        role: 'user',
      });

      let options = [...users.data].map((option) => {
        const contributor_user = props.contributor_users.filter(
          (contributor_user) =>
            contributor_user.user_id === option[props.keyValue],
        );
        option.default_upload_sharing = contributor_user.length
          ? contributor_user[0].default_upload_sharing
          : 'none';
        option.type = 'user';
        return option;
      });
      options = [...options, ...externalUsersResult];

      setAutoComplete([...options].filter((member) => !!member?.id));
      setIsSearching(false);
    } catch (e) {
      try {
        const response = await e.json();
        if (response.status === 'not-found') {
          setAutoComplete([...externalUsersResult]);
          setIsSearching(false);
          setError(externalUsersResult.length ? undefined : 'No users found');
          if (!externalUsersResult?.length) {
            setIsEmptyResults(true);
          }
        }
      } catch (e) {}
    }
  }

  async function inviteUser(email) {
    setIsInviting(true);
    const res = await User.request.proxy.inviteUsers({
      emails: [{ email, groups: [] }],
    });
    const json = await res.json();
    const user = json?.data?.users?.[0]?.data;
    setIsInviting(false);
    if (user) {
      setSelectedMembers({
        data: [
          ...selectedMembers,
          {
            id: user.id,
            email: user.email,
            default_upload_sharing: 'none',
            type: 'user',
          },
        ],
      });
      setSearchQuery('');
    }
  }

  // User's autocomplete dropdown
  React.useEffect(() => {
    clearTimeout(searchQueryTimeout);
    setAutoComplete([]);
    setSearchQueryTimeout(setTimeout(getAutoComplete, 500));
  }, [searchQuery, externalUsers]);

  React.useEffect(() => {
    if (props.contributor_users.length) {
      getUsers(props.contributor_users, true);
    }
  }, [props.contributor_users]);

  React.useEffect(() => {
    if (props.external_users?.length) {
      setSelectedMembers({
        data: selectedMembers.filter((member) => {
          if (member.type === 'external') {
            return !!props.contributor_users.filter(
              (contributor_user) => contributor_user.email === member.email,
            ).length;
          }
          return true;
        }),
        init: true,
      });
    }
  }, [props.external_users]);

  React.useEffect(() => {
    if (props.contributor_users.length) {
      if (members.length || props.external_users) {
        setSelectedMembers({
          data: [
            ...members.filter((member) => {
              return !!props.contributor_users.find(
                (contributor_user) =>
                  contributor_user.user_id !== undefined &&
                  contributor_user.user_id === member.id,
              );
            }),
            ...props.external_users
              .filter((email) => {
                return !!props.contributor_users.find(
                  (contributor_user) => contributor_user.email === email,
                );
              })
              .map((_external_user_email) => {
                const contributor_user = props.contributor_users.filter(
                  (contributor_user) =>
                    contributor_user.email === _external_user_email,
                );
                return Object({
                  email: _external_user_email,
                  default_upload_sharing: contributor_user.length
                    ? contributor_user[0].default_upload_sharing
                    : 'none',
                  type: 'external',
                  first_name: undefined,
                  last_name: undefined,
                  username: _external_user_email,
                  id: undefined,
                  uploads_need_approval: contributor_user.length
                    ? contributor_user[0].uploads_need_approval
                    : false,
                  uploads_enters_flow: contributor_user.length
                    ? contributor_user[0].uploads_enters_flow
                    : undefined,
                });
              }),
          ],
          init: true,
        });
      }
    }
  }, [members, props.contributor_users, props.external_users]);
  React.useEffect(() => {
    const external_users = props.external_users
      .filter((_external_user_email) => {
        const contributor_user = selectedMembers.filter(
          (_selectedMember) => _selectedMember.email === _external_user_email,
        );
        return (
          !contributor_user.length &&
          _external_user_email.toLowerCase().includes(searchQuery.toLowerCase())
        );
      })
      .map((_external_user_email) => {
        const contributor_user = props.contributor_users.filter(
          (contributor_user) => contributor_user.email === _external_user_email,
        );
        return Object({
          email: _external_user_email,
          default_upload_sharing: contributor_user.length
            ? contributor_user[0].default_upload_sharing
            : 'none',
          type: 'external',
          first_name: undefined,
          last_name: undefined,
          username: _external_user_email,
          id: undefined,
          uploads_need_approval: contributor_user.length
            ? contributor_user[0].uploads_need_approval
            : false,
          uploads_enters_flow: contributor_user.length
            ? contributor_user[0].uploads_enters_flow
            : undefined,
        });
      });
    setExternalUsers(external_users);
  }, [selectedMembers, props.external_users, props.contributor_users]);

  const usedFlows = [...(Flows?.flows ?? [])];

  let autoCompleteRows = autoComplete.map((member) => (
    <Dropdown.Option
      value={member[props.keyValue]}
      key={`autocomplete-${member.email}`}
    >
      <Icon name={member?.type === 'user' ? 'People' : 'Lock'} />{' '}
      {member[props.display]}
    </Dropdown.Option>
  ));
  if (
    error &&
    !isSearching &&
    (!autoComplete?.length || autoComplete?.length === 0)
  ) {
    autoCompleteRows = [
      <Dropdown.Option value={undefined} key="empty" disabled>
        {error}
      </Dropdown.Option>,
    ];
  }
  if (isEmptyResults && validateEmail(searchQuery)) {
    autoCompleteRows = (
      <Flex style={{ padding: 10 }} column>
        No user found with that emails
      </Flex>
    );
  }

  const contributorForm = (
    <>
      <Flex>
        <Dropdown
          placeholder="Select existing users"
          icon="PeopleFill"
          autoFocus
          searchable
          closeOnChange={false}
          width={300}
          zIndex={999999999}
          useV2
          isLoadingOptions={isSearching}
          loadingOptionsLabel="Searching..."
          searchPlaceholder="Search or type email to add"
          onSearch={(query) => setSearchQuery(query)}
          query={searchQuery}
          onChange={(value) => {
            if (!value) {
              return false;
            }
            const new_member = autoComplete.filter(
              (_autocomplete_member) =>
                _autocomplete_member[props.keyValue] === value,
            );
            setSelectedMembers({ data: [...selectedMembers, new_member[0]] });
          }}
        >
          {autoCompleteRows}
        </Dropdown>
        <Dropdown
          placeholder="Invite new user (email)"
          icon="PersonPlusFill"
          autoFocus
          searchable
          useV2
          closeOnChange={false}
          width={300}
          zIndex={999999999}
          isLoadingOptions={isSearching}
          loadingOptionsLabel="Searching..."
          searchPlaceholder="Type email to invite"
          onSearch={(query, setIsOpen) => {
            setNewEmail(query);
            if (validateEmail(newEmail)) {
              setIsOpen(true);
            }
          }}
          query={newEmail}
          isOpen={validateEmail(newEmail)}
          forceClosed={!newEmail || !validateEmail(newEmail)}
          style={{ marginLeft: 10 }}
          onChange={(value) => {
            if (!value) {
              return false;
            }
            const new_member = autoComplete.filter(
              (_autocomplete_member) =>
                _autocomplete_member[props.keyValue] === value,
            );
            setSelectedMembers({ data: [...selectedMembers, new_member[0]] });
          }}
        >
          {newEmail && validateEmail(newEmail) && (
            <Flex style={{ padding: 10 }} column>
              <Button
                disabled={isInviting}
                style={{ marginLeft: 0 }}
                outlined
                inline
                onClick={() => inviteUser(newEmail)}
              >
                {isInviting ? (
                  <Loader horizontal size="small">
                    Inviting user...
                  </Loader>
                ) : (
                  'Invite to your Pickit'
                )}
              </Button>
              <Button
                outlined
                inline
                style={{ marginLeft: 0, marginTop: 10 }}
                onClick={() => {
                  setSelectedMembers({
                    data: [
                      ...selectedMembers,
                      {
                        email: newEmail,
                        default_upload_sharing: 'none',
                        type: 'external',
                        first_name: undefined,
                        last_name: undefined,
                        username: newEmail,
                        id: undefined,
                      },
                    ],
                  });
                  setSearchQuery('');
                }}
              >
                Invite to this collection
              </Button>
            </Flex>
          )}
        </Dropdown>
      </Flex>
      <List cards style={{ marginTop: 10 }}>
        {selectedMembers.map((member) => (
          <List.Item
            key={member.email}
            icon={<Icon name={member?.type === 'user' ? 'People' : 'Lock'} />}
            contentStyle={{ alignItems: 'end' }}
            actionsStyle={{ alignItems: 'end' }}
            title={
              <>
                {(member.first_name
                  ? `${member.first_name} ${member.last_name}`
                  : member.username || member.email) || member.email}
              </>
            }
            subtitle={
              member?.type === 'user'
                ? member.first_name
                  ? member.username || member.email
                  : ''
                : 'External user'
            }
            actions={[
              userStatus.policies.access.services.approval &&
              !props.disableApproval ? (
                <Switch
                  label="Approval needed"
                  active={member.uploads_need_approval}
                  key={`switcher-approval-${member.email}`}
                  onChange={() => {
                    selectedMembers.forEach((selectedMember) => {
                      if (selectedMember.email === member.email) {
                        selectedMember.uploads_need_approval =
                          !selectedMember.uploads_need_approval;
                      }
                    });
                    setSelectedMembers({ data: [...selectedMembers] });
                  }}
                />
              ) : (
                <Switch
                  label="Hide uploads"
                  active={member.default_upload_sharing === 'none'}
                  key={`switcher-${member.email}`}
                  onChange={() => {
                    selectedMembers.forEach((selectedMember) => {
                      if (selectedMember.email === member.email) {
                        selectedMember.default_upload_sharing =
                          selectedMember.default_upload_sharing === 'not-set'
                            ? 'none'
                            : 'not-set';
                      }
                    });
                    setSelectedMembers({ data: [...selectedMembers] });
                  }}
                />
              ),
              <Button
                key={`remove-${member.email}`}
                auto
                inline
                onClick={() =>
                  setSelectedMembers({
                    data: [
                      ...selectedMembers.filter(
                        (_selectedMember) =>
                          _selectedMember.email !== member.email,
                      ),
                    ],
                  })
                }
              >
                Remove
              </Button>,
            ]}
          />
        ))}
      </List>
    </>
  );

  if (props.onlyForm) {
    return contributorForm;
  }

  return (
    <Popup
      style={{ maxWidth: 800, minHeight: '70vh' }}
      trigger={props.trigger}
      title="Add contributor"
      zIndex={1001}
      withCloseButton
      actions={(isOpen, setIsOpen) => (
        <Flex width="100%" justifyContent="space-between">
          <Button
            pixi
            inline
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            pixi
            inline
            onClick={() => {
              setIsOpen(false);
              props.onSave(selectedMembers);
            }}
          >
            Done
          </Button>
        </Flex>
      )}
    >
      {contributorForm}
    </Popup>
  );
}
