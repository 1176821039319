import React from 'react';
import ContributionsGridV2 from 'components/ContributionsGridV2';
import { inGoogleDocs, isAddin } from 'utils/platform';
import useInsert from 'hooks/files/useInsert';
import { useAppContext } from 'contexts/Providers/AppProvider';
import localStorageWrapper from 'utils/localStorageWrapper';
import Image from './Image';
// import "./style.scss";

function ImagesGrid(props) {
  const { downloadBag } = useAppContext();
  // Patch LIV behaviour
  const { isLoadingImage, insertImage, downloadImage } = useInsert({
    context: props.context,
  });

  const onRender = React.useCallback(
    (image, imageProps) => {
      return (
        <Image
          image={image}
          data={imageProps}
          isSelected={downloadBag.isInBag(imageProps.photo?.data)}
          onFileRedirect={(file, scenario, event) =>
            props.onFileRedirect(file, scenario, event)
          }
          downloadWrapper={props.downloadWrapper}
          insertImage={() =>
            insertImage(
              imageProps.photo?.data,
              localStorageWrapper.getItem('pickitBOAIInsertSize') || 'default',
            )
          }
          downloadImage={() => downloadImage(imageProps.photo?.data)}
          insertOrDownload={() => {
            if (!isAddin()) {
              downloadImage(imageProps.photo?.data);
            } else {
              insertImage(
                imageProps.photo?.data,
                localStorageWrapper.getItem('pickitBOAIInsertSize') ||
                  'default',
              );
            }
          }}
          includeDownloadBag={props.includeDownloadBag}
          isLoading={isLoadingImage.includes(imageProps.photo?.data?._id)}
          context={props.context}
          altLink={props.altLink}
          detailed={props.detailed}
          setSelectedFiles={() =>
            downloadBag.isInBag(imageProps?.photo?.data)
              ? downloadBag.removeFromBag(imageProps.photo?.data)
              : downloadBag.addToBag(imageProps.photo?.data)
          }
        />
      );
    },
    [
      downloadBag.files,
      props.includeDownloadBag,
      props.detailed,
      isLoadingImage,
      props.downloadWrapper,
    ],
  );

  return (
    <div className="FilesView__ImagesGrid" style={props.style}>
      <ContributionsGridV2
        images={props.images.map((image) => {
          const thumb = props.context.getThumbnail(
            image,
            {
              onClick: () => {},
            },
            {
              onlyImage: true,
            },
          );
          if (!thumb.width || isNaN(thumb.width)) {
            thumb.width = 200;
            thumb.height = 200;
          }
          return thumb;
        })}
        loadingImages={props.loadingImages}
        isLoading={props.isLoading}
        onBottom={props.onBottom}
        onRender={onRender}
        fileType="Media"
        detailed={props.detailed}
        forceFileTypeLabel={props.detailed}
        context={props.context}
        gridSize={inGoogleDocs() ? 'small' : false}
      />
    </div>
  );
}

export default ImagesGrid;
