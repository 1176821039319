import React, { forwardRef, useEffect, useState } from 'react';
import './style.scss';
import { Button, Flex, Icon, Input, Popover } from 'elements_v2';
import { TextInput } from '@mantine/core';
import PixiIcon from '@pixi/elements/Icon';
import PixiButton from '@pixi/elements/Button';

export const SearchForm = forwardRef((props, ref) => {
  const className = ['Pixi', 'Pixi__Search', 'Pixi--clickable'];
  const inputRef = React.useRef();
  const [value, setValue] = useState(props.value);

  if (value) {
    className.push('Pixi__Search--active');
  }

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value || '');
    }
  }, [props.value]);

  function onChange(event) {
    if (!event.target.value) {
      props.onEmpty?.();
    }
    setValue(event.target.value);
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        event.stopPropagation();
        props.onSearch(value);
      }}
    >
      <TextInput
        value={value}
        placeholder={props.placeholder}
        onChange={(event) => onChange(event)}
        leftSection={<PixiIcon name="magnifying-glass" />}
        rightSection={
          value && (
            <PixiButton
              onClick={() => {
                props.onEmpty?.();
              }}
              variant="subtle"
              color="dark"
              size="xs"
              px="xs"
              style={{ overflow: 'visible' }}
              mr={2}
            >
              <PixiIcon name="xmark" />
            </PixiButton>
          )
        }
      />
    </form>
  );
});

const Search = forwardRef((props, ref) => {
  const className = ['Pixi', 'Pixi__Search', 'Pixi--clickable'];
  const inputRef = React.useRef();
  const [value, setValue] = useState(props.value);

  if (props.value) {
    className.push('Pixi__Search--active');
  }

  useEffect(() => {
    if (props.debounce) {
      const searchTimeout = setTimeout(() => {
        props.onChange(value);
      }, props.debounce || 500);
      return () => {
        clearTimeout(searchTimeout);
      };
    }
  }, [props.debounce, value]);

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
  }, [props.value]);

  function onChange(event) {
    if (!props.debounce) {
      props.onChange(event.target.value, event);
    } else {
      setValue(event.target.value);
    }
    if (!event.target.value) {
      props.onEmpty?.();
    }
  }

  return (
    <div className={className.join(' ')} style={props.style}>
      <Input
        icon={<Icon name="Search" />}
        active={!!props.value}
        disabled={props.disabled}
        field={
          <input
            className="Pixi"
            value={!props.debounce ? props.value || '' : value || ''}
            onChange={onChange}
            type="text"
            ref={ref || inputRef}
            placeholder={props.placeholder}
          />
        }
        largeStatusOnActive
        addonRight={
          props.options ? (
            <Popover
              position="bottom-end"
              trigger={
                <Button
                  inline
                  style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    minHeight: 39,
                  }}
                >
                  <Icon
                    name="CaretDownFill"
                    style={{ margin: 0, fontSize: 8 }}
                  />
                </Button>
              }
            >
              <Flex column style={{ gap: 5 }}>
                {props.options}
              </Flex>
            </Popover>
          ) : undefined
        }
        statusStyle={{
          pointerEvents: 'auto',
        }}
        status={
          props.value && (
            <Icon
              bubble={!!props.value}
              onClick={(event) => {
                if (props.value) {
                  event.preventDefault();
                  event.stopPropagation();
                  props.onChange('');
                }
              }}
              name="X"
            />
          )
        }
      />
    </div>
  );
});

export default Search;
