import React, { CSSProperties, MouseEvent, useEffect, useState } from 'react';
import {
  Box,
  BoxProps,
  MantineColor,
  MantineSize,
  useMantineTheme,
} from '@mantine/core';
import { IconName } from './IconName';
import './style.css';

export type PixiIconName = IconName;

export interface PixiIconProps extends Omit<BoxProps, 'name'> {
  name: IconName;
  size?: MantineSize | number;
  variant?: 'light' | 'regular' | 'filled' | 'duotone';
  fallback?: IconName;
  color?: MantineColor;
  svgStyle?: CSSProperties;
  onClick?: (event: MouseEvent) => void;
}

// Promise-based cache for SVG content
const svgCache: Record<string, Promise<string>> = {};

const SvgComponent: React.FC<
  {
    name: PixiIconName;
    src: string;
  } & BoxProps
> = ({ src, name, ...props }) => {
  const [svgContent, setSvgContent] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!svgCache[src]) {
      // Create a new fetch promise and store it in the cache
      svgCache[src] = fetch(src).then((response) => {
        if (response.ok) return response.text();
        throw new Error(`Failed to load SVG: ${response.statusText}`);
      });
    }

    svgCache[src].then(
      (data) => setSvgContent(data),
      (error) => {
        setError(error.message);
        console.error('There was a problem with the fetch operation:', error);
      },
    );
  }, [src]);

  // Render error state
  if (error) {
    return <>{name}</>;
  }

  // Render while loading
  if (!svgContent) {
    return <></>;
  }

  // Render the SVG with any additional props
  return (
    <Box
      {...props}
      className="PixiIconV2"
      dangerouslySetInnerHTML={{ __html: svgContent }}
    />
  );
};

const PixiIcon = React.forwardRef<HTMLDivElement, PixiIconProps>(
  (props: PixiIconProps, ref) => {
    const theme = useMantineTheme();
    const {
      name,
      size,
      variant,
      fallback,
      color,
      svgStyle,
      style,
      onClick,
      ...rest
    } = props;

    const iconSize =
      size === 'xl'
        ? 17
        : size === 'lg'
          ? 16
          : size === 'sm'
            ? 12
            : size === 'xs'
              ? 10
              : size || 16;

    return (
      <Box
        style={{
          minWidth: '1em',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: color
            ? theme.colors[color === 'primary' ? theme.primaryColor : color][6]
            : undefined,
          ...(style || {}),
        }}
        onClick={onClick}
        ref={ref}
        {...rest}
      >
        <SvgComponent
          fz={iconSize}
          name={name}
          style={{
            fill: 'currentColor',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...(svgStyle || {}),
          }}
          src={`https://cdn.pickit.com/fonts/fontawesome/svgs/${variant === 'filled' ? 'solid' : variant || 'regular'}/${name}.svg`}
        />
      </Box>
    );
  },
);

export default PixiIcon;
