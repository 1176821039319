import {
  ActionIcon,
  Alert,
  AlertProps,
  Card,
  CardProps,
  Group,
  Stack,
  ThemeIcon,
} from '@mantine/core';
import { ReactNode } from 'react';
import PixiIcon, { PixiIconName } from '../Icon';
import PixiText, { PixiTitle } from '../Text';

export interface MessageProps extends CardProps {
  title: ReactNode;
  icon?: PixiIconName;
  message?: ReactNode;
  onClose?: () => Promise<void> | void;
  actions?: ReactNode;
  toolbar?: ReactNode;
}
export default function Message({
  title,
  icon,
  message,
  actions,
  toolbar,
  onClose,
  ...rest
}: MessageProps) {
  return (
    <Card
      withBorder
      style={{ borderColor: 'var(--mantine-color-dark-2)' }}
      shadow="sm"
      bg="white"
      p="lg"
      {...rest}
    >
      <Stack w="100%" gap="sm">
        <Group wrap="nowrap" w="100%" gap="md" align="start">
          {icon && (
            <ThemeIcon
              variant="transparent"
              color="dark"
              size="md"
              style={{ flexShrink: 0 }}
            >
              <PixiIcon name={icon} size="md" />
            </ThemeIcon>
          )}
          <Stack w="100%" gap="md" mt={2}>
            <PixiTitle
              w="100%"
              order={4}
              style={{
                width: '100%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {title}
            </PixiTitle>
            {message && <PixiText size="sm">{message}</PixiText>}
            {!!actions && <Group gap="xs">{actions}</Group>}
          </Stack>
          <Group ml="auto" gap="xs" style={{ flexShrink: 0 }}>
            {toolbar}
            <ActionIcon
              variant="light"
              color="dark"
              size="md"
              onClick={() => onClose?.()}
            >
              <PixiIcon name="xmark" size="lg" />
            </ActionIcon>
          </Group>
        </Group>
      </Stack>
    </Card>
  );
}
