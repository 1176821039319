import React, { useEffect, useRef, useState } from 'react';
import './style.scss';

export default function Progress(props) {
  let percentage = props.percentage || (props.current / props.max) * 100;
  if (!props.noRounding) {
    percentage = Math.ceil(percentage);
  }
  const progress = useRef(null);
  const parent = useRef(null);
  useEffect(() => {
    if (progress.current) {
      if (props.infinite) {
        progress.current.style.transition = 'none';
        progress.current.style.width = `${
          parent.current.getBoundingClientRect().width -
          (parent.current.getBoundingClientRect().width % 28)
        }px`;
        progress.current.style.margin = 'auto';
      } else {
        progress.current.style.width = '';
        progress.current.style.transition = '';

        progress.current.style.margin = '';
      }
    }
  }, [progress.current, props.infinite]);
  const currentProgress = React.useMemo(() => {
    const style = {
      ...(props.currentStyle ?? {}),
    };
    if (props.countdown) {
      style.animationDuration = `${props.countdownDuration ?? props.max}s`;
    } else {
      style.maxWidth = `${props.infinite ? 100 : percentage}%`;
    }
    return (
      <div
        className={`Pixi Pixi__Progress__bar__current${
          props.infinite ? ' Pixi__Progress__bar__current--infinite' : ''
        }${props.countdown ? ' Pixi__Progress__bar__current--countdown' : ''}`}
        style={{
          ...style,
        }}
        ref={progress}
      >
        <span>
          {props.currentLabel ||
            (props.currentLabelPercentage && `${Math.floor(percentage)}%`)}
        </span>
      </div>
    );
  }, [percentage, props.infinte, props.countdownDuration /* , props.max */]);

  return (
    <div
      className={[
        'Pixi Pixi__Progress',
        ...(props.white ? ['Pixi__Progress--white'] : []),
      ].join(' ')}
      style={props.style}
    >
      {props.labelPosition === 'top' && (
        <span style={{ ...(props.maxLabelStyle ?? {}) }}>{props.maxLabel}</span>
      )}

      <div
        className="Pixi Pixi__Progress__bar"
        style={props.barStyle}
        ref={parent}
      >
        {currentProgress}
        {!props.labelPosition && (
          <span
            style={{ ...(props.maxLabelStyle ?? {}) }}
            className="Pixi__Progress__bar__current__label"
          >
            {props.maxLabel}
          </span>
        )}
      </div>
      {props.labelPosition === 'bottom' && (
        <span style={{ ...(props.maxLabelStyle ?? {}) }}>{props.maxLabel}</span>
      )}
    </div>
  );
}
