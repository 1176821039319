/* eslint-disable */
import React, { Component } from 'react';
import deepmerge from 'deepmerge';
import clone from 'clone';

import { analytics, Api, insert, Storage } from 'services';
import { getRandomInt, getRandomItemsFromArray } from 'utils/random';
import { openInNewTab } from 'utils/utils';
import {
  inAdobeAddon,
  inGoogleDocs,
  inOffice,
  inTeams,
  isAddin,
  isNative,
} from 'utils/platform';
import { differenceInCalendarDays, differenceInMinutes } from 'date-fns';
import { openLink } from 'utils';
import objectPath from 'object-path';
import localStorageWrapper from 'utils/localStorageWrapper';
import Bugsnag from '@bugsnag/js';
import { UserContext } from 'contexts/User';
import User from '@pixi/classes/User';

export const DEFAULT_CONTEXT = Object.freeze({
  cosmosProfile: {},
  djangoProfile: null,
  authenticated: false,
  forceCommunitySlug: null,
  cloudStorage: {
    boardRecentSearches: [],
    tooltipShown: {},
    onboarding: {
      subNav: {},
      explore: {},
      manage: {},
    },
    notificationsShown: {},
    preferences: {
      media: {},
      documents: {},
    },
    assets: {
      displayAs: 'list',
    },
    manage: {
      messages: {},
      documents: {},
      media: {},
      walkthrough_started: false,
      favoriteTags: [],
    },
    documents: {
      explore_visited: false,
      tutorialCompleted: {},
    },
    media: {
      explore_visited: false,
      tutorialCompleted: {},
    },
    saved: {
      brandassets: {
        files: [],
        collections: [],
      },
      media: {
        files: [],
        collections: [],
      },
      documents: {
        files: [],
        collections: [],
      },
      pickitStock: {
        files: [],
        collections: [],
      },
    },
    search: {
      popularKeywords: [],
      recentKeywords: [],
    },
    insertHistory: [],
    transfer: {},
    LIV: {
      accepted: false,
      insertAmount: 0,
      slugs: [],
      showPayment: false,
      type: 'Individual', // Used types: Individual, ProTrial
    },
    seen_documents: {},
    tutorialCompleted: {
      walkthrough: {},
    },
    following: {
      collections: [],
      latestCheck: new Date(),
    },
    approval: {
      latestState: {},
      latestCheck: new Date(),
    },
    notifications: [],
    hasNewNotifications: [],
  },
  maxInserts: 5, // Change this when changing type
  newPeriod: 7,
  spaces: null,
  collections: {
    following: [],
  },
  initialized: false,
  rotateTokenInProgress: false,
});

export const BACKGROUND_VARIATIONS = ['A', 'B', 'C', 'D', 'E'];
export const VARIATIONS = ['A', 'B'];
export const ONBOARDING_VARIATIONS = ['Upload', 'Create'];

export const STORAGE_KEYS = {
  TOKEN: 'userToken',
  PROFILE: 'userProfile',
  COSMOS_PROFILE: 'userCosmosProfile',
  FAVORITES: 'userFavorites',
  COLLECTIONS: 'userCollections',
  FOLLOWING_COLLECTIONS: 'userFollowingCollections',
  SPACES: 'userSpaces',
  SELECTED_SPACE: 'userSelectedSpace',
  TOKEN_EXPIRES: 'userTokenExpires',
  TEMP_VARIATION: 'userTemporaryVariation',
  BACKGROUND_VARIATION: 'userBackgroundVariation',
  CLOUD_STORAGE_CACHE: 'userCloudStorageCache',
};

/**
 * I fucking love JavaScript cloning
 */

export const UserRequest = {
  user: new Api.User(),
  community: new Api.Community(),
  manage: new Api.Manage(),
  customer: new Api.Customer(),
  guideline: new Api.Guideline(),
  policy: new Api.Policy(),
  pickit: new Api.Pickit({ channel: 'office_addin' }),
  demo: new Api.Community(),
  insight: new Api.Insight(),
  license: new Api.License(),
  proxy: new Api.Proxy(),
  files: new Api.Files(),
  flows: new Api.Flows(),
  documents: new Api.Files('documents'),
  media: new Api.Files('media'),
  brandassets: new Api.Files('brandassets'),
}

export class UserProvider extends Component {

  state = JSON.parse(JSON.stringify(DEFAULT_CONTEXT))
  
  request = UserRequest;

  rotateTokenInProgress = false;

  init = async () => {
    const djangoProfile = Storage.getItem(STORAGE_KEYS.PROFILE);
    const token = Storage.getItem(STORAGE_KEYS.TOKEN);
    const spaces = Storage.getItem(STORAGE_KEYS.SPACES);
    User.setToken(token);
    if (token) {
      try {
        await this.request.files.clearCache(djangoProfile.id);
      } catch (e) {}
      await this.setStateAsync({
        token,
        djangoProfile,
        spaces,
      });
      await this.checkToken(false);

      /**
       * These functions will fire before every request,
       * to check if users token is still valid, otherwise rotate.
       */
      this.request.user.preCheck = async () => await this.checkToken(false);
      this.request.community.preCheck = async () =>
        await this.checkToken(false);
      // this.request.pickit.preCheck = async () => await this.checkToken(false);
      this.request.demo.preCheck = async () => await this.checkToken(false);
      this.request.manage.preCheck = async () => await this.checkToken(false);
      this.request.insight.preCheck = async () => await this.checkToken(false);
      this.request.proxy.preCheck = async () => await this.checkToken(false);
      this.request.documents.preCheck = async () =>
        await this.checkToken(false);
      this.request.brandassets.preCheck = async () =>
        await this.checkToken(false);
      this.request.media.preCheck = async () => await this.checkToken(false);
      this.request.files.preCheck = async () => await this.checkToken(false);

      await this.initFiles();
    } else {
      this.setState({
        initialized: true,
      });
    }
  };

  initFiles = async (clearCache, forceCommunitySlug) => {
    const { spaces, token } = this.state;
    this.setRequestHeaders();
    try {
      if (forceCommunitySlug || this.state.forceCommunitySlug) {
        const initData = await this.request.files.init(
          clearCache,
          forceCommunitySlug
        );
        Bugsnag.addOnError((event) => {
          event.addMetadata('community', {
            slug: initData.selectedCommunity.communitySlug,
          });
        });
        await this.setStateAsync({
          data: initData,
          forceCommunitySlug,
        });
      } else if (spaces?.selected?.slug) {
        await this.selectSpace(spaces?.selected, true);

        this.request.files.communitySlug = spaces?.selected?.slug;
        this.request.files.setDefaultHeaders(this.getRequestHeaders());

        this.request.flows.setCommunitySlug(spaces?.selected?.slug);
        this.request.flows.setDefaultHeaders(this.getRequestHeaders());

        const initData = await this.request.files.init(clearCache);

        if (
          initData?.selectedCommunity?.communitySlug &&
          initData.user?.communities?.length
        ) {
          const stateData = {
            spaces: this.parseSpaces(
              initData.user.communities.map((com) => ({
                ...com.data.djangoCommunity,
                groups: com.groups,
              }))
            ),
            djangoProfile: initData.user.djangoProfile.profile,
            country: initData.user.djangoProfile.country,
            data: initData,
            authenticated: true
          };
          await this.setStateAsync(stateData);
          await this.setProfile(true);
        }
      } else if (token) {
        try {
          const initData = await this.request.files.init(clearCache);
          const communities = initData?.user?.communities;
          const communitiesWithPriority = communities?.filter(
            (c) =>
              !!c?.data?.priority?.length &&
              !!c?.data?.priority?.filter((p) =>
                p?.forDomain?.includes(
                  initData?.user?.djangoProfile?.profile?.email
                    ?.split('@')
                    ?.pop()
                )
              )?.length
          );
          if (communitiesWithPriority?.length) {
            const sortedCommunities = communitiesWithPriority.sort(
              (a, b) => b?.data?.priority - a?.data?.priority
            );
            const highestPrio = sortedCommunities[0];
            await this.selectSpace(
              spaces.companies.find(
                (space) => space.slug === highestPrio?.data?.communitySlug
              )
            );
            await this.setStateAsync({
              spaces: this.parseSpaces(
                initData.user.communities.map((com) => ({
                  ...com.data.djangoCommunity,
                  groups: com.groups,
                }))
              ),
              djangoProfile: initData.user.djangoProfile.profile,
              country: initData.user.djangoProfile.country,
              data: initData,
            });
          }
        } catch (e) {}
        await this.refreshProfile(false, true);
      }
    } catch (e) {
      console.error(e);
    }
    if (!clearCache) {
      this.initFiles(true, forceCommunitySlug);
    }
    this.setState({
      initialized: true,
      authenticated: true
    });
  };

  initializePersistedStorage = async (userId) => {
    let data = await this.request.files.getCloudStorage();
    data = deepmerge(DEFAULT_CONTEXT.cloudStorage, data);
    data = this.refreshCloudStorage(data);
    this.setState({
      cloudStorage: data,
    });
    return data;
  };

  refreshCloudStorage = (data) => {
    const popularKeywords = [
      'business',
      'people',
      'intro',
      'teamwork',
      'education',
      'office',
      'background',
      'work',
      'time',
      'technology',
      'science',
      'idea',
      'happy',
      'success',
      'meeting',
      'digital',
      'ecology',
      'food',
      'animal',
      'environment',
    ];
    if (!data.search.popularKeywords.length) {
      data.search.popularKeywords = getRandomItemsFromArray(popularKeywords, 4);
    } else {
      const newPopularKeywords = data.search.popularKeywords;
      let newPopularKeyword =
        popularKeywords[getRandomInt(popularKeywords.length)];
      while (newPopularKeywords.indexOf(newPopularKeyword) > -1) {
        newPopularKeyword =
          popularKeywords[getRandomInt(popularKeywords.length)];
      }
      newPopularKeywords[getRandomInt(newPopularKeywords.length)] =
        newPopularKeyword;
    }
    if (
      data.LIV.lastInsertMonth !== new Date().getMonth() &&
      data.LIV.type !== 'ProTrial' &&
      data.LIV.insertAmount !== 0 &&
      data.LIV.slugs.length !== 0
    ) {
      data.LIV.insertAmount = 0;
      data.LIV.slugs = [];
      this.request.files.saveCloudStorage(data);
    }
    return data;
  };

  setCloudStorageMultiple = async (values) => {
    const { cloudStorage } = this.state;
    Object.keys(values).forEach((path) => {
      const value = values[path];
      objectPath.set(cloudStorage, path, value);
    });
    this.setState({ cloudStorage });
    await this.request.files.saveCloudStorage(cloudStorage);
    // Storage.setItem(STORAGE_KEYS.CLOUD_STORAGE_CACHE, cloudStorage);
  };

  setCloudStorage = async (path, value) => {
    const { cloudStorage } = this.state;
    const prevValue = objectPath.get({ ...cloudStorage }, path);
    if (prevValue === value) {
      return;
    }
    objectPath.set(cloudStorage, path, value);
    this.setState({ cloudStorage });
    await this.request.files.saveCloudStorage(cloudStorage);
    // Storage.setItem(STORAGE_KEYS.CLOUD_STORAGE_CACHE, cloudStorage);
  };

  getCloudStorage = async () => {
    let data = await this.request.files.getCloudStorage();
    data = deepmerge(DEFAULT_CONTEXT.cloudStorage, data);
    data = this.refreshCloudStorage(data);
    await this.request.files.saveCloudStorage(data);
    // Storage.setItem(STORAGE_KEYS.CLOUD_STORAGE_CACHE, data);
    this.setState({
      cloudStorage: data,
    });
    return data;
  };

  resetCloudStorage = () => {
    const { cloudStorage } = DEFAULT_CONTEXT;
    this.setState({ cloudStorage });
    this.request.files.saveCloudStorage(cloudStorage);
    // Storage.setItem(STORAGE_KEYS.CLOUD_STORAGE_CACHE, cloudStorage);
  };

  checkToken = async (force) => {
    const { rotateTokenInProgress } = this;
    const { token } = this.state;
    const tokenExpires = Storage.getItem(STORAGE_KEYS.TOKEN_EXPIRES);

    /**
     * A request has already been made so we have to wait until we have the new token.
     */
    if (rotateTokenInProgress) {
      return this.waitForToken();
    }

    /**
     * tokenExpires is set when user connects or when the token has been rotated.
     * A token lasts 30 minutes.
     */
    if (tokenExpires < new Date().getTime() || force) {
      try {
        return this.rotateToken(token);
      } catch (e) {
        throw e;
      }
    }
  };

  publicApi = () => {
    const { spaces } = this.state;
    return spaces?.selected ? this.request.community : this.request.pickit;
  };

  userApi = () => {
    const { spaces } = this.state;
    return spaces?.selected ? this.request.community : this.request.user;
  };

  getToken = () => {
    if (!this.state.token) {
      return false;
    }
    const { token } = this.state;
    return token.jwt;
  };

  getRequestHeaders() {
    const { token } = this.state;
    if (!token) {
      return null;
    }
    return {
      Authorization: `JWT ${token.jwt}`,
    };
  }

  setRequestHeaders = () => {
    const { token, djangoProfile } = this.state;
    if (!token?.jwt) {
      return false;
    }
    if (token?.jwt) {
      this.request.user.setDefaultHeader('Authorization', `JWT ${token.jwt}`);
      this.request.community.setDefaultHeader(
        'Authorization',
        `JWT ${token.jwt}`
      );
      this.request.demo.setDefaultHeader('Authorization', `JWT ${token.jwt}`);
      this.request.insight.setDefaultHeader(
        'Authorization',
        `JWT ${token.jwt}`
      );
      this.request.manage.setDefaultHeader('Authorization', `JWT ${token.jwt}`);
      this.request.customer.setDefaultHeader(
        'Authorization',
        `JWT ${token.jwt}`
      );
      this.request.guideline.setDefaultHeader(
        'Authorization',
        `JWT ${token.jwt}`
      );
      this.request.policy.setDefaultHeader(
        'Authorization',
        `Bearer ${token.jwt}`
      );
      this.request.license.setDefaultHeader(
        'Authorization',
        `JWT ${token.jwt}`
      );
      this.request.proxy.setDefaultHeader('Authorization', `JWT ${token.jwt}`);
      this.request.documents.setDefaultHeader(
        'Authorization',
        `JWT ${token.jwt}`
      );
      this.request.brandassets.setDefaultHeader(
        'Authorization',
        `JWT ${token.jwt}`
      );
      this.request.media.setDefaultHeader('Authorization', `JWT ${token.jwt}`);
      this.request.files.setDefaultHeader('Authorization', `JWT ${token.jwt}`);
      return false;
    }
    if (token?.jwt) {
      this.request.customer.setDefaultHeader(
        'Authorization',
        `Bearer ${token.jwt}`
      );
      this.request.license.setDefaultHeader(
        'Authorization',
        `Bearer ${token.jwt}`
      );
      this.request.guideline.setDefaultHeader(
        'Authorization',
        `Bearer ${token.jwt}`
      );
      this.request.policy.setDefaultHeader(
        'Authorization',
        `Bearer ${token.jwt}`
      );
    }
    this.request.user.setDefaultHeader('Authorization', `JWT ${token.jwt}`);
    this.request.community.setDefaultHeader(
      'Authorization',
      `JWT ${token.jwt}`
    );
    this.request.demo.setDefaultHeader('Authorization', `JWT ${token.jwt}`);
    this.request.insight.setDefaultHeader('Authorization', `JWT ${token.jwt}`);
    this.request.manage.setDefaultHeader('Authorization', `JWT ${token.jwt}`);
    this.request.proxy.setDefaultHeader('Authorization', `JWT ${token.jwt}`);
    this.request.documents.setDefaultHeader(
      'Authorization',
      `JWT ${token.jwt}`
    );
    this.request.media.setDefaultHeader('Authorization', `JWT ${token.jwt}`);
    this.request.brandassets.setDefaultHeader(
      'Authorization',
      `JWT ${token.jwt}`
    );
    this.request.files.setDefaultHeader('Authorization', `JWT ${token.jwt}`);

    this.request.user.setDefaultHeader(
      'pickit-api-key',
      window.pickit.keys.PICKIT_API
    );
    this.request.community.setDefaultHeader(
      'pickit-api-key',
      window.pickit.keys.PICKIT_API
    );
    this.request.demo.setDefaultHeader(
      'pickit-api-key',
      window.pickit.keys.PICKIT_API
    );
    this.request.insight.setDefaultHeader(
      'pickit-api-key',
      window.pickit.keys.PICKIT_API
    );
    this.request.manage.setDefaultHeader(
      'pickit-api-key',
      window.pickit.keys.PICKIT_API
    );
    this.request.proxy.setDefaultHeader(
      'pickit-api-key',
      window.pickit.keys.PICKIT_API
    );
    this.request.documents.setDefaultHeader(
      'pickit-api-key',
      window.pickit.keys.PICKIT_API
    );
    this.request.brandassets.setDefaultHeader(
      'pickit-api-key',
      window.pickit.keys.PICKIT_API
    );
    this.request.media.setDefaultHeader(
      'pickit-api-key',
      window.pickit.keys.PICKIT_API
    );
    this.request.files.setDefaultHeader(
      'pickit-api-key',
      window.pickit.keys.PICKIT_API
    );

    if (djangoProfile?.content_filter) {
      this.request.pickit.contentFilter = djangoProfile?.content_filter;
      this.request.community.contentFilter = djangoProfile?.content_filter;
      this.request.user.contentFilter = djangoProfile?.content_filter;
      this.request.manage.contentFilter = djangoProfile?.content_filter;
    }
  };

  setContentFilter() {
    const { djangoProfile } = this.state;
    if (djangoProfile.content_filter !== 'all') {
      this.request.user.contentFilter = djangoProfile.content_filter;
      this.request.community.contentFilter = djangoProfile.content_filter;
      this.request.demo.contentFilter = djangoProfile.content_filter;
      this.request.pickit.contentFilter = djangoProfile.content_filter;
      this.request.manage.contentFilter = djangoProfile.content_filter;
    }
  }

  get followingCollections() {
    return Storage.getItem(STORAGE_KEYS.FOLLOWING_COLLECTIONS) || [];
  }

  getFollowingCollections = async () => {
    const collections = await this.request.user.getAllFollowingCollections(1);
    Storage.setItem(STORAGE_KEYS.FOLLOWING_COLLECTIONS, collections);
    return collections;
  };

  getCollections = async (refreshCache) => {
    const { djangoProfile } = this.state;
    try {
      let cachedCollections = this.getCachedCollections();
      if (!djangoProfile) {
        return false;
      }
      if (!cachedCollections || refreshCache) {
        cachedCollections = {
          page_nr: 0,
          data: [],
        };
      }
      if (cachedCollections.finished) {
        return cachedCollections.data;
      }
      const res = await this.request.user.getUserSavedCollections(
        djangoProfile.id,
        cachedCollections.page_nr + 1
      );
      if (cachedCollections.data) {
        cachedCollections.data = cachedCollections.data.concat(res.collections);
      } else {
        cachedCollections.data = res.collections;
      }
      Storage.setItem(STORAGE_KEYS.COLLECTIONS, {
        data: cachedCollections.data,
        page_nr: cachedCollections.page_nr + 1,
        finished: res.collections.length < 50,
      });
      return cachedCollections.data;
    } catch (e) {
      throw e;
    }
  };

  clearCachedCollections() {
    return Storage.removeItem(STORAGE_KEYS.COLLECTIONS);
  }

  getCachedCollections() {
    return Storage.getItem(STORAGE_KEYS.COLLECTIONS);
  }

  waitForToken() {
    return new Promise((resolve) => {
      const checkExist = setInterval(() => {
        const { rotateTokenInProgress } = this;
        if (!rotateTokenInProgress) {
          resolve(true);
          clearInterval(checkExist);
        }
      }, 100);
    });
  }

  rotateToken = async () => {
    const { rotateTokenInProgress } = this;
    const { djangoProfile, token } = this.state;
    if (!token?.jwt) {
      return false;
    }
    if (rotateTokenInProgress) {
      return this.waitForToken();
    }
    try {
      this.rotateTokenInProgress = true;
      clearTimeout(this.rotateTokenTimeout);
      // TODO: Change this to "proxy" when prototype is ready.
      const data = await fetch(
        `${window.pickit.config.MEDIA_URL}/proxy/rotate-token`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'pickit-api-key': window.pickit.keys.PICKIT_API,
          },
          body: JSON.stringify({
            refresh_token: token.jwt_refresh_token,
            rotate: 'jwt_refresh_token',
            user: {
              user_id: djangoProfile.id,
            },
          }),
        }
      );
      const newToken = await data.json();
      if (!data.ok) {
        throw newToken;
      }
      this.rotateTokenInProgress = false;
      await this.setStateAsync({
        token: {
          jwt: newToken.jwt,
          jwt_refresh_token: newToken.jwt_refresh_token,
          simple_token: newToken.simple_token,
        },
      });
      this.setRequestHeaders();
      Storage.setItem(STORAGE_KEYS.TOKEN, {
        jwt: newToken.jwt,
        jwt_refresh_token: newToken.jwt_refresh_token,
        simple_token: newToken.simple_token,
      });
      Storage.setItem(
        STORAGE_KEYS.TOKEN_EXPIRES,
        new Date().getTime() + 28 * 60000
      );
      return newToken;
    } catch (e) {
      this.logout();
    }
  };

  refreshStorageToken = async () => {
    const tokens = await this.request.files.getTokens();
    this.setState({
      data: {
        ...this.state.data,
        storage: tokens,
      },
    });
  };

  refreshProfile = async (triedRotate, fromInit) => {
    const url = `${window.pickit.config.MEDIA_URL}/proxy/profile`;
    let connect;
    try {
      const data = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'pickit-api-key': window.pickit.keys.PICKIT_API,
          ...this.getRequestHeaders(),
        },
      });
      connect = await data.json();
      if (!data.ok) {
        throw connect;
      }
      const stateData = {
        spaces: this.parseSpaces(connect.spaces),
        djangoProfile: connect.profile,
        country: connect.country,
      };
      if (!fromInit) {
        try {
          await this.request.files.clearCache(stateData.djangoProfile.id);
          await this.initFiles(true);
        } catch (e) {
          console.error(e);
        }
      }
      await this.setStateAsync(stateData);
      await this.setProfile(true);

      return stateData;
    } catch (e) {
      if (!triedRotate) {
        await this.rotateToken();
        return this.refreshProfile(true);
      }

      if (connect.error === 'access-token-error') {
        console.error('access-token-error');
        localStorageWrapper.clear();
        window.location.reload();
      } else {
        throw e;
      }
    }
  };

  connect = async (type, body, settings) => {
    const url = `${window.pickit.config.MEDIA_URL}/proxy/connect/${type}`;
    try {
      if (body.mode === 'setup') {
        body.sign_up_source = 'azure_marketplace';

        if (body?.scenario === 'dropbox') {
          body.sign_up_source = 'dropbox';
        }
        if (body?.scenario === 'shutterstock') {
          body.sign_up_source = 'shutterstock';
        }

        if (inOffice()) {
          body.sign_up_source = 'office_addin';
        }
        if (inTeams()) {
          body.sign_up_source = 'microsoft_teams';
        }
        if (inGoogleDocs()) {
          body.sign_up_source = 'google_addon';
        }
      }
      const data = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-api-key': window.pickit.keys.PICKIT_API,
        },
        body: JSON.stringify({
          ...body,
          token_length: 60 * 2,
        }),
      });
      const connect = await data.json();
      if (!data.ok) {
        throw connect;
      }
      const token = {
        jwt: connect.jwt,
        jwt_refresh_token: connect.jwt_refresh_token,
        simple_token: connect.simple_token,
      };
      User.setToken(token);
      await this.setStateAsync({
        djangoProfile: connect.profile,
      });
      await this.setStateAsync({
        country: connect.country,
      });
      await this.setStateAsync({
        token,
        spaces: this.parseSpaces(connect.spaces, settings),
      });
      Storage.setItem(STORAGE_KEYS.TOKEN, token);
      Storage.setItem(
        STORAGE_KEYS.TOKEN_EXPIRES,
        new Date().getTime() + 30 * 60000
      );
      await this.initFiles();
      await this.setProfile();
      await this.rotateToken();
      return connect;
    } catch (e) {
      throw e;
    }
  };

  connectToken = async (token, redirect, community) => {
    Storage.setItem(STORAGE_KEYS.SELECTED_SPACE, community);
    await this.connect('pickit', {
      keep_me_logged_in: true,
      mode: 'login',
      simple_token: token,
    });
    window.location.hash = redirect;
    return false;
  };

  setStateAsync = (state) => {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  };

  /**
   * Update the djangoProfile object.
   * @param {string} path Target specific value in profile using dot notation. Example: "pickit.metadata.value"
   * @param {string} value The new value
   */
  updateDjangoProfile = async (path, value) => {
    const { djangoProfile } = this.state;
    objectPath.set(djangoProfile, path, value);
    await this.setDjangoProfile(djangoProfile);
  };

  setDjangoProfile = async (djangoProfile) => {
    await this.setStateAsync({
      djangoProfile,
    });
    this.setContentFilter();
    Storage.setItem(STORAGE_KEYS.PROFILE, djangoProfile);
  };

  setProfile = async () => {
    const { spaces, djangoProfile } = this.state;
    this.setRequestHeaders();

    this.request.user.djangoProfile = djangoProfile;
    if (spaces.demo) {
      this.request.demo.communitySlug = spaces.demo.slug;
      this.request.proxy.communitySlug = spaces.demo.slug;
      this.request.documents.communitySlug = spaces.demo.slug;
      this.request.brandassets.communitySlug = spaces.demo.slug;
      this.request.media.communitySlug = spaces.demo.slug;
      this.request.files.communitySlug = spaces.demo.slug;
    }
    if (spaces?.selected) {
      this.request.community.communitySlug = spaces?.selected?.slug;
      this.request.manage.communitySlug = spaces?.selected?.slug;
      this.request.proxy.communitySlug = spaces?.selected?.slug;
      this.request.documents.communitySlug = spaces?.selected?.slug;
      this.request.brandassets.communitySlug = spaces?.selected?.slug;
      this.request.media.communitySlug = spaces?.selected?.slug;
      this.request.files.communitySlug = spaces?.selected?.slug;
    }
    this.initializePersistedStorage(djangoProfile?.id);

    const stateToSet = {
      djangoProfile,
      spaces,
      // Not perfect. does not take Customer.settings.ENDED into account but it's the best we can do for now
      isLimited:
        djangoProfile?.reason_not_active === 'trial-ended' ||
        spaces?.selected?.subscription?.reason_not_active === 'trial-ended' ||
        (spaces?.selected && !spaces?.selected?.active),
    };

    await this.setStateAsync(stateToSet);
    await this.setStateAsync({
      authenticated: !!djangoProfile,
      initialized: true,
    });
    Storage.setItem(STORAGE_KEYS.PROFILE, djangoProfile);
  };

  refreshSpaces = async () => {
    let { spacesRaw } = this.state;
    if (!spacesRaw) {
      spacesRaw = await this.request.user.getSpaces();
    }
    const parsedSpaces = this.parseSpaces(spacesRaw);
    this.setState({
      spaces: parsedSpaces,
      spacesRaw,
    });
    if (parsedSpaces?.selected) {
      await this.selectSpace(parsedSpaces?.selected);
    }
    return parsedSpaces;
  };

  selectCommunityFromSlug = async (communitySlug) => {
    const space = this.state?.spaces?.companies?.find(
      (space) => space.slug === communitySlug
    );
    if (!space) {
      return false;
    }
    this.selectSpace(space);
    return true;
  };

  selectSpace = async (space) => {
    const { spaces } = this.state;
    spaces.selected = space;
    this.request.community.communitySlug = space.slug;
    this.request.manage.communitySlug = space.slug;
    this.request.proxy.communitySlug = space.slug;
    this.request.documents.communitySlug = space.slug;
    this.request.media.communitySlug = space.slug;
    this.request.files.communitySlug = space.slug;

    await this.setStateAsync({ spaces, isConnecting: true });

    Storage.setItem(STORAGE_KEYS.SPACES, spaces);
    Storage.setItem(STORAGE_KEYS.SELECTED_SPACE, space.slug);
    await this.setStateAsync({ isConnecting: false });
    return spaces;
  };

  isExternalSpace = (space) => {
    const permissions = space.groups.map((group) => group.split('_').pop());
    return (
      permissions.includes('limbo') &&
      !permissions.includes('admin') &&
      !permissions.includes('user')
    );
  };

  parseSpaces = (spacesRawData, settings) => {
    const { djangoProfile } = this.state;
    const spaces = {};

    if (djangoProfile?.id === 211683) {
      const demoIndex = spacesRawData.findIndex(
        (space) => space.is_demo && space.slug === 'demoaccount2'
      );
      spacesRawData[demoIndex].is_demo = false;
    }

    const activeSpaces = spacesRawData.filter(
      (space) => !space.is_demo && space.active
    );
    spaces.demo = spacesRawData.find((space) => space.is_demo) || false;
    spaces.tempCompany = spacesRawData.find(
      (space) => !space.is_demo && !space.is_demo_disabled
    );
    spaces.companies = activeSpaces || [];
    spaces.proTrialCompany = spacesRawData.find(
      (space) => !space.is_demo_disabled && !space.is_demo && space.active
    );
    spaces.activeCompanies = spacesRawData.filter(
      (space) => space.is_demo_disabled && !space.is_demo && space.active
    );
    spaces.inactiveCompanies = spacesRawData.filter(
      (space) => space.is_demo_disabled && !space.is_demo && !space.active
    );
    spaces.selected = null;
    if (spaces.activeCompanies.length === 1 && !spaces?.selected) {
      spaces.selected = spaces.activeCompanies[0];
    }
    if (
      activeSpaces.length === 1 &&
      !spaces?.selected &&
      !this.isExternalSpace(activeSpaces[0])
    ) {
      spaces.selected = activeSpaces[0];
    }

    const suggestedDomain = djangoProfile?.email
      ?.split('@')
      ?.pop()
      ?.split('.')?.[0];
    const suggestedSpace = activeSpaces.find(
      (space) =>
        (space?.host === suggestedDomain || space?.slug === suggestedDomain) &&
        !this.isExternalSpace(space)
    );

    if (suggestedSpace && !spaces.selected) {
      spaces.selected = suggestedSpace;
    }

    if (
      settings?.autoSelectCompany &&
      spaces.activeCompanies.length &&
      !this.isExternalSpace(spaces.activeCompanies[0])
    ) {
      spaces.selected = spaces.activeCompanies[0];
    }

    // Select community for user who auth as a limbo user
    if (settings?.forCommunity && settings.forCommunity.length) {
      spaces.selected = spaces.activeCompanies.filter(
        (space) =>
          space.slug === settings.forCommunity ||
          space.host === settings.forCommunity
      )[0];
    }

    if (djangoProfile && djangoProfile.username === 'demo@pickit.com') {
      spaces.demo = false;
      spaces.companies = [
        {
          active: true,
          is_demo: false,
          is_demo_disabled: true,
          name: 'demoaccount2',
          slug: 'demoaccount2',
        },
      ];
      spaces.selected = spaces.companies[0];
    }

    const storageSpace = Storage.getItem(STORAGE_KEYS.SELECTED_SPACE);
    const findStorageSpace = spaces.companies.find(
      (space) => space.host === storageSpace || space.slug === storageSpace
    );

    if (storageSpace && findStorageSpace) {
      spaces.selected = findStorageSpace;
    }

    Storage.setItem(STORAGE_KEYS.SPACES, spaces);

    return spaces;
  };

  /**
   * Update the space.selected object.
   * @param {string} path Target specific value in the space using dot notation. Example: "pickit.metadata.value"
   * @param {string} value The new value
   */
  updateSelectedSpace = async (path, value) => {
    const { selected: selectedSpace } = this.state.spaces;
    objectPath.set(selectedSpace, path, value);

    await this.selectSpace(selectedSpace);
  };

  logout = async () => {
    const { djangoProfile, token } = this.state;
    // make sure nothing runs while logging out
    this.setState({
      initialized: false,
      authenticated: false,
    });
    if (token?.jwt) {
      // block tokens for security reasons
      try {
        await fetch(
          `${window.pickit.config.PROXY_PROTO}://${window.pickit.config.PROXY_URL}/api/rotate-token`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'pickit-api-key': window.pickit.keys.PICKIT_API,
            },
            body: JSON.stringify({
              refresh_token: token.jwt_refresh_token,
              access_token: token.jwt,
            }),
          }
        );
      } catch (e) {}
    }

    Storage.removeItem(STORAGE_KEYS.TOKEN);
    Storage.removeItem(STORAGE_KEYS.PROFILE);
    Storage.removeItem(STORAGE_KEYS.COSMOS_PROFILE);
    Storage.removeItem(STORAGE_KEYS.FAVORITES);
    Storage.removeItem(STORAGE_KEYS.FOLLOWING_COLLECTIONS);
    Storage.removeItem(STORAGE_KEYS.SPACES);
    Storage.removeItem(STORAGE_KEYS.COLLECTIONS);
    // Storage.removeItem(STORAGE_KEYS.CLOUD_STORAGE_CACHE);
    localStorageWrapper.removeItem('microsoftProfileToken');
    localStorageWrapper.removeItem('SELECTED_ACCOUNT');
    localStorageWrapper.removeItem('auth_code');

    this.request.community.communitySlug = '';
    this.request.manage.communitySlug = '';
    this.request.proxy.communitySlug = '';
    this.request.documents.communitySlug = '';
    this.request.media.communitySlug = '';
    this.setState({
      ...DEFAULT_CONTEXT,
      initialized: true,
    });
    window.location.reload();
  };

  generateRedirectUrl = (path) => {
    const { token } = this.state;
    if (!token) {
      return false;
    }
    const setPath = path || window.location.hash.replace('#/', '');
    return `${window.location.origin}/#${setPath}?auth=${token.simple_token}&user_id=${this.state.djangoProfile?.id}&community=${this.state.spaces?.selected?.slug}&clear_cache=1`;
  };

  openInWeb = (path = '', newTab) => {
    let url;
    if (!path.includes('http')) {
      url = `${window.location.origin}/#${path}`;
    } else {
      url = path;
    }
    if (inTeams()) {
      newTab = true;
    }
    if (!isAddin() && !isNative()) {
      if (newTab) {
        return openInNewTab(url);
      }
      window.location.href = url;
      return false;
    }
    if (inGoogleDocs()) {
      openLink(url);
      return;
    }
    let auth;
    if (url.includes('?')) {
      auth = this.generateAuth('&');
    } else {
      auth = this.generateAuth();
    }
    if (inTeams() || inAdobeAddon() || isNative()) {
      openLink(`${url}${auth}`);
    }

    window.Office.context.ui.openBrowserWindow(`${url}${auth}`);
  };

  generateAuth = (start = '?') => {
    return `${start}auth=${this.state.token.simple_token}&user_id=${this.state.djangoProfile?.id}&community=${this.state.spaces?.selected?.slug}&clear_cache=1`;
  };

  tempDataSet = () => {
    const { djangoProfile } = this.state;
    this.setCloudStorage(
      '_temp',
      JSON.stringify({
        djangoProfile,
      })
    );
  };

  leaveCommunity = async (slug) => {
    await this.request.proxy.leaveCommunity(slug);
    await this.refreshProfile();
  };

  updateCommunity = async (data) => {
    await this.request.proxy.updateCommunityV2(data);
    await this.refreshProfile();
  };

  getLimitedInsertsLeft = () => {
    return this.state.maxInserts - this.state.cloudStorage.LIV.insertAmount;
  };

  hasInsertsLeft = () => {
    return this.state.cloudStorage.LIV.insertAmount < this.state.maxInserts;
  };

  increaseLimitedInsertions = (slug) => {
    if (
      !this.hasInsertsLeft() ||
      (this.state.cloudStorage.LIV.slugs &&
        this.state.cloudStorage.LIV.slugs.find((s) => s === slug))
    )
      return;
    const newTotal = this.state.cloudStorage.LIV.insertAmount + 1;
    this.setCloudStorage('LIV.insertAmount', newTotal);
    let slugs = this.state.cloudStorage.LIV?.slugs;
    if (!slugs) slugs = [];
    this.setCloudStorage('LIV.slugs', [...slugs, slug]);
    this.setCloudStorage('LIV.lastInsertMonth', new Date().getMonth());
    if (newTotal === Number(this.state.maxInserts)) {
      this.setLIVError(new Error('insert limit reached'));
    }
  };

  setLIVError = (error) => {
    this.setState({ LIVError: error });
  };

  showLIVPayment = (value) => {
    this.setState({ LIVPayment: value });
  };

  canInsert = (slug) => {
    return (
      this.state.cloudStorage.LIV.accepted &&
      (this.hasInsertsLeft() ||
        !this.state.cloudStorage.LIV.slugs ||
        this.state.cloudStorage.LIV.slugs.find((s) => s === slug))
    );
  };

  acceptLIV = () => {
    this.setCloudStorage('LIV.accepted', true);
  };

  setMaxInsert = async (amount) => {
    await this.setStateAsync({ maxInserts: amount });
  };

  enableForceLIV = () => {
    this.setState({ forceLIV: true });
  };

  setLIVType = (newType) => {
    let { maxInserts } = this.state;

    switch (newType) {
      case 'ProTrial':
        maxInserts = 25;
        this.setCloudStorage('LIV.type', newType);
        break;

      default:
        break;
    }

    this.setState({ maxInserts });
  };

  setNewDocuments = (documents, collections) => {
    const { cloudStorage, newPeriod } = this.state;
    const originalValues = clone(cloudStorage.seen_documents);
    const updatedValues = {};
    // Remove any document that no longer is considered new
    Object.keys(originalValues).forEach((key) => {
      if (
        differenceInCalendarDays(
          new Date(),
          new Date(originalValues[key].createdAt)
        ) <= newPeriod ||
        differenceInCalendarDays(
          new Date(),
          new Date(originalValues[key].updatedAt)
        ) <= newPeriod
      ) {
        updatedValues[key] = originalValues[key];
      }
    });
    if (documents) {
      // Append new documents that are considered new
      documents.forEach((document) => {
        // if the field exists we know it has been loaded/added once so no need to change it
        if (
          (differenceInCalendarDays(new Date(), new Date(document.createdAt)) <
            newPeriod &&
            !updatedValues[document._id]) ||
          (differenceInCalendarDays(new Date(), new Date(document.updatedAt)) <
            newPeriod &&
            (!updatedValues[document._id] ||
              updatedValues[document._id].updatedAt !== document.updatedAt))
        ) {
          /*
              Using a timed badge makes sure that any state changes, reloads and/or errors accidentaly removes the badge
              because it is the second time the document is loaded.
              */
          updatedValues[document._id] = {
            createdAt: document.createdAt,
            updatedAt: document.updatedAt,
            seenAt: new Date().toISOString(),
          };
        }
      });
    }
    // Remove this part to diable collection badge quickly
    if (collections) {
      const allCollections = [...collections.normal, ...collections.hero];
      allCollections.forEach((collection) => {
        // if the field exists we know it has been loaded/added once so no need to change it
        if (
          (differenceInCalendarDays(
            new Date(),
            new Date(collection.createdAt)
          ) < newPeriod &&
            !updatedValues[collection._id]) ||
          (differenceInCalendarDays(
            new Date(),
            new Date(collection.updatedAt)
          ) < newPeriod &&
            (!updatedValues[collection._id] ||
              updatedValues[collection._id].updatedAt !== collection.updatedAt))
        ) {
          /*
        Using a timed badge makes sure that any state changes, reloads and/or errors accidentaly removes the badge
        because it is the second time the document is loaded.
        */
          updatedValues[collection._id] = {
            createdAt: collection.createdAt,
            updatedAt: collection.updatedAt,
            seenAt: new Date().toISOString(),
          };
        }
      });
    }

    this.setCloudStorage('seen_documents', updatedValues);
  };

  isNewDoc = (document) => {
    // Why using time? check function setNewDocuments
    const { newPeriod } = this.state;
    const { seen_documents } = this.state.cloudStorage;
    if (seen_documents[document._id] && seen_documents[document._id].isOpened)
      return false;
    return (
      seen_documents[document._id] &&
      differenceInCalendarDays(new Date(), new Date(document.createdAt)) <
        newPeriod &&
      differenceInMinutes(
        new Date(),
        new Date(seen_documents[document._id].seenAt)
      ) < 3
    );
  };

  isUpdatedDoc = (document) => {
    // Why using time? check function setNewDocuments
    const { newPeriod } = this.state;
    const { seen_documents } = this.state.cloudStorage;
    if (seen_documents[document._id] && seen_documents[document._id].isOpened)
      return false;
    return (
      seen_documents[document._id] &&
      differenceInCalendarDays(new Date(), new Date(document.updatedAt)) <
        newPeriod &&
      differenceInMinutes(
        new Date(),
        new Date(seen_documents[document._id].seenAt)
      ) < 3
    );
  };

  setIsDocumentOpened = (document) => {
    const { seen_documents } = this.state.cloudStorage;
    const documents = clone(seen_documents);

    if (documents[document._id]) {
      documents[document._id].isOpened = true;
    }
    this.setCloudStorage('seen_documents', documents);
  };

  setNotificationFollows = (newValue) => {
    this.setCloudStorage('following', newValue);
  };

  setNotifications = (notifications) => {
    this.setCloudStorage('notifications', notifications);
  };

  setApproval = (approval) => {
    this.setCloudStorage('approval', approval);
  };

  /**
   * @param {boolean} newState Value is used to check if there are new notifications and is primarily
   *                           used to display the notification badge
   */
  setHasNewNotifications = (enable) => {
    const { spaces } = this.state;
    const { slug } = spaces.selected;
    let newState;
    if (!Array.isArray(this.state?.cloudStorage?.hasNewNotifications)) {
      newState = [];
    } else {
      newState = [...this.state?.cloudStorage?.hasNewNotifications];
    }
    if (enable) {
      newState = newState.concat(slug);
    } else {
      newState = newState.filter((slug_) => slug_ !== slug);
    }

    this.setCloudStorage('hasNewNotifications', newState);
  };

  saveUserSettingsPartial = async (settings) => {
    const data = await this.request.files.saveUserSettingsPartial(this.state.data.user._id, settings);
    this.setState({
      data: {
        ...this.state.data,
        user: { ...data.user },
      },
    });
  };
  saveLibrary = async (libraryId, data, arrayFilter) => {
    const newData = await this.request.files.saveLibrary(libraryId, data, arrayFilter);
    this.setState({
      data: {
        ...this.state.data,
        selectedCommunity: { ...newData.community },
      },
    });
  };
  saveSettingsPartial = async (settings, advancedFind) => {
    const data = await this.request.files.saveSettingsPartial(settings, advancedFind);
    this.setState({
      data: {
        ...this.state.data,
        selectedCommunity: { ...data.community },
      },
    });
  };
  saveProperty = async (settings, advancedFind) => {
    const data = await this.request.files.saveProperty(settings, advancedFind);
    this.setState({
      data: {
        ...this.state.data,
        selectedCommunity: { ...data.community },
      },
    });
    return data.property;
  };

  saveSettings = async (settings) => {
    const data = await this.request.files.saveSettings(settings);
    this.setState({
      data: {
        ...this.state.data,
        selectedCommunity: { ...data.community },
      },
    });
  };

  render() {
    const values = {
      ...this.state,
      saveSettings: this.saveSettings,
      saveSettingsPartial: this.saveSettingsPartial,
      saveProperty: this.saveProperty,
      saveLibrary: this.saveLibrary,
      settings: this.state.data?.selectedCommunity?.settings,
      init: this.init,
      resetInit: this.resetInit,
      leaveCommunity: this.leaveCommunity,
      updateDjangoProfile: this.updateDjangoProfile,
      setDjangoProfile: this.setDjangoProfile,
      selectSpace: this.selectSpace,
      refreshSpaces: this.refreshSpaces,
      updateSelectedSpace: this.updateSelectedSpace,
      setProfile: this.setProfile,
      connect: this.connect,
      logout: this.logout,
      request: this.request,
      updateCommunity: this.updateCommunity,
      publicApi: this.publicApi,
      userApi: this.userApi,
      getCollections: this.getCollections,
      getCachedCollections: this.getCachedCollections,
      getFollowingCollections: this.getFollowingCollections,
      clearCachedCollections: this.clearCachedCollections,
      generateRedirectUrl: this.generateRedirectUrl,
      generateAuth: this.generateAuth,
      getCloudStorage: this.getCloudStorage,
      setCloudStorage: this.setCloudStorage,
      resetCloudStorage: this.resetCloudStorage,
      setCloudStorageMultiple: this.setCloudStorageMultiple,
      getRequestHeaders: this.getRequestHeaders,
      getToken: this.getToken,
      saveUserSettingsPartial: this.saveUserSettingsPartial,
      connectToken: this.connectToken,
      tempDataInit: this.tempDataInit,
      tempDataSet: this.tempDataSet,
      refreshProfile: this.refreshProfile,
      rotateToken: async () => this.checkToken(true),
      getLimitedInsertsLeft: this.getLimitedInsertsLeft,
      hasInsertsLeft: this.hasInsertsLeft,
      increaseLimitedInsertions: this.increaseLimitedInsertions,
      setLIVError: this.setLIVError,
      canInsert: this.canInsert,
      acceptLIV: this.acceptLIV,
      setMaxInsert: this.setMaxInsert,
      enableForceLIV: this.enableForceLIV,
      setLIVType: this.setLIVType,
      checkToken: this.checkToken,
      showLIVPayment: this.showLIVPayment,
      setRequestHeaders: this.setRequestHeaders,
      setNewDocuments: this.setNewDocuments,
      isNewDoc: this.isNewDoc,
      isUpdatedDoc: this.isUpdatedDoc,
      initFiles: this.initFiles,
      setIsDocumentOpened: this.setIsDocumentOpened,
      openInWeb: this.openInWeb,
      setNotificationFollows: this.setNotificationFollows,
      setNotifications: this.setNotifications,
      setHasNewNotifications: this.setHasNewNotifications,
      setStateAsync: this.setStateAsync,
      isExternalSpace: this.isExternalSpace,
      setApproval: this.setApproval,
      selectCommunityFromSlug: this.selectCommunityFromSlug,
    };

    analytics.setUserContext(values);
    insert.setUserContext(values);

    this.request.user.userContext = values;
    this.request.community.userContext = values;
    this.request.demo.userContext = values;
    this.request.pickit.userContext = values;
    this.request.manage.userContext = values;
    this.request.proxy.userContext = values;
    this.request.files.userContext = values;
    this.request.documents.userContext = values;
    this.request.media.userContext = values;
    this.request.brandassets.userContext = values;

    return (
      <UserContext.Provider value={values}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
