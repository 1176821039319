import { useFlowsContext } from 'hooks';
import { useReducer, useEffect, useState } from 'react';
import { FlowData, InterfaceFlowStep } from 'services/api/Flows';

import { uniqueId } from 'utils';
import {
  Button,
  Confirm,
  Dropdown,
  Flex,
  Icon,
  List,
  Popup,
} from 'elements_v2';
import Input from 'elements_v2/Input/index_ts';

import SelectUsersDropdown from 'components/SelectUsersDropdown';
import SelectUserGroupsDropdown from 'components/SelectUserGroupsDropdown';
import { ToastService } from 'elements/Toast';

type ReducerAction = {
  action:
    | 'reset'
    | 'replace'
    | 'addStep'
    | 'removeStep'
    | 'updateStep'
    | 'changeName'
    | 'addStepGroup'
    | 'moveStepGroupUp'
    | 'moveStepGroupDown';
  index?: number;
  id?: string;
  value?: any;
};

const defaultPayload: FlowData = {
  _id: `${uniqueId()}_TEMP`,
  name: '',
  flowType: 'approvals',
  steps: [
    {
      // Mock id. delete before sending to BE
      _id: `${uniqueId()}_TEMP`,
      name: 'Admin approval',
      order: 0,
      responsibility: { responsibilityType: 'admins' },
    },
  ],
};
type Props = {
  isOpen: boolean;
  editingFlow?: FlowData;
  onClose: () => void;
};
const EditFlow = (props: Props) => {
  const { isOpen, editingFlow, onClose } = props;
  const Flows = useFlowsContext();
  const [editingStep, setEditingStep] = useState<undefined | number>(undefined);

  const [content, setContent] = useState<
    undefined | Array<undefined | Array<InterfaceFlowStep>>
  >(undefined);

  const [flowPayload, setPayload] = useReducer(
    (prevState: FlowData, action: ReducerAction): FlowData => {
      let newState = { ...prevState };
      const { index } = action;
      let tmpId;
      switch (action.action) {
        case 'addStep':
          tmpId = `${uniqueId()}_TEMP`;
          const groupHasAdminApproval = !!newState.steps.find(
            (step) =>
              step.responsibility.responsibilityType === 'admins' &&
              step.order === action.index,
          );
          newState.steps.push({
            // Mock id. delete before sending to BE
            _id: tmpId,
            name: groupHasAdminApproval ? 'Groups' : 'Admin approval',
            order: action.index ?? getOrderMax(newState.steps),
            responsibility: {
              responsibilityType: groupHasAdminApproval ? 'groups' : 'admins',
            },
          });
          break;

        case 'addStepGroup':
          const addAt = getOrderMax(newState.steps) + 1;
          tmpId = `${uniqueId()}_TEMP`;
          newState.steps.push({
            // Mock id. delete before sending to BE
            _id: tmpId,
            name: 'Admin approval',
            order: addAt,
            responsibility: { responsibilityType: 'admins' },
          });
          setEditingStep(addAt);
          break;

        case 'removeStep':
          const removedOrder = newState.steps.find(
            (step) => step._id === action.id,
          )?.order;
          newState.steps = newState.steps.filter(
            (step) => step._id !== action.id,
          );
          const groupRemoved = !newState.steps.find(
            (step) => step.order === removedOrder,
          );
          if (groupRemoved && typeof removedOrder === 'number') {
            newState.steps = newState.steps.map((step) => {
              if (step.order > removedOrder) {
                step.order -= 1;
              }
              return step;
            });
            setEditingStep(undefined);
          }
          break;

        case 'updateStep':
          if (action.id) {
            newState.steps.map((step) => {
              if (step._id === action.id) {
                step = action.value;
              }
              return step;
            });
          }
          break;

        case 'changeName':
          newState.name = action.value;
          break;

        case 'reset':
          const newPayload = { ...JSON.parse(JSON.stringify(defaultPayload)) };
          newPayload._id = `${uniqueId()}_TEMP`;
          newPayload.steps[0]._id = `${uniqueId()}_TEMP`;
          newState = newPayload;
          break;

        case 'replace':
          newState = action.value;
          break;

        case 'moveStepGroupUp':
          if (typeof index === 'number') {
            newState.steps.forEach((step) => {
              if (step.order === action.index) {
                step.order -= 1;
              } else if (step.order === index - 1) {
                step.order += 1;
              }
            });
          }
          break;

        case 'moveStepGroupDown':
          if (typeof index === 'number') {
            newState.steps.forEach((step) => {
              if (step.order === action.index) {
                step.order += 1;
              } else if (step.order === index + 1) {
                step.order -= 1;
              }
            });
          }
          break;

        default:
          break;
      }
      newState.steps.sort((a, b) => {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
      return newState;
    },
    { ...JSON.parse(JSON.stringify(defaultPayload)) },
  );
  useEffect(() => {
    Flows?.getFlows();
  }, []);
  useEffect(() => {
    if (editingFlow) {
      setPayload({ action: 'replace', value: editingFlow });
    } else {
      setPayload({ action: 'reset' });
    }
  }, [editingFlow]);

  function getOrderMax(steps: InterfaceFlowStep[]) {
    const highestStep = steps.reduce((value, step) => {
      if (step.order > value.order) return step;
      return value;
    });
    return highestStep.order;
  }
  useEffect(() => {
    if (flowPayload.steps) {
      const { steps } = flowPayload;
      steps.sort((a, b) => a.order - b.order);
      let contentLength = 0;
      steps.forEach((step) => {
        if (step.order > contentLength) contentLength = step.order;
      });

      const content = Array<undefined | Array<InterfaceFlowStep>>(
        contentLength,
      ).fill(undefined);

      steps.forEach((step) => {
        if (!content[step.order]) {
          content[step.order] = [];
        }
        content[step.order]?.push(step);
      });
      setContent(content);
    }
  }, [flowPayload.steps.map((step) => step._id).join(), flowPayload._id]);

  const save = async () => {
    const payload = { ...flowPayload };
    payload?.name.trim();
    if (payload._id?.includes('_TEMP')) payload._id = '';
    payload.steps.forEach((step) => {
      if (step._id?.includes('_TEMP')) step._id = '';
      step.name = step?.name.trim();
    });
    let sucess;
    if (editingFlow) {
      sucess = await Flows?.updateFlow(payload);
    } else {
      sucess = await Flows?.createFlow(payload);
    }

    if (sucess) {
      ToastService.createToast({
        message: editingFlow ? 'Flow saved' : 'Flow created',
      });
    } else {
      ToastService.createToast({
        message: editingFlow ? 'Flow failed to save' : 'Flow failed to create',
        error: true,
      });
    }
    reset();
    Flows?.getFlows();
    onClose();
  };
  const close = () => {
    reset();
    onClose();
  };

  const reset = () => {
    setEditingStep(undefined);
    setPayload({ action: 'reset' });
    onClose();
  };
  return (
    <Popup
      width={800}
      isOpen={isOpen}
      onClose={close}
      zIndex={999}
      key={flowPayload._id}
      actions={
        <Flex gap={10}>
          <Button pixi onClick={close}>
            Cancel
          </Button>
          <Button
            pixi
            primary
            onClick={() => save()}
            disabled={!flowPayload?.name}
          >
            Save
          </Button>
        </Flex>
      }
    >
      <Flex gap={10}>
        <InputWrapper
          value={flowPayload?.name}
          onChange={(value: string) =>
            setPayload({ action: 'changeName', value })
          }
          placeholder="Flow name"
          label="Flow name"
        />
      </Flex>
      <List>
        {content?.map((stepGroup, stepGroupIndex, stepGroups) => {
          if (!stepGroup) return <></>;
          const isEditingStep = typeof editingStep === 'number';
          return (
            <>
              <Input.Field
                style={{ marginTop: 15 }}
                label={
                  <Flex
                    style={{ width: '100%' }}
                    justifyContent="space-between"
                    alignItems="flex-end"
                  >
                    <div>Step {stepGroupIndex + 1}</div>
                    <Flex alignItems="center" style={{ gap: 5 }}>
                      <Button
                        disabled={stepGroupIndex === 0 || isEditingStep}
                        size="small"
                        onClick={() =>
                          setPayload({
                            action: 'moveStepGroupUp',
                            index: stepGroupIndex,
                          })
                        }
                      >
                        <Icon name="arrow-up" style={{ margin: 0 }} />
                      </Button>
                      <Button
                        disabled={
                          stepGroupIndex === stepGroups.length - 1 ||
                          isEditingStep
                        }
                        size="small"
                        onClick={() =>
                          setPayload({
                            action: 'moveStepGroupDown',
                            index: stepGroupIndex,
                          })
                        }
                      >
                        <Icon name="arrow-down" style={{ margin: 0 }} />
                      </Button>
                    </Flex>
                  </Flex>
                }
                custom={
                  <>
                    <Flex
                      gap={10}
                      style={{
                        width: '100%',
                        background: 'rgba(0, 0, 0, 0.05)',
                        borderRadius: 5,
                        padding: 10,
                      }}
                    >
                      <Flex
                        style={{ width: '100%' }}
                        key={stepGroup.map((step) => step._id)}
                        column
                      >
                        <List cards>
                          {stepGroup.map((step, index, steps) => {
                            return (
                              <List.Item
                                title={step.name}
                                key={step._id}
                                actions={
                                  <Confirm
                                    position="bottom-center"
                                    title="Are you sure?"
                                    text="This can't be reverted. Any files on this step will restart from the beginning of the flow."
                                    confirm="Delete"
                                    onConfirm={() => {
                                      setPayload({
                                        action: 'removeStep',
                                        id: step._id,
                                      });
                                    }}
                                  >
                                    <Button
                                      disabled={
                                        steps.length === 1 &&
                                        stepGroups.length === 1
                                      }
                                    >
                                      <Icon
                                        name="trash"
                                        style={{ margin: 0 }}
                                      />
                                    </Button>
                                  </Confirm>
                                }
                              >
                                <Flex
                                  column
                                  style={{
                                    width: '100%',
                                  }}
                                  key={step._id}
                                >
                                  <Flex column>
                                    <div>
                                      <Flex style={{ gap: 10 }}>
                                        <InputWrapper
                                          value={step?.name}
                                          onChange={(value: string) => {
                                            step.name = value;
                                            setPayload({
                                              action: 'updateStep',
                                              value: step,
                                              id: step._id,
                                            });
                                          }}
                                          placeholder="Step name"
                                          label="Step name"
                                          autoFocus
                                          key={step._id}
                                        />
                                        <Dropdown
                                          useV2
                                          placeholder="Approve by"
                                          label="Approve by"
                                          value={
                                            step.responsibility
                                              .responsibilityType
                                          }
                                          noClear
                                          onChange={(value: any) => {
                                            if (
                                              step.responsibility
                                                .responsibilityType !== value
                                            ) {
                                              step.responsibility.ids = [];
                                            }
                                            step.responsibility.responsibilityType =
                                              value;
                                            setPayload({
                                              action: 'updateStep',
                                              value: step,
                                              id: step._id,
                                            });
                                          }}
                                        >
                                          <Dropdown.Option
                                            value="admins"
                                            disabled={steps.find(
                                              (step) =>
                                                step.responsibility
                                                  .responsibilityType ===
                                                'admins',
                                            )}
                                          >
                                            Any admin
                                          </Dropdown.Option>

                                          <Dropdown.Option value="groups">
                                            Groups
                                          </Dropdown.Option>
                                          <Dropdown.Option value="people">
                                            Specific admins
                                          </Dropdown.Option>
                                        </Dropdown>
                                      </Flex>
                                    </div>
                                    {step.responsibility.responsibilityType ===
                                      'people' && (
                                      <Input.Field
                                        label="Required admins"
                                        style={{ marginTop: 10 }}
                                        custom={
                                          <SelectUsersDropdown
                                            placeholder="Select admins"
                                            value={step.responsibility.ids}
                                            adminsOnly
                                            onChange={(users: any) => {
                                              step.responsibility.ids = users;
                                              setPayload({
                                                action: 'updateStep',
                                                value: step,
                                                id: step._id,
                                              });
                                            }}
                                          />
                                        }
                                      />
                                    )}
                                    {step.responsibility.responsibilityType ===
                                      'groups' && (
                                      <SelectUserGroupsDropdown
                                        key="groupSelect"
                                        style={{
                                          marginTop: 10,
                                          width: '100%',
                                        }}
                                        placeholder="Select groups"
                                        value={step.responsibility.ids ?? []}
                                        onChange={(groups: any) => {
                                          step.responsibility.ids = groups;
                                          setPayload({
                                            action: 'updateStep',
                                            value: step,
                                            id: step._id,
                                          });
                                        }}
                                      />
                                    )}
                                  </Flex>
                                </Flex>
                              </List.Item>
                            );
                          })}
                        </List>
                        <Flex>
                          <Button
                            onClick={() =>
                              setPayload({
                                action: 'addStep',
                                index: stepGroupIndex,
                              })
                            }
                            inline
                            size="small"
                          >
                            <Icon name="arrow-return-right" />
                            Add parallel approval
                          </Button>
                        </Flex>
                      </Flex>
                      <>
                        {editingStep === stepGroupIndex ? (
                          <Flex
                            gap={10}
                            alignItems="end"
                            column
                            justifyContent="end"
                            style={{ flexGrow: 0 }}
                          >
                            <Button disabled onClick={() => {}}>
                              <Icon name="arrow-up" style={{ margin: 0 }} />
                            </Button>
                            <Button
                              onClick={() => setEditingStep(undefined)}
                              primary
                            >
                              <Icon name="check-lg" style={{ margin: 0 }} />
                            </Button>
                            <Button disabled onClick={() => {}}>
                              <Icon name="arrow-down" style={{ margin: 0 }} />
                            </Button>
                          </Flex>
                        ) : (
                          <Flex gap={10} column style={{ flexGrow: 0 }} />
                        )}
                      </>
                    </Flex>
                  </>
                }
              />
            </>
          );
        })}
      </List>
      <Flex justifyContent="center" style={{ margin: '15px 0' }}>
        <Button
          pixi
          inline
          onClick={() => setPayload({ action: 'addStepGroup' })}
        >
          <Icon name="plus-square" />
          Add step to workflow
        </Button>
      </Flex>
    </Popup>
  );
};
const InputWrapper = (props: any) => {
  const newInput = Input as any;

  return <newInput.Field {...props} />;
};

export default EditFlow;
