import { Textarea } from '@mantine/core';
import PixiForm, { PixiFormProps } from '@pixi/elements/Form';
import { useUserContext } from 'hooks';
import useContextFromFile from 'hooks/useContextFromFile';
import { ReactElement } from 'react';

export default function FeedbackForm({
  file,
  files,
  target,
  ...rest
}: {
  file?: Pickit.FileInterface;
  files?: Pickit.FileInterface[];
  target: ReactElement;
} & Omit<
  PixiFormProps<{
    message: string;
  }>,
  'onSubmit' | 'target' | 'form' | 'type'
>) {
  const context = useContextFromFile(file || files?.[0]);
  const User = useUserContext();
  const userFeedback = file?.file?.feedback?.find(
    (feedback: any) => feedback.createdBy === User.djangoProfile?.id,
  );
  return (
    <PixiForm<{
      message: string;
    }>
      title="Leave feedback"
      form={{
        message: {
          value: userFeedback?.message as string,
          key: 'message',
          render: ({ value, setValue }) => (
            <Textarea
              key="message"
              value={value}
              onChange={(event) => setValue(event.target.value)}
            />
          ),
        },
      }}
      onSubmit={async ({ message }) => {
        if (files?.length) {
          const updatedFiles = await context.saveDocumentsWhere(
            {
              _id: { $in: files.map((file) => file._id) },
              'file.feedback.createdBy': User.djangoProfile?.id,
            },
            {
              'file.feedback.$.message': message,
            },
          );
          const filesWithNoFeedback = files.filter(
            (file: Pickit.FileInterface) =>
              !updatedFiles?.documents?.find(
                (doc: Pickit.FileInterface) => doc._id === file._id,
              ),
          ) as Pickit.FileInterface[];

          if (filesWithNoFeedback?.length) {
            await context.saveDocumentsWhere(
              {
                _id: { $in: filesWithNoFeedback.map((file) => file._id) },
              },
              {
                $push: {
                  'file.feedback': {
                    message,
                    createdBy: User.data?.user?.userId,
                  },
                },
              },
            );
          }
          return;
        }
        if (userFeedback?.message) {
          await context.savePartial(
            {
              _id: file?._id,
              'file.feedback.$.message': message,
            },
            {
              'file.feedback._id': userFeedback?._id,
            },
          );
        } else {
          await context.savePartial({
            _id: file?._id,
            $push: {
              'file.feedback': { message, createdBy: User.data?.user?.userId },
            },
          });
        }
        // await context.saveDocumentFeedback(file, {}, { message });
      }}
      target={target}
      {...rest}
      type="dropdown"
    />
  );
}
