import {
  CompositeDecorator,
  Modifier,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
  getDefaultKeyBinding,
  RichUtils,
  ContentState,
  Editor,
  EditorState,
} from 'draft-js';
import PrismDecorator from 'draft-js-prism';
import Prism from 'prismjs';
import { Dropdown, Flex, Icon, Popover } from 'elements_v2';
import { Color } from 'elements_v2/Input';
import draftToHtml from 'draftjs-to-html';

import React, { useEffect, useRef, useState } from 'react';
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent';
import { analytics } from 'services';
import CodeUtils from 'draft-js-code';
import { List } from 'immutable';
import createStyles from './draft-js-custom-styles';
import './prism.css';
import PixiForm from '@pixi/elements/Form';
import { Switch, TextInput } from '@mantine/core';

const Span = (props) => <>{props.children}</>;

class CompoundDecorator {
  constructor(decorators = []) {
    this.decorators = decorators.map((decorator) => {
      return decorator.strategy && decorator.component
        ? new CompositeDecorator([decorator])
        : decorator;
    });
  }

  getDecorations(block, contentState) {
    const emptyTuples = Array(block.getText().length).fill(
      Array(this.decorators.length).fill(null),
    );

    const decorations = this.decorators.reduce((tuples, decorator, index) => {
      const blockDecorations = decorator.getDecorations(block, contentState);

      return tuples.map((tuple, tupleIndex) => {
        return [
          ...tuple.slice(0, index),
          blockDecorations.get(tupleIndex),
          ...tuple.slice(index + 1),
        ];
      });
    }, emptyTuples);

    return List(decorations.map(JSON.stringify));
  }

  getComponentForKey(key) {
    const tuple = JSON.parse(key);
    return (props) => {
      const { decoratorProps, ...compositionProps } = props;
      const Composed = tuple.reduce((Composition, decoration, index) => {
        if (decoration !== null) {
          const decorator = this.decorators[index];
          const Component = decorator.getComponentForKey(decoration);
          const componentProps = {
            ...compositionProps,
            ...decoratorProps[index],
          };
          return () => (
            <Component {...componentProps}>
              <Composition {...compositionProps} />
            </Component>
          );
        }
        return Composition;
      }, Span);
      return <Composed>{props.children}</Composed>;
    };
  }

  getPropsForKey(key) {
    const tuple = JSON.parse(key);
    return {
      decoratorProps: tuple.map((decoration, index) => {
        const decorator = this.decorators[index];
        return decoration !== null ? decorator.getPropsForKey(decoration) : {};
      }),
    };
  }
}

function isHTML(str) {
  const a = document.createElement('div');
  a.innerHTML = str;

  for (let c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType == 1) return true;
  }

  return false;
}
const Link = ({ entityKey, contentState, children }) => {
  const { url, openInNewWindow } = contentState.getEntity(entityKey).getData();
  let target = null;
  if (openInNewWindow) {
    target = '_BLANK';
  }
  return (
    <a
      className="brand__color__text"
      href={url}
      target={target}
      rel="noreferrer"
      onClick={() => {
        analytics.trackEvent('Text editor link clicked', {
          'Link url': url,
          'Link label': children?.[0]?.props?.text,
        });
      }}
      title={url}
    >
      {children}
    </a>
  );
};

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState?.getEntity(entityKey)?.getType() === 'LINK'
    );
  }, callback);
};

/**
 * Get current selected text
 * @param  {Draft.ContentState}
 * @param  {Draft.SelectionState}
 * @param  {String}
 * @return {String}
 */
function _getTextSelection(contentState, selection, blockDelimiter) {
  blockDelimiter = blockDelimiter || '\n';
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();
  const blocks = contentState.getBlockMap();

  let lastWasEnd = false;
  const selectedBlock = blocks
    .skipUntil(function (block) {
      return block.getKey() === startKey;
    })
    .takeUntil(function (block) {
      const result = lastWasEnd;

      if (block.getKey() === endKey) {
        lastWasEnd = true;
      }

      return result;
    });

  return selectedBlock
    .map(function (block) {
      const key = block.getKey();
      let text = block.getText();

      let start = 0;
      let end = text.length;

      if (key === startKey) {
        start = selection.getStartOffset();
      }
      if (key === endKey) {
        end = selection.getEndOffset();
      }

      text = text.slice(start, end);
      return text;
    })
    .join(blockDelimiter);
}
const decorators = new CompoundDecorator([
  new PrismDecorator({
    prism: Prism,
    defaultSyntax: 'javascript',
  }),
  new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]),
]);

export const onAddLink = (editorState, setEditorState) => {
  const linkUrl = window.prompt('Add link http:// ');
  const currentContent = editorState.getCurrentContent();
  let displayLink = _getTextSelection(
    currentContent,
    editorState.getSelection(),
  );
  if (linkUrl) {
    if (!displayLink) {
      displayLink = window.prompt('Display Text');
    }
    if (displayLink) {
      const createEntity = currentContent.createEntity('LINK', 'MUTABLE', {
        url: linkUrl,
      });
      const entityKey = currentContent.getLastCreatedEntityKey();
      const selection = editorState.getSelection();
      if (selection.isCollapsed()) {
        const textWithEntity = Modifier.insertText(
          currentContent,
          selection,
          displayLink,
          null,
          entityKey,
        );
        const newState = EditorState.createWithContent(
          textWithEntity,
          decorators,
        );
        setEditorState(newState);
      } else {
        const textWithEntity = Modifier.replaceText(
          currentContent,
          selection,
          displayLink,
          null,
          entityKey,
        );
        const newState = EditorState.createWithContent(
          textWithEntity,
          decorators,
        );
        setEditorState(newState);
      }
    }
  }
};

export const Wysiwyg = (props) => {
  const blocksFromHTML =
    isHTML(props.value) && !props.value.includes('{"blocks":')
      ? convertFromHTML(props.value)
      : null;

  let legacyText = '';
  try {
    const parse = JSON.parse(props.value);
    if (parse?.blocks?.[0]?.text?.includes('{"blocks":')) {
      legacyText = parse?.blocks?.[0]?.text;
    }
  } catch (e) {}
  const [editorState, setEditorState] = React.useState(() =>
    !props.value?.includes?.('{') && !isHTML(props.value)
      ? EditorState.createEmpty(decorators)
      : EditorState.createWithContent(
          blocksFromHTML
            ? ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
              )
            : convertFromRaw(JSON.parse(legacyText || props.value)),
          decorators,
        ),
  );
  const [isFocused, setIsFocused] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const tempColor = useRef();
  const cachedSelection = useRef();
  const editorRef = useRef();

  useEffect(() => {
    if (props.value && !isEdited) {
      const blocksFromHTML =
        isHTML(props.value) && !props.value.includes('{"blocks":')
          ? convertFromHTML(props.value)
          : null;
      setEditorState(
        !props.value?.includes?.('{') && !isHTML(props.value)
          ? EditorState.createEmpty(decorators)
          : EditorState.createWithContent(
              blocksFromHTML
                ? ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap,
                  )
                : convertFromRaw(JSON.parse(props.value)),
              decorators,
            ),
      );
    }
  }, [props.value]);

  const colorStyleMap = {
    red: {
      color: 'rgba(255, 0, 0, 1.0)',
    },
    orange: {
      color: 'rgba(255, 127, 0, 1.0)',
    },
    yellow: {
      color: 'rgba(180, 180, 0, 1.0)',
    },
    green: {
      color: 'rgba(0, 180, 0, 1.0)',
    },
    blue: {
      color: 'rgba(0, 0, 255, 1.0)',
    },
    indigo: {
      color: 'rgba(75, 0, 130, 1.0)',
    },
    violet: {
      color: 'rgba(127, 0, 255, 1.0)',
    },
  };
  const lineHeightMap = {
    auto: { lineHeight: 1.5 },
    '1.0': { lineHeight: 1.0 },
    1.25: { lineHeight: 1.25 },
    1.5: { lineHeight: 1.5 },
    1.75: { lineHeight: 1.75 },
    '2.0': { lineHeight: 2.0 },
  };
  const fontSizeMap = {
    auto: {},
    '8px': { fontSize: '8px' },
    '10px': { fontSize: '10px' },
    '12px': { fontSize: '12px' },
    '14px': { fontSize: '14px' },
    '16px': { fontSize: '16px' },
    '18px': { fontSize: '18px' },
    '20px': { fontSize: '20px' },
    '22px': { fontSize: '22px' },
    '24px': { fontSize: '24px' },
    '26px': { fontSize: '26px' },
    '28px': { fontSize: '28px' },
    '30px': { fontSize: '30px' },
    '36px': { fontSize: '36px' },
    '48px': { fontSize: '48px' },
    '54px': { fontSize: '54px' },
    '60px': { fontSize: '60px' },
    '72px': { fontSize: '72px' },
  };

  const styleMap = {
    CODE: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
    'text-indent-left': {
      textIndent: 40,
      display: 'block',
    },
    'align-left': {
      textAlign: 'left',
      display: 'block',
    },
    'align-center': {
      textAlign: 'center',
      display: 'block',
    },
    'align-right': {
      textAlign: 'right',
      display: 'block',
    },
    ...colorStyleMap,
    ...fontSizeMap,
    ...lineHeightMap,
  };

  const { styles, customStyleFn } = createStyles(
    ['color'],
    'CUSTOM_',
    styleMap,
  );

  useEffect(() => {
    if (props.onChange && isEdited) {
      if (props.outputHTML) {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const markup = draftToHtml(rawContentState);
        props.onChange(markup);
      } else {
        props.onChange(
          JSON.stringify(convertToRaw(editorState.getCurrentContent())),
        );
      }
    }
  }, [editorState, isEdited, props.outputHTML]);

  const onTab = (evt) => {
    if (CodeUtils.hasSelectionInBlock(editorState)) {
      setEditorState(CodeUtils.onTab(evt, editorState));
    }
    evt.preventDefault();

    const newEditorState = RichUtils.onTab(evt, editorState, 4 /* maxDepth */);
    if (newEditorState !== editorState) {
      setEditorState(newEditorState);
    }
  };
  function handleReturn(evt) {
    if (CodeUtils.hasSelectionInBlock(editorState)) {
      setEditorState(CodeUtils.handleReturn(evt, editorState));
      return 'handled';
    }
    const blockType = RichUtils.getCurrentBlockType(editorState);
    if (blockType !== 'unstyled' || !isSoftNewlineEvent(evt)) {
      // Just to be sure, soft returns are executed only with unstyled blocks.
      return 'not_handled';
    }
    const newState = RichUtils.insertSoftNewline(editorState);
    setEditorState(newState);
    // Returning handled sends the editor a message that we'll handle this
    // update.
    return 'handled';
  }

  function toggleCustom(customValue, style, type) {
    let nextEditorState = EditorState.forceSelection(
      editorState,
      cachedSelection?.current,
    );

    const currentStyle = editorState.getCurrentInlineStyle();

    if (type === 'line-height') {
      if (!style || style === 'auto') {
        const nextContentState = Object.keys(lineHeightMap).reduce(
          (contentState, color) => {
            return Modifier.removeInlineStyle(contentState, selection, color);
          },
          nextEditorState.getCurrentContent(),
        );
        nextEditorState = EditorState.push(
          editorState,
          nextContentState,
          'change-inline-style',
        );
        return nextEditorState;
      }
      const nextContentState = Object.keys(lineHeightMap).reduce(
        (contentState, color) => {
          return Modifier.removeInlineStyle(contentState, selection, color);
        },
        nextEditorState.getCurrentContent(),
      );
      nextEditorState = EditorState.push(
        editorState,
        nextContentState,
        'change-inline-style',
      );
    }
    if (type === 'font-size') {
      if (!style || style === 'auto') {
        const nextContentState = Object.keys(fontSizeMap).reduce(
          (contentState, color) => {
            return Modifier.removeInlineStyle(contentState, selection, color);
          },
          nextEditorState.getCurrentContent(),
        );
        nextEditorState = EditorState.push(
          editorState,
          nextContentState,
          'change-inline-style',
        );
        return nextEditorState;
      }
      const nextContentState = Object.keys(fontSizeMap).reduce(
        (contentState, color) => {
          return Modifier.removeInlineStyle(contentState, selection, color);
        },
        nextEditorState.getCurrentContent(),
      );
      nextEditorState = EditorState.push(
        editorState,
        nextContentState,
        'change-inline-style',
      );
    }

    if (style === 'custom') {
      nextEditorState = styles.color.toggle(
        nextEditorState,
        customValue?.color,
      );
    } else if (!currentStyle.has(style)) {
      if (selection.isCollapsed()) {
        nextEditorState = currentStyle.reduce((state, color) => {
          return RichUtils.toggleInlineStyle(state, color);
        }, nextEditorState);
      }
      nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, style);
    }

    return nextEditorState;
  }

  function getBlockStyle(block) {
    switch (block.getType()) {
      case 'text-indent-left':
        return 'text-indent-left';
      case 'text-indent-right':
        return 'text-indent-right';
      case 'blockquote':
        return 'RichEditor-blockquote';
      case 'align-left':
        return 'align-left';
      case 'align-center':
        return 'align-center';
      case 'align-right':
        return 'align-right';
      default:
        return null;
    }
  }

  function handleKeyCommand(command, editorState) {
    if (CodeUtils.hasSelectionInBlock(editorState)) {
      const newState = CodeUtils.handleKeyCommand(editorState, command);
      if (newState) {
        setEditorState(newState);
        return 'handled';
      }
    }
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  }
  function mapKeyToEditorCommand(e) {
    if (CodeUtils.hasSelectionInBlock(editorState)) {
      if (!CodeUtils.hasSelectionInBlock(editorState))
        return getDefaultKeyBinding(e);

      const command = CodeUtils.getKeyBinding(e);

      return command || getDefaultKeyBinding(e);
    }
    if (e.key === 'Tab') {
      // Preventing default behavior to keep cursor in the editor
      e.preventDefault();

      // Defining number of spaces to apply after tab press
      const tabIndent = '    ';

      // Getting current state
      const currentState = editorState;

      // Getting variables to know text selection
      const selectionState = editorState.getSelection();
      const anchorKey = selectionState.getAnchorKey();
      const currentContent = editorState.getCurrentContent();
      const currentContentBlock = currentContent.getBlockForKey(anchorKey);
      const start = selectionState.getStartOffset();
      const end = selectionState.getEndOffset();
      const selectedText = currentContentBlock.getText().slice(start, end);

      // Defining next state
      const nextState = Modifier.replaceText(
        currentContent,
        selectionState,
        tabIndent + selectedText,
      );

      // Setting next state
      setEditorState(EditorState.push(currentState, nextState, 'indent'));
    }
    return getDefaultKeyBinding(e);
  }

  function getCurrentBlock() {
    const currentSelection = editorState.getSelection();
    const blockKey = currentSelection.getStartKey();
    return editorState.getCurrentContent().getBlockForKey(blockKey);
  }

  function toggleBlockType(blockType) {
    const block = getCurrentBlock();
    const style = block.getType();
    if (
      (style === 'header-one' || style === 'header-two') &&
      blockType.style.includes('align')
    ) {
      setEditorState(RichUtils.toggleInlineStyle(editorState, blockType.style));
    } else if (blockType.type === 'indent') {
      setEditorState(RichUtils.toggleInlineStyle(editorState, blockType.style));
    } else {
      setEditorState(RichUtils.toggleBlockType(editorState, blockType.style));
    }
  }

  function toggleInlineStyle(link) {
    if (link.type === 'color') {
      return setEditorState(toggleCustom(link.value, link.style, link.type));
    }
    if (link.type === 'line-height') {
      return setEditorState(toggleCustom(link.value, link.style, link.type));
    }
    if (link.type === 'font-size') {
      return setEditorState(toggleCustom(link.value, link.style, link.type));
    }
    setEditorState(RichUtils.toggleInlineStyle(editorState, link.style));
  }
  const currentStyle = editorState.getCurrentInlineStyle();
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const COLORS = [
    { label: 'Reset', style: 'auto', type: 'color' },
    { label: 'Red', style: 'red', type: 'color' },
    { label: 'Orange', style: 'orange', type: 'color' },
    { label: 'Yellow', style: 'yellow', type: 'color' },
    { label: 'Green', style: 'green', type: 'color' },
    { label: 'Blue', style: 'blue', type: 'color' },
    { label: 'Indigo', style: 'indigo', type: 'color' },
    { label: 'Violet', style: 'violet', type: 'color' },
  ].map((color) => ({
    ...color,
    value: colorStyleMap[color.style]?.color,
  }));

  const ALIGN = [
    { label: 'Auto', style: 'align-auto', icon: 'justify-left', type: 'align' },
    { label: 'Left', style: 'align-left', icon: 'justify-left', type: 'align' },
    { label: 'Center', style: 'align-center', icon: 'justify', type: 'align' },
    {
      label: 'Right',
      style: 'align-right',
      icon: 'justify-right',
      type: 'align',
    },
  ];

  const FONT_SIZES = Object.keys(fontSizeMap).map((size) => ({
    label: size === 'auto' ? 'Auto' : size,
    style: size,
    type: 'font-size',
    value: size,
  }));
  const LINE_HEIGHTS = Object.keys(lineHeightMap).map((size) => ({
    label: size === 'auto' ? 'Auto' : size,
    style: size,
    type: 'line-height',
    value: size,
  }));

  const TYPES = [
    { label: 'Title', style: 'header-one', icon: 'type-h1' },
    { label: 'Subtitle', style: 'header-two', icon: 'type-h2' },
    { label: 'Body', style: 'unstyled', icon: 'paragraph' },
  ];

  const TYPE_TOOLBAR = [
    { label: 'Bold', style: 'BOLD', icon: 'type-bold', type: 'INLINE' },
    { label: 'Italic', style: 'ITALIC', icon: 'type-italic', type: 'INLINE' },
    {
      label: 'Underline',
      style: 'UNDERLINE',
      icon: 'type-underline',
      type: 'INLINE',
    },
    {
      label: 'Strikethrough',
      style: 'STRIKETHROUGH',
      icon: 'type-strikethrough',
      type: 'INLINE',
    },
  ];

  const TOOLBAR = [
    {
      label: 'Blockquote',
      style: 'blockquote',
      icon: 'blockquote-left',
      type: 'BLOCK',
    },
    {
      label: 'UL',
      style: 'unordered-list-item',
      icon: 'list-ul',
      type: 'BLOCK',
    },
    { label: 'OL', style: 'ordered-list-item', icon: 'list-ol', type: 'BLOCK' },
  ];

  const className = ['Pixi__Wysiwyg'];

  if (props.textEditorPrefs?.autoHeight) {
    className.push('Pixi__Wysiwyg--auto');
  }
  if (props.white) {
    className.push('Pixi__Wysiwyg--white');
  }

  return (
    <div className={className.join(' ')}>
      {!props.readOnly && (
        <div
          className={[
            'Pixi__Wysiwyg__Toolbar',
            ...(props.disableEditing
              ? ['Pixi__Wysiwyg__Toolbar--disabled']
              : []),
          ].join(' ')}
        >
          <div className="Pixi__Wysiwyg__Toolbar__icons">
            <Dropdown
              keepOpen
              useV2
              trigger={
                <Icon
                  name="type"
                  style={{
                    color: FONT_SIZES.find((type) =>
                      currentStyle.has(type.style),
                    )
                      ? '#6716d8'
                      : '',
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    cachedSelection.current = editorState.getSelection();
                  }}
                  bubble
                  button
                />
              }
              boxContentStyle={{ overflow: 'visible' }}
            >
              {(isOpen, setIsOpen) => (
                <>
                  <Dropdown
                    width={200}
                    position="right"
                    useV2
                    trigger={
                      <Dropdown.Option
                        onMouseDown={(e) => {
                          e.preventDefault();
                        }}
                        active={TYPES.find((type) => type.style === blockType)}
                      >
                        <Icon name="fonts" />
                        Type
                      </Dropdown.Option>
                    }
                  >
                    {TYPES.map((type) => (
                      <Dropdown.Option
                        key={type.style}
                        active={type.style === blockType}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          toggleBlockType(type);
                          setIsOpen(false);
                        }}
                      >
                        <Icon name={type.icon} />
                        {type.label}
                      </Dropdown.Option>
                    ))}
                  </Dropdown>
                  <Dropdown
                    width={200}
                    position="right"
                    useV2
                    trigger={
                      <Dropdown.Option
                        onMouseDown={(e) => {
                          e.preventDefault();
                        }}
                        active={FONT_SIZES.find((type) =>
                          currentStyle.has(type.style),
                        )}
                      >
                        <Icon name="type" />
                        Font size
                      </Dropdown.Option>
                    }
                  >
                    {FONT_SIZES.map((type) => (
                      <Dropdown.Option
                        key={type.style}
                        active={currentStyle.has(type.style)}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          toggleInlineStyle(type);
                          setIsOpen(false);
                        }}
                      >
                        {type.label}
                      </Dropdown.Option>
                    ))}
                  </Dropdown>
                  <Dropdown
                    width={200}
                    position="right"
                    useV2
                    trigger={
                      <Dropdown.Option
                        onMouseDown={(e) => {
                          e.preventDefault();
                        }}
                        active={LINE_HEIGHTS.find((type) =>
                          currentStyle.has(type.style),
                        )}
                      >
                        <Icon name="type" />
                        Line height
                      </Dropdown.Option>
                    }
                  >
                    {LINE_HEIGHTS.map((type) => (
                      <Dropdown.Option
                        key={type.style}
                        active={currentStyle.has(type.style)}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          toggleInlineStyle(type);
                          setIsOpen(false);
                        }}
                      >
                        {type.label}
                      </Dropdown.Option>
                    ))}
                  </Dropdown>
                  {TYPE_TOOLBAR.map((type) => (
                    <Dropdown.Option
                      key={type.style}
                      active={
                        type.type === 'INLINE'
                          ? currentStyle.has(type.style)
                          : type.style === blockType
                      }
                      onMouseDown={(e) => {
                        e.preventDefault();
                        setIsOpen(false);
                        if (type.type === 'INLINE') {
                          return toggleInlineStyle(type);
                        }
                        toggleBlockType(type);
                      }}
                    >
                      <Icon name={type.icon} />
                      {type.label}
                    </Dropdown.Option>
                  ))}
                  <Popover
                    width={240}
                    position="right"
                    useV2
                    hideOnClickOutside={false}
                    onClose={() => {
                      toggleInlineStyle({
                        type: 'color',
                        style: 'custom',
                        value: {
                          color: tempColor?.current,
                        },
                      });
                    }}
                    boxContentStyle={{ padding: 15 }}
                    trigger={
                      <Dropdown.Option
                        active={COLORS.find((type) =>
                          currentStyle.has(type.style),
                        )}
                      >
                        <Icon name="droplet" />
                        Color
                      </Dropdown.Option>
                    }
                  >
                    {(isOpen, setIsOpen) => (
                      <Flex flexWrap="wrap" style={{ width: '100%' }}>
                        <Color
                          inline
                          onChange={(color, colorId) => {
                            if (color) {
                              tempColor.current = color;
                            }
                            if (colorId) {
                              toggleInlineStyle({
                                type: 'color',
                                style: 'custom',
                                value: {
                                  color,
                                },
                              });
                              setIsOpen(false);
                            }
                          }}
                        />
                      </Flex>
                    )}
                  </Popover>
                </>
              )}
            </Dropdown>
            {TOOLBAR.map((type) => (
              <Icon
                key={type.style}
                name={type.icon}
                style={{
                  color: (
                    type.type === 'INLINE'
                      ? currentStyle.has(type.style)
                      : type.style === blockType
                  )
                    ? '#6716d8'
                    : '',
                }}
                bubble
                button
                onMouseDown={(e) => {
                  e.preventDefault();
                  if (type.type === 'INLINE') {
                    return toggleInlineStyle(type);
                  }
                  toggleBlockType(type);
                }}
              />
            ))}
            <Dropdown
              width={200}
              trigger={
                <Icon
                  name="justify-left"
                  style={{
                    color: ALIGN.find((type) => type.style === blockType)
                      ? '#6716d8'
                      : '',
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                  bubble
                  button
                />
              }
            >
              {ALIGN.map((type) => (
                <Dropdown.Option
                  key={type.style}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    toggleBlockType(type);
                  }}
                >
                  <Icon name={type.icon} />
                  {type.label}
                </Dropdown.Option>
              ))}
            </Dropdown>
            <Icon
              name="text-indent-left"
              bubble
              button
              onClick={(event) => {
                if (
                  getCurrentBlock(editorState)?.type ===
                    'unordered-list-item' ||
                  getCurrentBlock(editorState)?.type === 'ordered-list-item'
                ) {
                  const currentSelection = editorState.getSelection();
                  let newEditorState = RichUtils.onTab(
                    event,
                    editorState,
                    4 /* maxDepth */,
                  );
                  newEditorState = EditorState.forceSelection(
                    newEditorState,
                    currentSelection,
                  );
                  if (newEditorState !== editorState) {
                    setEditorState(newEditorState);
                  }
                  return;
                }
                toggleBlockType({
                  type: 'indent',
                  style: 'text-indent-left',
                });
              }}
            />
            <Icon
              name="code"
              bubble
              button
              onClick={(event) => {
                toggleBlockType({
                  type: 'code',
                  style: 'code-block',
                });
              }}
            />
            <PixiForm
              type="dropdown"
              target={
                <Icon
                  name="link-45deg"
                  // onClick={() => onAddLink(editorState, setEditorState)}
                  bubble
                  button
                />
              }
              onSubmit={({ url, openInNewWindow }) => {
                const currentContent = editorState.getCurrentContent();
                let displayLink = _getTextSelection(
                  currentContent,
                  editorState.getSelection(),
                );
                if (url) {
                  if (!displayLink) {
                    displayLink = window.prompt('Display Text');
                  }
                  if (displayLink) {
                    const createEntity = currentContent.createEntity(
                      'LINK',
                      'MUTABLE',
                      {
                        url,
                        openInNewWindow,
                      },
                    );
                    const entityKey = currentContent.getLastCreatedEntityKey();
                    const selection = editorState.getSelection();
                    if (selection.isCollapsed()) {
                      const textWithEntity = Modifier.insertText(
                        currentContent,
                        selection,
                        displayLink,
                        null,
                        entityKey,
                      );
                      const newState = EditorState.createWithContent(
                        textWithEntity,
                        decorators,
                      );
                      setEditorState(newState);
                    } else {
                      const textWithEntity = Modifier.replaceText(
                        currentContent,
                        selection,
                        displayLink,
                        null,
                        entityKey,
                      );
                      const newState = EditorState.createWithContent(
                        textWithEntity,
                        decorators,
                      );
                      setEditorState(newState);
                    }
                  }
                }
              }}
              form={{
                url: {
                  key: 'url',
                  value: '',
                  render: ({ value, setValue }) => (
                    <TextInput
                      value={value}
                      onChange={(event) => {
                        setValue(event.currentTarget.value);
                      }}
                      autoFocus
                      onFocus={(event) => event.currentTarget?.select()}
                    />
                  ),
                },
                openInNewWindow: {
                  key: 'openInNewWindow',
                  value: true,
                  render: ({ value, setValue }) => (
                    <Switch
                      label="Open in new window"
                      checked={value}
                      onChange={(event) => {
                        setValue(event.currentTarget.checked);
                      }}
                    />
                  ),
                },
              }}
            />
          </div>
          {props.toolbarInjectRight}
        </div>
      )}
      {props.placeholder && !isFocused && !isEdited ? (
        <div onClick={() => editorRef?.current?.focus()}>
          {props.placeholder}
        </div>
      ) : (
        ''
      )}
      <Editor
        blockStyleFn={getBlockStyle}
        editorState={editorState}
        stripPastedStyles
        handleKeyCommand={handleKeyCommand}
        keyBindingFn={mapKeyToEditorCommand}
        handlePastedText={(text) => {
          const selection = editorState.getSelection();
          if (selection.isCollapsed()) {
            const newContent = Modifier.insertText(
              editorState.getCurrentContent(),
              editorState.getSelection(),
              text,
            );
            setEditorState(
              EditorState.push(editorState, newContent, 'insert-characters'),
            );
            return 'HANDLED';
          }
          const newContent = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            text,
          );
          setEditorState(
            EditorState.push(editorState, newContent, 'insert-characters'),
          );
          return 'HANDLED';
        }}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => setIsFocused(false)}
        onChange={(state) => {
          if (state.getCurrentContent().hasText()) {
            setIsEdited(true);
          }
          setEditorState(state);
        }}
        handleReturn={handleReturn}
        ref={editorRef}
        customStyleMap={styleMap}
        customStyleFn={customStyleFn}
        onTab={onTab}
        spellCheck
        readOnly={props.readOnly || props.disableEditing}
      />
    </div>
  );
};

export const WysiwygPreview = (props) => {
  return (
    <div className="Pixi__Wysiwyg--readOnly">
      <Wysiwyg value={props.value} readOnly />
    </div>
  );
};
