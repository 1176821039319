import { createContext } from 'react';

export interface UserStatusInterface {
  show: {
    demo: boolean;
    public_images: boolean;
    changePassword: boolean;
  };
  hide: {
    company_images: boolean;
  };
  product: {
    type?: string;
    isActive?: boolean;
    isEnded?: boolean;
    hasProAndBusiness?: boolean;
    isCanceled?: boolean;
    isManagedByPickit?: boolean;
    isPayingStripe?: boolean;
    isSelfServiced?: boolean;
    isStarter?: boolean;
    isLimited?: boolean;
    pendingOwnership?: boolean;
    subscriptionDaysLeft?: number;
    subscriptionRenewsAt?: string | Date | null;
    isTrial?: boolean;
    isNFR?: boolean;
    isBusinessStarter?: boolean;
    isTrialEnded?: boolean;
    trialDaysLeft?: number | null;
    isBusinessOwner?: boolean;
    isBusinessAdmin?: boolean;
    isBusinessStripe?: boolean;
    isBusinessITadmin?: boolean;
    isExternalUser?: boolean;
    customerStatus?: {
      status: string;
      is_demo: boolean;
      is_demo_disabled: boolean;
      has_self_service: boolean;
      self_service_market: string;
    };
    isInvitationPendingAcceptance?: boolean;
    license_display_name?: string;
    data?: {
      name?: string;
      policy: any;
      partner?: {
        id?: string;
      };
    };
  };
  permissions: string[];
  isActive: boolean;
  notSelected?: boolean;
  products: any[];
  isReady: boolean;
  inactivateBusiness: () => void;
  activateBusiness: () => void;
  nullProductType: () => void;
  activatePro: () => void;
  activateProduct: (product: any) => void;
  activeFeatures: {
    audio_video: boolean;
    external_sharing: boolean;
    custom_branding: boolean;
    it_admin: boolean;
    api_connections: boolean;
    aad_profile: boolean;
    anonymous_boards: boolean;
    custom_email_templates: boolean;
    ocr_and_fulltext_search: boolean;
    attributes: boolean;
    attributes_ai: boolean;
    pickit_vision_ai: boolean;
    custom_boards_overview: boolean;
    user_activity_insights: boolean;
    disable_pickit_board_promotion: boolean;
    inspection_tool: boolean;
    video_transcription: boolean;
  };
  activeServices: {
    media: boolean;
    documents: boolean;
    insights: boolean;
    image_rights: boolean;
    guidelines: boolean;
    boards: boolean;
    license_management: boolean;
  };
  activeIntegrations: {
    pickit: boolean;
    dropbox: boolean;
    gettyimages: boolean;
    box: boolean;
    microsoft365_import: boolean;
    microsoft365_export: boolean;
    adobestock: boolean;
    shutterstock: boolean;
    googledrive: boolean;
    hubspot_export: boolean;
    hubspot_import: boolean;
    adobeccl: boolean;
    network_file_system: boolean;
  };
  isMediaMarketsActive: boolean;
  isOtherSourcesActive: boolean;
  policies: any;
  onboardingScenario: string;
  textFields: any;
}
export const UserStatusContext = createContext<UserStatusInterface>(
  {} as UserStatusInterface,
);
