import React, { useCallback, useEffect, useState } from 'react';
import './style.scss';
import { Button, Dropdown, Icon, Input, Tooltip } from 'elements_v2';
import TextForm from 'views/_Manage/BrandAssets/parts/forms/TextForm';
import useLibraries from 'hooks/useLibraries';

export default function AddRichText(props) {
  const { Module, onTextFileSave, textFile } = props;
  const Libraries = useLibraries();
  const [value, setValue] = useState(props.value);
  const [isReady, setIsReady] = useState(false);
  const [isConvertingToText, setIsConvertingToText] = useState(false);

  const document = (
    Libraries[textFile?.library] || Libraries.media
  )?.data?.files?.find((file) => file._id === textFile?.value);

  useEffect(() => {
    if (isReady) {
      const timeoutSave = setTimeout(() => {
        if (value) {
          props.onChange(value);
        }
      }, 200);
      return () => {
        clearTimeout(timeoutSave);
      };
    }
  }, [value, isReady]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const className = ['Board__Helpers__AddRichText'];

  if (props.small) {
    className.push('Board__Helpers__AddRichText--small');
  }

  // const SelectTextFile = useCallback(
  //   ({ trigger }) =>
  //     Libraries[props.library] || Libraries.media ? (
  //       <SelectFile
  //         style={{ maxWidth: 800 }}
  //         zIndex={99}
  //         context={Libraries[props.library] || Libraries.media}
  //         onChange={(data) => {
  //           onTextFileSave(data);
  //         }}
  //         forceFilters={{
  //           'data.type': 'text',
  //         }}
  //         // customFilter={customFilter}
  //         disabledVariants
  //         title="Select file"
  //         value={value}
  //         library={props.library}
  //         trigger={trigger}
  //         doneLabel={(filesLength) =>
  //           `Add ${filesLength} selected file${filesLength > 1 ? 's' : ''}`
  //         }
  //       />
  //     ) : (
  //       <></>
  //     ),
  //   [props.library]
  // );

  if (!Module?.module?._id) {
    return 'FAIL!';
  }
  if (!Module.isEditing) {
    return <Input.WysiwygPreview value={document?.data?.value || value} />;
  }

  return (
    <div className={className.join(' ')}>
      {isConvertingToText && (
        <TextForm
          isOpen={isConvertingToText}
          onClose={() => setIsConvertingToText(false)}
          onSave={(doc) => onTextFileSave(doc)}
          title={
            document?._id
              ? 'Edit text file'
              : value
                ? 'Convert to text file'
                : 'Create new text file'
          }
          description={
            document?._id ? (
              <>
                <strong>Note:</strong> Changes to this text file will be applied
                to all places this text file is used.
              </>
            ) : undefined
          }
          submitLabel={
            !document?._id && value
              ? 'Convert'
              : !document?._id
                ? 'Create new text file'
                : ''
          }
          context={
            Libraries[textFile?.library] ||
            Libraries[props.library] ||
            Libraries.media
          }
          libraries={[props.library]}
          metadata={
            document?._id
              ? document
              : {
                  data: {
                    value,
                  },
                }
          }
        />
      )}
      <Input.Wysiwyg
        id={props.id || Module.module?._id}
        value={document?.data?.value || value}
        placeholder={!document?.data?.value && !value && props.placeholder}
        white={props.white}
        key={Module.renderId}
        disableEditing={!!document?._id}
        toolbarInjectRight={
          onTextFileSave && (
            <>
              {document?._id ? (
                <div style={{ marginLeft: 'auto' }}>
                  <Dropdown
                    useV2
                    width={220}
                    trigger={
                      <Button
                        size="small"
                        onClick={() => setIsConvertingToText(true)}
                        inline
                        className="no_global_style"
                      >
                        <Icon name="gear" />
                        Edit text
                      </Button>
                    }
                  >
                    <Dropdown.Option
                      onClick={() => setIsConvertingToText(true)}
                    >
                      <Icon name="pencil" />
                      Edit source
                    </Dropdown.Option>
                    <Dropdown.Option
                      onClick={() => {
                        setValue(document?.data?.value);
                        onTextFileSave({});
                      }}
                    >
                      <Icon name="arrow-repeat" />
                      Convert to custom text
                    </Dropdown.Option>
                  </Dropdown>
                </div>
              ) : (
                <Tooltip.Tutorial
                  hover
                  delay={0.5}
                  noTriggerWrapper
                  trigger={
                    <Button
                      size="small"
                      onClick={() => setIsConvertingToText(true)}
                      inline
                      className="no_global_style"
                      style={{ marginLeft: 'auto' }}
                      dropdownProps={{
                        closeOnClick: true,
                      }}
                      dropdown={
                        <>
                          {/* <SelectTextFile
                            trigger={
                              <Dropdown.Option>
                                <Icon name="file-earmark-text" />
                                Select text file
                              </Dropdown.Option>
                            }
                          /> */}
                        </>
                      }
                    >
                      Convert to text file
                    </Button>
                  }
                >
                  <Tooltip.Tutorial.Description>
                    A text file is protected from being accidentally overwritten
                    when working on the board, but also reusable between boards.
                    If the text is important or will take time to write, we
                    always recommend to use text file.
                  </Tooltip.Tutorial.Description>
                </Tooltip.Tutorial>
              )}
            </>
          )
        }
        textEditorPrefs={props.textEditorPrefs}
        onChange={(val) => {
          if (val !== value) {
            setIsReady(true);
            setValue(val);
          }
        }}
        small={props.small}
      />
      {/* <CustomToolbar id={Module.module?._id} />
      <ReactQuill
        id={`wysiwyg_${Module.module?._id}`}
        theme="snow"
        defaultValue={value}
        modules={modules}
        formats={formats}
        onChange={(content) => setValue(content)}
      /> */}
      {/* <RichMarkdownEditor
        readOnly={!Board.isEditing}
        autoFocus
        theme={Board.theme === 'dark' && !props.isEditing ? dark : light}
        onChange={(value) => setValue(value())}
        defaultValue={props.value || (props.small ? smallDefaultValue : defaultValue)}
        disableExtensions={[
          ...['code_inline', 'checkbox_item', 'checkbox_list'],
          ...(props.small
            ? [
                'code_inline',
                'em',
                'placeholder',
                'blockquote',
                'checkbox_item',
                'checkbox_list',
                'code_block',
                'code_fence',
                'embed',
                'heading',
                'hr',
                'image',
                'container_notice',
                'table',
                'td',
                'th',
                'tr',
                'emoji',
              ]
            : []),
          ...(props.disableExtensions || []),
        ]}
      /> */}
    </div>
  );
}
