import {
  Image,
  ImageProps,
  Paper,
  ThemeIcon,
  ThemeIconProps,
} from '@mantine/core';
import { getPixiIconUrl } from '@pixi/elements/Icon/helpers';
import { useAssetThumbnail } from '@pixi/hooks/useAssetThumbnail';
import useStorageUrl from 'hooks/files/useStorageUrl';
import useContextFromFile from 'hooks/useContextFromFile';
import { ReactNode, useState } from 'react';
import { CategoryToIcon } from 'views/_Manage/BrandAssets/helpers/Categories';

export interface AssetIconProps extends ThemeIconProps {
  file: Pickit.FileInterface;
  imageProps?: Partial<Omit<ImageProps, 'style'>>;
  previewSize?: 'tiny' | 'small' | 'medium' | 'large';
}
export function AssetIcon({
  file,
  imageProps,
  previewSize,
  ...rest
}: AssetIconProps) {
  return (
    <ThemeIcon variant="default" radius="xs" {...rest}>
      <AssetThumbnail
        file={file}
        size={previewSize || 'tiny'}
        {...imageProps}
      />
    </ThemeIcon>
  );
}
export function AssetThumbnail({
  file,
  size,
  style,
  ...rest
}: {
  file: Pickit.FileInterface;
  size: 'large' | 'medium' | 'small' | 'tiny';
  style?: (
    imageType: 'source' | 'preview' | 'icon' | 'svg' | 'blank',
  ) => React.CSSProperties;
} & Omit<ImageProps, 'style'>) {
  const [isError, setIsError] = useState(false);
  let { imageType, imageUrl } = useAssetThumbnail({
    file,
    size,
  });

  if (isError) {
    imageType = 'blank';
    imageUrl = `https://cdn.pickit.com/images/file-icons/blank.svg`;
  }

  if (file.data?.type === 'color') {
    return (
      <Paper
        radius="md"
        style={{
          width: '60%',
          height: '60%',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          margin: 'auto',
          backgroundColor: file.data?.value?.color,
        }}
      />
    );
  }
  if (file.data?.type === 'text') {
    const textIcon = CategoryToIcon('text');
    if (textIcon) {
      return (
        <Image
          src={getPixiIconUrl(textIcon)}
          style={{
            ...style?.('icon'),
          }}
          {...rest}
        />
      );
    }
  }
  if (file.data?.type === 'font-family') {
    const textIcon = CategoryToIcon('font');
    if (textIcon) {
      return (
        <Image
          src={getPixiIconUrl(textIcon)}
          style={{
            ...style?.('icon'),
          }}
          {...rest}
        />
      );
    }
  }

  return (
    <Image
      src={imageUrl}
      style={{
        ...style?.(imageType),
      }}
      {...rest}
      onError={() => setIsError(true)}
    />
  );
}
