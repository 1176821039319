import { AppInfoContext } from 'contexts/AppInfo';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { fetchWithTimeout } from 'utils';
import localStorageWrapper from 'utils/localStorageWrapper';

export const useAppInfo = () => useContext(AppInfoContext);

export default function AppInfo({ children }) {
  const [isReady, setIsReady] = useState(false);
  const [data, setData] = useReducer((state, action) => ({ ...action }), {});
  async function getAppInfo() {
    try {
      const req = await fetchWithTimeout(
        'https://pickitappinfo.blob.core.windows.net/pickit-app/info.json',
        {
          timeout: 500,
        },
      );
      if (req.ok) {
        const data = await req.json();
        if (data.USE_V2) {
          redirectAppServerV2Traffic();
        }
        if (data.TEMPORARY_FILE_SERVICE) {
          redirectAppServerTraffic();
        }
        setData(data);
      }
      setIsReady(true);
    } catch (e) {}
    setIsReady(true);
  }

  async function redirectAppServerV2Traffic() {
    // if (window.pickit.config.BASE_URL === 'pickit.com') {
    //   window.pickit.config.MEDIA_URL = `https://files-2.${window.pickit.config.BASE_URL}`;
    // }
  }
  async function redirectAppServerTraffic() {
    if (window.pickit.config.BASE_URL === 'pickit.com') {
      window.pickit.config.MEDIA_URL = `https://files-temp.${window.pickit.config.BASE_URL}`;
    }
  }

  useEffect(() => {
    const TEMPORARY_FILE_SERVICE = localStorageWrapper.getItem(
      'TEMPORARY_FILE_SERVICE',
    );
    if (TEMPORARY_FILE_SERVICE) {
      redirectAppServerTraffic();
    }
    getAppInfo();
  }, []);

  return (
    <AppInfoContext.Provider
      value={{
        data,
      }}
    >
      {!isReady ? <></> : children}
    </AppInfoContext.Provider>
  );
}
