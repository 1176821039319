import { Box, Group, LoadingOverlay, Paper } from '@mantine/core';
import { useWindowEvent } from '@mantine/hooks';
import { AssetThumbnail } from '@pixi/components/AssetThumbnail';
import PDFViewer from '@pixi/elements/PDFViewer';
import PixiText from '@pixi/elements/Text';
import { TransparentPattern } from '@pixi/elements/TransparentPattern';
import { useConfigStoreValue } from '@pixi/store';
import Color from 'color';
import useParsedFile from 'hooks/files/useParsedFile';
import useStorageUrl from 'hooks/files/useStorageUrl';
import useContextFromFile from 'hooks/useContextFromFile';
import { useRef } from 'react';
import { inOffice } from 'utils/platform';

export const AssetPreviewThumbnail = ({
  file,
}: {
  file: Pickit.FileInterface;
}) => {
  const context = useContextFromFile(file);
  const { generateUrl } = useStorageUrl(context);
  const videoRef = useRef<HTMLVideoElement>(null);
  const metadata: Pickit.FileInterface = useParsedFile({ context, file });
  const colorScheme = useConfigStoreValue('APP_VIEW', 'colorScheme');

  useWindowEvent('keydown', (event) => {
    // pause play on space
    if (event.key === ' ') {
      if (videoRef.current) {
        event.preventDefault();
        event.stopPropagation();
        (videoRef.current as any).paused
          ? (videoRef.current as any).play()
          : (videoRef.current as any).pause();
      }
    }
  });
  let color;
  if (metadata?.data?.type === 'color') {
    try {
      color = Color(metadata.data?.value?.color);
    } catch (e) {
      color = Color('#6716d8');
    }
  }

  return (
    <>
      <Group
        w="100%"
        h="100%"
        wrap="nowrap"
        gap={0}
        align="stretch"
        style={{ overflow: 'hidden' }}
      >
        <Box w="100%" h="100%" pos="relative" miw={1}>
          <>
            {file.file?.ext === 'pdf' && !inOffice() ? (
              <PDFViewer url={generateUrl(file.file?.url)} />
            ) : file?.data?.type === 'color' ? (
              <Group w="100%" h="100%" align="center">
                <Paper
                  style={{
                    width: '100%',
                    maxWidth: 400,
                    maxHeight: 400,
                    height: '60%',
                    margin: 'auto',
                    background: color?.hex(),
                  }}
                  radius="md"
                >
                  <Group w="100%" h="100%" align="center" justify="center">
                    <PixiText size="xl" c="white">
                      {file?.name}
                    </PixiText>
                  </Group>
                </Paper>
              </Group>
            ) : file?.data?.type ? (
              <></>
            ) : (
              <>
                <LoadingOverlay
                  visible={file?.processing?.thumbnail?.isProcessing}
                />
                {file?.file?.ext === 'mp4' || file?.file?.previewVideo?.url ? (
                  <>
                    <video
                      playsInline
                      muted
                      autoPlay
                      controls
                      ref={videoRef}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        background: '#000',
                        position: 'relative',
                        zIndex: 10,
                      }}
                    >
                      <source
                        src={generateUrl(file?.file?.previewVideo?.url)}
                      />
                    </video>
                  </>
                ) : (
                  <AssetThumbnail
                    size="large"
                    file={file}
                    style={() => ({
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      objectPosition: 'center',
                      position: 'relative',
                      zIndex: 2,
                    })}
                  />
                )}
                {file?.file?.ext !== 'mp4' && (
                  <TransparentPattern
                    color={colorScheme === 'dark' ? 'dark.6' : 'gray.1'}
                  />
                )}
              </>
            )}
          </>
        </Box>
      </Group>
    </>
  );
};
