import { PaperProps, StackProps } from '@mantine/core';
import createDataStore from '@pixi/classes/DataStore';
import { PixiIconName } from '@pixi/elements/Icon';
import { MultiDataStoreList } from '@pixi/store';
import { PixiIcon } from 'elements_v2/Icon';
import { ReactElement, ReactNode, RefObject } from 'react';
import { AssetActionIds } from '../AssetActions';
import { useUserContext } from 'hooks';
import { useAssetActions } from '../AssetActions/useAssetActions';

export const TableFields = {
  menu: { label: 'Menu', path: '' },
  'file.name': { label: 'File Name', path: 'file.name' },
  'file.url': { label: 'File URL', path: 'file.url' },
  'file.contentLength': { label: 'File Size', path: 'file.contentLength' },
  'file.contentType': { label: 'File Type', path: 'file.contentType' },
  'file.previews.url': { label: 'Preview URL', path: 'file.previews.url' },
  'file.uploaded_at': { label: 'Uploaded', path: 'file.uploaded_at' },
  'file.metadata': { label: 'File Metadata', path: 'file.metadata' },
  'file.image_details.width': {
    label: 'Image Width',
    path: 'file.image_details.width',
  },
  'file.image_details.height': {
    label: 'Image Height',
    path: 'file.image_details.height',
  },
  'file.image_details.palette.Vibrant': {
    label: 'Vibrant Color',
    path: 'file.image_details.palette.Vibrant',
  },
  share_to: { label: 'Sharing Scope', path: 'share_to' },
  'share_to_external_users.token': {
    label: 'External Share Token',
    path: 'share_to_external_users.token',
  },
  'approval.status': { label: 'Approval Status', path: 'approval.status' },
  'processing.thumbnail.isProcessing': {
    label: 'Thumbnail Processing',
    path: 'processing.thumbnail.isProcessing',
  },
  attributes: { label: 'Custom Attributes', path: 'attributes' },
  'license.license': { label: 'License Type', path: 'license.license' },
  'license.usage': { label: 'License Usage Type', path: 'license.usage' },
  collections: { label: 'Collections', path: 'collections' },
  'vision.faces.faceRectangle': {
    label: 'Face Detection',
    path: 'vision.faces.faceRectangle',
  },
  'file.id': { label: 'File ID', path: 'file.id' },
  'file.alias': { label: 'File Alias', path: 'file.alias' },
  'file.version': { label: 'File Version', path: 'file.version' },
  'file.ext': { label: 'File Type', path: 'file.ext' },
  'file.previewVideo.url': {
    label: 'Preview Video URL',
    path: 'file.previewVideo.url',
  },
  'file.previewVideoTranscription.url': {
    label: 'Preview Video Transcription URL',
    path: 'file.previewVideoTranscription.url',
  },
  'file.external_file': {
    label: 'External File Flag',
    path: 'file.external_file',
  },
  'file.checksum': { label: 'File Checksum', path: 'file.checksum' },
  'file.storage': { label: 'Storage Location', path: 'file.storage' },
  'file.uploaded_by': {
    label: 'Uploaded by',
    path: 'file.uploaded_by',
  },
  'file.content_tags': { label: 'Content Tags', path: 'file.content_tags' },
  'file.variants': { label: 'File Variants', path: 'file.variants' },
  'file.slides_published': {
    label: 'Slides Published Flag',
    path: 'file.slides_published',
  },
  'file.allowFeedback': { label: 'Allow Feedback', path: 'file.allowFeedback' },
  'file.allowFeedbackFromExternal': {
    label: 'Allow External Feedback',
    path: 'file.allowFeedbackFromExternal',
  },
  'file.allowEmbedCode': {
    label: 'Allow Embed Code',
    path: 'file.allowEmbedCode',
  },
  'file.feedback.updatedAt': {
    label: 'Feedback Updated At',
    path: 'file.feedback.updatedAt',
  },
  communitySlug: { label: 'Community Slug', path: 'communitySlug' },
  createdAt: { label: 'Creation Date', path: 'createdAt' },
  updatedAt: { label: 'Last Update Date', path: 'updatedAt' },
  country: { label: 'Country', path: 'country' },
  categories: { label: 'Categories', path: 'categories' },
  share_to_users: { label: 'Share To Specific Users', path: 'share_to_users' },
  share_to_user_groups: {
    label: 'Share To User Groups',
    path: 'share_to_user_groups',
  },
  share_to_collections: {
    label: 'Share To Collections',
    path: 'share_to_collections',
  },
  share_to_anonymous: {
    label: 'Share To Anonymous',
    path: 'share_to_anonymous',
  },
  'data.type': { label: 'Data Type', path: 'data.type' },
  folders: { label: 'Folders', path: 'folders' },
  'vision.brands.name': {
    label: 'Detected Brand Names',
    path: 'vision.brands.name',
  },
  'vision.categories.name': {
    label: 'Detected Categories',
    path: 'vision.categories.name',
  },
  type: { label: 'File Interface Type', path: 'type' },
  'approval.status_change_reason': {
    label: 'Approval Status Change Reason',
    path: 'approval.status_change_reason',
  },
  'approval.message': { label: 'Comment', path: 'approval.reason' },
  'file.previews': {
    label: 'Thumbnail',
    path: 'file.file.previews',
  },
  'file.file.ext': { label: 'Type', path: 'file.file.ext' },
  name: {
    label: 'Name',
    path: 'name',
  },
  'feedback.createdBy': {
    label: 'Feedback Created By',
    path: 'feedback.createdBy',
  },
  'feedback.message': {
    label: 'Feedback',
    path: 'feedback.message',
  },
  actions: {
    label: '',
    path: '',
  },
  'license.releases': {
    label: 'License Releases',
    path: 'license.releases',
  },
  latestUserTransferDate: {
    label: 'Created',
    path: 'X',
  },
  latestUserTransferTitle: {
    label: 'Transfer title',
    path: 'X',
  },
  fileToolbar: {
    label: ' ',
    path: 'X',
  },
  'trash.addedAt': {
    label: 'Deleted',
    path: 'X',
  },
  'trash.addedBy': {
    label: 'Deleted by',
    path: 'X',
  },
};

interface ManageFilesSortBy {
  row: string;
  name: string;
  direction: string;
  icon?: PixiIconName;
}

export type AssetGridDisplayAsTypes = 'grid' | 'table' | 'gallery';
export const AssetGridDisplayAs: {
  name: string;
  value: AssetGridDisplayAsTypes;
  icon: PixiIconName;
}[] = [
  {
    name: 'Details',
    icon: 'grid',
    value: 'grid',
  },
  {
    name: 'Gallery',
    icon: 'columns-3',
    value: 'gallery',
  },
  {
    name: 'Table',
    icon: 'table',
    value: 'table',
  },
];

export type AssetGridGroupByTypes = 'createdAt' | 'updatedAt';
export const AssetGridGroupBy: {
  name: string;
  value: AssetGridGroupByTypes;
  icon: PixiIconName;
}[] = [
  {
    name: 'Created',
    icon: 'calendar',
    value: 'createdAt',
  },
  {
    name: 'Updated',
    icon: 'calendar',
    value: 'updatedAt',
  },
];

export type AssetGridSortByTypes =
  | 'createdAt'
  | 'updatedAt'
  | 'name'
  | 'trash.addedAt'
  | '_cosineSimilarty';

export type AssetGridSortByActive = {
  field: AssetGridSortByTypes;
  direction: 'ascending' | 'descending';
};

export type AssetGridCenterTabs = {
  id: string;
  name: ReactNode;
  icon: PixiIconName;
  content?: ReactNode;
  disabled?: boolean;
  disabledTooltip?: ReactNode;
};

export interface AssetGridUploadProps {
  label?: string;
  metadata?: Partial<Pickit.FileInterface>;
  onIntegrationClick?: (integration: any) => void;
  displayIntegrations?: boolean;
  isImage?: boolean;
  markets?: string[];
  onUpload?: (file: any) => void;
  onAlreadyExists?: (file: any) => void;
  uploadToCollection?: Pickit.CollectionInterface;
  disableIntegrations?: boolean;
  withBrandAssets?: boolean;
}

export type AssetGridTableFields = keyof typeof TableFields;

export interface AssetGridProps {
  id: string;
  context: any;
  groupBy?: AssetGridGroupByTypes | null;
  forceDisplayType?: AssetGridDisplayAsTypes;
  displayAs?: AssetGridDisplayAsTypes;
  filters?: any;
  viewportRef?: React.RefObject<HTMLDivElement>;
  prependFiles?: any;
  globalSettings?: string;
  globalFilesHook?: string;
  rightPanelPlaceholder?: boolean;
  selectBehaviour?: 'multiple';
  ignore?: Record<string, string>;
  customFilter?: (file: Pickit.FileInterface) => boolean;
  libraries?: string[];
  forceFilters?: Pickit.LibraryActiveFilters;
  defaultFilters?: Pickit.LibraryActiveFilters;
  defaultFilterShortcuts?: ShortcutIds[];
  uploadProps?: Partial<AssetGridUploadProps>;
  forceFilterShortcuts?: ShortcutIds[];
  forceSortBy?: {
    field: string;
    direction: 'ascending' | 'descending';
  };
  onFilterChange?: (files: any) => void;
  onStatusChange?: (status: {
    isReady: boolean;
    isLoading: boolean;
    isLoadingMore: boolean;
    isAllFilesLoaded: boolean;
    isError: boolean;
    totalFiles: number | null;
  }) => void;
  withCenterTabs?: boolean;
  leftPanelInjection?: ReactNode;
  setAtBottomListener?: (fn: () => void) => void;
  customCenterTabs?: AssetGridCenterTabs[];
  defaultCenterTab?: string;
  centerTabs?: string[];
  noToolbar?: boolean;
  noStats?: boolean;
  readOnly?: boolean;
  addMore?: (files: any) => ReactNode;
  isFavorites?: boolean;
  maxResults?: number;
  setAtBottom?: (fn: () => void) => void;
  onFileClick?: (
    event: any,
    file: Pickit.FileInterface,
    fileToolbar: ReturnType<typeof useAssetActions>,
    allFiles: Pickit.FileInterface[],
  ) => void;
  onFileDoubleClick?: (
    event: any,
    file: Pickit.FileInterface,
    fileToolbar: ReturnType<typeof useAssetActions>,
    allFiles: Pickit.FileInterface[],
  ) => void;
  onVariantClick?: (event: any, file: any, allFiles: any) => void;
  loadingFiles?: string[];
  fileContextMenu?: (file: any) => ReactNode;
  fileToolbar?: AssetActionIds[];
  fileToolbarCustom?: {
    key: string;
    icon: PixiIconName;
    label: string;
    tooltip?: ReactNode;
    wrapper?: (trigger: ReactElement, file: Pickit.FileInterface) => ReactNode;
    onClick?: (file: Pickit.FileInterface) => void;
  }[];
  fileActions?: (file: any) => ReactNode;
  altLink?: boolean;
  multiSelect?: boolean;
  setSelectedFiles?: (fileIds: string[], action: 'add' | 'remove') => void;
  draggable?: boolean;
  fields?: AssetGridTableFields[];
  popupMode?: boolean;
  filePreview?: boolean;
  fullscreenKeys?: string[];
  fullscreenEditPanelOptions?: any;
  fullscreenEditPanelWidth?: string;
  fullscreenRefreshOnClose?: boolean;
  onNoFiles?: () => void;
  toolbarLeftInjection?: ReactNode;
  toolbarLeftAfterUploadInjection?: ReactNode;
  toolbarRightInjection?: ReactNode;
  toolbarTopInjection?: ReactNode;
  isSearchProperties?: boolean;
  disableFilters?: string[];
  addUploadedFiles?: boolean;
  disableFullscreen?: boolean;
  isContributor?: boolean;
  onDisplayTypeChange?: (displayAs: string) => void;
  preventDisplayTypeSave?: boolean;
  noBottomEvent?: boolean;
  toolbarBelowInjection?: ReactNode;
  setEditCollection?: any;
  skipTopbar?: boolean;
  emptyView?: ReactNode;
  noResultsView?: ReactNode;
  collectionId?: string;
  noGlobal?: boolean;
  onClose?: () => void;
  folderId?: string;
  libraryView?: string;
  toolbar?: {
    displayAss?: string[];
    sortBy?: ManageFilesSortBy[];
  };
  selectedFiles?: string[];
  noPadding?: boolean;
  isolated?: boolean;
  disabledVariants?: boolean;
  disable?: (
    | 'centerPanel'
    | 'rightPanel'
    | 'leftPanel'
    | 'toolbar'
    | 'assets'
    | 'status'
    | 'tableHeader'
    | 'selection'
    | 'fullscreen'
    | 'upload'
    | 'filter'
  )[];
  defaultDisable?: (
    | 'centerPanel'
    | 'rightPanel'
    | 'leftPanel'
    | 'toolbar'
    | 'assets'
    | 'status'
    | 'tableHeader'
    | 'selection'
    | 'fullscreen'
    | 'upload'
  )[];
  panelProps?: {
    leftPanel?: Partial<AssetGridLeftPanelProps>;
    rightPanel?: Partial<AssetGridRightPanelProps>;
    centerPanel?: Partial<PaperProps>;
  };
  forceLeftPanelSection?: 'filters';
  onlyFileTypes?: string;
  onSort?: (column: string, direction: string) => void;
  downloadWrapper?: (
    trigger: ReactElement,
    onConfirm?: () => void,
    label?: string,
  ) => void;
  mounted?: boolean;
  isInPopup?: boolean;
  autoState?: (
    files: Pickit.FileInterface,
    change: 'added' | 'updated' | 'removed',
    filesRendered: Pickit.FileInterface[],
  ) => 'prepend' | 'append' | 'remove' | null;
  fileStatusListener?: (
    storeKey: string,
    state: ReturnType<
      typeof createDataStore<
        MultiDataStoreList['FILES_STATUS']['storeData'],
        string
      >
    >,
  ) => void;
  customRender?: (file: Pickit.FileInterface) => ReactNode;
  paperProps?: PaperProps;
}

export interface AssetGridFiltersProps {
  onClose?: () => void;
  disable?: ('count' | 'search')[];
  noBackground?: boolean;
  specificFilters?: string[];
  asDropdown?: boolean;
}
export interface AssetGridLeftPanelProps extends Partial<PaperProps> {
  forceLeftPanelSection?: 'filters';
  assetGridFiltersProps?: Partial<AssetGridFiltersProps>;
}
export interface AssetGridRightPanelProps extends Partial<PaperProps> {
  keepPlaceholder?: boolean;
}

export type ShortcutIds =
  | 'license_expired'
  | 'license_missing'
  | 'feedback_needed'
  | 'feedback_reviewed'
  | 'approvals.approval_needed'
  | 'approvals.approved'
  | 'approvals.rejected'
  | 'user_uploaded'
  | 'trash.active'
  | 'trash.user'
  | 'ai.image_similarity';
export type ShortcutGroupIds =
  | 'license'
  | 'feedback'
  | 'approvals'
  | 'user'
  | 'trash'
  | 'ai';

export type ShortcutDataRow = {
  id: ShortcutIds;
  icon: PixiIconName;
  label: string;
  buttonLabel?: string;
  description: string;
  filter?: Pickit.LibraryActiveFilters;
  settings?: {
    displayAs?: AssetGridDisplayAsTypes;
    groupBy?: AssetGridGroupByTypes | null;
    sortBy?: AssetGridSortByActive | null;
    fields?: AssetGridTableFields[];
  };
  revert: () => void;
};

export interface AssetGridContextInterface {
  id: string;
  context: any;

  viewport: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

  allFiles: Pickit.FileInterface[];

  status: {
    isReady: boolean;
    isLoading: boolean;
    isLoadingMore: boolean;
    isLoadingFilters: boolean;
    isAllFilesLoaded: boolean;
    isError: boolean;
    totalFiles: number | null;
  };

  // fileToolbar?: {
  //   key: string;
  //   icon: PixiIconName;
  //   label: string;
  //   onClick: (file: Pickit.FileInterface) => void;
  // }[];

  tableFields?: AssetGridProps['fields'];
  onFileClick: AssetGridProps['onFileClick'];

  fileToolbar?: AssetGridProps['fileToolbar'];
  fileToolbarCustom?: AssetGridProps['fileToolbarCustom'];

  disable?: AssetGridProps['disable'];
  setDisable: (disable: AssetGridProps['disable']) => void;

  displayAs: AssetGridDisplayAsTypes;
  setDisplayAs: (displayAs: AssetGridDisplayAsTypes) => void;
  gridSize: 'small' | 'medium' | 'large';
  setGridSize: (size: 'small' | 'medium' | 'large') => void;

  groupBy: AssetGridGroupByTypes | null;
  setGroupBy: (groupBy: AssetGridGroupByTypes | null) => void;

  sortBy: AssetGridSortByActive | null;
  setSortBy: (sortBy: AssetGridSortByActive) => void;

  activeFilters: Pickit.LibraryActiveFilters;
  forceFilters?: Pickit.LibraryActiveFilters;
  defaultFilters?: Pickit.LibraryActiveFilters;
  combinedFilters: Pickit.LibraryActiveFilters; // Includes forceFilters, shortcut filters and normal filters

  selectedFiles: { _id: string }[];

  availableFilters: Record<string, Pickit.LibraryFilter>;
  frozenFilters: Record<string, Pickit.LibraryFilter>;
  setFilter: (
    filterName: string,
    row: Pickit.LibraryActiveFilterRow[] | null,
    prefs?: { replace?: boolean },
  ) => void;
  setFilters: (filters: Pickit.LibraryActiveFilters) => void;
  setParam: (key: string, data: any) => void;

  readOnly?: boolean;

  shortcuts: {
    id: ShortcutGroupIds;
    label: string;
    icon: PixiIconName;
    hidden?: boolean;
    data: ShortcutDataRow[];
  }[];
  activeShortcutsData: ShortcutDataRow[];
  activeShortcuts: {
    id: ShortcutIds;
    filter?: Pickit.LibraryActiveFilters;
  }[];
  activeShortcutIds: ShortcutIds[];
  forceFilterShortcuts?: string[];
  activeShortcutsFilter: Pickit.LibraryActiveFilters;
  toggleActiveShortcut: (id: {
    id: ShortcutIds;
    filter?: Pickit.LibraryActiveFilters;
  }) => void;
  setActiveShortcuts: (
    ids: {
      id: ShortcutIds;
      filter?: Pickit.LibraryActiveFilters;
    }[],
    prefs?: {
      force?: boolean; // Forcefully reset the shortcut even if it already is active
    },
  ) => void;

  isInPopup?: boolean;
  leftPanelInjection?: ReactNode;

  getFiles: (clearCache?: boolean) => Promise<boolean | void>;

  uploadProps: Partial<AssetGridUploadProps>;

  viewportRef?: RefObject<HTMLDivElement>;
  multiSelect?: boolean;
  libraries?: string[];
}

export function filterRowValueIsActive(
  filter: Pickit.LibraryActiveFilterRow[],
  dataRowValue: Pickit.LibraryActiveFilterRow['value'],
) {
  return !!filter
    ?.map((val) =>
      Array.isArray(val.value) ? val : filterValueToString(val.value),
    )
    ?.filter((val) => {
      if (typeof val === 'string') {
        return val === filterValueToString(dataRowValue);
      }
      return (val.value as string[]).includes(
        filterValueToString(dataRowValue),
      );
    })?.length;
}
export function filterValueToString(
  dataRowValue: Pickit.LibraryActiveFilterRow['value'],
) {
  return typeof dataRowValue === 'object'
    ? Array.isArray(dataRowValue)
      ? dataRowValue?.join(', ')
      : JSON.stringify(dataRowValue)
    : typeof dataRowValue === 'boolean'
      ? dataRowValue
        ? 'Yes'
        : 'No'
      : dataRowValue?.toString();
}
