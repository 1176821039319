import React, { useEffect, forwardRef } from 'react';
import './style.scss';
import { Flex, Icon, Input } from 'elements_v2';
// import { usePopper } from 'react-popper';
import useBoundingclientrect from '@rooks/use-boundingclientrect';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import Tooltip from 'rc-tooltip';
import { mergeRefs } from 'utils';

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}
// type Placement =
//   | 'auto'
//   | 'auto-start'
//   | 'auto-end'
//   | 'top'
//   | 'top-start'
//   | 'top-end'
//   | 'bottom'
//   | 'bottom-start'
//   | 'bottom-end'
//   | 'right'
//   | 'right-start'
//   | 'right-end'
//   | 'left'
//   | 'left-start'
//   | 'left-end';

const Popover = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = React.useReducer((isOpen, newIsOpen) => {
    if (props.isForceOpen) {
      return true;
    }
    return newIsOpen;
  }, props.defaultOpen || props.isOpen || false);
  const [isHover, setIsHover] = React.useState(false);
  const referenceElement = React.useRef();
  const referenceRects = useBoundingclientrect(referenceElement);
  // const [popperStyle, setPopperStyle] = React.useReducer((state, action) => ({ ...action }), {});

  const contentRef = useBottomScrollListener(() => {
    props.onBottomScroll?.();
  });
  useOnClickOutside(referenceElement, () => {
    setTimeout(() => {
      if (isOpen && !props.locked && props.hideOnClickOutside !== false) {
        setIsOpen(false);
        if (props.onClose) {
          console.log('76');
          props.onClose();
        }
      }
    }, 100);
  });

  // function updatePositionInterval() {
  //   if (!isOpen) {
  //     return false;
  //   }
  //   if (update) {
  //     update();
  //   }
  //   setTimeout(() => {
  //     updatePositionInterval();
  //   }, 200);
  // }

  React.useEffect(() => {
    if (typeof props.isOpen === 'boolean') {
      setIsOpen(props.isOpen);
    }
  }, [props.isOpen]);

  React.useEffect(() => {
    if (typeof props.isForceOpen === 'boolean') {
      setIsOpen(props.isForceOpen);
    }
  }, [props.isForceOpen]);

  React.useEffect(() => {
    if (typeof props.forceClosed === 'boolean') {
      setIsOpen(props.forceClosed);
    }
  }, [props.forceClosed]);

  React.useEffect(() => {
    if (isOpen) {
      props.onOpen?.();
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (isHover) {
      const timeout = setTimeout(() => {
        if (isHover) {
          setIsOpen(true);
        } else {
          setIsOpen(false);
        }
      }, props.hoverDelay || 0);
      return () => clearTimeout(timeout);
    }
  }, [isHover, props.hoverDelay]);

  function onMouseOver() {
    if (!isHover) {
      setIsHover(true);
    }
  }
  function onMouseLeave() {
    setIsHover(false);
    setIsOpen(false);
  }

  function onChange(value) {
    if (props.onChange) {
      props.onChange(value);
    }
    setIsOpen(false);
  }

  // const { styles, attributes, update } = usePopper(referenceElement?.current, ref?.current, {
  //   placement: props.position || 'bottom',
  //   strategy: props.strategy,
  // });

  const className = ['Pixi', 'Pixi__Popover', 'Pixi--clickable'];

  if (isOpen) {
    className.push('Pixi__Popover--open');
  }

  if (props.active) {
    className.push('Pixi__Popover--active');
  }

  if (props.onlyIcon) {
    className.push('Pixi__Popover--onlyIcon');
  }

  if (props.position && props.position === 'right') {
    className.push('Pixi__Popover--right');
  }

  if (props.position && props.position === 'left') {
    className.push('Pixi__Popover--left');
  }

  if (props.trigger && className.includes('Pixi--clickable')) {
    className.splice(className.indexOf('Pixi--clickable'), 1);
  }
  if (props.noPopover) {
    className.push('Pixi__Popover--noPopover');
  }

  const content =
    typeof props.children === 'function'
      ? props.children(isOpen, setIsOpen)
      : props.children;

  const popperBoxRef = mergeRefs([ref, referenceElement]);

  const popperBox = (
    <div
      className={`Pixi ${
        props.noPopover ? 'Pixi__Popover__box--noPopover' : ''
      } Pixi__Popover__box ${props.boxClassName} ${
        isOpen ? 'Pixi__Popover__box--open' : ''
      }`}
      ref={popperBoxRef}
      data-popper-placement={props.position || 'bottom'}
      style={
        isOpen
          ? {
              width: props.width,
              maxWidth: props.auto || props.width ? 'none' : '',
              minWidth: props.auto
                ? 0
                : props.width
                ? props.width
                : props.sameWidthAsTrigger
                ? referenceRects?.width > 235
                  ? referenceRects?.width
                  : 235
                : null,
              zIndex: props.zIndex,
              // ...popperStyle,
              ...(props.boxStyle || {}),
            }
          : {}
      }
    >
      {!props.close && (
        <div
          className={[
            'Pixi__Popover__box__content',
            ...(props.title || props.action
              ? ['Pixi__Popover__box__content--card']
              : []),
          ].join(' ')}
          ref={contentRef}
          style={props.boxContentStyle}
          onClick={props.closeOnClick ? () => setIsOpen(false) : () => {}}
        >
          {!!props.title && (
            <div
              className="Pixi__Popover__content__header"
              style={props.headerStyle}
            >
              <div className="Pixi Pixi__Popover__content__header__title">
                <h1>{props.title}</h1>
                {typeof props.description === 'function' ? (
                  <p>{props.description(isOpen, setIsOpen)}</p>
                ) : (
                  !!props.description && <p>{props.description}</p>
                )}
              </div>
              {props.titleAction}
            </div>
          )}
          {!!props.actions || !!props.title ? (
            <div className="Pixi__Popover__content__text">{content}</div>
          ) : (
            content
          )}
          {!!props.actions && (
            <div className="Pixi__Popover__content__actions">
              {typeof props.actions === 'function'
                ? props.actions(isOpen, setIsOpen)
                : props.actions}
            </div>
          )}
        </div>
      )}
    </div>
  );
  if (props.onlyBox) {
    return popperBox;
  }

  const triggerProps = {};
  if (!props.locked && !props.hover && !props.customOnClick) {
    triggerProps.onClick = (event) => {
      if (!props.locked) {
        event.preventDefault();
        event.stopPropagation();
        setIsOpen(!isOpen);
      }
    };
  }

  const PopperBox = popperBox;
  const triggerButton =
    typeof props.trigger === 'function'
      ? props.trigger(isOpen, setIsOpen)
      : typeof props.isOpen !== 'boolean' && props.trigger
      ? React.cloneElement(props.trigger, {
          ...triggerProps,
        })
      : props.trigger;

  if (props.useV2) {
    const trigger =
      props.noTriggerWrapper && props.trigger ? (
        triggerButton
      ) : (
        <div
          style={props.triggerStyle}
          onClick={
            props.trigger?.type === React.Fragment ? triggerProps.onClick : null
          }
          // onMouseOver={props.hover ? onMouseOver : null}
          // onMouseLeave={props.hover ? onMouseLeave : null}
          className={`Pixi__Popover__trigger ${
            props.noPopover ? 'Pixi__Popover__trigger--noPopover' : ''
          } ${isOpen ? 'Pixi__PopoverV2__trigger--open' : ''} ${
            props.triggerClassName || ''
          }`}
        >
          {triggerButton}
        </div>
      );
    if (props.disabled) {
      return trigger;
    }
    return (
      <Tooltip
        visible={props.locked ? true : props.forceClosed ? false : isOpen}
        onClose={() => {
          console.log('317');
          props.onClose?.();
          setIsOpen(false);
        }}
        placement={props.position || 'bottomLeft'}
        overlayClassName={[
          'Pixi__PopoverV2',
          ...(props.overlay ? ['Pixi__PopoverV2--overlay'] : []),
          ...(props.limitHeight ? ['Pixi__PopoverV2--limitHeight'] : []),
        ].join(' ')}
        arrowContent={<></>}
        overlayStyle={{
          ...props.overlayStyle,
          ...(props.fixed ? { position: 'fixed' } : {}),
        }}
        zIndex={props.zIndex}
        trigger={
          props.locked ? [] : props.hover ? ['hover', 'click'] : ['click']
        }
        mouseLeaveDelay={props.mouseLeaveDelay ?? 0.05}
        getTooltipContainer={props.getPopoverContainer}
        align={
          props.overlay
            ? {
                points: ['cc', 'cc'], // align top left point of sourceNode with top right point of targetNode
                offset: [0, 0], // the offset sourceNode by 10px in x and 20px in y,
                targetOffset: ['0%', '0%'], // the offset targetNode by 30% of targetNode width in x and 40% of targetNode height in y,
                overflow: { adjustX: true, adjustY: true }, // auto adjust position when sourceNode is overflowed
              }
            : {}
        }
        onVisibleChange={(visible) => {
          if (props.customOnClick) return;

          if (!visible) {
            props.onClose?.();
          }

          setIsOpen(visible);
        }}
        overlay={
          <div ref={referenceElement}>
            {props.formWrap ? (
              <form onSubmit={props.onSubmit}>{PopperBox}</form>
            ) : (
              PopperBox
            )}
          </div>
        }
      >
        {trigger}
      </Tooltip>
    );
  }

  return (
    <>
      <>
        {props.triggerRender ? props.triggerRender(PopperBox) : ''}
        {props.trigger && !props.triggerRender && (
          <div
            style={props.triggerStyle}
            onClick={
              props.trigger?.type === React.Fragment
                ? triggerProps.onClick
                : null
            }
            onMouseOver={props.hover ? onMouseOver : null}
            onMouseLeave={props.hover ? onMouseLeave : null}
            className={`Pixi__Popover__trigger ${
              props.noPopover ? 'Pixi__Popover__trigger--noPopover' : ''
            } ${isOpen ? 'Pixi__Popover__trigger--open' : ''} ${
              props.triggerClassName || ''
            }`}
            ref={referenceElement}
          >
            {typeof props.trigger === 'function'
              ? props.trigger(isOpen, setIsOpen)
              : React.cloneElement(props.trigger, triggerProps)}
            {PopperBox}
          </div>
        )}
      </>
      {!props.trigger && (
        <div
          className={className.join(' ')}
          style={{ ...props.style }}
          ref={referenceElement}
        >
          <Input
            className={props.className}
            onClick={() => setIsOpen(!isOpen)}
            icon={
              props.icon && (
                <Icon
                  name={props.icon}
                  style={{ color: props.iconColor }}
                  onClick={() => props.onChange(null)}
                />
              )
            }
            field={props.value || props.placeholder}
            active={props.active}
            largeStatusOnActive={props.largeStatusOnActive}
            status={
              <Icon
                bubble={!!props.value && !props.noClear}
                onClick={(event) => {
                  if (props.value && !props.noClear) {
                    event.preventDefault();
                    event.stopPropagation();
                    onChange(null);
                  }
                }}
                style={{
                  transform: `scale(${props.value && !props.noClear ? 1 : 1})`,
                }}
                name={props.value && !props.noClear ? 'X' : 'CaretDownFill'}
              />
            }
          />
          {PopperBox}
        </div>
      )}
    </>
  );
});

export default Popover;
