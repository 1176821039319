import React, { useEffect, useReducer, useRef } from 'react';
import './style.scss';
import { useTextContext, useUserContext } from 'hooks';
import useUserStatus from 'hooks/useUserStatus';
import { FileThumbnailV2 } from 'components/FileThumbnail';
import { PinkToolbar } from 'elements_v2/PinkToolbar';
import useStorageUrl from 'hooks/files/useStorageUrl';
import IconFile from 'elements_v2/IconFile';
import {
  CollectionGroup,
  Flex,
  Icon,
  Icon as PixiIcon,
  Tooltip,
} from '../../elements_v2';

const PublishedFolderImage = React.memo((props) => {
  const imageRef = useRef();
  if (React.isValidElement(props.thumbnail)) {
    return (
      <div className="PublishedFolder__image" ref={imageRef}>
        {props.thumbnail}
        {!!props.toolbar && (
          <PinkToolbar parentRef={imageRef}>{props.toolbar}</PinkToolbar>
        )}
      </div>
    );
  }
  return (
    <>
      <div
        className="PublishedFolder__image"
        ref={imageRef}
        style={{ backgroundImage: `url(${props.thumbnail})` }}
      >
        {props.type?.includes('video') && (
          <video muted autoPlay="autoplay" loop playsinline>
            <source src={props.thumbnail} mimetype={props.type} />
          </video>
        )}
        {!!props.toolbar && (
          <PinkToolbar parentRef={imageRef}>{props.toolbar}</PinkToolbar>
        )}
      </div>
    </>
  );
});

const PublishedFolder = React.forwardRef((props, ref) => {
  const Text = useTextContext();
  const User = useUserContext();
  const userStatus = useUserStatus();
  const { generateUrl } = useStorageUrl();
  const contentRef = useRef();
  const [folderData, setFolderData] = useReducer(
    (state, action) => ({ ...action }),
    []
  );
  const { item, library } = props;

  useEffect(() => {
    if (!folderData?._id) {
      // getFirstItems();
    }
  }, [props.preferences]);

  const className = ['PublishedFolder'];
  if (props.className) {
    className.push(props.className);
  }
  if (props.createNewPlaceholder) {
    className.push('PublishedFolder--createNewPlaceholder');
  }
  if (props.ignoreResponsive) {
    className.push('PublishedFolder--ignoreResponsive');
  }
  if (props.onClick && !props.disabled) {
    className.push('PublishedFolder--clickable');
  }
  if (props.createNewPlaceholder) {
    className.push('PublishedFolder--createNewPlaceholder');
  }
  if (props.size === 'large') {
    className.push('PublishedFolder--hero');
  }
  if (props.size === 'small') {
    className.push('PublishedFolder--small');
  }
  if (
    item?.publish?.[library]?.thumbnail_size ||
    item?.preferences?.thumbnail?.size
  ) {
    className.push(`PublishedFolder--${item?.preferences?.thumbnail?.size}`);
  }
  if (item?.preferences?.thumbnail?.style) {
    className.push(
      `PublishedFolder--style-${props.item?.preferences?.thumbnail?.style}`
    );
  }
  if (props.active) {
    className.push('PublishedFolder--active');
  }

  if (props.isDragging) {
    className.push('PublishedFolder--isDragging');
  }

  const toolbar = props.toolbar && (
    <PinkToolbar parentRef={contentRef}>
      {props.toolbar?.map((item) => (
        <Tooltip
          key={item.tooltip}
          position="bottom"
          message={item.tooltip}
          small
          delay={800}
        >
          <PixiIcon
            button
            bubble
            name={item.icon}
            style={item.iconStyle}
            onClick={(event) => item.onClick(event, item)}
          />
        </Tooltip>
      ))}
    </PinkToolbar>
  );

  if (item.preferences?.thumbnail?.display_as === 'collection_group') {
    return (
      <CollectionGroup
        style={{ width: '100%', ...(props.style || {}) }}
        title={item?.publish?.name || item?.name}
        collections={[
          ...(item._firstFiveItems?.map((item) => ({
            title: item.name,
            onClick: () =>
              window.alert('Nice try, no sneak peek until I get more BOSK!'),
            customThumbnail:
              item.type === 'folder' ? (
                <Icon name="folder" style={{ fontSize: 20 }} />
              ) : (
                <IconFile
                  name={
                    item.referenceData?.file?.ext ||
                    item.name?.split('.')?.pop() ||
                    'blank'
                  }
                  style={{ width: 20 }}
                  alt={item.referenceData?.name}
                />
              ),
          })) || []),
          ...(item._totalItems > 5
            ? [
                {
                  title: `See all ${item._totalItems} items`,
                  style: { color: '#6716d8' },
                  customThumbnail: (
                    <Icon name="folder2-open" style={{ fontSize: 20 }} />
                  ),
                },
              ]
            : []),
        ]}
      />
    );
  }

  // This algoritm determines the fontSize so it fits perfectly
  const thumbnailRects = contentRef?.current?.getBoundingClientRect();
  const nameLength = item?.publish?.name?.length || item?.name?.length;
  const fontSizeScale = thumbnailRects?.width / (nameLength * 8.4);
  let fontSize = fontSizeScale * nameLength;
  if (fontSize < 12) {
    fontSize = 12;
  }

  return (
    <div ref={ref} style={props.style} className={className.join(' ')}>
      <div className="PublishedFolder__wrapper" ref={contentRef}>
        {props.overlay}
        <PinkToolbar parentRef={contentRef} {...(props.contextMenuProps || {})}>
          {props.contextMenu}
        </PinkToolbar>
        <div
          className="PublishedFolder__wrapper__content brand__color__border__before"
          onClick={props.onClick}
        >
          {props.createNewPlaceholder && (
            <div className="PublishedFolder__wrapper__content__overlay">
              {props.overlayContent}
            </div>
          )}

          {props.isNew && (
            <div className="newBadge" title={Text.get('ui/new')} />
          )}
          {props.isUpdated && !props.isNew && (
            <div className="updatedBadge" title={Text.get('ui/updated')} />
          )}
          {toolbar}
          <PublishedFolderImage
            type={props.type}
            thumbnail={
              <>
                {props.thumbnail ||
                  (item?.preferences?.thumbnail?.custom?.id && (
                    <img
                      alt={item?.name}
                      src={
                        item?.preferences?.thumbnail?.custom?.id ? (
                          generateUrl(item?.preferences?.thumbnail?.custom?.url)
                        ) : !item?.preferences?.thumbnail?.from_media?.id ? (
                          <FileThumbnailV2
                            file={!item?.preferences?.thumbnail?.from_media}
                          />
                        ) : undefined
                      }
                    />
                  ))}
              </>
            }
            overlay={props.overlay}
          />
          {props.withArrows && (
            <>
              <div
                className="PublishedFolder__action PublishedFolder__action--left"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  props.onLeftArrowClick();
                }}
              >
                <Icon name="ChevronLeft" />
              </div>
              <div
                className="PublishedFolder__action PublishedFolder__action--right"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  props.onRightArrowClick();
                }}
              >
                <Icon name="ChevronRight" />
              </div>
            </>
          )}
          {!item?.thumbnail?.id &&
            !item?.thumbnail_media?._id &&
            props.noThumbnailView && (
              <div className="PublishedFolder__wrapper__noThumbnailView">
                {props.noThumbnailView}
              </div>
            )}
          {props.icon ? (
            <div className="PublishedFolder__icon">
              <Tooltip message={props.icon.label}>
                <Icon name={props.icon.name} />
              </Tooltip>
            </div>
          ) : (
            ''
          )}
          {!!item?.name && (!item?.hide_name || props.forceName) && (
            <div
              className="PublishedFolder__name"
              style={
                item?.preferences?.thumbnail?.style === 'bold'
                  ? { fontSize }
                  : {}
              }
            >
              <h1>{item?.publish?.name || item?.name}</h1>
            </div>
          )}
          {!userStatus.product.isBusinessAdmin &&
            item?.libraryType &&
            item?.permissions?.contributor_users?.some(
              (obj) =>
                obj?.email === User.djangoProfile?.email ||
                obj.user_id === User.djangoProfile?.id
            ) && (
              <div
                className="PublishedFolder__author-icon brand__color__bg"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  User.openInWeb(
                    `/manage/${item?.libraryType}?library-collection=${item._id}`,
                    true
                  );
                }}
              >
                <Tooltip message="The admin has given you access to upload to this collection">
                  <Flex>
                    <PixiIcon
                      name="CloudArrowUpFill"
                      key="preview_collection"
                    />
                  </Flex>
                </Tooltip>
              </div>
            )}
        </div>
      </div>
    </div>
  );
});

export default PublishedFolder;
