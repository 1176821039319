import { differenceInHours } from 'date-fns';
import { useDocumentsContext } from 'hooks/useDocumentsContext';
import { useMediaContext } from 'hooks/useMediaContext';
import { useUserContext } from 'hooks/useUserContext';
import { useEffect, useReducer, useState } from 'react';
import asyncPool from 'utils/async-pool';
import { adobeMimetoExt, googleMimetoExt } from 'utils';
import localStorageWrapper from 'utils/localStorageWrapper';

export default function useAdobeCCL(props) {
  const { Adobe } = props;
  const DAILY_SYNC_INTERVAL_HOURS = 8;
  const Media = useMediaContext();
  const Documents = useDocumentsContext();
  const User = useUserContext();
  const [jobs, setJobs] = useReducer((state, action) => {
    if (Array.isArray(action)) {
      return [...action];
    }
    if (action?.type === 'UPDATE_STATUS') {
      return state.map((j) =>
        j._id === action.jobId
          ? { ...j, status: { ...j.status, ...action.status } }
          : j
      );
    }
    if (action?.type === 'UPDATE_FILE_STATUS') {
      return state.map((j) =>
        j._id === action.jobId
          ? {
              ...j,
              status: {
                ...j.status,
                files: {
                  ...(j.status?.files || {}),
                  [action.fileId]: action.status,
                },
              },
            }
          : j
      );
    }
  }, []);

  const [jobQueue, setJobQueue] = useReducer(
    (state, action) => [...action],
    []
  );
  const [isRunningJobs, setIsRunningJobs] = useState(false);

  useEffect(() => {
    if (jobQueue?.length && !isRunningJobs) {
      runJobQueue();
    }
  }, [jobQueue, isRunningJobs]);

  useEffect(() => {
    if (jobs.length && !jobQueue?.length) {
      const notChecked = jobs.filter((job) => {
        return (
          (!job.status || (!job.status?.isRunning && !job.status?.isChecked)) &&
          !jobQueue.includes(job._id) &&
          (job.occurence === 'daily' || !job.lastRun) &&
          differenceInHours(new Date(), new Date(job.lastRun)) >=
            DAILY_SYNC_INTERVAL_HOURS
        );
      });
      setJobQueue([...jobQueue, ...notChecked.map((job) => job._id)]);
    }
  }, [jobs]);

  async function getImportedFiles() {
    await Promise.all(
      ['media', 'documents'].map(async (type) => {
        if (type === 'media') {
          await Media.importGetFiles('adobeccl');
        }
        if (type === 'documents') {
          await Documents.importGetFiles('adobeccl');
        }
      })
    );
  }

  async function queueJob(job) {
    setJobQueue([...jobQueue, job._id]);
  }

  async function runJobQueue() {
    const firstJob = jobQueue?.[0];
    if (isRunningJobs) {
      return false;
    }
    if (!firstJob) {
      setIsRunningJobs(false);
      return false;
    }
    const activeJob = jobs.find((job) => job._id === firstJob);
    if (activeJob?.status?.isRunning) {
      setJobQueue(jobQueue.filter((j, key) => !!key));
      return false;
    }
    setJobQueue(jobQueue.filter((j, key) => !!key));
    setIsRunningJobs(true);
    await runJob(activeJob);
    setIsRunningJobs(false);
  }

  async function getJobs() {
    const data = await User.request.files.importJobs.getJobs('adobeccl');

    const jobs = await asyncPool(10, data.jobs, async (job) => {
      let metadata;
      if (job.itemId) {
        try {
          metadata = await getLibrary(job.itemId);
        } catch (e) {
          console.error(
            `failed getting drive item for job. drive: ${job.driveId} | item: ${job.itemId}`
          );
        }
      }
      return {
        ...job,
        metadata,
      };
    });

    setJobs(jobs);
    setJobQueue(jobs.map((job) => job._id));
  }

  function getToken() {
    const adobeCC = JSON.parse(localStorageWrapper.getItem('adobe_cc'));
    return adobeCC.access_token;
  }

  async function runJob(job) {
    const Library =
      job.library === 'media'
        ? Media
        : job.library === 'documents'
        ? Documents
        : null;

    if (!Library) {
      updateJobStatus({
        type: 'ERROR',
        code: 'NO_LIBRARY_SELECTED',
        files: [],
      });
      return false;
    }

    function updateJobStatus(status) {
      setJobs({ type: 'UPDATE_STATUS', jobId: job._id, status });
    }
    function updateFileStatus(file, status) {
      setJobs({
        type: 'UPDATE_FILE_STATUS',
        jobId: job._id,
        fileId: file.id,
        status: { ...status, data: file },
      });
    }

    /**
     * Check if Job collection has been deleted
     */
    const isSyncingToCollection = job.libraryCollection;
    const collection = job.libraryCollection
      ? Library.data.collections.find(
          (collection) => collection._id === job.libraryCollection
        )
      : false;

    if (isSyncingToCollection && !collection?._id) {
      updateJobStatus({
        code: 'ERROR_COLLECTION_DELETED',
        isLoading: false,
        isError: true,
      });
      return false;
    }
    /**
     * end check
     */

    updateJobStatus({ code: 'FETCHING_DRIVE', isLoading: true, files: [] });

    updateJobStatus({ isRunning: true });
    // const item = await getElement(job.driveId, job.itemId);

    // Get all files ## NESTED
    updateJobStatus({ code: 'FETCHING_ALL_FILES', files: [] });
    let files;
    try {
      files = await getAllElementsFromLibrary(
        job.itemId,
        undefined,
        'representations'
      );
    } catch (e) {
      return updateJobStatus({
        type: 'ERROR',
        code: 'NO_PERMISSION',
        isLoading: false,
      });
    }

    const fileIds = files.map((file) => file.id);

    // Get all imported files
    updateJobStatus({ code: 'CHECKING_ALREADY_IMPORTED', isLoading: true });
    const importedReferences = await Library.importCheckReferences(
      'adobeccl',
      fileIds
    );

    const filesNotImported = files.filter(
      (file) =>
        !importedReferences.find((f) => f?.import?.reference === file.id)
    );
    let filesModified = files.filter((file) => {
      const importedFile = importedReferences.find(
        (f) => f?.import?.reference === file.id
      );
      if (!importedFile) {
        return false;
      }

      if (
        importedFile &&
        new Date(importedFile.file.uploaded_at).getTime() <
          new Date(file.modified_date).getTime()
      ) {
        updateFileStatus(file, { type: 'ACTION', code: 'FILE_CHANGES_EXISTS' });
        return true;
      }
      updateFileStatus(file, { type: 'SUCCESS', code: 'IMPORTED' });
      return false;
    });

    const filesNotInCollection = !job.libraryCollection
      ? []
      : importedReferences.filter(
          (file) => !file?.collections?.includes(job.libraryCollection)
        );

    if (filesNotInCollection?.length) {
      updateJobStatus({ code: 'ADDING_FILES_TO_COLLECTION', files: [] });
      await asyncPool(5, filesNotInCollection, async (file) => {
        if (!file?.collections?.includes(job.libraryCollection)) {
          updateFileStatus(file, {
            type: 'PENDING',
            code: 'ADDING_FILE_TO_COLLECTION',
          });
          await Library.saveDocument({
            _id: file._id,
            collections: [...(file.collections || []), job.libraryCollection],
          });
          updateFileStatus(file, {
            type: 'PENDING',
            code: 'ADDED_FILE_TO_COLLECTION',
          });
        }
      });
    }

    if (filesModified?.length) {
      updateJobStatus({
        code: 'REPLACING_UPDATED_FILES',
        current,
        total: filesModified?.length,
      });
      await asyncPool(5, filesModified, async (file) => {
        const importedFile = importedReferences?.find(
          (f) => f.import.reference === file.id
        );
        await Library.replaceMedia(
          file.representations.find((item) => item.relationship === 'primary')
            .storage_href,
          importedFile._id,
          'adobeccl',
          file.md5,
          {
            requestHeaders: {
              ...Adobe.getHeaders(),
            },
            approval: !!job.requireApproval,
          }
        );
        updateJobStatus({ current, total: files?.length });
      });
      filesModified = [];
    }

    if (
      !filesModified?.length &&
      !filesNotImported?.length &&
      !filesNotInCollection?.length
    ) {
      updateJobStatus({ code: 'JOB_CHECKED', isLoading: false });
    }

    filesNotImported.map((file) =>
      updateFileStatus(file, { type: 'SUCCESS', code: 'IMPORT_QUEUED' })
    );

    let current = files?.length - filesNotImported?.length;

    if (filesNotImported?.length) {
      updateJobStatus({
        code: 'IMPORTING_FILES',
        current,
        total: files?.length,
      });
    }

    await asyncPool(5, filesNotImported, async (file) => {
      return new Promise(async (resolve, reject) => {
        updateFileStatus(file, {
          type: 'SUCCESS',
          code: 'CHECKING_FILE',
          isLoading: true,
        });
        const mimeType = file.representations.find(
          (item) => item.relationship === 'primary'
        ).type;
        const extension = adobeMimetoExt(mimeType);
        if (!Library.isFileSupported(null, extension)) {
          current += 1;
          updateJobStatus({ current, total: files?.length });
          resolve(true);
          return updateFileStatus(file, {
            type: 'ERROR',
            code: 'FILE_NOT_SUPPORTED',
            isLoading: false,
          });
        }
        updateFileStatus(file, { type: 'SUCCESS', code: 'FILE_VALIDATION_OK' });

        updateFileStatus(file, { type: 'PENDING', code: 'IMPORTING' });
        Library.importMedia(
          file.representations.find((item) => item.relationship === 'primary')
            .storage_href,
          {
            name: `${file.name}.${file.representations[0].name
              .split('.')
              .pop()}`,
            file: {
              ext: extension,
            },
            // file: file.representations.find(item => item.relationship === 'primary'),
            import: {
              from: 'adobeccl',
              reference: file.id,
              referenceLocation: file.parent_id, // Location id
              source: job._id,
            },
            uploadedAt: file.created_date,
            collections: job.libraryCollection
              ? [job.libraryCollection]
              : undefined,
            ...(job.requireApproval
              ? {
                  approval: {
                    status: 'none',
                    status_change_reason: 'imported',
                  },
                }
              : {}),
          },
          null,
          {
            useSocket: true,
            onProcess: (status) => {
              updateFileStatus(file, {
                type:
                  status.status === 'ALREADY_UPLOADED' ? 'ERROR' : 'PENDING',
                ...status,
              });
            },
            onClose: () => {
              current += 1;
              updateJobStatus({ current, total: files?.length });
              updateFileStatus(file, { code: 'UPLOADED', isLoading: false });
              resolve(true);
            },
            onError: () => {
              reject();
            },
          },
          {
            requestHeaders: {
              ...Adobe.getHeaders(),
            },
            useApprovalFlow: job.useApprovalFlow,
          }
        );
      });
    });
    updateJobStatus({
      code: 'JOB_CHECKED',
      isLoading: false,
      isRunning: false,
      isChecked: true,
      current: 0,
      total: 0,
    });
    const updatedJob = await User.request.files.importJobs.saveJob('adobeccl', {
      _id: job._id,
      lastRun: new Date(),
    });
    setJobs(
      jobs.map((job) =>
        job._id === updatedJob._id
          ? {
              ...job,
              ...updatedJob,
              status: {
                code: 'JOB_CHECKED',
                isLoading: false,
                isRunning: false,
                isChecked: true,
                current: 0,
                total: 0,
              },
            }
          : job
      )
    );
  }

  // Use same "replace" function as the job
  async function syncFileChanges(file, onStatus = () => {}) {
    try {
      onStatus({
        code: 'GETTING_FILE',
        message: 'Getting file from Adobe Creative Cloud Library',
      });
      const item = await getElement(
        file.import.referenceLocation,
        file.import.reference,
        'representations'
      );
      if (item?.status === 404) {
        throw new Error();
      }
      let Library;
      if (item.name) {
        const mimeType = item.representations.find(
          (item) => item.relationship === 'primary'
        ).type;
        const extension = adobeMimetoExt(mimeType);

        if (Media.isFileSupported(null, extension.toLowerCase())) {
          Library = Media;
        }
        if (Documents.isFileSupported(null, extension.toLowerCase())) {
          Library = Documents;
        }
      }
      const hasChanges =
        new Date(file.file.uploaded_at).getTime() <
        new Date(item.modified_date).getTime();
      if (hasChanges) {
        onStatus({
          code: 'CHANGES_DETECTED',
          message: 'File has been changed. Uploading...',
        });
        if (!file?.file?.external_file) {
          await Library.replaceMedia(
            item.representations.find((item) => item.relationship === 'primary')
              .storage_href,
            file._id,
            'adobeccl',
            item.md5,
            {
              requestHeaders: {
                ...Adobe.getHeaders(),
              },
            }
          );
        }
        onStatus({
          code: 'FILE_UPDATED',
          message: 'File updated',
        });
      } else {
        onStatus({ code: 'NO_CHANGES', message: 'No changes' });
      }
    } catch {
      onStatus({
        type: 'ERROR',
        code: 'NO_PERMISSION',
        message: 'No access to this file',
      });
    }
  }

  /**
   *
   */
  async function getAllElementsFromLibrary(
    libraryId,
    page,
    selector,
    orderBy,
    limit
  ) {
    let result = await getLibraryElements(
      libraryId,
      undefined,
      page,
      selector,
      orderBy,
      limit
    );
    await Media.importGetFiles('adobeccl');
    let nextRef = result?._links?.next?.href;
    let elements = [...result.elements];
    while (nextRef) {
      result = await getLibraryElements(undefined, nextRef);

      elements = elements.concat(result.elements);
      nextRef = result?._links?.next?.href;
    }
    return elements;
  }

  /**
   *
   */
  async function getAllLibraries() {
    let result = await getLibraries(undefined, undefined);

    let nextRef = result?._links?.next?.href;
    let libraries = [...result.libraries];
    while (nextRef) {
      result = await getLibraries(undefined, nextRef);
      libraries = libraries.concat(result.libraries);
      nextRef = result?._links?.next?.href;
    }
    let result2 = await getLibraries(undefined, undefined, 'public');
    nextRef = result2?._links?.next?.href;
    libraries = libraries.concat(result2.libraries ?? []);

    while (nextRef) {
      result2 = await getLibraries(undefined, nextRef, 'public');
      libraries = libraries.concat(result2.libraries);
      nextRef = result2?._links?.next?.href;
    }

    return libraries;
  }

  /**
   *https://www.adobe.io/creative-cloud-libraries/docs/api/#operation/getLibraryElement
   */
  async function getElement(libraryId, elementId, selector) {
    const params = [];

    if (selector) {
      params.push(`selector=${selector}`);
    }

    const constructedParams = params.length > 0 ? `?${params.join('&')}` : '';
    const resp = await Adobe.request(
      `/adobe/api/v1/libraries/${libraryId}/elements/${elementId}${constructedParams}`
    );
    return resp;
  }
  /**
   *https://www.adobe.io/creative-cloud-libraries/docs/api/#operation/getLibraryElement
   */
  async function getImage() {
    const resp = await Adobe.request('/adobe/getImage');
    return resp;
  }
  /**
   * https://www.adobe.io/creative-cloud-libraries/docs/api/#operation/getLibraryElements
   */
  async function getLibraryElements(
    libraryId,
    nextHref,
    page,
    selector,
    orderBy,
    limit
  ) {
    const params = [];
    if (page) {
      params.push(`start=${page}`);
    }
    if (selector) {
      params.push(`selector=${selector}`);
    }
    if (orderBy) {
      params.push(`orderBy=${orderBy}`);
    }
    if (limit) {
      params.push(`limit=${limit}`);
    }
    const constructedParams = params.length > 0 ? `?${params.join('&')}` : '';
    const resp = await Adobe.request(
      nextHref
        ? `/adobe${nextHref}`
        : `/adobe/api/v1/libraries/${libraryId}/elements${constructedParams}`
    );
    return resp;
  }
  /**
   *  https://www.adobe.io/creative-cloud-libraries/docs/api/#operation/getLibraries
   */
  async function getLibraries(start, nextHref, owner) {
    const params = [];
    if (start) {
      params.push(`start=${start}`);
    }

    if (owner) {
      params.push(`owner=${owner}`);
    }
    const constructedParams = params.length > 0 ? `?${params.join('&')}` : '';
    const resp = await Adobe.request(
      nextHref
        ? `/adobe${nextHref}`
        : `/adobe/api/v1/libraries${constructedParams}`
    );
    return resp;
  }
  /**
   * https://www.adobe.io/creative-cloud-libraries/docs/api/#operation/getLibrary
   */
  async function getLibrary(libraryId, selector) {
    const params = [];

    if (selector) {
      params.push(`selector=${selector}`);
    }
    const constructedParams = params.length > 0 ? `?${params.join('&')}` : '';

    const resp = await Adobe.request(
      `/adobe/api/v1/libraries/${libraryId}${constructedParams}`
    );
    return resp;
  }

  async function importFolder(item, prefs) {
    if (
      jobs.find(
        (job) => job.itemId === item.id && job.library === prefs.library
      )
    ) {
      return jobs.find(
        (job) => job.itemId === item.id && job.library === prefs.library
      );
    }
    const metadata = await getLibrary(item);
    const job = await User.request.files.importJobs.saveJob('adobeccl', {
      itemId: item,
      library: prefs.library,
      libraryCollection: prefs.libraryCollection,
      occurence: prefs.occurence,
      requireApproval: prefs.requireApproval,
      useApprovalFlow: prefs.useApprovalFlow,
    });
    setJobs([
      ...jobs,
      {
        ...job,
        metadata,
      },
    ]);
  }

  const translations = {
    FETCHING_DRIVE: 'Fetching Adobe Creative Cloud Library',
    FETCHING_ALL_FILES:
      'Fetching all files from your Adobe Creative Cloud Library',
    CHECKING_ALREADY_IMPORTED: 'Checking for any file changes',
    REPLACING_UPDATED_FILES: 'Replacing changed files',
    CONNECTED: 'Starting upload',
    UPLOADING: 'Uploading file',
    PROCESSING: 'Processing file',
    AI: 'Processing file (AI)',
    JOB_CHECKED: 'All files imported',
    IMPORTING_FILES: 'Uploading files',
    ADDING_FILES_TO_COLLECTION: 'Adding already uploaded files to collection',
    ERROR_COLLECTION_DELETED: 'Sync failed. Collection has been deleted.',
    NO_PERMISSION: 'No access to this library',
  };

  function translateCode(code) {
    if (translations[code]) {
      return translations[code];
    }
    return code;
  }

  return {
    getElement,
    getLibraryElements,
    getAllElementsFromLibrary,
    getLibraries,
    getAllLibraries,
    getLibrary,
    syncFileChanges,
    translateCode,

    getImage,

    getImportedFiles,
    getJobs,

    saveJob: async (updates, clearStatus) => {
      const newJob = await User.request.files.importJobs.saveJob(
        'adobeccl',
        updates
      );
      setJobs(
        jobs.map((job) =>
          job._id === newJob._id
            ? { ...job, ...newJob, status: clearStatus ? null : job.status }
            : job
        )
      );
      return newJob;
    },

    deleteJob: async (jobId, prefs) => {
      await User.request.files.importJobs.deleteJob(jobId, prefs);
      if (prefs.deleteAllSyncedFiles) {
        Documents.setData({
          type: 'replace',
          store: 'files',
          data: Documents.data.files.filter(
            (file) => file?.import?.source !== jobId
          ),
        });
        Media.setData({
          type: 'replace',
          store: 'files',
          data: Media.data.files.filter(
            (file) => file?.import?.source !== jobId
          ),
        });
      }

      setJobs(jobs.filter((job) => job._id !== jobId));
    },

    getImportedReferences: async (items) => {
      const libraries = {
        media: [],
        documents: [],
      };
      items.forEach((item) => {
        if (item.name && !item.folder) {
          const extension =
            item.fileExtension || googleMimetoExt(item.mimeType);

          if (Media.isFileSupported(null, extension?.toLowerCase())) {
            libraries.media.push(item.id);
          }
          if (Documents.isFileSupported(null, extension?.toLowerCase())) {
            libraries.documents.push(item.id);
          }
        }
      });
      const mediaFiles = await Media.importCheckReferences(
        'adobeccl',
        libraries.media
      );
      const documentFiles = await Documents.importCheckReferences(
        'adobeccl',
        libraries.documents
      );
      return [...mediaFiles, ...documentFiles];
    },

    jobs,
    queueJob,
    getToken,
    jobQueue,

    importFolder,
    importedFiles: [
      ...(Media.data?.['imported_refs_google-drive'] || []),
      ...(Documents.data?.['imported_refs_google-drive'] || []),
    ],
  };
}
