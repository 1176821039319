import { Group } from '@mantine/core';
import { AppConfigInterface } from '@pixi/AppController';
import PixiButton from '@pixi/elements/Button';
import PixiPopup, { PixiPopupProps } from '@pixi/elements/Popup';
import {
  getMultiStore,
  getStore,
  useMultiDataStoreCallback,
} from '@pixi/store';
import useContextFromType from 'hooks/useContextFromType';
import { AssetGrid } from './AssetGrid/AssetGrid';
import { AssetGridProps } from './AssetGrid/Helpers';

export default function SelectFile({
  onSelect,
  onClose,
  libraryId,
  assetGridProps,
  multiple,
  selectButtonLabel,
  ...rest
}: {
  onSelect?: (file: Pickit.FileInterface[]) => void;
  onClose?: () => void;
  libraryId?: string;
  assetGridProps?: Partial<AssetGridProps>;
  selectButtonLabel?: AppConfigInterface['selectFile']['selectButtonLabel'];
  multiple?: boolean;
} & Omit<Partial<PixiPopupProps>, 'onSelect'>) {
  const context = useContextFromType(libraryId);
  const selectedFiles = useMultiDataStoreCallback(
    'FILES_SELECTED',
    'selectFileGlobal',
    (data) =>
      data.state
        .filter((row) => !!getStore('FILES').getByKey(row._id))
        .map(
          (row) => getStore('FILES').getByKey(row._id) as Pickit.FileInterface,
        ),
  );
  return (
    <>
      <PixiPopup
        fullHeight
        opened={!!onSelect}
        onClose={() => {
          getMultiStore('FILES_SELECTED', 'selectFileGlobal').replace([]);
          onClose?.();
        }}
        zIndex={15}
        size={1400}
        bodyProps={{
          w: 1400,
          maw: '100%',
          h: '100%',
        }}
        actions={
          <>
            <PixiButton
              size="wide-md"
              disabled={!selectedFiles?.length}
              ml="auto"
              onClick={() => {
                onSelect?.(selectedFiles);
                getMultiStore('FILES_SELECTED', 'selectFileGlobal').replace([]);
                onClose?.();
              }}
            >
              {typeof selectButtonLabel === 'function'
                ? selectButtonLabel(selectedFiles)
                : selectButtonLabel ||
                  (multiple ? 'Select files' : 'Select file')}
            </PixiButton>
          </>
        }
        {...rest}
      >
        <Group w="100%" h="100%" bg="gray.1" pl="xs">
          {context && onSelect && (
            <AssetGrid
              id="selectFileGlobal"
              context={context}
              multiSelect={multiple || false}
              groupBy={null}
              isInPopup
              libraries={libraryId ? [libraryId] : undefined}
              {...assetGridProps}
            />
          )}
        </Group>
      </PixiPopup>
    </>
  );
}
