import { Box, Group } from '@mantine/core';
import PixiButton from '@pixi/elements/Button';
import PixiPopup, { PixiPopupProps } from '@pixi/elements/Popup';
import { AppConfigInterface, openAssetPreview } from '@pixi/AppController';
import { useContextFromCollection } from 'hooks/useContextFromCollection';
import * as React from 'react';
import FilesWithHook from '../../views/Files/parts/FilesWithHook';
import { AssetGrid } from './AssetGrid/AssetGrid';
import { AssetGridProps } from './AssetGrid/Helpers';

const CollectionPreview = ({
  collection,
  onClose,
  zIndex,
  popupProps,
  assetGridProps,
  openAssetPreviewPrefs,
}: {
  collection?: any;
  zIndex?: number;
  onClose?: () => void;
  popupProps?: Partial<PixiPopupProps>;
  assetGridProps?: Partial<AssetGridProps>;
  openAssetPreviewPrefs?: (
    file: Pickit.FileInterface,
  ) => Partial<AppConfigInterface['filePreview']['prefs']>;
}) => {
  const context = useContextFromCollection(collection);
  const [selectedFile, setSelectedFile] = React.useReducer(
    (
      state: null | Pickit.FileInterface,
      action: null | Pickit.FileInterface,
    ) => (!action ? null : action),
    null,
  );

  React.useEffect(() => {
    if (selectedFile?._id) {
      openAssetPreview(selectedFile);
    }
  }, [selectedFile?._id]);

  if (!collection?._id) {
    return <></>;
  }

  return (
    <>
      <PixiPopup
        size={1400}
        fullHeight
        onClose={() => {
          onClose?.();
        }}
        zIndex={zIndex}
        bodyProps={{
          w: 1400,
          maw: '100%',
          h: '100%',
        }}
        title={`Viewing collection ${collection?.name}`}
        actions={
          <Group w="100%">
            <PixiButton
              ml="auto"
              size="wide-lg"
              color="dark"
              variant="glass"
              onClick={() => onClose?.()}
            >
              Done
            </PixiButton>
          </Group>
        }
        opened={!!collection?._id}
        {...popupProps}
      >
        <Group w="100%" h="100%" bg="gray.1" pl="xs">
          <AssetGrid
            id={`PreviewCollection-${collection._id}`}
            context={context}
            disable={['rightPanel']}
            onFileClick={(event, file) => {
              openAssetPreview(file, openAssetPreviewPrefs?.(file));
            }}
            isInPopup
            forceFilters={{
              collections: [{ value: collection._id }],
            }}
            {...assetGridProps}
          />
        </Group>
        {/* <FilesWithHook
          context={context}
          collectionId={collection?._id}
          view="collection"
          includeDownloadBag
          loadMoreEvent="collection_popup_bottom_scroll"
          filters={context.filters.filter(
            (filter: any) => filter.view?.key !== 'collections',
          )}
          onFileRedirect={(file: Pickit.FileInterface) => {
            setSelectedFile(file);
          }}
          popupMode
          goBack={() => {
            context.setGlobalSettings({
              key: 'files_filters',
              data: {
                filters: {},
              },
            });
            onClose?.();
          }}
          setPreviewFile={setSelectedFile}
        /> */}
      </PixiPopup>
    </>
  );
};

export default CollectionPreview;
