import { createContext, useContext } from 'react';
import User from './classes/User';
import { PixiIconName } from './elements/Icon';
import {
  inAdobeAddon,
  inGoogleDocs,
  inOffice,
  inTeams,
  isNative,
} from 'utils/platform';
import Community from './classes/Community';

class Report {
  async sendError(error: Error) {
    const errorData = {
      message: error.message,
      stack: error.stack,
      properties: {
        userAgent: navigator.userAgent,
        url: window.location.href,
        timestamp: new Date().toISOString(),
        userId: User?.rawData?.user?.userId,
        communitySlug: User?.rawData?.selectedCommunity?.communitySlug,
        channel: inOffice()
          ? 'Office Add-in'
          : inTeams()
            ? 'Teams App'
            : inGoogleDocs()
              ? 'Google Editor Add-on'
              : isNative()
                ? 'Pickit Desktop'
                : inAdobeAddon()
                  ? 'Adobe Add-on'
                  : 'Web App',
      },
    };
    const res = await fetch(`${window.pickit.config.MEDIA_URL}/report/error`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ error: errorData }),
    });
    return res.ok;
  }

  logError(e: Error) {
    console.log('SENDING ERROR');
    console.log(e);
    this.sendError(e);
  }
}

export default class System {
  static Report = new Report();

  static User = User;

  static Community: Community;

  static readonly Products: {
    name: string;
    id: string;
    icon: PixiIconName;
  }[] = [
    {
      name: 'Media',
      id: 'media',
      icon: 'image',
    },
    {
      name: 'Documents',
      id: 'documents',
      icon: 'file-lines',
    },
    {
      name: 'Insights',
      id: 'insights',
      icon: 'chart-bar',
    },
    {
      name: 'Image Rights',
      id: 'image_rights',
      icon: 'shield',
    },
    {
      name: 'Guidelines',
      id: 'guidelines',
      icon: 'book',
    },
    {
      name: 'Boards',
      id: 'boards',
      icon: 'layer-group',
    },
    {
      name: 'Family',
      id: 'family',
      icon: 'family',
    },
    {
      name: 'Approval',
      id: 'approval',
      icon: 'shield-check',
    },
    {
      name: 'Brand Assets',
      id: 'brand_assets',
      icon: 'palette',
    },
    {
      name: 'Assets',
      id: 'assets',
      icon: 'hard-drive',
    },
    {
      name: 'License Management',
      id: 'license_management',
      icon: 'shield-check',
    },
  ];
}
