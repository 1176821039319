import useIntegrationHelpers from 'hooks/import/useIntegrationHelpers';
import { useUserContext } from 'hooks/useUserContext';
import { useEffect, useReducer, useState } from 'react';
import localStorageWrapper from 'utils/localStorageWrapper';

export default function useDropboxApi() {
  const userContext = useUserContext();
  const [token, setToken] = useReducer((state, action) => ({ ...action }), {});
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [DROPBOX_ACCOUNT, SET_DROPBOX_ACCOUNT] = useState(null);

  const ALLOWED_IMAGE_EXT = [
    'jpg',
    'jpeg',
    'png',
    'tiff',
    'tif',
    'gif',
    'bmp',
    'cr2',
  ];

  const IntegrationHelpers = useIntegrationHelpers();

  useEffect(() => {
    const token = localStorageWrapper.getItem('userDropboxToken');
    if (token) {
      setToken(JSON.parse(token));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(token)?.length) {
      localStorageWrapper.setItem('userDropboxToken', JSON.stringify(token));
    }
  }, [token]);

  useEffect(() => {
    if (token.access_token) {
      setIsConnected(true);
    }
  }, [token]);

  const search = async (value, cursor, foldersOnly) => {
    const token = await getToken();
    let URL = '';
    let OPTIONS = {};
    if (cursor) {
      URL = 'https://api.dropboxapi.com/2/files/search/continue_v2';
      OPTIONS = {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          cursor,
        }),
      };
    } else {
      const bodyData = {
        query: value,
        options: {
          path: '',
          max_results: 20,
          file_status: 'active',
          filename_only: false,
        },
        match_field_options: { include_highlights: false },
      };
      if (foldersOnly) {
        bodyData.options.file_categories = ['folder'];
      }
      URL = 'https://api.dropboxapi.com/2/files/search_v2';
      OPTIONS = {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      };
    }
    const JSONresult = await fetch(URL, OPTIONS);
    const result = await JSONresult.json();
    if (foldersOnly) {
      return {
        ...result,
        matches: result.matches
          .map((folder) => folder.metadata.metadata)
          .filter((folder) => folder['.tag'] === 'folder')
          .map((item) =>
            IntegrationHelpers.createFileObject(
              item.path_lower,
              item.name,
              null,
              null,
              item.path_display,
              null,
              item.id
            )
          ),
      };
    }
    return result;
  };

  const getAccount = async () => {
    if (DROPBOX_ACCOUNT) return DROPBOX_ACCOUNT;
    const token = await getToken();
    const GET_TMP_LINK_URL =
      'https://api.dropboxapi.com/2/users/get_current_account';
    const OPTIONS = {
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(GET_TMP_LINK_URL, OPTIONS);
    const result = await response.json();
    SET_DROPBOX_ACCOUNT(result);

    return result;
  };

  function http_header_safe_json(v) {
    const charsToEncode = /[\u007f-\uffff]/g;
    return JSON.stringify(v).replace(charsToEncode, function (c) {
      return `\\u${`000${c.charCodeAt(0).toString(16)}`.slice(-4)}`;
    });
  }

  const getRefreshToken = async (code) => {
    const { data: result } =
      await userContext.request.policy.dropboxCodeToToken(
        code,
        `${window.location.origin}/genericoauth.html`
      );
    if (result.error) return;
    const dropbox = {
      accessToken: result.access_token,
      refreshToken: result.refresh_token,
      expires: result.expires_in * 1000 + Date.now(),
    };
    localStorageWrapper.setItem('dropbox', JSON.stringify(dropbox));
    setIsConnected(true);
  };
  async function uploadFile(folderId, file, fileName, replace, root) {
    fileName = fileName
      .replaceAll(/^\s+|\s+$/g, '')
      .replaceAll(
        /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
        ''
      );

    const token = await getToken();
    const fileSize = file.size;
    const simpleUploadLimit = 150 * 1024 * 1024; // 150MB
    const chunkSize = 100 * 1024 * 1024; // 100MB for chunked upload

    // If the file is smaller than 150MB, use the simple upload endpoint
    if (fileSize <= simpleUploadLimit) {
      const headerdata = {
        path: replace
          ? `${folderId.replace(/^\s+|\s+$/g, '')}`
          : `${folderId}/${fileName}`,
        mode: replace ? 'overwrite' : 'add',
        autorename: !replace,
        mute: false,
        strict_conflict: false,
      };

      const OPTIONS = {
        method: 'post',
        headers: {
          'Content-Type': 'application/octet-stream',
          Authorization: `Bearer ${token}`,
          'Dropbox-API-Arg': http_header_safe_json(headerdata),
        },
        body: file,
      };

      const account = await getAccount();
      const hasTeamSpaces =
        account?.root_info['.tag'] === 'team' ||
        account?.root_info?.root_namespace_id !==
          account?.root_info?.home_namespace_id;
      if (hasTeamSpaces) {
        OPTIONS.headers[
          'Dropbox-API-Path-Root'
        ] = `{".tag": "root", "root": "${account.root_info.root_namespace_id}"}`;
      }

      const response = await fetch(
        'https://content.dropboxapi.com/2/files/upload',
        OPTIONS
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to upload: ${errorData.error_summary}`);
      }

      const data = await response.json();
      return data;
    }

    // For files larger than 150MB, use the chunked upload process
    let sessionId = null;
    let offset = 0;

    // Start the upload session
    while (offset < fileSize) {
      const chunk = file.slice(offset, offset + chunkSize);
      const isLastChunk = offset + chunkSize >= fileSize;

      let URL = '';
      let headerdata = {};

      if (offset === 0) {
        // Start the session with the first chunk
        URL = 'https://content.dropboxapi.com/2/files/upload_session/start';
        headerdata = {
          close: false,
        };
      } else if (isLastChunk) {
        // Finish the session with the last chunk
        URL = 'https://content.dropboxapi.com/2/files/upload_session/finish';
        headerdata = {
          cursor: {
            session_id: sessionId,
            offset,
          },
          commit: {
            path: replace
              ? `${folderId.replace(/^\s+|\s+$/g, '')}`
              : `${folderId}/${fileName}`,
            mode: replace ? 'overwrite' : 'add',
            autorename: !replace,
            mute: false,
            strict_conflict: false,
          },
        };
      } else {
        // Append the chunk to the session
        URL = 'https://content.dropboxapi.com/2/files/upload_session/append_v2';
        headerdata = {
          cursor: {
            session_id: sessionId,
            offset,
          },
        };
      }

      const OPTIONS = {
        method: 'post',
        headers: {
          'Content-Type': 'application/octet-stream',
          Authorization: `Bearer ${token}`,
          'Dropbox-API-Arg': http_header_safe_json(headerdata),
        },
        body: chunk,
      };

      const account = await getAccount();
      const hasTeamSpaces =
        account?.root_info['.tag'] === 'team' ||
        account?.root_info?.root_namespace_id !==
          account?.root_info?.home_namespace_id;
      if (hasTeamSpaces) {
        OPTIONS.headers[
          'Dropbox-API-Path-Root'
        ] = `{".tag": "root", "root": "${account.root_info.root_namespace_id}"}`;
      }

      // Wait for the fetch to complete before moving on
      const response = await fetch(URL, OPTIONS);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to upload: ${errorData.error_summary}`);
      }

      const data = await response.json();

      if (offset === 0) {
        // Store the session ID after the first chunk
        sessionId = data.session_id;
      }

      offset += chunkSize;

      if (isLastChunk) {
        // Return the file metadata after finishing the session
        return data;
      }
    }

    throw new Error('File upload did not complete correctly.');
  }

  async function getAllFilesFromFolder(itemId, fetchMax) {
    const token = await getToken();
    let currentCursor = '';
    let result = [];
    let hasMore = true;
    let FIRST_LOOP = true;

    const account = await getAccount();
    const hasTeamSpaces =
      account?.root_info['.tag'] === 'team' ||
      account?.root_info?.root_namespace_id !==
        account?.root_info?.home_namespace_id;

    if (!itemId.includes('.')) {
      const LIMIT = fetchMax ? 2000 : 20;
      while (hasMore && (result.length >= LIMIT || FIRST_LOOP)) {
        FIRST_LOOP = false;
        const URL = currentCursor
          ? 'https://api.dropboxapi.com/2/files/list_folder/continue'
          : 'https://api.dropboxapi.com/2/files/list_folder';

        const OPTIONS = {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: currentCursor
            ? JSON.stringify({ cursor: currentCursor })
            : JSON.stringify({
                path: itemId,
                recursive: false,
                include_media_info: true,
                limit: LIMIT,
                include_deleted: false,
                include_has_explicit_shared_members: false,
                include_mounted_folders: true,
                include_non_downloadable_files: false,
              }),
        };

        if (hasTeamSpaces) {
          OPTIONS.headers[
            'Dropbox-API-Path-Root'
          ] = `{".tag": "root", "root": "${account.root_info.root_namespace_id}"}`;
        }

        const jsonResult = await fetch(URL, OPTIONS);
        const responseResult = await jsonResult.json();
        if (responseResult?.error) {
          if (responseResult.error_summary.includes('not_found')) {
            throw new Error('NO_ACCESS');
          } else {
            console.error({ responseResult });
            throw new Error();
          }
        }
        result = [...result, ...responseResult.entries];
        hasMore = responseResult.has_more;
        currentCursor = responseResult.cursor;
      }
    } else {
      const URL = 'https://api.dropboxapi.com/2/files/get_metadata';
      const OPTIONS = {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          path: itemId,
        }),
      };
      if (hasTeamSpaces) {
        OPTIONS.headers[
          'Dropbox-API-Path-Root'
        ] = `{".tag": "root", "root": "${account.root_info.root_namespace_id}"}`;
      }
      const response = await fetch(URL, OPTIONS);
      const result = await response.json();
      if (result?.error) {
        if (result.error_summary.includes('not_found')) {
          throw new Error('NO_ACCESS');
        } else {
          console.error({ result });
          throw new Error();
        }
      }
      return [result];
    }
    return result.filter((item) => item.name.includes('.'));
  }

  async function getFolderItem(id) {
    const token = await getToken();
    const GET_TMP_LINK_URL = 'https://api.dropboxapi.com/2/files/get_metadata';
    const OPTIONS = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        path: id,
        include_media_info: false,
        include_deleted: false,
        include_has_explicit_shared_members: false,
      }),
    };
    const account = await getAccount();
    const hasTeamSpaces =
      account?.root_info['.tag'] === 'team' ||
      account?.root_info?.root_namespace_id !==
        account?.root_info?.home_namespace_id;
    if (hasTeamSpaces) {
      OPTIONS.headers[
        'Dropbox-API-Path-Root'
      ] = `{".tag": "root", "root": "${account.root_info.root_namespace_id}"}`;
    }
    const linkJSON = await fetch(GET_TMP_LINK_URL, OPTIONS);
    const result = await linkJSON.json();
    return result;
  }

  async function createFolder(id, name) {
    const token = await getToken();
    const GET_TMP_LINK_URL =
      'https://api.dropboxapi.com/2/files/create_folder_v2';
    const OPTIONS = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        path: decodeURI(`${id}/${name}`.replace(/^\s+|\s+$/g, ''))
          .replace(
            /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
            ''
          )
          .trim(),
        autorename: false,
      }),
    };
    const account = await getAccount();
    const hasTeamSpaces =
      account?.root_info['.tag'] === 'team' ||
      account?.root_info?.root_namespace_id !==
        account?.root_info?.home_namespace_id;
    if (hasTeamSpaces) {
      OPTIONS.headers[
        'Dropbox-API-Path-Root'
      ] = `{".tag": "root", "root": "${account.root_info.root_namespace_id}"}`;
    }
    const linkJSON = await fetch(GET_TMP_LINK_URL, OPTIONS);

    const result = await linkJSON.json();
    return result;
  }

  async function renameFolder(id) {
    const token = await getToken();
    const GET_TMP_LINK_URL = 'https://api.dropboxapi.com/2/files/get_metadata';
    const OPTIONS = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        path: id,
        include_media_info: false,
        include_deleted: false,
        include_has_explicit_shared_members: false,
      }),
    };
    const account = await getAccount();
    const hasTeamSpaces =
      account?.root_info['.tag'] === 'team' ||
      account?.root_info?.root_namespace_id !==
        account?.root_info?.home_namespace_id;
    if (hasTeamSpaces) {
      OPTIONS.headers[
        'Dropbox-API-Path-Root'
      ] = `{".tag": "root", "root": "${account.root_info.root_namespace_id}"}`;
    }
    const linkJSON = await fetch(GET_TMP_LINK_URL, OPTIONS);
    const result = await linkJSON.json();
    return result;
  }

  const getTemporaryLink = async (path) => {
    const account = await getAccount();
    const hasTeamSpaces =
      account.root_info['.tag'] === 'team' ||
      account.root_info.root_namespace_id !==
        account.root_info.home_namespace_id;
    const token = await getToken();

    const GET_TMP_LINK_URL =
      'https://api.dropboxapi.com/2/files/get_temporary_link';
    const OPTIONS = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        path,
      }),
    };
    if (hasTeamSpaces) {
      OPTIONS.headers[
        'Dropbox-API-Path-Root'
      ] = `{".tag": "root", "root": "${account.root_info.root_namespace_id}"}`;
    }
    const linkJSON = await fetch(GET_TMP_LINK_URL, OPTIONS);
    const result = await linkJSON.json();
    if (result.error) {
      if (result?.error_summary === 'email_not_verified') {
        throw new Error('NOT_VERIFIED');
      } else {
        console.error({ result });
        throw new Error();
      }
    }
    return result?.link;
  };

  const generateThumbnailQueries = async (images) => {
    const thumbnailQuery = images
      .map((image) => {
        const ext = image.external.name.split('.')[1];
        if (ext && ALLOWED_IMAGE_EXT.includes(ext)) {
          return image;
        }
        return undefined;
      })
      .filter((item) => !!item)
      .map((image) => ({
        path: image.external.path,
        format: 'jpeg',
      }));
    const thumbnailQueries = [[]];
    let index = 0;
    while (thumbnailQuery.length) {
      if (thumbnailQueries[index] < 25) {
        thumbnailQueries[index].push(thumbnailQuery.pop());
      } else {
        index++;
        thumbnailQueries.push([]);
        thumbnailQueries[index].push(thumbnailQuery.pop());
      }
    }
    return thumbnailQueries;
  };

  const getThumbnails = async (files) => {
    const token = await getToken();
    const supportedImages = files.filter(
      (file) => file.external.type !== 'folder' && file.media.supported
    );
    const thumbnailQueries = await generateThumbnailQueries(supportedImages);
    const promises = [];

    const THUMB_URL =
      'https://content.dropboxapi.com/2/files/get_thumbnail_batch';
    const THUMB_OPTIONS = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        entries: thumbnailQueries.flat(),
      }),
    };
    promises.push(fetch(THUMB_URL, THUMB_OPTIONS));

    const thumbnailsJSON = await Promise.all(promises);
    const thumbnailsAll = await Promise.all(
      thumbnailsJSON.map((res) => res.clone().json())
    );
    return thumbnailsAll
      .flat()
      .map((all) => all.entries)
      .flat();
  };

  const getToken = async () => {
    if (JSON.parse(localStorageWrapper.getItem('dropbox'))) {
      const { expires, refreshToken, accessToken } = JSON.parse(
        localStorageWrapper.getItem('dropbox')
      );
      const isExpired = Date.now() > expires;
      if (isExpired) {
        const { data: result } =
          await userContext.request.policy.dropboxRefreshToken(refreshToken);
        const dropbox = {
          accessToken: result.access_token,
          refreshToken,
          expires: result.expires_in * 1000 + Date.now(),
        };
        if (JSON.parse(localStorageWrapper.getItem('dropbox'))) {
          localStorageWrapper.setItem('dropbox', JSON.stringify(dropbox));
        }
        return result.access_token;
      }
      return accessToken;
    }
  };

  const getFiles = async (path, cursor, teamSpace, account, fetchMax) => {
    const token = await getToken();
    const URL = cursor
      ? 'https://api.dropboxapi.com/2/files/list_folder/continue'
      : 'https://api.dropboxapi.com/2/files/list_folder';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    if (teamSpace) {
      headers[
        'Dropbox-API-Path-Root'
      ] = `{".tag": "root", "root": "${account.root_info.root_namespace_id}"}`;
    }

    const OPTIONS = {
      method: 'post',
      headers,
      body: cursor
        ? JSON.stringify({ cursor })
        : JSON.stringify({
            path,
            recursive: false,
            include_media_info: true,
            limit: fetchMax ? 2000 : 20,
            include_deleted: false,
            include_has_explicit_shared_members: false,
            include_mounted_folders: true,
            include_non_downloadable_files: false,
          }),
    };
    const jsonResult = await fetch(URL, OPTIONS);
    return await jsonResult.json();
  };

  const deleteItem = async (path) => {
    const account = await getAccount();

    const token = await getToken();
    const URL = 'https://api.dropboxapi.com/2/files/delete_v2';
    const OPTIONS = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ path }),
    };
    const hasTeamSpaces =
      account?.root_info['.tag'] === 'team' ||
      account?.root_info?.root_namespace_id !==
        account?.root_info?.home_namespace_id;
    if (hasTeamSpaces) {
      OPTIONS.headers[
        'Dropbox-API-Path-Root'
      ] = `{".tag": "root", "root": "${account.root_info.root_namespace_id}"}`;
    }
    const jsonResult = await fetch(URL, OPTIONS);
    return await jsonResult.json();
  };

  function disconnect() {
    setIsConnected(false);
    localStorageWrapper.removeItem('userDropboxToken');
    localStorageWrapper.removeItem('dropbox');
    window.location.reload();
  }

  return {
    token,
    isConnected,
    getFiles,
    getThumbnails,
    setIsConnected,
    search,
    uploadFile,
    getFolderItem,
    getRefreshToken,
    getAccount,
    renameFolder,
    createFolder,
    getTemporaryLink,
    isScopeConnected: (scope) => token?.scope?.includes(scope),
    isConnecting,
    disconnect,
    deleteItem,
    getAllFilesFromFolder,
  };
}
