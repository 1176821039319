import React, { useEffect, useState } from 'react';
import { useUserContext } from 'hooks';
import Tags from 'elements_v2/Tags';
// import "./style.scss";

export default function SelectUserGroups(props) {
  const [isSearching, setIsSearching] = React.useState(false);
  const [groups, setGroups] = React.useState([]);
  const [query, setQuery] = useState(undefined);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const User = useUserContext();

  useEffect(() => {
    getGroups();
  }, [props.fromCommunity]);

  async function getGroups() {
    setIsSearching(true);
    try {
      const groups = await User.request.proxy.getGroups(props.fromCommunity);
      setGroups(groups.data);
      setIsSearching(false);
    } catch (e) {
      setIsAtEnd(true);
    }
  }
  const useGroups =
    groups
      .filter(
        (group) =>
          !query ||
          (group.name &&
            group.name.toLowerCase().includes(query?.toLowerCase() || ''))
      )
      ?.filter((group) => !props.value?.includes(group.slug))
      ?.map((group) => {
        return {
          label: group?.name,
          value: group.slug,
          text: <>{group?.name}</>,
        };
      }) || [];
  return (
    <>
      <Tags
        onRemove={(tag) =>
          props.value.includes(tag)
            ? props.onChange(
                props.value.filter((t) => t !== tag),
                tag
              )
            : props.onChange([...props.value, tag], tag)
        }
        isDeletingDisabled={props.disabled}
        isAddingDisabled={props.disableAdding}
        inputType="text"
        value={props.value}
        placeholder={props.placeholder ?? 'Select groups'}
        tagLabel={(tag) => {
          return groups.find((group) => group.slug === tag)?.name;
        }}
        isDropdownOnly
        pixi
        style={props.style}
        onInputValue={(value) => {
          setQuery(value);
        }}
        dropdownProps={{
          disabled: props.disabled,
          useV2: true,
          onChange: (users) => {
            props.onChange(users);
          },
          isLoadingOptions: isSearching,
          onBottomScroll: () => {
            if (!isAtEnd) {
              getGroups(query, false);
            }
          },
          width: 400,
          value: props.value,
          zIndex: 9999999,
          multiple: true,
          keepOpen: true,
          options:
            useGroups.length === 0
              ? [
                  {
                    label: 'No result',
                    value: 'N/A',
                    text: 'No groups found',
                    disabled: true,
                  },
                ]
              : useGroups,
        }}
      />
    </>
  );
}
