import { useEffect } from 'react';
import { useUserContext } from 'hooks';
import { getStore, useDataStoreCallback } from '@pixi/store';
import System from '@pixi/System';

let pendingRequest: Promise<Response> | null = null;
let idAccumulator: string[] = [];
let batchTimeout: NodeJS.Timeout | null = null;

export const getFoldersBatched = (User: any, value: string) => {
  // Convert value to an array if it's not already one
  const values = Array.isArray(value) ? value : [value];

  // Add new values to the accumulator
  idAccumulator = [...new Set([...idAccumulator, ...values])];

  // Clear existing timeout if it exists
  if (batchTimeout && !pendingRequest) {
    clearTimeout(batchTimeout);
  }

  // Create a new promise if one doesn't already exist
  if (!pendingRequest) {
    pendingRequest = new Promise((resolve, reject) => {
      batchTimeout = setTimeout(async () => {
        try {
          const folders =
            await User.request.files.folders.getFoldersByArrayIds(
              idAccumulator,
            );
          getStore('FOLDERS').addOrUpdate(folders.data);
          resolve(folders.data);
        } catch (e) {
          System.Report.logError(e as Error);
          reject(e);
        } finally {
          // Reset the accumulator and pendingRequest for the next batch
          idAccumulator = [];
          pendingRequest = null;
        }
      }, 200); // Use a shorter delay if needed to batch more aggressively
    });
  }

  return pendingRequest;
};

export function FolderIdToName({ id }: { id: string }) {
  const User = useUserContext();

  const folder = useDataStoreCallback(
    'FOLDERS',
    (data) => {
      return data.find((folder) => folder._id === id);
    },
    [id],
  );

  useEffect(() => {
    if (id && !folder?._id) {
      getFoldersBatched(User, id);
    }
  }, [id, User, folder]);

  return <>{folder?.name || ''}</>;
}
