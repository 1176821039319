import { useEffect, useRef, useState } from 'react';

export default function useFullViewportHeight() {
  const ref = useRef<HTMLDivElement>(null); // Reference to the component's root element

  const [dynamicHeight, setDynamicHeight] = useState<string | number>('100vh');
  useEffect(() => {
    const rightPanel = ref.current;
    if (!rightPanel) return;

    const scrollableParent = rightPanel.closest('.pixi-ScrollArea-viewport');
    if (!scrollableParent) return;

    const handleScroll = () => {
      if (!rightPanel) return;
      const viewportHeight = window.innerHeight;
      const scrollableParentBounding = scrollableParent.getBoundingClientRect();
      const topPos = rightPanel.getBoundingClientRect().top;
      const newHeight = viewportHeight - topPos; // adjust height to cover from top of the panel to the bottom of the viewport
      setDynamicHeight(
        newHeight > scrollableParentBounding.height
          ? scrollableParentBounding.height
          : newHeight,
      );
    };

    // Initial height adjustment when the component mounts
    handleScroll();

    scrollableParent.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll); // Handle window resizing

    return () => {
      scrollableParent.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [ref]);

  return {
    height: dynamicHeight,
    ref,
  };
}
